<template>
  <CardLayout
    v-model="tableState.search"
    title="Incident History"
    header-type="search"
  >
    <template #header-actions>
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['1m', '2m', '3m']"
        default="1m"
        max-range="6m"
        custom
        inverted
        class="mr-4"
      />
      <IncidentFilter class="mr-4" @apply="applyFilter" />
    </template>

    <v-skeleton-loader
      :loading="incidentsLoading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :headers="headers"
        :items="incidents"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView @click="gotoIncident(item.id)" />
        </template>

        <template #item.incidentType="{ item }">
          <IconIncidentType :incident="item" class="mr-2" />
          {{ item.incidentType }}
        </template>

        <template #item.device_location="{ item }">
          <div v-if="item?.devices[0]?.location">
            {{ item?.devices[0]?.location }}
          </div>
          <div v-else class="grey--text">N/A</div>
        </template>

        <template #item.startedAt="{ item }">
          {{ facilityFormatDateTime4Humans(item.startedAt) }}
        </template>

        <template #item.duration="{ item }">
          {{ durationInWords(item.startedAt, item.endedAt) }}
        </template>

        <template #item.statusText="{ item }">
          <IconIncidentStatus left small :incident="item" />
        </template>

        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="incidents"
            :headers="headers"
            filename="SD_Incident_History"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </CardLayout>
</template>

<script>
import IncidentFilter from "./IncidentFilter.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import ButtonView from "@atoms/ButtonView.vue";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { INCIDENT_HISTORY } from "./graphql";
import { diff, durationInWords } from "@tod-ui/helpers/datetime";
import { incidentStatus, formatIncidentType } from "../common/helpers/incident";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useDataTable from "../common/useDataTable";
import useFacility from "../authentication/useFacility";
import { stringSortCollator } from "@tod-ui/helpers/strings";

export default {
  name: "IncidentHistory",
  components: {
    IncidentFilter,
    CardLayout,
    IconIncidentStatus,
    ButtonView,
    IconIncidentType,
    ButtonPrint,
    ButtonExportCSV,
    DateTimeFilter,
  },
  setup() {
    const {
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
    } = useFacility();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["startedAt"],
      sortDesc: [true],
    });
    return {
      currentFacilityId,
      facilityFeatureEnabled,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      facilityFormatDateTime,
      tableState,
      showAllItems,
      restoreItemsPerPage,
    };
  },
  data: () => ({
    dateRange: { after: undefined },
    incidents: [],
    filter: {},
  }),
  computed: {
    incidentsLoading() {
      return this.$apollo.queries.incidents.loading;
    },
    headers() {
      const formatDateTime = this.facilityFormatDateTime;
      const zoneHeader = this.facilityFeatureEnabled("smart_zones")
        ? "Smart Unit/Zone"
        : "Smart Unit #";
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          class: "action-column",
          cellClass: "action-column",
          value: "actions",
          width: "1%",
        },
        { text: zoneHeader, value: "zoneName" },
        { text: "Type", value: "incidentType" },
        { text: "Device Location", value: "device_location" },
        { text: "Renter", value: "responder" },
        {
          text: "Started At",
          value: "startedAt",
          toString: formatDateTime,
        },
        {
          text: "Duration",
          value: "duration",
          toString: (duration) => (duration < 1 ? 1 : Math.round(duration)),
        },
        { text: "Status", value: "statusText" },
      ];
    },
  },
  apollo: {
    incidents: {
      query: INCIDENT_HISTORY,
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: this.dateRange,
          ...this.filter,
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ incidents }) {
        return incidents.map((incident) => {
          const responder = incident.responders?.length
            ? // sort responders by escalation level asc
              incident.responders.sort(
                (a, b) => a.escalationLevel - b.escalationLevel
              )[0]
            : "--";

          const endedAt =
            incident.lastUntriggeredAt ||
            incident.closedAt ||
            incident.resolvedAt;
          return {
            ...incident,
            zoneName: incident.organization.name,
            incidentType: formatIncidentType(incident),
            responder: formatResponder(responder),
            startedAt: incident.startedAt,
            endedAt,
            duration: diff(incident.startedAt, endedAt).as("minutes"),
            statusText: incidentStatus(incident),
          };
        });
      },
    },
  },
  methods: {
    applyFilter(filter) {
      // translate to incident query args here
      const _filter = { ...filter };

      _filter.organizationSubtypes = _filter.locations;
      delete _filter.locations;

      if (_filter.statuses?.length) {
        _filter.resolutions = [];

        if (_filter.statuses.includes("authorized")) {
          _filter.resolutions.push("claimed");
        }

        if (_filter.statuses.includes("unauthorized")) {
          _filter.resolutions.push("denied");
          _filter.resolutions.push("surveyed");
        }

        if (_filter.statuses.includes("unacknowledged")) {
          _filter.resolutions.push("unexplained");
        }
      }
      delete _filter.statuses;

      // ignore empty/null filters
      for (let k in _filter) {
        if (_filter[k] === null) {
          delete _filter[k];
        } else if (typeof _filter[k] === "boolean" && !_filter[k]) {
          delete _filter[k];
        } else if (Array.isArray(_filter[k]) && !_filter[k].length) {
          delete _filter[k];
        }
      }

      if (_filter.afterHours) {
        _filter["duringAccessHours"] =
          _filter.afterHours === "after-hours" ? false : undefined;
      }

      this.filter = _filter;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      const _search = search.toLowerCase();
      const match = [
        item.zoneName.toLowerCase(),
        item.type,
        item.responder.toLowerCase(),
        this.facilityFormatDateTime4Humans(item.startedAt),
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (_sortBy === "statusText") {
          // TODO: Give weights to status and resolutions to determine order
          const aStatus = `${a.status} ${a.resolution}`;
          const bStatus = `${b.status} ${b.resolution}`;

          return (bStatus < aStatus ? -1 : 1) * _sortDesc;
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    gotoIncident(incidentId) {
      this.$router.push({
        name: "Incident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    durationInWords,
  },
};

function formatResponder(responder) {
  if (!responder?.name) return "--";
  if (!responder?.contactMethod?.value) return responder.name;
  return `${responder.name} @ ${formatPhoneNumber(
    responder.contactMethod.value
  )}`;
}
</script>
