<template>
  <LayoutPage title="Contact Us">
    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon color="blue lighten-3"> fas fa-globe </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a href="https://storage-defender.com/contact-us" target="_new">
              Contact {{ storageDefender.name }} Today!
            </a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="blue lighten-3"> fas fa-envelope </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            <a :href="`mailto:${storageDefender.profile.email}`">
              {{ storageDefender.profile.email }}
            </a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon class="ml-1" color="blue lighten-3">
            fas fa-mobile-alt
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ phoneNumber }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";

export default {
  name: "ContactUs",
  components: { LayoutPage },
  data: () => ({
    applications: {},
  }),
  computed: {
    storageDefender() {
      // if (this.applications.length > 0) {
      //   return this.applications[0];
      // }
      // return null;
      return {
        name: "StorageDefender",
        profile: {
          email: "support@storage-defender.com",
        },
      };
    },
    phoneNumber() {
      return "(877) 533-3363 Ext: 1";
      // if (this.storageDefender.profile.extension != null) {
      //   return (
      //     formatPhoneNumber(this.storageDefender.profile.phoneNumber) +
      //     " Ext: " +
      //     this.storageDefender.profile.extension
      //   );
      // } else {
      //   return formatPhoneNumber(this.storageDefender.profile.phoneNumber);
      // }
    },
  },
  apollo: {
    // applications: {
    //   query: gql`
    //     query {
    //       applications: organizations(
    //         searchText: "StorageDefender"
    //         type: "application"
    //       ) {
    //         name
    //         profile {
    //           ... on DefaultProfile {
    //             email
    //             phoneNumber
    //             extension
    //           }
    //           ... on FacilityProfile {
    //             email
    //             phoneNumber
    //             extension
    //           }
    //         }
    //       }
    //     }
    //   `,
    // },
  },
};
</script>

<style lang="scss" scoped>
.v-list--dense {
  .v-list-item__title {
    font-size: 1rem;
  }
}
</style>
