<template>
  <CardLayout
    v-model="inventorySearch"
    title="Available Device Inventory"
    header-type="search"
  >
    <v-simple-table>
      <thead>
        <tr>
          <th>Device #</th>
          <th>Device Health</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="devicesLoading">
        <tr>
          <td colspan="3">
            <em>Loading devices from inventory...</em>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="organizationDevices.length > 0">
        <tr v-for="device in organizationDevices" :key="device.id">
          <td style="width: 40%; white-space: nowrap">
            {{ device.shortId || device.externalId }}
          </td>
          <td style="width: 40%; white-space: nowrap">
            <DeviceGaugeCluster :device="device" />
          </td>
          <td style="white-space: nowrap">
            <v-btn
              :disabled="device.status !== 'ok'"
              depressed
              small
              tile
              color="success"
              @click="addDevice(device)"
              >Assign Device</v-btn
            >
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <em>No devices are available</em>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </CardLayout>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import CardLayout from "@layout/CardLayout.vue";

import { addOrganizationDevices } from "../../graphql/organizations";
import { ORGANIZATION_DEVICES } from "./graphql";
import { organization } from "../../graphql/organizations/zone";
import useFacility from "../authentication/useFacility";

export default {
  name: "CardDeviceInventory",
  components: { CardLayout, DeviceGaugeCluster },
  props: {
    zoneId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    organizationDevices: {},
    inventorySearch: "",
  }),
  computed: {
    devicesLoading() {
      return this.$apollo.queries.organizationDevices.loading;
    },
  },
  apollo: {
    organizationDevices: {
      query: ORGANIZATION_DEVICES,
      variables() {
        return {
          id: this.currentFacilityId,
          searchText: this.inventorySearch,
          status: "ok",
          dispositions: ["in_service"],
        };
      },
      update({ organizationDevices }) {
        return organizationDevices
          .filter((device) => !device.rmaRequest)
          .sort((a, b) =>
            (a.shortId || a.externalId) > (b.shortId || b.externalId) ? 1 : -1
          );
      },
    },
  },
  methods: {
    async addDevice({ id }) {
      try {
        await this.$apollo.mutate({
          mutation: addOrganizationDevices,
          variables: {
            id: this.zoneId,
            devices: [id],
          },
          refetchQueries: this.zoneRefetchQueries,
        });
      } catch (error) {
        console.error(error);
      }
    },
    zoneRefetchQueries() {
      return [
        {
          query: organization,
          variables: { id: this.zoneId },
        },
        {
          query: ORGANIZATION_DEVICES,
          variables: {
            id: this.currentFacilityId,
            searchText: this.inventorySearch,
            status: "ok",
            dispositions: ["in_service"],
          },
          update({ organizationDevices }) {
            return organizationDevices.filter((device) => !device.rmaRequest);
          },
        },
      ];
    },
  },
};
</script>
