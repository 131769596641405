<template>
  <span v-if="startsAt && endsAt">{{ startsAt }} - {{ endsAt }}</span>
  <span v-else>{{ startsAt || endsAt }}</span>
</template>

<script>
import { formatTime12hr } from "../helpers/datetime";

export default {
  name: "TimeRange",
  props: {
    scheduleWindow: {
      validator: (val) =>
        typeof val === "object" &&
        val.hasOwnProperty &&
        val.hasOwnProperty("startsAt") &&
        val.hasOwnProperty("endsAt") &&
        val.hasOwnProperty("schedule"),
      required: true,
    },
  },
  computed: {
    startsAt() {
      return formatTime12hr(this.scheduleWindow.startsAt);
    },
    endsAt() {
      return formatTime12hr(this.scheduleWindow.endsAt);
    },
  },
};
</script>
