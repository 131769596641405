import { titleCase } from "./strings";

export function billingAccountStatusText(statusValue) {
  if (!statusValue) return "";
  switch (statusValue) {
    case "disabled_maintenance":
      return "Disabled for Maintenance";
    case "disabled_device_upgrade":
      return "Disabled for Device Upgrade";
    case "disabled_lien":
      return "Disabled due to Non-Payment";
    default:
      return titleCase(statusValue);
  }
}
