export function celsiusToFahrenheit(celsius) {
  const value = Number(celsius);
  if (!value) return value;
  return value * 1.8 + 32;
}

export function fahrenheitToCelsius(fahrenheit) {
  const value = Number(fahrenheit);
  if (!value) return value;
  return (value - 32) * (5 / 9);
}
