<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },

      legend: {
        position: "top",
      },
      onClick: this.handleClick, // Registering click event handler
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (items.length > 0) {
        const clickedData = this.chartData.labels[items[0]._index];
        console.log(clickedData);
        let status = "";
        if (clickedData === "Units Uncleaned Within 7 Days") {
          status = "7,yes";
        } else if (clickedData === "Units Cleaned Within 7 Days") {
          status = "7,no";
        } else if (clickedData === "Vacant Not Visited Within 30 Days") {
          status = "30,yes";
        } else if (clickedData === "Unit Visited Within 30 Days") {
          status = "30,no";
        }
        if (clickedData.toLowerCase().includes("within")) {
          this.$router.push({
            name: "VacantUnitUncleanScoreTable",
            params: { status },
          });
        }
      }
    },
  },
};
</script>
