<template>
  <validation-provider
    v-slot="{ errors, required: validationRequired }"
    tag="div"
    class="d-flex align-center"
    :rules="rules"
    mode="eager"
    :name="name"
  >
    <v-text-field
      type="tel"
      :label="name"
      :error-messages="errors"
      :required="validationRequired"
      :value="phoneNumber"
      v-bind="$attrs"
      @input="updateNumber"
      @blur="emitChange"
    >
      <template #label>
        {{ name }}
        <span v-if="validationRequired" class="required">*</span>
      </template>
    </v-text-field>
    <ContactMethodWarning
      v-if="showFailing && isFailing"
      :contact-method="value"
      :contact-log-time-zone="contactLogTimeZone"
    />
  </validation-provider>
</template>

<script>
import { computed, ref, unref, watch } from "vue";
import { ValidationProvider, extend } from "vee-validate";
import { formatPhoneNumber, isValidNumber } from "../helpers/phone";
import ContactMethodWarning from "./ContactMethodWarning.vue";
import { required } from "vee-validate/dist/rules";

extend("phonenumber", {
  message: '"{_value_}" is not a valid phone number',
  validate: (value) => isValidNumber(value),
});

extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "InputPhoneNumber",
  components: {
    ValidationProvider,
    ContactMethodWarning,
  },
  props: {
    name: {
      type: String,
      default: "Primary Mobile Phone",
    },
    required: {
      type: Boolean,
      default: false,
    },
    contactLogTimeZone: {
      type: String,
      default: "America/Chicago",
    },
    showFailing: {
      type: Boolean,
      default: false,
    },
    value: {
      validator: (val) =>
        val === null || typeof val === "string" || val.hasOwnProperty("value"),
      default: "",
    },
  },
  setup(props, { emit }) {
    const isContactMethod = computed(() => {
      const model = unref(props.value);
      return (
        model !== null &&
        typeof model !== "string" &&
        model.hasOwnProperty("value")
      );
    });
    // coming from API should be E.164 formatted
    const initialPhoneNumber = computed(() => {
      const model = unref(props.value);
      return isContactMethod.value ? model.value : model;
    });

    // initially format in default "national" for now
    const phoneNumber = ref(formatPhoneNumber(initialPhoneNumber.value));
    const normalizedPhoneNumber = computed(() =>
      formatPhoneNumber(phoneNumber.value, true)
    );
    watch(initialPhoneNumber, (val) => {
      if (normalizedPhoneNumber.value !== val) {
        phoneNumber.value = formatPhoneNumber(val);
      }
    });

    const updateNumber = (val) => {
      phoneNumber.value = formatPhoneNumber(val);
    };

    const emitChange = () => {
      const updatedValue = isContactMethod.value
        ? {
            ...props.value,
            value: normalizedPhoneNumber.value,
            isActive:
              phoneNumber.value === initialPhoneNumber.value
                ? props.value?.isActive
                : undefined,
          }
        : phoneNumber.value;
      emit("input", updatedValue);
    };

    const isFailing = computed(
      () =>
        normalizedPhoneNumber.value === initialPhoneNumber.value &&
        props.value?.isActive === false
    );

    const rules = computed(
      () => `${props.required ? "required|" : ""}phonenumber`
    );
    return {
      phoneNumber,
      isFailing,
      rules,
      updateNumber,
      emitChange,
      isContactMethod,
      initialPhoneNumber,
    };
  },
};
</script>
