<template>
  <v-icon small :color="color" :title="_title" v-bind="$attrs">{{
    icon
  }}</v-icon>
</template>

<script>
import { userDisplayName } from "./helper";
import { billingAccountStatusText } from "@tod-ui/helpers/billingAccount";
import { isTimeAfterNow } from "@tod-ui/helpers/datetime";

export default {
  name: "UserIcon",
  props: {
    billingAccount: {
      validator: (val) =>
        typeof val === "object" && val.hasOwnProperty("status"),
      default: null,
    },
    responder: {
      validator: (val) =>
        !val || (typeof val === "object" && val.hasOwnProperty("status")),
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    status() {
      return this.billingAccount
        ? this.billingAccount.status
        : this.responder?.status;
    },
    isActiveDemo() {
      return (
        this.responder &&
        this.responder.demoEndingAt !== null &&
        isTimeAfterNow(this.responder.demoEndingAt)
      );
    },
    icon() {
      if (this.isActiveDemo) {
        return "fas fa-user-clock";
      }
      switch (this.status) {
        case "disabled_maintenance":
        case "disabled_device_upgrade":
          return "fas fa-user-slash";
        case "disabled_lien":
          return "fas fa-user-lock";
        default:
          return "fas fa-user";
      }
    },
    color() {
      if (this.isActiveDemo) return "warning";
      if (this.status !== "active") return "error lighten-2";
      return undefined;
    },
    _title() {
      if (this.title) return this.title;

      if (this.isActiveDemo) {
        return `Demoing unit ${
          this.responder?.organization?.name
            ? this.responder.organization.name
            : ""
        } to ${userDisplayName(this.responder.user)}`;
      }
      return billingAccountStatusText(this.status);
    },
  },
};
</script>
