<template>
  <v-btn small :title="title" v-on="$listeners">
    <v-icon left small>mdi-download</v-icon>
    <slot>Download</slot>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonDownload",
  props: {
    title: {
      type: String,
      default: "Download",
    },
  },
};
</script>
