<template>
  <span
    v-if="Array.isArray(icon)"
    :class="combinedClass"
    class="fa-stack"
    :title="_title"
    v-bind="$attrs"
  >
    <i :class="icon[0]" class="fa-stack-2x"></i>
    <i :class="icon[1]" class="fa-stack-1x"></i>
  </span>
  <v-icon v-else :title="_title" :color="_color" v-bind="$attrs">
    {{ icon }}
  </v-icon>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "DocumentIcon",
  props: {
    status: {
      type: String,
      default: null,
      required: true,
    },
    color: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    statusIcons() {
      return {
        disabled: {
          icon: ["fas fa-file", "fas fa-ban"],
          color: "grey",
        },
        unavailable: {
          icon: ["fas fa-file", "fas fa-question"],
          color: "text--lighten-1 grey",
        },
        error: {
          icon: "fas fa-file-exclamation",
          color: "error",
        },
        signed: {
          icon: "fas fa-file-check",
          color: "success",
        },
        unsigned: {
          icon: ["fas fa-file", "fas fa-clock"],
          color: "warning",
        },
      };
    },
    _color() {
      return (
        this.color ||
        this.statusIcons[this.status]?.color ||
        this.statusIcons.unavailable.color
      );
    },
    icon() {
      return (
        this.statusIcons[this.status]?.icon || this.statusIcons.unavailable.icon
      );
    },
    _title() {
      return this.title === null
        ? undefined
        : this.title || titleCase(this.status);
    },
    combinedClass() {
      const size =
        this.$attrs.small !== undefined ? "stacked-small" : "stacked";
      const right = this.$attrs.right !== undefined ? "align-right" : undefined;
      return [this._color + "--text", size, right];
    },
  },
};
</script>

<style lang="scss" scoped>
.stacked {
  font-size: 0.87em;
}
.stacked-small {
  font-size: 0.6em;
}
.align-right {
  .fa-stack-1x,
  .fa-stack-2x {
    text-align: right;
  }
  .fa-stack-1x {
    transform: translate(-0.3em, 0.3em);
  }
}
.fa-stack-1x {
  transform: translateY(0.3em);
  color: white;
}
</style>
