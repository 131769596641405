import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { initApolloProvider } from "./apollo-client/vue-apollo";
import vuetify from "./plugins/vuetify";
import autoupdate from "./plugins/autoupdate";
import sentryInit from "./plugins/sentry";
import tracking from "./plugins/tracking";

import { addBeforeNavigationAuthHook } from "./router/auth-hooks";

Vue.use(autoupdate);
Vue.use(tracking);

sentryInit(router);
addBeforeNavigationAuthHook();

new Vue({
  el: "#app",
  router,
  apolloProvider: initApolloProvider(),
  vuetify,
  render: (h) => h(App),
});
