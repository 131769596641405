<template>
  <component
    :is="transitionComponent"
    v-if="alertsToShow.length"
    v-bind="transitionAttrs"
  >
    <v-alert
      v-for="alert in alertsToShow"
      :key="alert.id"
      :type="alert.type"
      :icon="alert.icon"
      v-bind="extraAttrs"
    >
      <div v-if="alert.title" class="text-h6 mb-4">{{ alert.title }}</div>
      <div>{{ alert.message }}</div>
      <div v-if="alert.actions" class="d-flex mt-4">
        <v-spacer />
        <v-btn
          v-for="(value, name) in alert.actions"
          :key="name"
          text
          :color="alert.type"
          @click="$emit(name, alert)"
        >
          {{ value }}
        </v-btn>
      </div>
    </v-alert>
  </component>
</template>

<script>
import useAlerts from "../composables/useAlerts";
import { computed, unref, version } from "vue";

export default {
  name: "AlertsAnimated",
  props: {
    alerts: {
      type: Array,
      default: () => null,
    },
  },
  setup(props, { attrs }) {
    const isVue3 = version.split(".")[0] === "3";
    const { sectionAlerts } = useAlerts();
    const alertsToShow = computed(() => {
      const alerts = unref(props.alerts);
      return alerts || sectionAlerts.value || [];
    });

    const defaultAttrs = isVue3
      ? { border: "start", variant: "tonal" }
      : { border: "left", text: true, outlined: true };
    const extraAttrs = { ...defaultAttrs, attrs };

    const transitionComponent = isVue3
      ? "v-expand-transition"
      : "transition-group";
    const transitionAttrs = isVue3
      ? {
          group: true,
        }
      : {
          name: "alerts",
          tag: "div",
        };

    return { alertsToShow, extraAttrs, transitionComponent, transitionAttrs };
  },
};
</script>

<style scoped>
.alerts-enter,
.alerts-leave-to {
  opacity: 0;
  margin-top: -68px;
}
.alerts-enter-to,
.alerts-leave {
  opacity: 1;
  margin-top: 0;
}
</style>
