<template>
  <CardLayout
    title="Assigned Devices"
    :header-type="allowActions ? '' : 'manage'"
    hint="Add &amp; Remove Devices"
    @manage-button-click="manageDevices"
  >
    <v-simple-table>
      <thead>
        <tr>
          <th>Device #</th>
          <th>Device Health</th>
          <th v-if="allowActions">Actions</th>
          <th v-else>Last Motion</th>
        </tr>
      </thead>
      <tbody v-if="smartUnitLoading">
        <tr>
          <td colspan="3">
            <em>Loading assigned devices...</em>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="devices.length > 0">
        <tr v-for="device in devices" :key="device.id">
          <td style="width: 40%; white-space: nowrap">
            {{ device.shortId || device.externalId }}
          </td>
          <td style="width: 40%; white-space: nowrap">
            <DeviceGaugeCluster :device="device" />
          </td>
          <td v-if="allowActions" style="width: 20%">
            <v-btn
              depressed
              small
              tile
              color="warning"
              :disabled="disallowRemoveDevice"
              @click="showWarning(device)"
            >
              Remove Device
            </v-btn>
          </td>

          <td v-else style="width: 20%; white-space: nowrap">
            {{ deviceLastMotion(device) }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <em>No devices are available</em>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <DialogLayout
      v-if="removingDevice"
      v-model="openRemoveDeviceWarning"
      title="Remove Device"
    >
      <p>
        Have you physically removed device
        {{ removingDevice.shortId || removingDevice.externalId }} from the unit
        {{ smartUnit.name }}?
      </p>
      <template #actions>
        <v-btn color="primary" text @click="openRemoveDeviceWarning = false">
          No
        </v-btn>
        <v-btn color="primary" text @click="removeDevice()"> Yes </v-btn>
      </template>
    </DialogLayout>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";

import { deviceLastMotion } from "../common/helpers/devices";

import { addOrganizationDevices } from "../../graphql/organizations/index";
import { GET_SMART_UNIT, ORGANIZATION_DEVICES } from "./graphql";
import useSmartUnit from "./useSmartUnit";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import DialogLayout from "@layout/DialogLayout.vue";
import { ref } from "vue";

export default {
  name: "CardAssignedDevices",
  components: { CardLayout, DeviceGaugeCluster, DialogLayout },
  props: {
    allowActions: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { smartUnit, smartUnitLoading, smartUnitDevices } = useSmartUnit();
    const { currentFacilityId } = useFacility();
    const { addAlert } = useAlerts();

    const openRemoveDeviceWarning = ref(false);
    const removingDevice = ref(null);

    return {
      smartUnit,
      smartUnitLoading,
      devices: smartUnitDevices,
      currentFacilityId,
      openRemoveDeviceWarning,
      removingDevice,
      addAlert,
    };
  },
  computed: {
    disallowRemoveDevice() {
      return (
        this.devices.length === 1 && this.smartUnit.billingAccounts.length > 0
      );
    },
  },
  methods: {
    manageDevices() {
      this.$router.push({
        name: "ManageSmartUnitDevices",
        params: { zone_id: this.smartUnit.id },
      });
    },
    showWarning(device) {
      this.removingDevice = device;
      this.openRemoveDeviceWarning = true;
    },
    async removeDevice() {
      this.openRemoveDeviceWarning = false;
      if (this.disallowRemoveDevice) {
        this.addAlert({
          type: "error",
          message:
            "Cannot remove last assigned device from an active Smart Unit.",
          timeout: 20,
        });
        return;
      }
      if (!this.removingDevice?.id) return;

      try {
        const response = await this.$apollo.mutate({
          // "add" it back to facility inventory
          mutation: addOrganizationDevices,
          variables: {
            devices: [this.removingDevice.id],
            id: this.currentFacilityId,
          },
          refetchQueries: this.refetchQueries,
        });
        this.removingDevice = null;
      } catch (error) {
        // Error should be already displayed
        // console.error(error);
      }
    },
    refetchQueries() {
      return [
        {
          query: ORGANIZATION_DEVICES,
          variables: {
            id: this.currentFacilityId,
            searchText: "",
            status: "ok",
            dispositions: ["in_service", "waiting"],
          },
        },
        {
          query: GET_SMART_UNIT,
          variables: { id: this.smartUnit.id },
        },
      ];
    },
    deviceLastMotion,
  },
};
</script>
