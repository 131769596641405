<template>
  <CardLayout
    v-model="editing"
    title="Renter Information"
    :header-type="headerType"
  >
    <template #edit>
      <CreateRenterAlert
        v-if="newRenter"
        ref="createAlert"
        v-model="userExists"
        @renter-created="renterCreated"
        @reset-phone="resetPrimaryPhone"
      />
      <FormRenterInfo
        ref="formInfo"
        :new-renter="newRenter"
        :demo-renter="demoRenter"
        v-on="$listeners"
      />
    </template>

    <template v-if="newRenter">
      <CreateRenterAlert
        ref="createAlert"
        v-model="userExists"
        @renter-created="renterCreated"
        @reset-phone="resetPrimaryPhone"
      />
      <dl>
        <dt>Name</dt>
        <dd>{{ renter.name }}</dd>
        <dt>Phone</dt>
        <dd>{{ primaryPhone }}</dd>
      </dl>
    </template>
    <template v-else>
      <FormRenterInfo :demo-renter="demoRenter" v-on="$listeners" />
    </template>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import FormRenterInfo from "./FormRenterInfo.vue";
import CreateRenterAlert from "./CreateRenterAlert.vue";

import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useRenter from "./useRenter";
import { computed } from "vue";

export default {
  name: "CardRenterInfo",
  components: {
    CardLayout,
    FormRenterInfo,
    CreateRenterAlert,
  },
  props: {
    newRenter: {
      type: Boolean,
      default: false,
    },
    demoRenter: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { renter, primaryPhoneMethod } = useRenter();
    const primaryPhone = computed(() =>
      formatPhoneNumber(primaryPhoneMethod.value.value)
    );

    return { renter, primaryPhone };
  },
  data: () => ({
    editing: "",
    userExists: false,
  }),
  computed: {
    headerType() {
      return this.newRenter ? "edit" : undefined;
    },
  },
  watch: {
    userExists(val) {
      if (val) {
        this.editing = "editing";
      }
    },
  },
  methods: {
    async createRenter() {
      if (!this.editing || (await this.$refs.formInfo.validate())) {
        await this.$refs.createAlert.tryCreateRenter();
      }
    },
    resetPrimaryPhone() {
      this.$refs.formInfo.reset();
      // console.log(this.$refs.formInfo);
      this.$refs.formInfo.focusPrimaryPhone();
    },
    renterCreated(user) {
      this.$emit("renter-created", user);
    },
  },
};
</script>

<style lang="scss" scoped>
dl {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
dt {
  text-align: right;
  &::after {
    content: ":";
  }
}
dd {
  margin-inline-start: 0.5em;
  font-weight: bold;
}
</style>
