<template>
  <v-btn outlined small v-bind="$attrs" v-on="$listeners">
    <v-icon small left>fas fa-edit</v-icon>
    Edit
  </v-btn>
</template>

<script>
export default {
  name: "ButtonEdit",
};
</script>
