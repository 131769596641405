// Query for getting a single organization, typically a Zone/Unit
import gql from "graphql-tag";
import {
  accountFields,
  contactMethodFields,
  deviceFields,
  organizationFields,
  userFields,
} from "../../graphql/fragments";

export const GET_ASSIGNED_UNITS = gql`
  query UserOrganizations($userId: ID!, $parentOrganizationId: ID!) {
    userOrganizations(
      userId: $userId
      parentOrganizationId: $parentOrganizationId
      type: "zone"
    ) {
      ...OrganizationFields
      billingAccounts(type: "consumer") {
        ...AccountFields
      }
      devices {
        ...DeviceFields
      }
    }
  }
  ${accountFields}
  ${deviceFields}
  ${organizationFields}
`;

export const ADD_USER_CONTACT_METHOD = gql`
  mutation AddUserContactMethod(
    $userId: ID!
    $type: String!
    $value: String!
    $isDefault: Boolean
  ) {
    addUserContactMethod(
      userId: $userId
      type: $type
      value: $value
      isDefault: $isDefault
    ) {
      ...ContactMethodFields
    }
  }
  ${contactMethodFields}
`;

export const GET_USER_CONTACT_METHODS = gql`
  query User($id: ID!) {
    user(id: $id) {
      id
      contactMethods {
        ...ContactMethodFields
      }
    }
  }
  ${contactMethodFields}
`;

export const ADD_UNIT_RESPONDERS = gql`
  mutation AddOrganizationResponders($id: ID!, $responders: [AddResponder!]) {
    addOrganizationResponders(id: $id, responders: $responders) {
      id
      escalationLevel
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const REMOVE_UNIT_RESPONDER = gql`
  mutation RemoveOrganizationResponder($id: ID!, $organizationId: ID!) {
    removeOrganizationResponder(id: $id, organizationId: $organizationId) {
      id
      escalationLevel
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const UPDATE_UNIT_RESPONDER = gql`
  mutation UpdateOrganizationResponder(
    $id: ID!
    $contactMethodId: ID!
    $escalationLevel: Int!
  ) {
    updateOrganizationResponder(
      id: $id
      contactMethodId: $contactMethodId
      escalationLevel: $escalationLevel
    ) {
      id
      escalationLevel
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const UPDATE_BILLING_ACCOUNT = gql`
  mutation UpdateBillingAccount(
    $id: ID!
    $name: String!
    $status: String!
    $type: String!
    $cause: String
  ) {
    updateBillingAccount(
      id: $id
      name: $name
      status: $status
      type: $type
      cause: $cause
    ) {
      ...AccountFields
    }
  }
  ${accountFields}
`;

const accountEventFields = gql`
  fragment AccountEventFields on AccountEvent {
    id
    name
    timestamp
    data
    cause
  }
`;

export const BILLING_ACCOUNT_EVENTS = gql`
  query BillingAccountEvents($billingAccountId: ID!, $name: String) {
    billingAccountEvents(billingAccountId: $billingAccountId, name: $name) {
      ...AccountEventFields
    }
  }
  ${accountEventFields}
`;

export const AVAILABLE_SMART_UNITS = gql`
  query AvailableSmartUnits($id: ID!) {
    availableSmartUnits: organizationDescendants(
      id: $id
      assigned: false
      type: "zone"
      subtype: "unit"
    ) {
      ...OrganizationFields
      responders {
        id
        demoEndingAt
        status
        user {
          id
          firstName
          lastName
        }
      }
      devices {
        ...DeviceFields
        assignmentHistory {
          organizationId
          organizationName
          timestamp
        }
        organization {
          id
        }
      }
    }
  }
  ${organizationFields}
  ${deviceFields}
`;
