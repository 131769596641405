import PageOperationAndSecurity from "./PageOperationAndSecurity.vue";
import PageHumanBehaviorSummaryReport from "./PageHumanBehaviorSummaryReport.vue";
import PageVacantUnitReport from "./PageVacantUnitReport.vue";

const routes = {
  navigation: [
    {
      path: "/operation_and_security",
      name: "OperationAndSecurityDashboard",
      component: PageOperationAndSecurity,
    },
    {
      path: "/operation_and_security/human_behavior_summary_report/:date/:label",
      name: "HumanBehaviorSummaryReport",
      component: PageHumanBehaviorSummaryReport,
    },
    {
      path: "/operation_and_security/vacant_unit/:selectedDuration",
      name: "PageVacantUnitReport",
      component: PageVacantUnitReport,
    },
  ],
};

export default routes;
