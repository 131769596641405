import PageHelp from "./PageHelp.vue";

const routes = {
  navigation: [
    {
      path: "/help",
      name: "Help",
      component: PageHelp,
    },
  ],
};

export default routes;
