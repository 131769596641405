<template>
  <v-card v-if="editing" outlined tile>
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left>fas fa-temperature-hot</v-icon>
      Zone Climate
      <v-spacer />
      <v-switch
        v-model="config.disabled"
        title="Enable/Disable"
        hide-details
        inset
        :false-value="true"
        :true-value="false"
      />
    </v-toolbar>
    <v-divider />
    <v-card-text>
      <v-row dense>
        <v-col md="6">
          <ValidatedTextField
            v-model="lowTemperatureThreshold"
            label="Low Temp °F"
            name="Low Temp °F"
            type="number"
            rules="max:3"
            :disabled="config.disabled"
          />
        </v-col>
        <v-col md="6">
          <ValidatedTextField
            v-model="highTemperatureThreshold"
            label="High Temp °F"
            name="High Temp °F"
            type="number"
            rules="max:3"
            :disabled="config.disabled"
          />
        </v-col>
        <v-col md="6">
          <ValidatedTextField
            v-model="config.lowHumidityThreshold"
            label="Low RH%"
            name="Low RH%"
            type="number"
            rules="max:3"
            :disabled="config.disabled"
          />
        </v-col>
        <v-col md="6">
          <ValidatedTextField
            v-model="config.highHumidityThreshold"
            label="High RH%"
            name="High RH%"
            type="number"
            rules="max:3"
            :disabled="config.disabled"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card v-else outlined tile :disabled="config.disabled">
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left :color="config.disabled ? null : 'success'"
        >fas fa-temperature-hot</v-icon
      >
      Zone Climate
    </v-toolbar>
    <v-divider />
    <v-card-text v-if="!config.disabled">
      <v-row dense>
        <v-col md="6">
          <span>
            Temperature: <br />
            {{ lowTemperatureThreshold }}°F - {{ highTemperatureThreshold }}°F
          </span>
        </v-col>
        <v-col md="6">
          <span>
            Humidity: <br />
            {{ config.lowHumidityThreshold }}% -
            {{ config.highHumidityThreshold }}%
          </span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else> Disabled </v-card-text>
  </v-card>
</template>

<script>
import { celsiusToFahrenheit, fahrenheitToCelsius } from "../utils/converters";
import ValidatedTextField from "../utils/ValidatedTextField.vue";

export default {
  name: "ClimateConfigWidget",
  components: {
    ValidatedTextField,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    config: {
      get() {
        return this.value;
      },
      set(values) {
        this.$emit("input", values);
      },
    },
    highTemperatureThreshold: {
      get() {
        return convertToFahrenheit(this.config.highTemperatureThreshold);
      },
      set(value) {
        this.config.highTemperatureThreshold = convertFromFahrenheit(value);
      },
    },
    lowTemperatureThreshold: {
      get() {
        return convertToFahrenheit(this.config.lowTemperatureThreshold);
      },
      set(value) {
        this.config.lowTemperatureThreshold = convertFromFahrenheit(value);
      },
    },
  },
};

function convertToFahrenheit(val) {
  return String(Math.round(celsiusToFahrenheit(val)));
}
function convertFromFahrenheit(val) {
  return fahrenheitToCelsius(val).toFixed(2);
}
</script>
