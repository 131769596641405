<template>
  <v-row>
    <v-col v-for="video in videos" :key="video.id" lg="4" md="6" cols="12">
      <iframe
        width="100%"
        style="aspect-ratio: 16/9"
        :src="`https://www.youtube.com/embed/${video.id}`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <v-col lg="12">
        <h3>
          {{ video.category ? `${video.category}:` : "" }}
          <strong>{{ video.title }}</strong>
        </h3>
        <br />
        <br />
        <br />
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "YoutubeVideos",
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
h3 {
  white-space: pre-line;
}
</style>
