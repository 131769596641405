import { ref, computed } from "vue";

import { UPDATE_BILLING_ACCOUNT } from "./graphql";
import {
  userBillingAccounts,
  organizationUsersReport,
} from "../../graphql/users/index";
import {
  acceptInvitation,
  addAccountOrganizations,
  createBillingAccount,
  inviteAccountUser,
} from "../../graphql/organizations/zone";
import useFacility from "../authentication/useFacility";

const userBillingAccount = ref({});
const $billingAccount = ref({});

async function updateAccountStatus(apolloClient, status) {
  let cause = "";
  switch (status) {
    case "disabled_lien":
    case "disabled_maintenance":
      cause = "Manually disabled";
      break;
    case "active":
      cause =
        $billingAccount.value.status === "ended_service"
          ? "Restore service for the renter"
          : "Manually enabled";
      break;
    case "ended_service":
      cause = "Ended service for the renter, but leaving device(s) in unit(s)";
      break;
  }
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_BILLING_ACCOUNT,
      variables: {
        id: $billingAccount.value.id,
        name: $billingAccount.value.name,
        type: $billingAccount.value.type,
        status,
        cause,
      },
    });
    $billingAccount.value = response.data.updateBillingAccount;
  } catch (error) {
    // console.error(error);
    // Error should be already logged
    throw "There was an error updating the renter";
  }

  return "Renter updated successfully";
}

const $accountDisabled = computed(() =>
  [
    "disabled_maintenance",
    "disabled_device_upgrade",
    "disabled_lien",
    "ended_service",
  ].includes($billingAccount.value?.status)
    ? $billingAccount.value.status
    : ""
);
const $endedService = computed(
  () => $billingAccount.value?.status === "ended_service"
);

function useBillingAccount() {
  const { currentFacility, currentFacilityId } = useFacility();

  async function fetchUserBillingAccounts(
    apolloClient,
    userId,
    fetchPolicy = "cache-first"
  ) {
    const response = await apolloClient.query({
      query: userBillingAccounts,
      variables: {
        userId,
        parentOrganizationId: currentFacilityId.value,
      },
      fetchPolicy,
    });

    const userConsumerAccount = response.data.userBillingAccounts.find(
      (account) => account.billingAccount.type == "consumer"
    );
    userBillingAccount.value = userConsumerAccount;
    $billingAccount.value = userConsumerAccount?.billingAccount;

    return response.data.userBillingAccounts;
  }

  async function assignUser(apolloClient, user, justCreatedUser = true) {
    if (!user || !currentFacility.value) return null;

    // Create a billing account for the user @ the facility
    const ba_res = await apolloClient.mutate({
      mutation: createBillingAccount,
      variables: {
        name: `${user.firstName} ${user.lastName} @ ${currentFacility.value.name}`,
        type: "consumer",
        status: "active",
      },
    });

    const billingAccountId = ba_res.data.createBillingAccount.id;

    // Invite the user to the billing account
    const iau_res = await apolloClient.mutate({
      mutation: inviteAccountUser,
      variables: {
        userId: user.id,
        billingAccountId,
        recipient: user.username,
      },
    });

    // Accept the invitation & set the user's default organization
    // Set `isOwner: false` to avoid triggering the Tenant Addendum.
    const ai_res = await apolloClient.mutate({
      mutation: acceptInvitation,
      variables: {
        defaultOrganizationId: justCreatedUser
          ? currentFacilityId.value
          : undefined,
        invitationId: iau_res.data.inviteAccountUser.id,
      },
    });

    // Associate the facility with the billing account. This will establish
    // the "renter" relationship.
    await apolloClient.mutate({
      mutation: addAccountOrganizations,
      variables: {
        billingAccountId,
        organizations: [{ id: currentFacilityId.value }],
      },
      refetchQueries: [
        {
          query: organizationUsersReport,
          variables: { id: currentFacilityId.value },
        },
      ],
    });

    await fetchUserBillingAccounts(apolloClient, user.id, "network-only");
    return true;
  }

  return {
    userBillingAccount,
    billingAccount: $billingAccount,
    accountDisabled: $accountDisabled,
    endedService: $endedService,
    fetchUserBillingAccounts,
    updateAccountStatus,
    assignUser,
  };
}

export default useBillingAccount;
