// Provides utility functions for GraphQL

// See https://github.com/apollographql/apollo-client/issues/1564#issuecomment-357492659
export function omitTypename({ __typename, ...obj }) {
  if (!__typename) return obj;
  for (const key in obj) {
    if (obj[key] && typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      obj[key] = omitTypename(obj[key]);
    }
  }
  return obj;
}
