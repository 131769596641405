import gql from "graphql-tag";
import { profileFields } from "../../../graphql/fragments";

export const HUMAN_BEHAVIOR_SUMMARY = gql`
  query IncidentsSummary(
    $dateFilter: DateFilter!
    $facilityIds: [ID]!
    $reportInterval: ReportInterval!
  ) {
    incidentsSummary(
      dateFilter: $dateFilter
      facilityIds: $facilityIds
      reportInterval: $reportInterval
    ) {
      afterHoursOccupied
      afterHoursVacant
      authorized
      facilityId
      facilityName
      reportDateTime
      unAcknowledged
      unAuthorized
    }
  }
`;

export const FACILITY_ZONE_CLIMATE_SUMMARY = gql`
  query FacilityZoneClimateSummary(
    $climateType: ClimateType!
    $facilityIds: [ID]!
    $limit: Int!
    $dateTimeFilter: DateTimeFilter
    $deviceType: String
    $incidentType: String
  ) {
    facilityZoneClimateSummary(
      climateType: $climateType
      facilityIds: $facilityIds
      limit: $limit
    ) {
      climateReading
      climateType
      facilityId
      facilityName
      zoneId
      zoneName
      organization {
        id
        name
        parentId
        code
        location {
          spots
        }
        devices(deviceType: $deviceType) {
          id
        }
        incidents(dateTimeFilter: $dateTimeFilter, type: $incidentType) {
          id
          type
        }
        profile {
          ...ProfileFields
        }
      }
    }
  }
  ${profileFields}
`;

export const FACILITY_CLIMATE_STATS = gql`
  query FacilityClimateStats(
    $climateType: ClimateType!
    $dateFilter: DateFilter!
    $facilityIds: [ID]!
    $reportInterval: ReportInterval!
    $numberOfIncidents: Int!
  ) {
    facilityClimateStats(
      climateType: $climateType
      dateFilter: $dateFilter
      facilityIds: $facilityIds
      reportInterval: $reportInterval
      numberOfIncidents: $numberOfIncidents
    ) {
      startDate
      endDate
      facilityId
      facilityName
      zoneId
      zoneName
      incidentCount
    }
  }
`;

export const FACILITY_VACANT_UNIT_HYGIENE_STATS = gql`
  query FacilityVacantUnitHygieneStats(
    $facilityIds: [ID]!
    $cleanedWithinDays: Int!
  ) {
    facilityVacantUnitHygieneStats(
      facilityIds: $facilityIds
      cleanedWithinDays: $cleanedWithinDays
    ) {
      averageCleaningDays
      cleanedVacantUnits
      facilityId
      facilityName
      organizationId
      organizationName
      otherReasonIncidents
      region
      suspiciousIncidents
      totalOccupiedUnits
      totalUnits
      totalVacantUnits
      uncleanedVacantUnits
      vacantUnitUncleanScore
    }
  }
`;

export const FACILITY_VACANT_UNIT_INCIDENTS_REPORT = gql`
  query FacilityVacantUnitIncidentsReport(
    $cleanedWithinDays: Int!
    $dateFilter: DateFilter!
    $facilityIds: [ID]!
  ) {
    facilityVacantUnitIncidentsReport(
      cleanedWithinDays: $cleanedWithinDays
      dateFilter: $dateFilter
      facilityIds: $facilityIds
    ) {
      facilityId
      facilityName
      movedOutOn
      organizationId
      organizationName
      incidentAfterMoveOutOn
      cleanedOn
      zoneName
      purposeOfVisit
    }
  }
`;
