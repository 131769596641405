<template>
  <v-skeleton-loader :loading="loading" type="image" height="300px">
    <ZoneEnvironmentReportChart
      v-if="hasEvents"
      :chart-data="chartData"
      :min="chartMin"
      :max="chartMax"
    />
    <div v-else>
      <em>No data available</em>
    </div>
  </v-skeleton-loader>
</template>

<script>
import { ref, computed } from "vue";
import { deviceTelemetryEvents } from "../../../graphql/organizations/zone";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import ZoneEnvironmentReportChart from "@components/reports/ZoneEnvironmentReportChart.vue";
import { SD_ACCENT } from "@tod-ui/constants/colors";

export default {
  name: "ZoneTemperatureChart",
  components: {
    ZoneEnvironmentReportChart,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    dateTimeFilter: {
      type: Object,
      default: () => ({
        before: null,
        after: null,
      }),
    },
    zoneProfile: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const events = ref({
      tempEvents: [],
      htThresh: null,
      ltThresh: null,
    });
    const loading = ref(false);

    const hasEvents = computed(() => events.value.tempEvents.length > 0);
    const chartMin = computed(() => events.value.tempEvents[0]?.x);
    const chartMax = computed(
      () => events.value.tempEvents[events.value.tempEvents.length - 1]?.x
    );

    const chartData = computed(() => {
      if (!hasEvents.value) return null;

      let ds = {
        datasets: [
          {
            label: "Temperature °F",
            data: events.value.tempEvents,
            backgroundColor: "rgba(255, 196, 196, 0.8)",
            borderColor: SD_ACCENT,
            borderWidth: 2,
            pointBackgroundColor: SD_ACCENT,
            pointBorderColor: SD_ACCENT,
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            fill: true,
          },
        ],
      };

      // Add temperature thresholds if zone profile exists and climate config is enabled
      if (
        props.zoneProfile?.climateConfig &&
        !props.zoneProfile.climateConfig.disabled
      ) {
        const { highTemperatureThreshold, lowTemperatureThreshold } =
          props.zoneProfile.climateConfig;

        // Add high temperature threshold
        if (highTemperatureThreshold && events.value.tempEvents.length > 0) {
          const htThreshData = [
            {
              y: convertCelsiusToFahrenheit(highTemperatureThreshold),
              x: events.value.tempEvents[0].x,
            },
            {
              y: convertCelsiusToFahrenheit(highTemperatureThreshold),
              x: events.value.tempEvents[events.value.tempEvents.length - 1].x,
            },
          ];

          ds.datasets.push({
            label: "Upper Temperature Threshold °F",
            data: htThreshData,
            backgroundColor: "transparent",
            borderColor: SD_ACCENT,
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            borderDash: [10, 10],
            fill: false,
            pointRadius: 0,
          });
        }

        // Add low temperature threshold
        if (lowTemperatureThreshold && events.value.tempEvents.length > 0) {
          const ltThreshData = [
            {
              y: convertCelsiusToFahrenheit(lowTemperatureThreshold),
              x: events.value.tempEvents[0].x,
            },
            {
              y: convertCelsiusToFahrenheit(lowTemperatureThreshold),
              x: events.value.tempEvents[events.value.tempEvents.length - 1].x,
            },
          ];

          ds.datasets.push({
            label: "Lower Temperature Threshold °F",
            data: ltThreshData,
            backgroundColor: "transparent",
            borderColor: "Blue",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            hoverRadius: 5,
            lineTension: 0.5,
            showLine: true,
            borderDash: [10, 10],
            fill: false,
            pointRadius: 0,
          });
        }
      }

      return ds;
    });

    async function fetchData() {
      loading.value = true;
      try {
        const response = await this.$apollo.query({
          query: deviceTelemetryEvents,
          variables: {
            deviceId: props.deviceId,
            dateTimeFilter: props.dateTimeFilter,
          },
        });

        const tempEvents = [];
        response.data.events.forEach((event) => {
          const payload = JSON.parse(event.data);
          if (payload.temp_celcius != undefined) {
            tempEvents.push({
              y: Math.floor(convertCelsiusToFahrenheit(payload.temp_celcius)),
              x: new Date(payload.timestamp).toISOString(),
            });
          }
        });

        events.value = { tempEvents };
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    }

    return {
      loading,
      hasEvents,
      chartData,
      chartMin,
      chartMax,
      fetchData,
    };
  },
  watch: {
    deviceId: {
      immediate: true,
      handler: "fetchData",
    },
    dateTimeFilter: {
      handler: "fetchData",
      deep: true,
    },
  },
};
</script>
