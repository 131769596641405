<template>
  <v-card tile>
    <v-card-text>
      <v-row>
        <v-col :md="hasPmsStats ? 12 : 8">
          <CardLayout title="Available Unit Types">
            <v-skeleton-loader :loading="loading" type="table">
              <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(updateItem)">
                  <v-data-table
                    :headers="headers"
                    :items="unitTypesGrouped"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :custom-sort="sortItems"
                    group-by="groupPos"
                    :group-desc="sortDesc"
                    no-data-text="No unit types added"
                    :footer-props="{ 'items-per-page-options': [25, 50, -1] }"
                  >
                    <template #header.occupied="{ header }">
                      <div class="d-inline-block">
                        <div>{{ header.text }}</div>
                        <div>/ Total Occupied</div>
                      </div>
                    </template>
                    <template #header.vacant="{ header }">
                      <div class="d-inline-block">
                        <div>{{ header.text }}</div>
                        <div>/ Total Vacant</div>
                      </div>
                    </template>
                    <template #header.total="{ header }">
                      <div class="d-inline-block">
                        <div>{{ header.text }}</div>
                        <div>/ Total Units</div>
                      </div>
                    </template>

                    <template #group.header="{ items, isOpen, toggle }">
                      <td>
                        Size: <strong>{{ items[0]?.size }}</strong> ({{
                          items[0]?.groupCount
                        }})
                      </td>
                      <td
                        class="text-center grey--text text--darken-2 font-weight-bold"
                      >
                        <NumberOfUnits
                          count-kind="occupied"
                          :smart-units="items[0].groupOccupiedSmartUnits"
                          :all-units="items[0].groupOccupied"
                          :total="
                            hasPmsStats
                              ? items[0].groupTotal
                              : items[0].groupTotalSmartUnits
                          "
                          :count-only-smart-units="!hasPmsStats"
                        />
                      </td>
                      <td
                        class="text-center grey--text text--darken-2 font-weight-bold"
                      >
                        <NumberOfUnits
                          count-kind="vacant"
                          :smart-units="items[0].groupVacantSmartUnits"
                          :all-units="items[0].groupVacant"
                          :total="
                            hasPmsStats
                              ? items[0].groupTotal
                              : items[0].groupTotalSmartUnits
                          "
                          :count-only-smart-units="!hasPmsStats"
                        />
                      </td>
                      <td
                        class="text-center grey--text text--darken-2 font-weight-bold"
                      >
                        <NumberOfUnits
                          count-kind="total"
                          :smart-units="items[0].groupTotalSmartUnits"
                          :all-units="items[0].groupTotal"
                          :total="
                            hasPmsStats
                              ? items[0].groupTotal
                              : items[0].groupTotalSmartUnits
                          "
                          :count-only-smart-units="!hasPmsStats"
                        />
                      </td>
                      <td class="text-right">
                        <v-btn icon @click="toggle">
                          <v-icon>
                            fas fa-chevron-up
                            {{ isOpen ? "" : "fa-rotate-180" }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </template>

                    <template #item="{ item }">
                      <tr>
                        <template v-if="editingId === item.id">
                          <td colspan="5" class="pl-8">
                            <v-row dense>
                              <v-col md="4">
                                <ValidatedTextField
                                  v-model="editUnitType.name"
                                  name="Unit Type"
                                  autofocus
                                  placeholder="I.e. Self-storage"
                                  rules="min:3|max:30"
                                  :counter="30"
                                />
                              </v-col>
                              <v-col md="2">
                                <ValidatedTextField
                                  v-model="editUnitType.width"
                                  name="Width"
                                  type="number"
                                  :min="1"
                                />
                              </v-col>
                              <v-col md="2">
                                <ValidatedTextField
                                  v-model="editUnitType.length"
                                  name="Length"
                                  type="number"
                                  :min="1"
                                />
                              </v-col>
                              <v-col
                                md="4"
                                class="align-self-center text-right"
                              >
                                <ButtonSubmit class="mr-2" />
                                <ButtonCancel @click="editingId = null" />
                              </v-col>
                            </v-row>
                          </td>
                        </template>
                        <template v-else>
                          <td class="pl-8">
                            <PossiblePMS as-icon :sync-obj="item">
                              {{ item.typeName }}
                            </PossiblePMS>
                          </td>
                          <td class="text-center grey--text text--darken-2">
                            <NumberOfUnits
                              count-kind="occupied"
                              :smart-units="item.occupiedSmartUnits"
                              :all-units="item.occupied"
                              :total="
                                hasPmsStats ? item.total : item.totalSmartUnits
                              "
                              :count-only-smart-units="!hasPmsStats"
                            />
                          </td>
                          <td class="text-center grey--text text--darken-2">
                            <NumberOfUnits
                              count-kind="vacant"
                              :smart-units="item.vacantSmartUnits"
                              :all-units="item.vacant"
                              :total="
                                hasPmsStats ? item.total : item.totalSmartUnits
                              "
                              :count-only-smart-units="!hasPmsStats"
                            />
                          </td>
                          <td class="text-center grey--text text--darken-2">
                            <NumberOfUnits
                              count-kind="total"
                              :smart-units="item.totalSmartUnits"
                              :all-units="item.total"
                              :total="
                                hasPmsStats ? item.total : item.totalSmartUnits
                              "
                              :count-only-smart-units="!hasPmsStats"
                            />
                          </td>
                          <td>
                            <PossiblePMS
                              v-if="!hasPmsStats"
                              v-slot="{ disabled }"
                              :sync-obj="item"
                            >
                              <ButtonEdit
                                :disabled="disabled"
                                @click="editItem(item.id)"
                              />
                            </PossiblePMS>
                          </td>
                        </template>
                      </tr>
                    </template>
                  </v-data-table>
                </form>
              </validation-observer>
            </v-skeleton-loader>
          </CardLayout>
        </v-col>

        <v-col v-if="!hasPmsStats" md="6">
          <CardLayout title="Add New Unit Type">
            <validation-observer v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(addItem)">
                <v-row>
                  <v-col md="6">
                    <PossiblePMS v-slot="{ disabled }">
                      <ValidatedTextField
                        v-model="newUnitType.name"
                        name="Unit Type"
                        placeholder="I.e. Self Storage"
                        rules="min:3|max:30"
                        :counter="30"
                        :disabled="disabled"
                      />
                    </PossiblePMS>
                  </v-col>
                  <v-col md="2">
                    <PossiblePMS v-slot="{ disabled }">
                      <ValidatedTextField
                        v-model="newUnitType.width"
                        name="Width"
                        type="number"
                        :min="1"
                        :disabled="disabled"
                      />
                    </PossiblePMS>
                  </v-col>
                  <v-col md="2">
                    <PossiblePMS v-slot="{ disabled }">
                      <ValidatedTextField
                        v-model="newUnitType.length"
                        name="Length"
                        type="number"
                        :min="1"
                        :disabled="disabled"
                      />
                    </PossiblePMS>
                  </v-col>
                  <v-col align-self="center">
                    <PossiblePMS v-slot="{ disabled }">
                      <ButtonSubmit :disabled="disabled"> Add </ButtonSubmit>
                    </PossiblePMS>
                  </v-col>
                </v-row>
              </form>
            </validation-observer>
          </CardLayout>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import NumberOfUnits from "@atoms/NumberOfUnits.vue";
import { ValidationObserver } from "vee-validate";
import CardLayout from "@layout/CardLayout.vue";
import ButtonCancel from "@atoms/ButtonCancel.vue";
import ButtonEdit from "@atoms/ButtonEdit.vue";
import ButtonSubmit from "@atoms/ButtonSubmit.vue";
import PossiblePMS from "../common/PossiblePMS.vue";

import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed, ref, watch } from "vue";
import { CREATE_UNIT_TYPE, UPDATE_UNIT_TYPES } from "./graphql";
import { displayUnitType } from "@tod-ui/helpers/unitType";
import { sortItems } from "@tod-ui/helpers/dataItems";
import usePMS from "@components/common/usePMS";

export default {
  name: "UnitTypes",
  components: {
    ButtonCancel,
    ButtonEdit,
    ButtonSubmit,
    CardLayout,
    ValidatedTextField,
    ValidationObserver,
    PossiblePMS,
    NumberOfUnits,
  },
  props: {
    facility: {
      validator: (val) => val === null || typeof val === "object",
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { currentFacilityId } = useFacility();
    const { addAlert } = useAlerts();
    const {
      fetchPMSUnitTypesStats,
      pmsUnitTypeStats,
      hasPMS: hasPmsStats,
    } = usePMS();

    const unitTypes = ref([]);
    const sortBy = ref("name");
    const sortDesc = ref(false);

    watch(
      [() => props.facility?.unitTypes, hasPmsStats],
      ([orgUnitTypes, hasStatsVal]) => {
        const existingTypes = orgUnitTypes
          ? hasStatsVal
            ? orgUnitTypes.filter((ut) => ut?.units?.length > 0)
            : orgUnitTypes
          : [];
        unitTypes.value = existingTypes.map(enrichTypeWithUnitsCount);
      },
      { immediate: true }
    );

    const unitTypesGrouped = computed(() => {
      const enrichedWithPmsStats =
        Array.isArray(pmsUnitTypeStats.value) && Array.isArray(unitTypes.value)
          ? unitTypes.value.map((ut) =>
              maybeEnrichTypeWithPmsStats(
                ut,
                pmsUnitTypeStats.value.find(
                  (pmsUT) => pmsUT.internalId === ut.id
                )
              )
            )
          : unitTypes.value;
      const groups = enrichedWithPmsStats.reduce(groupBySize, {});
      return Object.entries(groups).flatMap((groupEntry) =>
        enrichWithGroupInfo(groupEntry, sortBy.value)
      );
    });

    const newUnitType = ref({});
    const editUnitType = ref({});
    const editing = ref(null);
    const headers = computed(() => [
      { text: "Unit Type", value: "name" },
      {
        text: "Occupied Smart Units",
        value: hasPmsStats.value ? "occupied" : "occupiedSmartUnits",
        align: "center",
      },
      {
        text: "Vacant Smart Units",
        value: hasPmsStats.value ? "vacant" : "vacantSmartUnits",
        align: "center",
      },
      {
        text: "Total Smart Units",
        value: hasPmsStats.value ? "total" : "totalSmartUnits",
        align: "center",
      },
      {
        text: hasPmsStats.value ? "" : "Actions",
        value: "id",
        sortable: false,
      },
    ]);

    return {
      currentFacilityId,
      addAlert,
      unitTypesGrouped,
      unitTypes,
      sortBy,
      sortDesc,
      headers,
      newUnitType,
      editUnitType,
      editingId: editing,
      fetchPMSUnitTypesStats,
      hasPmsStats,
    };
  },
  watch: {
    hasPmsStats: {
      handler(val) {
        if (val) {
          this.fetchPMSUnitTypesStats(this.$apolloProvider);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async addItem() {
      const validated = validateUnitType(this.newUnitType);
      if (!validated) return;

      try {
        const response = await this.$apollo.mutate({
          mutation: CREATE_UNIT_TYPE,
          variables: {
            organizationId: this.currentFacilityId,
            ...validated,
          },
        });

        this.unitTypes.push(response.data.createUnitType);
        this.newUnitType = {};
      } catch (error) {
        if (error.message.endsWith("name: has already been taken")) {
          this.addAlert({
            type: "error",
            message: `Size ${this.newUnitType} already exists. Please add new unique type.`,
            timeout: 15,
          });
        } else {
          this.addAlert({
            type: "error",
            message: "There was an error adding new smart unit type",
          });
        }
      }
    },

    editItem(unitTypeId) {
      const editingIndex = this.unitTypes.findIndex(
        (unitType) => unitType.id === unitTypeId
      );
      if (editingIndex < 0) return;

      this.editingId = unitTypeId;
      this.editUnitType = this.unitTypes[editingIndex];
    },

    async updateItem() {
      const validated = validateUnitType(this.editUnitType);
      if (!validated) return;

      try {
        const response = await this.$apollo.mutate({
          mutation: UPDATE_UNIT_TYPES,
          variables: {
            id: this.editingId,
            organizationId: this.currentFacilityId,
            ...validated,
          },
        });

        const editingIndex = this.unitTypes.findIndex(
          (unitType) => unitType.id === this.editingId
        );
        this.unitTypes[editingIndex] = response.data.updateUnitType;
        this.editUnitType = {};
        this.editingId = null;
      } catch (error) {
        console.log(error);
        if (error.message.endsWith("name: has already been taken")) {
          this.addAlert({
            type: "error",
            message: `Size ${displayUnitType(
              this.editUnitType
            )} already exists. Please use unique type.`,
            timeout: 15,
          });
        } else {
          this.addAlert({
            type: "error",
            message: "There was an error updating smart unit type",
          });
        }
      }
    },
    sortItems,
  },
};

function validateUnitType({ name, width, length }) {
  const validated = {
    name: name.trim(),
    width: width ? parseFloat(width) : undefined,
    length: length ? parseFloat(length) : undefined,
  };
  return validated.name ? validated : null;
}

/**
 * Formats type name, type size,
 * adds total, totalSmartUnits as # of units with devices,
 * occupiedSmartUnits as # of units with billing account and devices,
 * calculates vacantSmartUnits
 *
 * @param {Object} unitType
 */
function enrichTypeWithUnitsCount(unitType) {
  if (!unitType?.units) return unitType;
  const withDevices = unitType.units.filter((u) => u.devices.length > 0);
  const billingAccountsWithDevices = withDevices.filter((u) => {
    const notClosedAccounts = u.billingAccounts.filter(
      (ba) => ba.status !== "closed"
    );
    return notClosedAccounts.length > 0;
  });

  return {
    ...unitType,
    typeName: displayUnitType(unitType, "type"),
    size: displayUnitType(unitType, "size"),
    total: unitType?.units?.length,
    totalSmartUnits: withDevices.length,
    occupiedSmartUnits: billingAccountsWithDevices.length,
    vacantSmartUnits: withDevices.length - billingAccountsWithDevices.length,
  };
}

/**
 * Adds total occupied and calculates total vacant
 *
 * @param {Object} unitType
 * @param {Object} pmsUnitType
 */
function maybeEnrichTypeWithPmsStats(unitType, pmsUnitType) {
  return pmsUnitType
    ? {
        ...unitType,
        occupied: pmsUnitType?.occupiedUnits,
        vacant:
          pmsUnitType?.occupiedUnits !== undefined
            ? unitType.total - pmsUnitType.occupiedUnits
            : undefined,
      }
    : unitType;
}

function groupBySize(acc, unitType) {
  const sizeKey = unitType.size;
  return {
    ...acc,
    [sizeKey]: acc[sizeKey] ? [...acc[sizeKey], unitType] : [unitType],
  };
}

function enrichWithGroupInfo([sizeKey, unitTypes], sortBy) {
  const groupArea = (unitTypes[0]?.width || 1) * (unitTypes[0].length || 1);
  const groupTotal = countUnits(unitTypes, "total");
  const groupTotalSmartUnits = countUnits(unitTypes, "totalSmartUnits");
  const groupOccupied = countUnits(unitTypes, "occupied");
  const groupOccupiedSmartUnits = countUnits(unitTypes, "occupiedSmartUnits");
  const groupVacant = countUnits(unitTypes, "vacant");
  const groupVacantSmartUnits = countUnits(unitTypes, "vacantSmartUnits");
  const groupPos = `${groupPosition(
    sortBy,
    {
      name: -groupArea,
      total: groupTotal,
      totalSmartUnits: groupTotalSmartUnits,
      occupied: groupOccupied,
      occupiedSmartUnits: groupOccupiedSmartUnits,
      vacant: groupVacant,
      vacantSmartUnits: groupVacantSmartUnits,
    },
    -groupArea
  )}_${sizeKey}`;
  return unitTypes.map((unitType) => ({
    ...unitType,
    groupPos,
    groupArea,
    groupTotal,
    groupTotalSmartUnits,
    groupOccupiedSmartUnits,
    groupOccupied,
    groupVacant,
    groupVacantSmartUnits,
    groupCount: unitTypes.length,
  }));
}

function countUnits(unitTypes, field) {
  return unitTypes.reduce(
    (acc, ut) =>
      Number.isInteger(ut[field]) && Number.isInteger(acc)
        ? acc + ut[field]
        : undefined,
    0
  );
}

function groupPosition(sortBy, groupValues, defaultPositionValue) {
  return groupValues[sortBy] ?? defaultPositionValue;
}
</script>
