<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    isKudo: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            // Get the original data from the realData property
            const realData = data.datasets[tooltipItem.datasetIndex].realData;
            const label = data.labels[tooltipItem.index];
            const value = realData[tooltipItem.index];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        onClick: (e) => e.stopPropagation(), // Disable clicking on legend
      },
      onClick: this.handleClick,
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (this.isKudo) {
        return;
      }
      if (items.length > 0) {
        const clickedData = this.chartData.labels[items[0]._index];
        this.goToRevenueTable(clickedData);
      }
    },
    replaceString(input, replacements) {
      for (const [key, value] of Object.entries(replacements)) {
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        input = input.replace(new RegExp(escapedKey, "g"), value);
      }
      return input;
    },
    goToRevenueTable(value) {
      // Define your replacements object here
      const replacements = {
        "2 - 10 mins": "2To10",
        "10 - 20 mins": "10To20",
        "1 - 20": "1To20",
        "20 - 40": "20To40",
      };

      // Call the replaceString method and log the replaced value
      const modifiedValue = this.replaceString(value, replacements);

      // Push the modified value to the router
      this.$router.push(`/demo/management/average-visit/${modifiedValue}`);
    },
  },
};
</script>
