<template>
  <v-skeleton-loader
    :loading="loading"
    type="table-thead, table-row-divider@10"
  >
    <v-data-table
      class="table-striped"
      :headers="headers"
      :items="inventory"
      :custom-sort="customSort"
      :custom-filter="customFilter"
      :search="search"
      :sort-by="['name']"
      :items-per-page.sync="tableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
    >
      <template #item.action="{ item }">
        <ButtonView v-if="item.type === 'unit'" @click="gotoUnit(item.id)" />
        <ButtonAssignDevice v-else :device="item.devices[0]" />
      </template>

      <template #item.name="{ item }">
        <span v-if="item.type === 'unit'">
          <PossiblePMS as-icon :sync-obj="item">
            <span :class="{ 'grey--text': !!item.responder }">
              {{ item.name }}
            </span>
          </PossiblePMS>
          <v-btn
            v-if="item.responder"
            icon
            small
            :to="{
              name: 'ViewRenter',
              params: {
                renter_id: item.responder.user.id,
              },
            }"
          >
            <UserIcon
              :responder="item.responder"
              :title="
                item.responder.status === 'ended_service'
                  ? `Unit is occupied by ended SD service renter ${item.responder.user.firstName} ${item.responder.user.lastName}`
                  : ''
              "
            />
          </v-btn>
        </span>
        <AutocompleteInventoryUnits
          v-else
          :key="item.id"
          :device="item.devices[0]"
        />
      </template>

      <template #item.devicesName="{ item: { devicesName } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in devicesName"
            :key="device"
            class="ml-0 pl-0"
          >
            <v-list-item-content>{{ device }}</v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.devicesHealth="{ item: { devices } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="device in devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-content>
              <DeviceGaugeCluster :device="device" />
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.devicesLastMotion="{ item: { devicesLastMotion } }">
        <v-list dense color="transparent">
          <v-list-item
            v-for="(lastMotion, index) in devicesLastMotion"
            :key="index"
            class="ml-0 pl-0"
          >
            <v-list-item-content>
              {{ lastMotion }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template
        #item.devicesLastAssignedTo="{
          item: { devicesLastAssignedTo, devices },
        }"
      >
        <v-list dense color="transparent">
          <v-list-item
            v-for="(lastAssignedTo, index) in devicesLastAssignedTo"
            :key="index"
            class="ml-0 pl-0"
          >
            <v-list-item-icon class="action-btn mr-4">
              <DialogAssignmentHistory :device="devices[index]" />
            </v-list-item-icon>
            <v-list-item-content>
              {{ lastAssignedTo }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.manage="{ item: { type, devices, name } }">
        <MenuManageDevice
          v-for="device in devices"
          :key="device.id"
          :device="device"
          :unit-name="type === 'unit' ? name : undefined"
          v-on="$listeners"
        />
      </template>

      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="inventory"
          :headers="headers"
          filename="SD_Inventory_Available"
        />
        <ButtonPrint
          class="order-last"
          :before-print="showAllItems"
          :after-print="restoreItemsPerPage"
        />
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import ButtonView from "@atoms/ButtonView.vue";
import UserIcon from "../../renters/UserIcon.vue";
import ButtonAssignDevice from "./ButtonAssignDevice.vue";
import AutocompleteInventoryUnits from "./AutocompleteInventoryUnits.vue";
import DialogAssignmentHistory from "./DialogAssignmentHistory.vue";
import MenuManageDevice from "./MenuManageDevice.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { stringSortCollator } from "@tod-ui/helpers/strings";
import useDataTable from "../../common/useDataTable";
import useInventory from "../useInventory";
import PossiblePMS from "@components/common/PossiblePMS.vue";

export default {
  name: "InventoryAssignableTable",
  components: {
    DeviceGaugeCluster,
    ButtonView,
    DialogAssignmentHistory,
    UserIcon,
    ButtonPrint,
    ButtonExportCSV,
    MenuManageDevice,
    ButtonAssignDevice,
    AutocompleteInventoryUnits,
    PossiblePMS,
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { loading, availableInventory } = useInventory();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
    });

    return {
      loading,
      inventory: availableInventory,
      tableState,
      showAllItems,
      restoreItemsPerPage,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Actions",
          value: "action",
          width: "1%",
          class: "action-column",
          cellClass: "action-column",
          filterable: false,
          sortable: false,
        },
        {
          text: "Smart Unit #",
          value: "name",
          width: "15%",
        },
        { text: "Unit Type", value: "unitTypeName", width: "10%" },
        { text: "Unit Size", value: "unitTypeSize", width: "10%" },
        {
          text: "Device #",
          value: "devicesName",
          width: "10%",
        },
        {
          text: "Device Health",
          value: "devicesHealth",
          width: "10%",
          sortable: false,
          class: "non-export",
          cellClass: "non-export",
        },
        {
          text: "Last Motion",
          value: "devicesLastMotion",
          width: "15%",
          class: "text-no-wrap",
        },
        {
          text: "Last Assigned Unit",
          value: "devicesLastAssignedTo",
          width: "15%",
          class: "text-no-wrap",
          sortable: false,
        },
        {
          text: "Manage Device",
          value: "manage",
          width: "1%",
          class: "action-column",
          cellClass: "action-column",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      const _search = search.toLowerCase();

      if (item.name.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `device`
      if (
        item.devicesName.find((d) => d.toLowerCase().indexOf(_search) >= 0) !==
        undefined
      )
        return true;

      // Filter on lastAssigned
      if (
        item.devicesLastAssignedTo.find(
          (d) => d.toLowerCase().indexOf(_search) >= 0
        ) !== undefined
      )
        return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;
      items.sort((a, b) => {
        if (_sortBy === "name") {
          return (
            (a.type === "device"
              ? 1
              : b.type === "device"
              ? -1
              : stringSortCollator.compare(a.name, b.name)) * _sortDesc
          );
        } else if (_sortBy === "devicesName") {
          const aDevices = a.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");
          const bDevices = b.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");

          return stringSortCollator.compare(aDevices, bDevices) * _sortDesc;
        } else if (_sortBy === "devicesLastMotion") {
          var Aname = "";
          var Bname = "";
          var AextContactDate = a.devices[0].motionStartedAt;
          if (a.devices.length > 1) {
            for (const dev in a.devices) {
              if (a.devices[dev].motionStartedAt > AextContactDate) {
                if (_sortDesc) {
                  Aname = a.devices[dev].shortId || a.devices[dev].externalId;
                }
                AextContactDate = _sortDesc
                  ? a.devices[dev].motionStartedAt
                  : AextContactDate;
              } else {
                AextContactDate = !_sortDesc
                  ? a.devices[dev].motionStartedAt
                  : AextContactDate;
                if (!_sortDesc) {
                  Aname = a.devices[dev].shortId || a.devices[dev].externalId;
                }
              }
            }
          } else {
            Aname = a.devices[0].shortId || a.devices[0].externalId;
          }
          var BextContactDate = b.devices[0].motionStartedAt;
          if (b.devices.length > 1) {
            for (const dev in b.devices) {
              if (b.devices[dev].motionStartedAt > BextContactDate) {
                BextContactDate = _sortDesc
                  ? b.devices[dev].motionStartedAt
                  : BextContactDate;
                if (_sortDesc) {
                  Bname = b.devices[dev].shortId || b.devices[dev].externalId;
                }
              } else {
                BextContactDate = !_sortDesc
                  ? b.devices[dev].motionStartedAt
                  : BextContactDate;
                if (!_sortDesc) {
                  Bname = b.devices[dev].shortId || b.devices[dev].externalId;
                }
              }
            }
          } else {
            Bname = b.devices[0].shortId || b.devices[0].externalId;
          }
          AextContactDate =
            AextContactDate == null
              ? "1970-01-01T00:00:00.000000Z"
              : AextContactDate;
          BextContactDate =
            BextContactDate == null
              ? "1970-01-01T00:00:00.000000Z"
              : BextContactDate;

          return (BextContactDate < AextContactDate ? -1 : 1) * _sortDesc;
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    gotoUnit(unitId) {
      this.$router.push({
        name: "ViewSmartUnit",
        params: {
          zone_id: unitId,
        },
      });
    },
  },
};
</script>
