import gql from "graphql-tag";

export const notificationFields = gql`
  fragment NotificationFields on Notification {
    id
    type
    name
    severity
    status
    triggeringEntities
    insertedAt
    updatedAt
    body
    title
    automatedResolution
  }
`;

export const NOTIFICATIONS = gql`
  query UserNotifications(
    $userId: ID!
    $organizationId: ID
    $severity: String
    $severities: [String]
    $status: String
    $statuses: [String]
    $type: String
    $types: [String]
  ) {
    userNotifications(
      userId: $userId
      organizationId: $organizationId
      severity: $severity
      severities: $severities
      status: $status
      statuses: $statuses
      type: $type
      types: $types
      sort: "insertedAt"
    ) {
      ...NotificationFields
      organization {
        id
      }
      user {
        id
      }
    }
  }
  ${notificationFields}
`;

export const NOTIFICATION_DETAILS = gql`
  query NotificationDetails($id: ID!, $markAsRead: Boolean) {
    userNotification(id: $id, markAsRead: $markAsRead) {
      ...NotificationFields
      organization {
        id
        name
        type
        subtype
      }
      events {
        type
        name
        timestamp
        data
      }
    }
  }
  ${notificationFields}
`;

export const NOTIFICATION_FROM_SIBLING = gql`
  query UserNotificationFromSibling($id: ID!) {
    userNotification(id: $id, markAsRead: true) {
      id
      notificationSiblings(type: "application") {
        id
        name
        triggeringEntities
        organization {
          id
        }
      }
    }
  }
`;

export const DISMISS_NOTIFICATION = gql`
  mutation DismissNotification($id: ID!) {
    updateNotification(id: $id, status: "dismissed") {
      id
      status
    }
  }
`;

export const MARK_NOTIFICATION_READ = gql`
  query MarkNotificationRead($id: ID!) {
    userNotification(id: $id, markAsRead: true) {
      id
      status
    }
  }
`;
