/**
 * @callback onResultCallback
 * @param {import("apollo-client").ApolloQueryResult.data} data
 * @param {boolean} loading
 */
/**
 * Runs the query end returns
 * @param {import("apollo-client").ApolloClient} apolloClient
 * @param {import("apollo-client").WatchQueryOptions} queryProps
 * @param {onResultCallback} onResultCallback
 * @returns
 */
export function apolloWatchQuery(apolloClient, queryProps, onResultCallback) {
  /** @type {import("apollo-client").ObservableQuery} */
  const observableQuery = apolloClient.watchQuery(queryProps);
  observableQuery.subscribe({
    next({ data, loading }) {
      // console.log(queryProps.query.definitions[0].name.value, loading, data);
      onResultCallback(data, loading);
    },
    error(err) {
      //Errors should be already logged with apollo link
      // console.log("observable", err);
    },
  });
  return observableQuery;
}
