<template>
  <LayoutPage :title="['Renters', renterName, 'Remove']" :loading="userLoading">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(save)">
        <v-row class="mx-10">
          <v-col lg="6">
            <ValidationProvider
              v-slot="{ errors }"
              name="A reason"
              rules="required"
            >
              <v-radio-group
                v-model="eos_form.reason"
                dense
                :error-messages="errors"
              >
                <p class="body-1">
                  Why won't this customer be using StorageDefender service?
                  <span class="required">*</span>
                </p>
                <v-radio label="Normal End of Lease" value="eos" />
                <v-radio label="Customer not paying" value="not_paying" />
                <v-radio label="Price was too high" value="price" />
                <v-radio label="Didn't like service" value="did_not_like" />
                <v-radio label="Didn't need service" value="did_not_need" />
                <v-radio
                  label="Experienced significant service issue"
                  value="service_issue"
                />
                <v-radio label="Company Testing" value="testing" />
              </v-radio-group>
            </ValidationProvider>
          </v-col>

          <v-col lg="6">
            <p class="body-1 mt-4">Additional Comments</p>
            <v-textarea v-model="eos_form.comments" outlined solo />
          </v-col>
        </v-row>

        <v-card-actions class="mt-8 mx-12">
          <v-spacer />
          <v-btn color="warning" type="submit"> Remove Renter </v-btn>
          <v-btn outlined @click="$router.go(-1)"> Cancel </v-btn>
        </v-card-actions>
      </form>
    </ValidationObserver>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import { userDisplayName } from "../renters/helper";

import { UPDATE_BILLING_ACCOUNT } from "../renters/graphql";
import {
  findUser,
  createEosForm,
  userBillingAccounts,
  organizationUsersReport,
} from "../../graphql/users";

import { required } from "vee-validate/dist/rules";
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "PageRenterRemove",
  components: {
    ValidationObserver,
    ValidationProvider,
    LayoutPage,
  },
  setup() {
    const { currentFacilityId } = useFacility();
    const { addAlert } = useAlerts();
    return { currentFacilityId, addAlert };
  },
  data: () => ({
    user: null,
    eos_form: {},
    userBillingAccount: [],
  }),
  computed: {
    userLoading() {
      return this.$apollo.queries.user.loading;
    },
    renterName() {
      if (this.userLoading || !this.user) return "...";
      return userDisplayName(this.user);
    },
  },
  apollo: {
    user: {
      query: findUser,
      variables() {
        return { id: this.$route.params.renter_id };
      },
    },
    userBillingAccount: {
      query: userBillingAccounts,
      variables() {
        return {
          userId: this.$route.params.renter_id,
          parentOrganizationId: this.currentFacilityId,
        };
      },
      update({ userBillingAccounts }) {
        return userBillingAccounts.find(
          (account) => account.billingAccount.type == "consumer"
        );
      },
    },
  },
  methods: {
    async save() {
      try {
        // This is reasonably safe, as billing accounts are filtered to only
        // this facility. It's possible, but unlikely that an account will have
        // organizations across multiple facilities.
        const billingAccount = this.userBillingAccount.billingAccount;
        // First, create the EOS form
        await this.$apollo.mutate({
          mutation: createEosForm,
          variables: {
            ...this.eos_form,
            billingAccountId: billingAccount.id,
            organizationId: this.currentFacilityId,
          },
        });

        // Next, update the billing account status to "closed".
        // This will also remove renter's facility and assigned zones/units
        // from the account
        await this.$apollo.mutate({
          mutation: UPDATE_BILLING_ACCOUNT,
          variables: {
            ...billingAccount,
            status: "closed",
          },
          refetchQueries: [
            {
              query: organizationUsersReport,
              variables: { id: this.currentFacilityId },
            },
          ],
        });

        // Back to View Renters.
        this.$router.push({ name: "Renters" });
        this.addAlert({
          type: "success",
          message: `Renter ${this.renterName} was remove successfully.`,
          timeout: 15,
        });
      } catch (error) {
        this.addAlert({
          type: "error",
          message: `Error removing renter ${this.renterName}.`,
          timeout: 25,
        });
      }
    },
  },
};
</script>
