<template>
  <LayoutPage :title="incidentTitle">
    <template v-if="!needsSurvey" #actions>
      <ButtonPrint :icon="false" color="grey darken-2" />
    </template>

    <v-row>
      <v-col v-if="!needsSurvey" md="8">
        <FormIncidentSurvey />
      </v-col>

      <v-col
        :lg="needsSurvey ? 4 : 8"
        :md="needsSurvey ? 4 : 10"
        :sm="needsSurvey ? 4 : 12"
        class="mx-auto print-full-width"
      >
        <CardIncidentInfo :single-column="needsSurvey" />
      </v-col>
      <v-col v-if="surveyed" lg="8" md="10" sm="12" class="print-full-width">
        <CardIncidentReport />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { computed, onUnmounted } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import CardIncidentInfo from "./DemoCardIncidentInfo.vue";
import CardIncidentReport from "@components/incident/CardIncidentReport.vue";
import FormIncidentSurvey from "@components/incident/FormIncidentSurvey.vue";
import useIncident from "@components/incident/useIncident";
import ButtonPrint from "@atoms/ButtonPrint.vue";

export default {
  name: "PageIncident",
  components: {
    LayoutPage,
    CardIncidentInfo,
    FormIncidentSurvey,
    CardIncidentReport,
    ButtonPrint,
  },
  setup() {
    const {
      incident,
      incidentTitle,
      loading,
      fetchIncident,
      cleanupIncidentRef,
    } = useIncident();

    const needsSurvey = computed(() => incident.value?.needsSurvey);
    const surveyed = computed(() => incident.value?.surveyed);

    onUnmounted(cleanupIncidentRef);
    return {
      incidentTitle,
      loading,
      needsSurvey,
      surveyed,
      fetchIncident,
    };
  },
  computed: {
    incidentId() {
      return this.$route.params.incident_id;
    },
  },
  beforeMount() {
    this.fetchIncident(this.$apollo, this.incidentId);
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
    padding: 0 0 0.35em 0;
  }
}
</style>
