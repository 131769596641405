<template>
  <v-card outlined :disabled="disabled" class="sd-card">
    <v-toolbar dense color="grey lighten-2">
      <v-toolbar-title class="subtitle-1">
        {{ title }}
      </v-toolbar-title>
      <v-progress-linear
        :active="loading"
        absolute
        bottom
        height="2"
        indeterminate
      />
      <v-spacer />
      <slot name="header-actions"></slot>
      <template v-if="headerType === 'edit'">
        <template v-if="!model">
          <PossiblePMS v-if="pmsAwareEdit" v-slot="{ disabled: disabledEdit }">
            <v-btn
              outlined
              small
              :disabled="disabledEdit"
              :title="editHint"
              @click="model = 'editing'"
            >
              <v-icon small left>{{ icon }}</v-icon>
              Edit
            </v-btn>
          </PossiblePMS>
          <v-btn
            v-else
            outlined
            small
            :title="editHint"
            @click="model = 'editing'"
          >
            <v-icon small left>{{ icon }}</v-icon>
            Edit
          </v-btn>
        </template>
        <template v-else>
          <v-btn outlined small :title="cancelHint" @click="model = ''">
            <v-icon small left>fas fa-times-circle</v-icon>
            Cancel
          </v-btn>
        </template>
      </template>
      <template v-else-if="headerType === 'manage'">
        <v-btn
          outlined
          small
          :title="hint"
          @click="$emit('manage-button-click')"
        >
          <v-icon small left>fas fa-cog</v-icon>
          Manage
        </v-btn>
      </template>
      <template v-else-if="headerType === 'search'">
        <div style="max-width: 240px">
          <SearchField v-model="model" />
        </div>
      </template>
    </v-toolbar>
    <v-divider />

    <template v-if="hasTopActions">
      <v-card-actions class="mt-2">
        <v-spacer />
        <slot name="top-actions"></slot>
      </v-card-actions>
    </template>

    <v-card-text :class="{ 'text-center': loading }">
      <template v-if="loading">
        <slot name="load">
          <em>Loading...</em>
        </slot>
      </template>

      <template v-else-if="headerType === 'edit' && model">
        <slot name="edit"></slot>
      </template>

      <template v-else>
        <slot></slot>
      </template>
    </v-card-text>

    <template v-if="hasActions">
      <v-divider />
      <v-card-actions class="action-btn">
        <v-spacer />
        <slot name="actions"></slot>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import PossiblePMS from "../common/PossiblePMS.vue";
import SearchField from "@atoms/SearchField.vue";

export default {
  name: "CardLayout",
  components: {
    SearchField,
    PossiblePMS,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    headerType: {
      validator: (value) =>
        ["", "edit", "manage", "search"].includes(value) !== -1,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    cancelHint: {
      type: String,
      default: "Cancel Changes",
    },
    pmsAwareEdit: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    iconType: {
      type: String,
      default: "edit",
    },
  },
  emits: ["input"],
  computed: {
    editHint() {
      return this.hint || "Edit information";
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    hasTopActions() {
      return !!this.$slots["top-actions"];
    },
    hasActions() {
      return !!this.$slots.actions;
    },
    icon() {
      return this.iconType === "edit" ? "fas fa-edit" : "mdi-cog-outline";
    },
  },
};
</script>
