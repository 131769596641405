<template>
  <LayoutPage title="Monthly Trends">
    <template #actions>
      <SwitchRegionalView v-model="regionalView" />
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedReport"
          :items="reportOptions"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedPeriod"
          :items="periodOptions"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <template v-if="selectedReport === 'revenue'">
      <MonthlyRevenueTrend
        :selected-facilities="selectedFacilities"
        :selected-period="selectedPeriod"
        :regional-view="regionalView"
      />
    </template>
    <template v-else>
      <MonthlyConversionTrend
        :selected-facilities="selectedFacilities"
        :selected-period="selectedPeriod"
        :regional-view="regionalView"
      />
    </template>
  </LayoutPage>
</template>

<script>
import { ref, watchEffect } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import MonthlyRevenueTrend from "./MonthlyRevenueTrend.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import useDashboard from "../useDashboard";
import MonthlyConversionTrend from "./MonthlyConversionTrend.vue";
import { useRoute } from "vue-router/composables";
import SwitchRegionalView from "@atoms/SwitchRegionalView.vue";

export default {
  components: {
    LayoutPage,
    RegionAndFacilitySelect,
    MonthlyRevenueTrend,
    MonthlyConversionTrend,
    SwitchRegionalView,
  },
  setup() {
    const route = useRoute();
    const { selectedFacilities } = useDashboard();

    const regionalView = ref(true);

    const reportOptions = ref([
      { text: "Revenue", value: "revenue" },
      { text: "Conversion", value: "conversion" },
    ]);

    const periodOptions = ref([
      { text: "By Trends", value: "byTrends" },
      { text: "By Months", value: "byMonths" },
    ]);

    const selectedReport = ref(reportOptions.value[0].value);
    const selectedPeriod = ref(periodOptions.value[0].value);

    watchEffect(() => {
      selectedReport.value = route.query.report;
      selectedPeriod.value = route.query.period;
    });
    return {
      selectedFacilities,
      reportOptions,
      periodOptions,
      selectedReport,
      selectedPeriod,
      regionalView,
    };
  },
  watch: {
    selectedReport(newReport) {
      if (this.$route.query.report !== newReport) {
        this.$router.push({
          name: "MonthlyTrends",
          query: {
            ...this.$route.query,
            report: newReport,
          },
        });
      }
    },
  },
};
</script>
