<template>
  <LayoutPage title="Lifetime / Missed Revenue Report">
    <template #actions>
      <SwitchRegionalView v-model="regionalView" />
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedYear"
          :items="YEAR_OPTIONS"
          label="Year"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedMonth"
          :items="MONTH_OPTIONS"
          label="Month"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <SearchField v-model="tableState.search" />
      </v-col>
    </v-row>
    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :items="tableData.items"
        :headers="tableData.headers"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.lifetimeRevenueAdds="{ item }">
          <td>
            <TableCellWrapper :is-highlighted="highlightColumn.lifetime_adds">
              ${{ thousandSeparator(roundValue(item.lifetimeRevenueAdds)) }}
            </TableCellWrapper>
          </td>
        </template>
        <template #item.missedPotentialRevenue="{ item }">
          <td>
            <TableCellWrapper
              :is-highlighted="highlightColumn.missed_lifetime_revenue"
            >
              ${{ thousandSeparator(roundValue(item.missedPotentialRevenue)) }}
            </TableCellWrapper>
          </td>
        </template>
        <template #item.averageSubscriptionDuration="{ item }">
          {{ thousandSeparator(roundValue(item.averageSubscriptionDuration)) }}
        </template>
        <template #body.append>
          <tr class="table-total">
            <td>Total</td>
            <td>{{ thousandSeparator(totalAddedSubscriptionsMTD) }}</td>
            <td>{{ thousandSeparator(totalMissedSubscriptionsMTD) }}</td>
            <td>{{ thousandSeparator(totalMoveInsMTD) }}</td>
            <td>{{ thousandSeparator(totalAverageSubscriptionDuration) }}</td>
            <td>
              <TableCellWrapper :is-highlighted="highlightColumn.lifetime_adds">
                ${{ thousandSeparator(totalLifetimeRevenueAdds) }}
              </TableCellWrapper>
            </td>
            <td>
              <TableCellWrapper
                :is-highlighted="highlightColumn.missed_lifetime_revenue"
              >
                ${{ thousandSeparator(totalMissedPotentialRevenue) }}
              </TableCellWrapper>
            </td>
          </tr>
        </template>
        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="tableData.items"
            :headers="tableData.headers"
            filename="Monthly_Trends"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router/composables";
import useDashboard from "../useDashboard";
import useDataTable from "@components/common/useDataTable";
import { getDateParts } from "@tod-ui/helpers/datetime";
import { calculateTotal, roundValue } from "@tod-ui/helpers/math";
import { REVENUE_REPORT } from "./graphql";
import { MONTH_OPTIONS, YEAR_OPTIONS } from "@tod-ui/constants/dashboard";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import SearchField from "@atoms/SearchField.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import TableCellWrapper from "@layout/TableCellWrapper.vue";
import { thousandSeparator } from "@tod-ui/helpers/strings";
import { groupByRegion } from "../helper";
import SwitchRegionalView from "@atoms/SwitchRegionalView.vue";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    LayoutPage,
    ButtonExportCSV,
    ButtonPrint,
    SearchField,
    RegionAndFacilitySelect,
    TableCellWrapper,
    SwitchRegionalView,
  },
  setup() {
    const { selectedFacilities } = useDashboard();
    const { defaultOrganization } = useUser();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });

    const selectedYear = ref(
      getDateParts(
        { year: true },
        undefined,
        defaultOrganization.value.timeZone
      )
    );
    const selectedMonth = ref(
      getDateParts(
        { month: true },
        undefined,
        defaultOrganization.value.timeZone
      )
    );
    const selectedDate = computed(
      () => `${selectedYear.value}-${selectedMonth.value}-01`
    );

    const route = useRoute();
    const highlightColumn = computed(() => ({
      lifetime_adds:
        route.query.highlight_column === "lifetimerevenueadds(mtd)",
      missed_lifetime_revenue:
        route.query.highlight_column === "missedlifetimerevenue",
    }));

    return {
      selectedFacilities,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      selectedYear,
      selectedMonth,
      selectedDate,
      YEAR_OPTIONS,
      MONTH_OPTIONS,
      highlightColumn,
      roundValue,
      thousandSeparator,
    };
  },
  data() {
    return {
      revenueReport: [],
      regionalView: true,
    };
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
    regionWiseReport() {
      const fieldsToSum = [
        "lifetimeRevenueAdds",
        "missedPotentialRevenue",
        "missedSubscriptionsInMonth",
        "moveInsInMonth",
        "netAddedSubscriptionsInMonth",
      ];
      const avgFields = ["averageSubscriptionDuration"];

      return groupByRegion(this.revenueReport, fieldsToSum, avgFields);
    },

    tableData() {
      return {
        headers: [
          this.regionalView
            ? {
                text: "Organization / Region",
                value: "organizationPath",
                width: 200,
              }
            : { text: "Facility Name", value: "facilityName", width: 200 },
          {
            text: "Added Subscriptions (MTD)",
            value: "netAddedSubscriptionsInMonth",
            width: 200,
          },
          {
            text: "Missed Subscriptions (MTD)",
            value: "missedSubscriptionsInMonth",
            width: 200,
          },
          { text: "Total Move-Ins (MTD)", value: "moveInsInMonth", width: 200 },
          {
            text: "Average Subscription Duration (Months)",
            value: "averageSubscriptionDuration",
            width: 200,
          },
          {
            text: "Lifetime Adds ($)",
            value: "lifetimeRevenueAdds",
            width: 130,
          },
          {
            text: "Missed Potential Revenue ($)",
            value: "missedPotentialRevenue",
            width: 200,
          },
        ],
        items: this.regionalView ? this.regionWiseReport : this.revenueReport,
      };
    },
    totalAddedSubscriptionsMTD() {
      return calculateTotal(this.revenueReport, "netAddedSubscriptionsInMonth");
    },
    totalMissedSubscriptionsMTD() {
      return calculateTotal(this.revenueReport, "missedSubscriptionsInMonth");
    },
    totalMoveInsMTD() {
      return calculateTotal(this.revenueReport, "moveInsInMonth");
    },
    totalAverageSubscriptionDuration() {
      return roundValue(
        calculateTotal(this.revenueReport, "averageSubscriptionDuration") /
          this.revenueReport.length
      );
    },
    totalLifetimeRevenueAdds() {
      return roundValue(
        calculateTotal(this.revenueReport, "lifetimeRevenueAdds")
      );
    },
    totalMissedPotentialRevenue() {
      return roundValue(
        calculateTotal(this.revenueReport, "missedPotentialRevenue")
      );
    },
  },
  apollo: {
    revenueReport: {
      query: REVENUE_REPORT,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          month: this.selectedDate,
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
};
</script>
