<template>
  <v-btn
    small
    title="Print/Export to PDF this data"
    class="action-btn"
    :icon="icon"
    :outlined="!icon"
    v-bind="$attrs"
    v-on="$listeners"
    @click="print"
  >
    <v-icon small>fas fa-print</v-icon>
    <span v-if="!icon" class="ml-2">Print</span>
  </v-btn>
</template>

<script>
import usePrinting from "../usePrinting";

export default {
  name: "ButtonPrint",
  props: {
    icon: {
      type: Boolean,
      default: true,
    },
    beforePrint: {
      validator: (val) => typeof val === "function",
      default: null,
    },
    afterPrint: {
      validator: (val) => typeof val === "function",
      default: null,
    },
  },
  setup() {
    const { setPrinting } = usePrinting();
    return { setPrinting };
  },
  methods: {
    print() {
      if (this.beforePrint) {
        this.beforePrint();
      }
      this.setPrinting(true);

      this.$nextTick(() => {
        const allTableFooters = document.querySelectorAll(
          ".v-data-table .v-data-footer"
        );
        // hide table footer with pagination
        allTableFooters.forEach((el) => {
          el.classList.add("action-btn");
        });
        window.print();
        // restore
        allTableFooters.forEach((el) => el.classList.remove("action-btn"));
        if (this.afterPrint) {
          this.afterPrint();
        }
        this.setPrinting(false);
      });
    },
  },
};
</script>
