<template>
  <v-autocomplete
    v-model="model"
    :disabled="disabled"
    :search-input.sync="search"
    :items="items"
    :item-text="formatUnitName"
    item-value="id"
    item-disabled="assigned"
    dense
    outlined
    hide-details
    clearable
    clear-icon="far fa-times-circle"
    :placeholder="!disabled ? 'Select Unit' : ''"
  >
    <template #no-data>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="font-italic">
            No existing units that match
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-if="allowCreation && !exactMatch" #append-item>
      <v-list-item
        :to="{
          name: 'AddSmartUnit',
          query: {
            name: search,
            device: device.id,
          },
        }"
      >
        <v-list-item-icon title="Click to Create Smart Unit" class="mr-2">
          <v-icon small color="success"> fas fa-plus-circle </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ search }}
          </v-list-item-title>
          <v-list-item-subtitle class="font-italic">
            Create a new Smart Unit
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { computed, ref } from "vue";
import useInventory from "../useInventory";
import useDevice from "../useDevice";
import useSmartUnits from "../useSmartUnits";
import { stringSearch } from "@tod-ui/helpers/strings";
import usePMS from "../../common/usePMS";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "AutocompleteInventoryUnits",
  props: {
    device: {
      type: Object,
      required: true,
    },
    exclude: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { inventoryUnits } = useInventory();
    const { searchExistingUnits } = useSmartUnits();
    const { hasPMS } = usePMS();
    const allowCreation = computed(
      () => !hasPMS.value && search.value?.length >= 2
    );

    const search = ref("");
    const rentedUnits = ref([]);

    const items = computed(() => [
      ...(props.exclude
        ? inventoryUnits.value.filter((unit) => unit.name !== props.exclude)
        : inventoryUnits.value),
      ...rentedUnits.value,
    ]);

    const exactMatch = computed(() => {
      const matchSearchText = stringSearch(search.value);
      return items.value.find((unit) => matchSearchText.exactMatch(unit.name));
    });

    const { setSelectedUnit, selectedUnit } = useDevice(props.device);
    const disabled = computed(() => props.device.status !== "ok");
    const model = computed({
      get: () => selectedUnit.value,
      set: (unitId) => setSelectedUnit(unitId),
    });

    const { addAlert } = useAlerts();

    return {
      items,
      disabled,
      model,
      search,
      exactMatch,
      allowCreation,
      rentedUnits,
      searchExistingUnits,
      addAlert,
    };
  },
  watch: {
    async search(searchText) {
      if (!searchText?.trim()) return;
      try {
        this.rentedUnits = await this.searchExistingUnits(
          this.$apollo,
          searchText,
          {
            assigned: true,
          }
        );
      } catch (errorMessage) {
        this.addAlert({
          message: errorMessage,
        });
      }
    },
  },
  methods: {
    formatUnitName(unit) {
      return unit.renter
        ? `${unit.name} (${unit.renter.firstName} ${unit.renter.lastName})`
        : unit.name;
    },
  },
};
</script>
