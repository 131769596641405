<template>
  <v-list-item :router="!!toRoute" :disabled="disabled" :to="toRoute">
    <v-list-item-icon>
      <v-icon small>{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title><slot></slot></v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { computed } from "vue";
export default {
  name: "SideMenuListItem",
  props: {
    route: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    facilityId: {
      type: String,
      default: undefined,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const toRoute = computed(() => {
      if (props.disabled) return undefined;
      return {
        name: props.route,
        params: props.facilityId
          ? { organization_id: props.facilityId }
          : undefined,
      };
    });
    return { toRoute };
  },
};
</script>
