<template>
  <v-sheet>
    <v-toolbar
      v-if="!hideRoot"
      class="toolbar-custom"
      :height="itemHeight"
      :class="{ current: isCurrent }"
      @click="orgAction"
    >
      <v-btn
        v-if="rootOrg.type != leafType"
        icon
        small
        :class="{ 'align-self-baseline': hasSubheader && subheader !== '' }"
      >
        <v-progress-circular
          v-show="loading"
          indeterminate
          color="primary"
          size="24"
          width="2"
        />
        <v-icon v-show="!loading" small>
          fas fa-caret-{{ expanded ? "down" : "right" }}
        </v-icon>
      </v-btn>
      <OrganizationIcon v-else :organization="rootOrg" small class="ml-n1" />

      <div class="ml-2 d-flex flex-column">
        <span :class="{ 'font-weight-bold': hasSubheader }">
          {{ rootOrg.name }}
        </span>
        <span v-if="hasSubheader" class="subheader">
          {{ subheader }}
        </span>
      </div>

      <v-spacer />
      <slot name="actions" :organization="rootOrg"></slot>
    </v-toolbar>

    <div v-if="expanded" class="ml-4 subtree">
      <OrganizationTree
        v-for="child in orgChildren"
        :key="child.id"
        :root-org="child"
        :leaf-type="leafType"
        :value="orgChildren.length === 1"
        :current-org-id="currentOrgId"
        @action="$listeners.action"
      >
        <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
          <slot :name="slot" v-bind="scope"></slot>
        </template>
      </OrganizationTree>
    </div>
  </v-sheet>
</template>

<script>
import { ORGANIZATION_TREE } from "../../graphql/organizations";
import OrganizationIcon from "./OrganizationIcon.vue";

export default {
  name: "OrganizationTree",
  components: {
    OrganizationIcon,
  },
  props: {
    rootOrg: {
      type: Object,
      required: true,
    },
    leafType: {
      type: String,
      default: "zone",
    },
    hideRoot: {
      type: Boolean,
      default: false,
    },
    rootAction: {
      type: Function,
      default: null,
    },
    currentOrgId: {
      type: String,
      default: "",
    },
    subheader: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default() {
        return !!this.rootOrg.children;
      },
    },
  },
  data() {
    const allOrgTypes = [
      "platform",
      "application",
      "organization",
      "region",
      "facility",
      "zone",
    ];
    const leafIndex = allOrgTypes.findIndex((type) => type === this.leafType);
    const types = allOrgTypes.slice(0, leafIndex + 1);
    return {
      orgChildren: this.rootOrg.children || [],
      expanded: !!this.rootOrg.children?.length,
      loading: false,
      types,
    };
  },
  computed: {
    isCurrent() {
      return this.currentOrgId && this.currentOrgId === this.rootOrg.id;
    },
    hasSubheader() {
      return typeof this.subheader === "string";
    },
    itemHeight() {
      return `${
        22 + // extra whitespace
        (1 + Math.floor(this.rootOrg.name.length / 22)) * 14 + //number of rows * font size
        (this.hasSubheader ? 12 : 0) // extra for subheader
      }px`;
    },
  },
  watch: {
    value(val) {
      this.expanded = val;
    },
    async expanded(val) {
      if (val) {
        await this.fetchOrganizationChildren();
      } else {
        this.orgChildren = [];
      }
      if (this.value !== val) {
        this.$emit("input", val);
      }
    },
  },
  async mounted() {
    if (this.value && this.rootOrg?.type !== this.leafType) {
      if (this.expanded) {
        await this.fetchOrganizationChildren();
      } else {
        this.expanded = true;
      }
    }
  },
  methods: {
    async fetchOrganizationChildren() {
      this.loading = true;
      try {
        const { data } = await this.$apollo.query({
          query: ORGANIZATION_TREE,
          variables: { id: this.rootOrg.id, expanded: true },
          // fetchPolicy: "network-first",
        });

        this.orgChildren = data.organization.children.filter((org) =>
          this.types.includes(org.type)
        );
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    orgAction() {
      if (this.rootOrg.type !== this.leafType) {
        this.expanded = !this.expanded;
        if (this.expanded && typeof this.rootAction === "function") {
          this.rootAction(this.rootOrg);
        }
      } else {
        this.$emit("action", this.rootOrg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-custom {
  cursor: pointer;
  line-height: 1em;
  &::before {
    background-color: currentColor;
    opacity: 0;
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }
  &:hover::before {
    opacity: 0.04;
  }
  &.current::before {
    opacity: 0.12;
  }
}
.subheader {
  font-size: 0.9rem;
  color: #0008;
}
.subtree {
  border-left: 1px solid #0002;
}
</style>
