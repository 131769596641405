<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row justify="end">
        <v-col md="2">
          <v-select
            v-model="weekCount"
            label="Number of Weeks"
            :items="weekOptions"
            small
            dense
          />
        </v-col>
        <v-col md="4">
          <v-btn small depressed outlined tile @click="zoomIn">
            <v-icon small left color="grey darken-1">
              fas fa-search-plus
            </v-icon>
            Zoom In
          </v-btn>
          <v-btn class="ml-2" small depressed outlined tile @click="zoomOut">
            <v-icon small left color="grey darken-1">
              fas fa-search-minus
            </v-icon>
            <v-spacer />
            Zoom Out
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        :loading="loading || !chartDataInitialized"
        type="image, image"
        height="300px"
      >
        <FacilityIncidentReportWeeklyChart
          :chart-data="chartData"
          :max="chartMax"
          :min="chartMin"
          :buckets-per-day="bucketsPerDay"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { FACILITY_REPORT_INCIDENTS } from "./graphql";
import FacilityIncidentReportWeeklyChart from "./FacilityIncidentReportWeeklyChart.vue";
import useFacility from "../authentication/useFacility";
import { getRangeAgo } from "@tod-ui/helpers/datetime";

const BASE_DATASET = {
  label: null,
  backgroundColor: "transparent",
  borderColor: null,
  borderWidth: 2,
  data: [],
  fill: false,
  lineTension: 0,
  pointBackgroundColor: null,
  pointBorderColor: null,
  showLine: true,
  steppedLine: "after",
};

// 20 Unique Colors from the Material Design color pallette
const LINE_COLORS = [
  "#F44336",
  "#673AB7",
  "#03A9F4",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#E91E63",
  "#3F51B5",
  "#00BCD4",
  "#8BC34A",
  "#FFC107",
  "#795548",
  "#9C27B0",
  "#2196F3",
  "#009688",
  "#CDDC39",
  "#FF9800",
  "#607D8B",
  "#9E9E9E",
  "#000000",
];

export default {
  name: "WeeklyIncidentReport",
  components: {
    FacilityIncidentReportWeeklyChart,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    incidents: {},
    chartData: {},
    rangeSelector: 0,
    chartMin: 0,
    chartMax: 8,
    selectedOption: 0,
    bucketsPerDay: 1,
    bucketOptions: [1, 6, 12, 24],
    weekCount: 2,
    weekOptions: [1, 2, 3, 4, 6, 8, 10, 14, 16, 18, 20],
    chartDataInitialized: false,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.incidents.loading;
    },
  },
  apollo: {
    incidents: {
      // This is already filtering for only "consumer" users
      query: FACILITY_REPORT_INCIDENTS,
      variables() {
        return {
          parentId: this.currentFacilityId,
          after: getRangeAgo(
            `${this.weekCount}w`,
            "today",
            this.facilityTimeZone
          ),
        };
      },
      update({ incidents }) {
        this.formatIncidents(incidents);
        return incidents;
      },
    },
  },
  methods: {
    zoomIn() {
      if (this.selectedOption + 1 < this.bucketOptions.length) {
        this.chartDataInitialized = false;
        this.selectedOption += 1;
        this.bucketsPerDay = this.bucketOptions[this.selectedOption];
        this.formatIncidents(this.incidents);
      }
    },
    zoomOut() {
      if (this.selectedOption - 1 >= 0) {
        this.chartDataInitialized = false;
        this.selectedOption -= 1;
        this.bucketsPerDay = this.bucketOptions[this.selectedOption];
        this.formatIncidents(this.incidents);
      }
    },
    formatIncidents(incidents) {
      // Track the max event count for the chart scale/boundary
      let maxCount = 0;

      //Info for initializing data buckets.
      const weekEndTime = DateTime.local()
        .setZone(this.facilityTimeZone)
        .endOf("week");

      // Convert/Map the events into chart datasets
      let week_arrays = this.initializeDataArray(
        0,
        weekEndTime,
        this.weekCount
      );
      for (let incident of incidents) {
        const incStart = DateTime.fromISO(incident.startedAt).setZone(
          this.facilityTimeZone
        );
        let weekOffset =
          DateTime.local().setZone(this.facilityTimeZone).weekNumber -
          incStart.weekNumber;
        let ts = this.convertDateTime(incStart);
        const dataElem = week_arrays[weekOffset].find(({ x }) => x == ts);
        if (dataElem) {
          dataElem.y += 1;
          if (dataElem.y > maxCount) {
            maxCount = dataElem.y;
          }
        }
      }
      week_arrays = this.convertWeekArrays(week_arrays);
      if (maxCount != 0) {
        this.chartMax = maxCount + Math.ceil(maxCount / 20);
      }
      let datasets = [];
      for (let i = 0; i < week_arrays.length; i++) {
        let label = `${i} week(s) ago`;
        if (i == 0) {
          label = "This week";
        }
        datasets.push({
          label: label,
          borderColor: LINE_COLORS[i],
          pointBackgroundColor: LINE_COLORS[i],
          pointBorderColor: LINE_COLORS[i],
          data: week_arrays[i],
          showLine: true,
        });
      }
      this.chartData = {
        datasets: datasets,
      };
      this.$nextTick(() => {
        // Add the component back in
        this.chartDataInitialized = true;
      });
    },
    convertWeekArrays(week_arrays) {
      //This buckets per day
      for (let i = 0; i < week_arrays.length; i++) {
        for (let j = 0; j < week_arrays[i].length; j++) {
          week_arrays[i][j].x = j;
        }
      }
      return week_arrays;
    },
    convertDateTime(datetime) {
      if (!datetime) return datetime;
      const dt = DateTime.fromISO(datetime, {
        zone: this.facilityTimeZone,
      });
      let end_of_day = dt.endOf("day");
      if (this.bucketsPerDay == 1) {
        return end_of_day.toISO();
      }
      const hour_of_day = dt.hour;
      let bucket_size = 24 / this.bucketsPerDay;
      let hour_tracker = bucket_size;
      for (let i = 0; i < this.bucketsPerDay; i++) {
        if (
          hour_of_day >= hour_tracker &&
          hour_of_day <= hour_tracker + bucket_size
        ) {
          return end_of_day
            .minus({
              hours: hour_tracker + bucket_size,
            })
            .toISO();
        }
        hour_tracker += bucket_size;
      }
    },
    initializeDataArray(initialValue, weekEnd, weekCount) {
      let bucket_size = 24 / this.bucketsPerDay;
      let bucket_time = weekEnd;
      let week_arrays = [];
      let init_array = [];
      for (let j = 0; j < weekCount; j++) {
        for (let i = 0; i < this.bucketsPerDay * 7; i++) {
          init_array.push({
            x: bucket_time.toISO(),
            y: initialValue,
          });
          bucket_time = bucket_time.minus({
            hours: bucket_size,
          });
        }
        init_array = init_array.reverse();
        week_arrays.push(init_array);
        init_array = [];
      }
      return week_arrays;
    },
  },
};
</script>
