<template>
  <v-text-field
    v-model="_value"
    background-color="white"
    :autofocus="autofocus"
    :disabled="disabled"
    :placeholder="placeholder"
    dense
    hide-details
    outlined
  >
    <template #append>
      <v-icon small class="mt-1">fas fa-search</v-icon>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: "SearchField",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Search...",
    },
    value: {
      type: String,
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
};
</script>
