export const VISITS_FREQUENCY = {
  LESS_THAN_1: "<1",
  BETWEEN_1_AND_20: "1 - 20",
  BETWEEN_20_AND_40: "20 - 40",
  GREATER_THAN_40: ">40",
};

export const VISIT_DURATION = {
  LESS_THAN_2: "<2 mins",
  BETWEEN_2_AND_10: "2 - 10 mins",
  BETWEEN_10_AND_20: "10 - 20 mins",
  GREATER_THAN_20: ">20 mins",
};

export const YEAR_OPTIONS = [
  { text: "2024", value: "2024" },
  { text: "2023", value: "2023" },
  { text: "2022", value: "2022" },
  { text: "2021", value: "2021" },
  { text: "2020", value: "2020" },
];
export const MONTH_OPTIONS = [
  { text: "January", value: "01" },
  { text: "February", value: "02" },
  { text: "March", value: "03" },
  { text: "April", value: "04" },
  { text: "May", value: "05" },
  { text: "June", value: "06" },
  { text: "July", value: "07" },
  { text: "August", value: "08" },
  { text: "September", value: "09" },
  { text: "October", value: "10" },
  { text: "November", value: "11" },
  { text: "December", value: "12" },
];

export const ROLLING_AVG = "rollingaverage";
