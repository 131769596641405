<script>
import { Line, mixins } from "vue-chartjs";
// https://github.com/chartjs/chartjs-adapter-luxon
// FIXME: import "chartjs-adapter-luxon";
const { reactiveProp } = mixins;

import { formatDate } from "@tod-ui/helpers/datetime";

export default {
  name: "ZoneTrafficChart",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 500,
    },
    title: {
      type: String,
      required: false,
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      tooltips: {
        callbacks: {
          title: function (item) {
            return `Traffic on ${formatDate(item[0].label)}`;
          },
        },
      },
      scales: {
        xAxes: [
          {
            // TODO: Scale based on the date window?
            display: true,
            distribution: "linear",
            type: "time",
            time: {
              unit: "day",
              displayFormats: {
                day: "ddd",
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: Number(this.min),
              max: Number(this.max),
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
  methods: {},
};
</script>
