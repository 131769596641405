<template>
  <v-card
    :id="id"
    :color="backgroundColor"
    style="border: 1px solid black !important"
  >
    <v-card-text class="pt-2 pb-0">
      <v-row dense>
        <v-col md="3" align="left">
          <v-icon :size="$vuetify.breakpoint.xs ? 30 : 50" color="white">
            {{ icon }}
          </v-icon>
        </v-col>
        <v-col md="9" align="right">
          <h4 class="white--text mb-2 text-md-h4 text-h5">
            <template v-if="loading">
              <v-progress-circular indeterminate color="white" />
            </template>
            <template v-else>{{ value }}</template>
          </h4>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col md="12" align="right">
          <p class="white--text text-subtitle-1">{{ title }}</p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-btn :color="color" block @click="$emit('click', id)">
      <v-row>
        <v-col align="left" md="10">
          <v-toolbar-title alight="left" class="body-2 black--text">
            View Details
          </v-toolbar-title>
        </v-col>
        <v-col md="2">
          <v-icon
            small
            align="right"
            class="icon-details"
            :class="{ open: details }"
            color="grey darken-4"
            >fas fa-arrow-circle-right</v-icon
          >
        </v-col>
      </v-row>
    </v-btn>
  </v-card>
</template>

<script>
export default {
  name: "InfoCard",
  props: {
    backgroundColor: {
      type: String,
      required: false,
      default: "#31708f",
    },
    color: {
      type: String,
      default: "#AAAAAA",
    },
    icon: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, String, Number],
      required: true,
    },
  },
};
</script>
