<template>
  <td
    :class="cellClass"
    :style="{
      backgroundColor: isHighlighted ? highlightColor : '',
      minWidth: '180px',
    }"
  >
    <template v-if="isHighlighted">
      {{ formattedValue }}
    </template>
    <template v-else>
      <span
        v-if="typeof value === 'number' && isHighlightColor"
        :style="{ color: textColor }"
      >
        {{ formattedValue }}
        <v-icon :color="iconColor" size="18">
          {{
            value > 0
              ? "mdi-arrow-top-right-thin"
              : value === 0
              ? ""
              : "mdi-arrow-bottom-right-thin"
          }}
        </v-icon>
      </span>
      <span v-else>
        {{ formattedValue }}
      </span>
    </template>
  </td>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    cellClass: {
      type: String,
      default: "",
    },
    highlightColor: {
      type: String,
      default: "rgba(14, 59, 110, 0.2)",
    },
    isHighlightColor: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
      default: "",
    },
  },
  computed: {
    textColor() {
      return this.value > 0 ? "green" : this.value === 0 ? "black" : "red";
    },
    iconColor() {
      return this.value > 0 ? "green" : "red";
    },
    formattedValue() {
      if (this.unit === "$") {
        return `${this.unit}${this.value}`;
      } else if (this.unit) {
        return `${this.value}${this.unit}`;
      }
      return this.value;
    },
  },
};
</script>
