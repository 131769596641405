<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="model"
    :items="users"
    label="User"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { computed } from "vue";
import useUser from "@components/authentication/useUser";
import useFacility from "../authentication/useFacility";
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "SelectUser",
  props: {
    value: {
      validator: (prop) =>
        typeof prop === "string" ||
        typeof prop === "undefined" ||
        prop === null,
      required: true,
    },
  },
  setup() {
    const { facilityUsers, fetchFacilityUsers } = useFacility();

    const { currentUser, displayUserName } = useUser();
    const currentUserId = computed(() => currentUser.value?.id);
    const users = computed(() =>
      [
        currentUser.value,
        ...facilityUsers.value.filter((u) => u.id !== currentUserId.value),
      ]
        .map((user) => ({
          value: user.id,
          text: titleCase(displayUserName(user)),
        }))
        .sort((item1, item2) => item1.text.localeCompare(item2.text))
    );

    return {
      users,
      fetchFacilityUsers,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(userId) {
        this.$emit("input", userId);
      },
    },
  },
  mounted() {
    this.fetchFacilityUsers(this.$apollo, ["manager"]);
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
  },
};
</script>
