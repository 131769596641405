<template>
  <div
    :style="{
      position: 'sticky',
      top: '65px',
      zIndex: 4,
      backgroundColor: 'white',
      padding: '0 10px',
    }"
  >
    <v-row>
      <AddTaskDialog v-model="isOpen" />
      <v-col cols="3">
        <v-select
          v-model="selectedRegionIds"
          :items="regions"
          label="Select Regions"
          outlined
          item-text="name"
          item-value="id"
          multiple
          dense
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedRegionIds.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-autocomplete
          v-model="selectedFacilities"
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
          dense
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{
              item.name
            }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="1">
        <v-btn dense style="height: 40px" color="primary" @click="openDialog"
          ><v-icon>mdi-plus</v-icon> Add Task</v-btn
        >
      </v-col>
      <v-col cols="2" />
      <v-col cols="3">
        <v-select
          v-model="dashboard"
          :items="dashboards"
          label="Select Dashboard"
          outlined
          item-text="name"
          item-value="id"
          dense
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AddTaskDialog from "./AddTaskDialog";
export default {
  components: {
    AddTaskDialog,
  },
  setup() {
    return {};
  },
  data() {
    return {
      isOpen: false,
      dashboards: [
        { id: 1, name: "Management Summary" },
        { id: 2, name: "Monitoring & Control Hub" },
        { id: 3, name: "Operations Summary" },
      ],
      dashboard: 2,
      selectedRegion: "Three by Three Storage",
      regions: ["Three by Three Storage", "Region 2", "Region 3"],
      selectedRegionIds: ["Three by Three Storage"],
      selectedFacilities: [
        { id: 1, name: "3x3 Self Storage - Denton" },
        { id: 2, name: "3x3 Self Storage - Fort Worth" },
        { id: 3, name: "3x3 Self Storage - Waco" },
      ],
      facilities: [
        { id: 1, name: "3x3 Self Storage - Denton" },
        { id: 2, name: "3x3 Self Storage - Fort Worth" },
        { id: 3, name: "3x3 Self Storage - Waco" },
      ],
    };
  },
  watch: {
    dashboard(newDashboard) {
      if (newDashboard === 1) {
        this.$router.push({ path: "/demo/management" });
      }
      if (newDashboard === 3) {
        this.$router.push({ path: "/demo/operation-and-security" });
      }
    },
  },
  methods: {
    openDialog() {
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped></style>
