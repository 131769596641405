<template>
  <CardLayoutV2 title="MTD New Subscriptions" :loading="loading">
    <SubscriptionsChart :chart-data="chartData" title="MTD New Subscriptions" />
    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@7"
    >
      <v-data-table
        :headers="tableData.headers"
        :items="tableData.data"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [7, 14, 50, -1] }"
      >
        <template #item.rowNumber="{ index }">
          {{ (tableState.page - 1) * tableState.itemsPerPage + index + 1 }}
        </template>
        <template #item.name="{ item }">
          <a @click="() => goToFacilityDashboard(item)">{{ item.name }}</a>
        </template>
        <template #body.append>
          <tr class="total">
            <td></td>
            <td style="font-weight: bold">Total</td>
            <td style="font-weight: bold">
              {{ tableData.totalSubscriptions }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import SubscriptionsChart from "./SubscriptionsChart.vue";
import { isDateMatchingOffset } from "@tod-ui/helpers/datetime";
import { calculateTotal } from "@tod-ui/helpers/math";
import { thousandSeparator } from "@tod-ui/helpers/strings";
import { CHART_COLORS } from "@tod-ui/constants/colors";
import useFacility from "@components/authentication/useFacility";
import useDataTable from "@components/common/useDataTable";
import useUser from "@components/authentication/useUser";
import { DateTime } from "luxon";

export default {
  components: {
    CardLayoutV2,
    SubscriptionsChart,
  },
  props: {
    monthlySubscriptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { selectFacility } = useFacility();
    const { defaultOrganization } = useUser();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 7,
      sortBy: ["subscriptions"],
      sortDesc: [true],
    });
    return {
      selectFacility,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      defaultOrganization,
    };
  },
  computed: {
    filteredSubscriptions() {
      return this.monthlySubscriptions.filter((facility) => {
        const subscriptionDate = DateTime.fromISO(facility.subscriptionMonth, {
          zone: this.defaultOrganization.timeZone,
        });
        return isDateMatchingOffset(
          subscriptionDate,
          0,
          0,
          this.defaultOrganization.timeZone
        );
      });
    },
    tableData() {
      return {
        headers: [
          {
            text: "",
            align: "start",
            sortable: false,
            value: "rowNumber",
          },
          {
            text: "Facility",
            align: "start",
            sortable: false,
            value: "name",
          },
          { text: "Subscriptions", value: "subscriptions" },
        ],
        data: this.filteredSubscriptions.map((facility, index) => {
          return {
            id: facility.facilityId,
            name: facility.facilityName,
            subscriptions: thousandSeparator(facility.subscriptionsCount),
          };
        }),
        totalSubscriptions: thousandSeparator(
          calculateTotal(this.filteredSubscriptions, "subscriptionsCount")
        ),
      };
    },
    chartData() {
      const sortedData = this.filteredSubscriptions
        .slice()
        .sort((a, b) => b.subscriptionsCount - a.subscriptionsCount);
      // Take top 7 facilities, and group the rest under "Others"
      const topFacilities = sortedData.slice(0, 7);
      const othersCount = sortedData
        .slice(7)
        .reduce((sum, facility) => sum + facility.subscriptionsCount, 0);

      // Top 7 facilities and "Others" labels
      const labels = topFacilities.map((facility) => facility.facilityName);
      labels.push("Others");

      const data = topFacilities.map((facility) => facility.subscriptionsCount);
      data.push(othersCount);

      return {
        labels: labels,
        datasets: [
          {
            label: "MTD New Subscriptions",
            backgroundColor: CHART_COLORS.slice(0, 8),
            data: data,
          },
        ],
      };
    },
  },
  methods: {
    goToFacilityDashboard(item) {
      this.selectFacility(this.$apollo, item);
      this.$router.push({
        name: "Facility",
        params: {
          organization_id: item.id,
        },
      });
    },
  },
};
</script>
