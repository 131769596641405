import { ref } from "vue";
import router from "../router";

const CHECK_INTERVAL =
  import.meta.env.VUE_APP_CHECK_VERSION_INTERVAL_MIN * 60 * 1000;
const BUNDLE_STRING = "{{VUE_APP_BUILD_HASH}}";
const versionFileUrl = `${import.meta.env.BASE_URL}assets/version.json`;

let scheduledReload = false;
let pendingReload = ref(false);

export default {
  install(Vue) {
    if (import.meta.env.MODE !== "development") {
      router.afterEach((to, from) => {
        maybeReload();
      });
      const interval = setInterval(checkVersion, CHECK_INTERVAL);
    }
    Vue.prototype.$pendingReload = pendingReload;
  },
};

async function checkVersion() {
  if (scheduledReload) return;

  let buildHash = null;
  try {
    const response = await fetch(`${versionFileUrl}?t=${new Date().getTime()}`);
    buildHash = (await response.json()).version;
  } catch (error) {
    console.log(
      "Unable to check the web app version",
      import.meta.env.VUE_APP_CONSOLE_ERRORS === "yes" ? error : undefined
    );
  }
  if (!buildHash || buildHash !== BUNDLE_STRING) {
    console.log("New version is available. Scheduling app reload.");
    scheduledReload = true;
  }
}

function maybeReload() {
  if (scheduledReload) {
    pendingReload.value = true;
    window.location.reload();
  }
}
