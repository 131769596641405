<template>
  <v-card v-if="!loading" outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :loading="loading"
        :headers="[
          {
            text: 'Details',
            align: 'start',
            filterable: false,
            sortable: false,
            value: 'actions',
            width: '1%',
          },
          { text: 'Type', value: 'incidentType' },
          { text: 'Device Location', value: 'device_location' },
          { text: 'Started At', value: 'startedAt' },
          {
            text: 'Duration',
            value: 'duration',
            toString: (duration) => (duration < 1 ? 1 : Math.round(duration)),
          },
          { text: 'Status', value: 'statusText' },
        ]"
        :items="incidents"
        :search="search"
        :custom-filter="customFilter"
        :sort-by="['startedAt']"
        :sort-desc="[true]"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView @click="gotoIncident(item.id)" />
        </template>

        <template #item.incidentType="{ item }">
          <IconIncidentType :incident="item" class="mr-2" />
          {{ item.incidentType }}
        </template>

        <template #item.device_location="{ item }">
          <div v-if="item?.deviceLocation">
            {{ item?.deviceLocation }}
          </div>
          <div v-else class="grey--text">N/A</div>
        </template>
        <template #item.startedAt="{ item }">
          {{ facilityFormatDateTime4Humans(item.startedAt) }}
        </template>
        <template #item.duration="{ item }">
          {{ durationInWords(item.startedAt, item.endedAt) }}
        </template>
        <template #item.statusText="{ item }">
          {{ incidentStatus(item) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";
import SearchField from "@atoms/SearchField.vue";

import ButtonView from "@atoms/ButtonView.vue";
import useFacility from "../authentication/useFacility";
import IconIncidentType from "@atoms/IconIncidentType.vue";
import { durationInWords } from "@tod-ui/helpers/datetime";
import { incidentStatus } from "@components/common/helpers/incident";

export default {
  name: "CardZoneIncidentTable",
  components: {
    ButtonView,
    SearchField,
    IconIncidentType,
  },
  props: {
    incidents: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { facilityFormatDateTime4Humans } = useFacility();

    const color = "#aaaaaa";

    return {
      facilityFormatDateTime4Humans,
      color,
    };
  },
  data: () => ({
    search: "",
  }),
  methods: {
    titleCase,
    gotoIncident(incidentId) {
      this.$router.push({
        name: "ZoneIncident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      let _search = search.toLowerCase();

      //Filter on 'type
      const type = `${item.type}`;
      if (type.toLowerCase().indexOf(_search) >= 0) return true;
      // Filter on `name`
      if (item.organization) {
        const name = `${item.organization.name}`;
        if (name.toLowerCase().indexOf(_search) >= 0) return true;
      }
      // Filter on `startedAt`
      if (
        this.facilityFormatDateTime4Humans(item.startedAt).indexOf(_search) >= 0
      )
        return true;
    },
    durationInWords,
    incidentStatus,
  },
};
</script>
