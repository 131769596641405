<template>
  <CardLayout
    v-model="editing"
    title="Smart Unit Information"
    header-type="edit"
    hint="Edit Smart Unit"
    pms-aware-edit
    :loading="loading"
  >
    <template #load>
      <em>Loading unit details</em>
    </template>
    <template #edit>
      <AlertsAnimated
        :alerts="localAlerts"
        dense
        @cancel-edit="cancelEdit"
        @proceed-edit="proceedEdit"
      />
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveUnit)">
          <v-row>
            <v-col>
              <PossiblePMS v-slot="{ disabled }">
                <ValidatedTextField
                  ref="smartUnitName"
                  v-model="subject.name"
                  name="Smart Unit #"
                  rules="required|max:250|min:2"
                  :disabled="disabled || !allowEditName"
                />
              </PossiblePMS>
            </v-col>
            <v-col>
              <PossiblePMS v-slot="{ disabled }">
                <SelectUnitType
                  v-model="subject.unitTypeId"
                  :disabled="disabled"
                />
              </PossiblePMS>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="subject.description"
                label="Description"
                rows="2"
                class="unit-description"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="subject.amenities"
                multiple
                :items="amenityOptions"
                label="Unit Amenities"
              />
            </v-col>
          </v-row>
          <PossiblePMS v-slot="{ disabled }">
            <v-btn small color="success" type="submit" :disabled="disabled">
              Save Smart Unit
            </v-btn>
          </PossiblePMS>
        </form>
      </ValidationObserver>
    </template>

    <dl class="property-list">
      <dt>Smart Unit #</dt>
      <dd>{{ smartUnit.name }}</dd>
      <dt>Unit Type</dt>
      <dd>{{ displayUnitType(smartUnit.unitType) }}</dd>

      <dt v-if="smartUnit.description">Description</dt>
      <dd v-if="smartUnit.description" class="unit-description">
        {{ smartUnit.description }}
      </dd>
      <dt v-if="smartUnit.amenities && smartUnit.amenities.length > 0">
        Unit Amenities
      </dt>
      <dd v-if="smartUnit.amenities && smartUnit.amenities.length > 0">
        {{ smartUnit.amenities.map(titleCase).join(", ") }}
      </dd>
    </dl>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import SelectUnitType from "../common/SelectUnitType.vue";
import PossiblePMS from "../common/PossiblePMS.vue";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import { ValidationObserver } from "vee-validate";

import { ref } from "vue";
import { titleCase } from "@tod-ui/helpers/strings";
import useSmartUnit from "./useSmartUnit";
import useAlerts from "@tod-ui/composables/useAlerts";
import { displayUnitType } from "@tod-ui/helpers/unitType";

export default {
  name: "CardSmartUnitInfo",
  components: {
    ValidationObserver,
    ValidatedTextField,
    CardLayout,
    PossiblePMS,
    SelectUnitType,
    AlertsAnimated,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { smartUnit, smartUnitLoading, unitAmenityOptions, updateSmartUnit } =
      useSmartUnit();
    const {
      addLocalAlert,
      clearAllLocalAlerts,
      localAlerts,
      addAlert,
      clearAllAlerts,
    } = useAlerts();

    const subject = ref({});
    const allowEditName = ref(false);

    const amenityOptions = unitAmenityOptions.map((val) => ({
      value: val,
      text: titleCase(val),
    }));
    return {
      smartUnit,
      loading: smartUnitLoading,
      amenityOptions,
      updateSmartUnit,
      addLocalAlert,
      clearAllLocalAlerts,
      localAlerts,
      addAlert,
      clearAllAlerts,
      subject,
      allowEditName,
    };
  },
  computed: {
    editing: {
      get() {
        return this.value;
      },
      set(editingUnit) {
        this.$emit("input", editingUnit);
      },
    },
  },
  watch: {
    editing(editingUnit) {
      if (editingUnit) {
        this.clearAllAlerts();
        this.subject = {
          name: this.smartUnit.name,
          unitTypeId: this.smartUnit.unitType?.id,
          description: this.smartUnit.description,
          amenities: this.smartUnit?.amenities || [],
        };
        if (!this.localAlerts.length) {
          this.addLocalAlert({
            type: "warning",
            message:
              "Please, edit Smart Unit name only to fix a typo. Use Renters page to transfer a renter to a different Smart Unit.",
            actions: { "cancel-edit": "Cancel", "proceed-edit": "Fix Typo" },
          });
        }
      } else {
        if (!this.subject.success) {
          this.clearAllAlerts();
        }
        this.subject = {};
        this.allowEditName = false;
      }
    },
  },
  methods: {
    proceedEdit() {
      this.clearAllLocalAlerts();
      this.allowEditName = true;
      this.$nextTick(() =>
        this.$refs.smartUnitName.$el.querySelector("input").focus()
      );
    },
    cancelEdit() {
      this.clearAllLocalAlerts();
    },
    async saveUnit() {
      const isNameChanged =
        this.subject.name.toLowerCase() !== this.smartUnit.name.toLowerCase();

      this.clearAllAlerts();
      try {
        const message = await this.updateSmartUnit(
          this.$apollo,
          this.subject,
          isNameChanged
        );

        this.editing = "";
        this.subject.success = true;
        this.addAlert({
          type: "success",
          message,
          timeout: 15, // clears after 15 seconds
        });
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
        });
      }
    },
    displayUnitType,
    titleCase,
  },
};
</script>

<style scoped>
.unit-description {
  color: gray;
  font-size: 0.9em;
}
</style>
