<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    :rules="rules"
    :name="name"
    :vid="vid"
  >
    <v-text-field
      v-model="localValue"
      :autofocus="autofocus"
      :counter="counter"
      :dense="dense"
      :disabled="disabled"
      :hint="hint"
      :outlined="outlined"
      :placeholder="placeholder"
      :type="type"
      :error-messages="errors"
      :single-line="singleLine"
      :required="required"
    >
      <template #label>
        {{ name }}
        <span class="required">{{ required ? "*" : "" }}</span>
      </template>
    </v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  name: "ValidatedTextField",
  components: {
    ValidationProvider,
  },
  props: {
    counter: {
      type: Number,
      default: null,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    rules: {
      type: [String, Object],
      default: "",
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    vid: {
      type: String,
      default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(localValue) {
        this.$emit("input", localValue);
      },
    },
  },
};
</script>
