<template>
  <v-dialog v-model="show" max-width="600px">
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        title="View Assignment History"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-information </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Assignment History for Device
        {{ deviceName }}
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-list two-line>
          <template
            v-if="device.assignmentHistory && device.assignmentHistory.length"
          >
            <v-list-item
              v-for="assignment in device.assignmentHistory"
              :key="assignment.timestamp"
            >
              <v-list-item-icon>
                <v-icon right>mdi-garage-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ assignment.organizationName }}
                </v-list-item-title>

                <v-list-item-subtitle>
                  {{
                    facilityFormatDateTime4Humans(
                      assignment.timestamp,
                      false,
                      false
                    )
                  }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  <em>No assignment history</em>
                </v-list-item-title>
                <v-list-item-subtitle> &nbsp; </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from "vue";
import useFacility from "../../authentication/useFacility";
import useDevice from "../useDevice";

export default {
  name: "DialogAssignmentHistory",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { facilityFormatDateTime4Humans } = useFacility();
    const { deviceName } = useDevice(props.device);
    const show = ref(false);
    return { facilityFormatDateTime4Humans, deviceName, show };
  },
};
</script>
