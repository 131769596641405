<template>
  <span
    v-if="
      !hasPMS ||
      (isDeviceControlledEnrollment && !forceTooltip) ||
      (!asIcon && !controlDisabled)
    "
    :class="classNames"
  >
    <slot :disabled="controlDisabled"></slot>
  </span>

  <div v-else-if="isPrinting" :class="classNames">
    <slot></slot>
    <v-icon v-if="asIcon" :color="iconColor" x-small>fas fa-{{ icon }}</v-icon>
  </div>

  <v-tooltip v-else bottom nudge-top="20" max-width="380">
    <template #activator="{ on }">
      <span v-if="asIcon" class="pms-icon-span" :class="classNames" v-on="on">
        <slot></slot>
        <v-icon :color="iconColor" x-small>fas fa-{{ icon }}</v-icon>
      </span>

      <div v-else :class="classNames" v-on="on">
        <slot :disabled="controlDisabled"></slot>
      </div>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
import { computed } from "vue";
import usePMS from "./usePMS";
import usePrinting from "./usePrinting";

export default {
  name: "PossiblePMS",
  props: {
    asIcon: {
      type: Boolean,
      default: false,
    },
    syncObj: {
      type: Object,
      default: () => ({ noSyncCheck: true }),
    },
    hintFor: {
      type: String,
      default: "",
    },
    inline: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: "",
    },
    checkEnrollmentMode: {
      type: Boolean,
      default: false,
    },
    forceTooltip: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const {
      hasPMS,
      propertyManagementSystem,
      isDeviceControlledEnrollment,
      syncCompleted,
      getFieldHint,
    } = usePMS();
    const classNames = computed(
      () => (props.inline ? "d-inline-block" : "") + props.contentClass
    );
    const isRenter = computed(
      () => !!props.syncObj?.firstName && !!props.syncObj?.lastName
    );
    const synced = computed(
      () => props.syncObj?.noSyncCheck === true || !!props.syncObj?.externalId
    );
    const icon = computed(() =>
      synced.value ? "check" : syncCompleted.value ? "question-circle" : "clock"
    );
    const iconColor = computed(() =>
      synced.value ? "success" : syncCompleted.value ? "warning" : "grey"
    );

    const controlDisabled = computed(() => synced.value && hasPMS.value);
    const tooltipText = computed(() =>
      props.asIcon
        ? synced.value
          ? `Synced with ${propertyManagementSystem.value}\.`
          : syncCompleted.value
          ? `Unable to match ${
              isRenter.value ? "name or mobile phone" : "name"
            } with ${
              propertyManagementSystem.value
            }. Please check spelling or remove possible duplicate.`
          : `Synchronization with ${propertyManagementSystem.value} is in process`
        : `${
            propertyManagementSystem.value
          } has Authority over this; please update it there. ${getFieldHint(
            props.hintFor
          )}`
    );

    const { isPrinting } = usePrinting();

    return {
      classNames,
      icon,
      iconColor,
      hasPMS,
      controlDisabled,
      tooltipText,
      synced,
      isPrinting,
      isDeviceControlledEnrollment: computed(
        () => props.checkEnrollmentMode && isDeviceControlledEnrollment.value
      ),
    };
  },
};
</script>
