<template>
  <v-card>
    <GlobalNotifications />
    <AlertsAnimated v-on="$listeners" />
    <v-toolbar class="sd-page-header">
      <slot name="title">
        <template v-if="isMainPage">
          <v-toolbar-title class="headline">{{ mainPage }}</v-toolbar-title>
        </template>

        <template v-else>
          <v-btn class="action-btn" icon @click="$router.go(-1)">
            <v-icon color="primary">fas fa-chevron-circle-left</v-icon>
          </v-btn>

          <v-toolbar-title class="headline">
            <span class="grey--text text--darken-2">{{ mainPage }}</span>
            <template v-for="(subPage, index) in subPages">
              <v-icon :key="'chevron-' + index" small left right>
                fas fa-chevron-right
              </v-icon>
              <span :key="index">{{ subPage }}</span>
            </template>
          </v-toolbar-title>
        </template>
      </slot>
      <v-progress-linear
        :active="loading"
        absolute
        bottom
        height="2"
        indeterminate
      />
      <v-spacer />
      <slot name="actions"></slot>
    </v-toolbar>

    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
import { computed } from "vue";
import { onBeforeRouteLeave } from "vue-router/composables";
import useAlerts from "@tod-ui/composables/useAlerts";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import GlobalNotifications from "@components/notifications/GlobalNotifications.vue";

export default {
  name: "LayoutPage",
  components: { AlertsAnimated, GlobalNotifications },
  props: {
    title: {
      type: [Array, String],
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isMainPage = !Array.isArray(props.title);
    const mainPage = computed(() =>
      isMainPage ? props.title : props.title[0]
    );
    const subPages = computed(() => !isMainPage && props.title.slice(1));

    const { clearAllAlerts } = useAlerts();

    onBeforeRouteLeave((to, from, next) => {
      clearAllAlerts();
      next();
    });

    return {
      isMainPage,
      mainPage,
      subPages,
    };
  },
};
</script>
