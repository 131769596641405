<template>
  <v-row dense>
    <v-col v-if="label" md="12">
      {{ label }}
    </v-col>
    <v-col md="4">
      <v-select
        v-model="_value.hour"
        :dense="dense"
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="hourOptions"
        label="Hour"
        @change="changeValue"
      />
    </v-col>
    <v-col md="4">
      <v-select
        v-model="_value.minute"
        :dense="dense"
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="minuteOptions"
        label="Minutes"
        @change="changeValue"
      />
    </v-col>
    <v-col md="4">
      <v-select
        v-model="_value.meridiem"
        :dense="dense"
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="meridiemOptions"
        @change="changeValue"
      />
    </v-col>
  </v-row>
</template>

<script>
import { convertToLuxonDT } from "@tod-ui/helpers/datetime";
import { DateTime } from "luxon";

export default {
  name: "TimeSelector",
  props: {
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: undefined,
    },
    value: {
      validator: (val) =>
        val === null || typeof val === "object" || typeof val === "string",
      required: true,
    },
  },
  data: () => ({
    hourOptions: [
      "12",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
    ],
    minuteOptions: ["00", "15", "30", "45"],
    meridiemOptions: ["AM", "PM"],
  }),
  computed: {
    _value: {
      get() {
        let _value = null;

        if (!this.value) {
          _value = {
            hour: null,
            minute: null,
            meridiem: null,
          };
        } else {
          // Convert ISO Time into components
          let { hour, minute } = convertToLuxonDT(this.value);
          let meridiem = hour < 12 ? "AM" : "PM";

          // The hour select uses '12' and not '0' for midnight
          if (hour === 0) hour = 12;

          _value = {
            hour: (hour <= 12 ? hour : hour % 12).toString(),
            minute: minute.toLocaleString("en", { minimumIntegerDigits: 2 }),
            meridiem,
          };
        }

        return _value;
      },
      set(localValue) {
        const time = DateTime.fromISO(localValue).toISOTime();

        this.$emit("input", time);
      },
    },
  },
  methods: {
    changeValue() {
      const format = "h:mm a";
      const { hour, minute, meridiem } = this._value;
      const time = `${hour || "12"}:${minute || "00"} ${meridiem || "AM"}`;

      this._value = DateTime.fromFormat(time, format).toISOTime();
    },
  },
};
</script>
