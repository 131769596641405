<template>
  <v-skeleton-loader
    :loading="loading"
    type="table-thead, table-row-divider@10"
  >
    <div class="table-container">
      <v-data-table
        :headers="tableHeaders"
        :items="aggregatedFacilityData"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #item.organization="{ item }">
          {{ item.organizationName }}
        </template>
        <template #item.vacantUnitUncleanScore="{ item }">
          {{ roundValue(item.vacantUnitUncleanScore) }} %
        </template>
        <template #item.vacantUnits="{ item }">
          {{ item.totalVacantUnits }}
        </template>
        <template #item.cleanedVacantUnits="{ item }">
          {{ item.cleanedVacantUnits }}
        </template>
        <template #item.vacantUnitHaveIncident="{ item }">
          {{ item.vacantUnitHaveIncident }}
        </template>
        <template #item.averageCleaningDays="{ item }">
          {{ roundValue(item.averageCleaningDays) }} days
        </template>
        <template #item.suspiciousMotions="{ item }">
          {{ item.suspiciousIncidents }}
        </template>
        <template #item.otherReasonIncidents="{ item }">
          {{ item.otherReasonIncidents }}
        </template>
      </v-data-table>
    </div>
  </v-skeleton-loader>
</template>

<script>
import { FACILITY_VACANT_UNIT_HYGIENE_STATS } from "./graphql";
import { roundValue } from "@tod-ui/helpers/math";

export default {
  name: "RegionalViewTable",
  props: {
    selectedFacilities: {
      type: Array,
      required: true,
    },
    dateTime: {
      type: Object,
      required: true,
    },
    cleanedWithinDays: {
      type: Number,
      required: true,
      default: 30,
    },
  },
  data() {
    return {
      facilityVacantUnitHygieneStats: [],
    };
  },

  computed: {
    aggregatedFacilityData() {
      if (!Array.isArray(this.facilityVacantUnitHygieneStats)) return [];

      const regionData = {};

      this.facilityVacantUnitHygieneStats.forEach((item) => {
        const regionName = item.region;

        if (!regionData[regionName]) {
          regionData[regionName] = {
            region: regionName,
            organizationName: item.organizationName,
            totalVacantUnits: 0,
            suspiciousIncidents: 0,
            otherReasonIncidents: 0,
            averageCleaningDays: 0,
            uncleanedVacantUnits: 0,
            cleanedVacantUnits: 0,
            vacantUnitHaveIncident: 0,
          };
        }

        regionData[regionName].totalVacantUnits += item.totalVacantUnits;
        regionData[regionName].cleanedVacantUnits += item.cleanedVacantUnits;
        regionData[regionName].suspiciousIncidents += item.suspiciousIncidents;
        regionData[regionName].otherReasonIncidents +=
          item.otherReasonIncidents;
        regionData[regionName].averageCleaningDays += item.averageCleaningDays;
        regionData[regionName].uncleanedVacantUnits +=
          item.uncleanedVacantUnits;
        regionData[regionName].vacantUnitHaveIncident +=
          item.suspiciousIncidents +
          item.cleanedVacantUnits +
          item.otherReasonIncidents;
      });

      for (const regionName in regionData) {
        const region = regionData[regionName];
        const regionItems = this.facilityVacantUnitHygieneStats.filter(
          (item) => item.region === regionName
        );

        region.averageCleaningDays =
          region.averageCleaningDays / region.cleanedVacantUnits;

        region.vacantUnitUncleanScore =
          (region.uncleanedVacantUnits / region.totalVacantUnits) * 100;
      }

      return Object.values(regionData);
    },
    incidentsByRegion() {
      return this.calculateIncidentsByRegion();
    },
    tableHeaders() {
      return [
        { text: "Organization", value: "organization" },
        { text: "Vacant Unit Unclean score", value: "vacantUnitUncleanScore" },
        { text: "Vacant Units", value: "vacantUnits" },
        {
          text: `Vacant units that have incidents within ${this.cleanedWithinDays} days`,
          value: "vacantUnitHaveIncident",
        },
        { text: "No.of units that were cleaned", value: "cleanedVacantUnits" },
        { text: "Avg. Cleaning Days", value: "averageCleaningDays" },
        {
          text: "No. of units that has suspicious motions",
          value: "suspiciousMotions",
        },
        {
          text: "No. of units that had motions due to other reason",
          value: "otherReasonIncidents",
        },
      ];
    },
    loading() {
      return this.$apollo.queries.facilityVacantUnitHygieneStats.loading;
    },
  },
  apollo: {
    facilityVacantUnitHygieneStats: {
      query: FACILITY_VACANT_UNIT_HYGIENE_STATS,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          cleanedWithinDays: this.cleanedWithinDays,
          dateFilter: this.dateTime,
        };
      },
      skip() {
        return !this.selectedFacilities.length;
      },
    },
  },
  methods: {
    vacantUnitUncleanScore(item) {
      return (item.uncleanedVacantUnits / item.totalVacantUnits) * 100;
    },

    calculateIncidentsByRegion() {
      const regionIncidents = {};

      this.facilityVacantUnitHygieneStats.forEach((item) => {
        if (!regionIncidents[item.region]) {
          regionIncidents[item.region] = {
            suspiciousIncidents: 0,
            otherReasonIncidents: 0,
          };
        }
        regionIncidents[item.region].suspiciousIncidents +=
          item.suspiciousIncidents;
        regionIncidents[item.region].otherReasonIncidents +=
          item.otherReasonIncidents;
      });

      return regionIncidents;
    },
    roundValue,
  },
};
</script>
