export function calculateTotal(data, field) {
  if (!Array.isArray(data) || data.length === 0 || !field) {
    return 0;
  }

  return data.reduce((sum, item) => {
    const value = parseFloat(item[field]);
    return isNaN(value) ? sum : sum + value;
  }, 0);
}

export function roundValue(value) {
  if (value == null || isNaN(value)) {
    return 0;
  } else {
    return Math.round(Number(value));
  }
}

export function calculateAverage(value, numberOfItems) {
  if (value == null || isNaN(value) || numberOfItems <= 0) {
    return 0;
  } else {
    return value / numberOfItems;
  }
}
