<template>
  <v-card
    style="min-height: 130px; max-height: 190px"
    :elevation="isShadow ? 5 : 0"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div v-if="heading" class="mb-3 d-flex justify-center">
          {{ heading }}
        </div>
        <div class="d-flex justify-center align-center">
          <div
            v-if="topValue"
            class="value-container cursor-pointer"
            @click="emitTopClickEvent"
          >
            {{ topValueWithUnit }}
          </div>
          <span v-if="topValueChange" class="ml-3">
            <IconArrow
              :color="topValueChange === 'up' ? '#2AAA8A' : '#FB9E85'"
              :direction="topValueChange"
            >
              <span v-if="topValueChangePercent">
                {{ topValueChangePercent }}%
              </span>
            </IconArrow>
          </span>
        </div>
      </v-list-item-content>
    </v-list-item>
    <div
      v-if="bottomTitle"
      :style="{ height: '10px', backgroundColor: avatarColor }"
    ></div>
  </v-card>
</template>

<script>
import useDashboard from "./useDashboard";
import IconArrow from "../IconArrow.vue";

export default {
  name: "CardDashboardTile",
  components: {
    IconArrow,
  },
  props: {
    heading: {
      type: String,
      default: "",
    },
    isShadow: {
      type: Boolean,
      default: true,
    },
    topTitle: {
      type: String,
      default: "",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: "",
    },
    bottomValue: {
      type: [String, Number],
      default: "",
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },
    topValueChange: {
      type: String,
      default: "",
    },
    topValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    bottomValueChange: {
      type: String,
      default: "",
    },
    bottomValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { dashboardLoading, organizationDescendantsLoading } = useDashboard();
    return {
      dashboardLoading,
      organizationDescendantsLoading,
    };
  },
  computed: {
    topValueWithUnit() {
      return this.topUnit === "$"
        ? `${this.topUnit} ${this.topValue}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${this.bottomValue}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },
    arrowAlignClass() {
      return this.topValueChangePercent
        ? "arrowAlign-column"
        : "arrowAlign-row";
    },
    avatar() {
      if (this.avatarName === "revenue") {
        return "mdi-finance";
      } else if (this.avatarName === "conversion") {
        return "fa-solid fa-funnel-dollar";
      } else if (this.avatarName === "penetration") {
        return "fa-solid fa-bullseye";
      }
      return null;
    },
  },
  methods: {
    emitTopClickEvent() {
      let value = this.topTitle ? this.topTitle : this.heading;
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("topClickEvent", value);
    },
    emitBottomClickEvent() {
      let value = this.bottomTitle;
      value = value.replace(/\s/g, "").toLowerCase;
      this.$emit("bottomClickEvent", value);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.tile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrowAlign-column {
  display: flex;
}

.arrowAlign-row {
  display: flex;
}

.value-container {
  font-size: 1.5rem;
  font-weight: 500;
}
</style>
