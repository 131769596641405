<template>
  <LayoutPage title="Demo Smart Unit">
    <v-row>
      <v-col xl="5">
        <CardRenterInfo
          ref="cardInfo"
          new-renter
          demo-renter
          @renter-created="assignDemoUnit"
        />
      </v-col>
      <v-col xl="7">
        <CardSmartUnitsInventory @start-demo-unit="startDemo" />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CardRenterInfo from "./CardRenterInfo.vue";
import CardSmartUnitsInventory from "./CardSmartUnitsInventory.vue";

import useRenter from "./useRenter";
import useAlerts from "@tod-ui/composables/useAlerts";
import { ADD_UNIT_RESPONDERS } from "./graphql";
import { GET_SMART_UNIT } from "../smart_units/graphql";
import { onUnmounted } from "vue";

export default {
  name: "PageDemoSmartUnit",
  components: {
    LayoutPage,
    CardSmartUnitsInventory,
    CardRenterInfo,
  },
  setup() {
    const { resetRenter, initRenter, findContactMethod } = useRenter();
    const { addAlert, clearAllAlerts } = useAlerts();
    onUnmounted(resetRenter);
    return { initRenter, findContactMethod, addAlert, clearAllAlerts };
  },
  data() {
    return {
      userExistsAlert: false,
      selectedZone: null,
    };
  },
  async beforeMount() {
    const { renterFirst, renterLast, primaryPhone } = this.$route.query;
    this.initRenter(renterFirst, renterLast, primaryPhone);
  },
  methods: {
    async startDemo(zone) {
      this.selectedZone = zone;
      this.clearAllAlerts();
      this.$refs.cardInfo.createRenter();
    },
    async assignDemoUnit(newUser) {
      const primaryCM = this.findContactMethod(
        newUser.contactMethods,
        "mobile",
        true
      );

      if (!primaryCM) {
        this.addAlert({
          type: "error",
          message: `${newUser.name} profile is missing a Primary Mobile Phone. Try creating a New Renter Profile`,
        });
        return;
      }

      const responder = {
        userId: newUser.id,
        contactMethodId: primaryCM.id,
        escalationLevel: 1,
        demo: true,
      };

      try {
        await this.$apollo.mutate({
          mutation: ADD_UNIT_RESPONDERS,
          variables: {
            id: this.selectedZone.id,
            responders: [responder],
          },
          refetchQueries: [
            {
              query: GET_SMART_UNIT,
              variables: { id: this.selectedZone.id },
            },
          ],
        });
        this.$nextTick(() =>
          this.$router.replace({
            name: "ViewRenter",
            params: {
              renter_id: newUser.id,
            },
          })
        );
      } catch (error) {
        this.addAlert({ message: "Error assigning demo smart unit." });
      }
    },
  },
};
</script>
