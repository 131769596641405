<template>
  <LayoutPage title="Smart Units" :loading="smartUnitsLoading">
    <template #actions>
      <div class="mr-4" style="min-width: 240px">
        <SearchField v-model="tableState.search" />
      </div>
      <PossiblePMS v-slot="{ disabled }">
        <v-btn
          color="success"
          class="action-btn"
          :disabled="disabled"
          :to="{ name: 'AddSmartUnit', query: { subtype: 'unit' } }"
        >
          Create New Smart Unit
        </v-btn>
      </PossiblePMS>
    </template>

    <v-skeleton-loader
      :loading="smartUnitsLoading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :headers="dynamicHeaders"
        :items="smartUnits"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView @click="gotoUnit(item)" />
        </template>

        <template #item.name="{ item }">
          <PossiblePMS as-icon :sync-obj="item">
            {{ item.name }}
          </PossiblePMS>
        </template>

        <template #item.document="{ item }">
          <DocumentDialog :unit="item" />
        </template>

        <template #item.devicesName="{ item: { devicesName } }">
          <v-list dense color="transparent">
            <v-list-item
              v-for="(device, index) in devicesName"
              :key="index"
              class="ml-0 pl-0"
            >
              <v-list-item-content>
                {{ device }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template #item.devicesHealth="{ item }">
          <v-list dense color="transparent">
            <v-list-item
              v-for="device in item.devices"
              :key="device.id"
              class="mx-0 px-0"
            >
              <v-list-item-icon class="mx-0">
                <DeviceGaugeCluster :device="device" />
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </template>
        <template #item.temperature="{ item }">
          <v-list dense color="transparent">
            <v-list-item
              v-for="device in item.devices"
              :key="device.id"
              class="mx-0 px-0"
            >
              <div
                v-if="device.temperature"
                :class="`${smartUnitTemperatureColor(
                  device.temperature
                )}--text`"
              >
                {{ convertCelsiusToFahrenheit(device.temperature) }}℉
              </div>
              <div v-else class="grey--text">N/A</div>
            </v-list-item>
          </v-list>
        </template>
        <template #item.humidity="{ item }">
          <v-list dense color="transparent">
            <v-list-item
              v-for="device in item.devices"
              :key="device.id"
              class="mx-0 px-0"
            >
              <div
                v-if="device.humidity"
                :class="`${smartUnitHumidityColor(device.humidity)}--text`"
              >
                {{ roundValue(device.humidity) }}%
              </div>
              <div v-else class="grey--text">N/A</div>
            </v-list-item>
          </v-list>
        </template>

        <template #item.incident="{ item }">
          <v-btn
            v-if="item.incidents.length > 0"
            color="amber lighten-3"
            depressed
            small
            tile
            title="View Incident"
            @click="gotoFirstIncident(item)"
          >
            <v-icon left small>fas fa-exclamation-triangle</v-icon>
            Resolve
          </v-btn>
        </template>

        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="smartUnits"
            :headers="dynamicHeaders"
            filename="SD_Smart_Units"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";
import PossiblePMS from "@components/common/PossiblePMS.vue";
import DocumentDialog from "../documents/DocumentDialog.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { SMART_UNITS } from "./graphql";
import useFacility from "../authentication/useFacility";
import useDataTable from "@components/common/useDataTable";
import usePMS from "@components/common/usePMS";
import { extractDevicesFields } from "@components/common/helpers/devices";
import { stringSortCollator, titleCase } from "@tod-ui/helpers/strings";
import { smartUnitDocument } from "../documents/helper";
import { displayUnitType } from "@tod-ui/helpers/unitType";
import { computed } from "vue";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import { roundValue } from "@tod-ui/helpers/math";
import { smartUnitHumidityColor, smartUnitTemperatureColor } from "./helper";

export default {
  name: "SmartUnits",
  components: {
    DeviceGaugeCluster,
    SearchField,
    ButtonView,
    DocumentDialog,
    PossiblePMS,
    LayoutPage,
    ButtonPrint,
    ButtonExportCSV,
  },
  setup() {
    const { currentFacilityId, facilityFeatureEnabled } = useFacility();
    const { hasPMS, pmsServiceHasDocuments } = usePMS();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["name"],
    });
    const showDocuments = computed(
      () =>
        facilityFeatureEnabled("facility_integrated_esign") ||
        pmsServiceHasDocuments.value
    );
    return {
      currentFacilityId,
      hasPMS,
      showDocuments,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      roundValue,
    };
  },
  data: () => ({
    smartUnits: [],
  }),
  computed: {
    smartUnitsLoading() {
      return this.$apollo.queries.smartUnits.loading;
    },
    headers() {
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          class: "action-column",
          cellClass: "action-column",
          value: "actions",
        },
        { text: "Smart Unit #", value: "name" },
        {
          text: "Unit Type",
          value: "unitTypeName",
        },
        {
          text: "Unit Size",
          value: "unitTypeSize",
        },
        { text: "First Name", value: "responderFirstName" },
        { text: "Last Name", value: "responderLastName" },
        {
          text: "Document Status",
          align: "center",
          value: "document",
          toString: (document) => titleCase(document.status),
        },
        { text: "Assigned Devices", value: "devicesName" },
        {
          text: "Device Health",
          filterable: false,
          sortable: false,
          class: "non-export",
          cellClass: "non-export",
          value: "devicesHealth",
        },
        { text: "Temperature °F", value: "temperature" },
        { text: "Humidity", value: "humidity" },
        {
          text: "Motion Incident",
          class: "action-column",
          cellClass: "action-column",
          value: "incident",
        },
      ];
    },
    dynamicHeaders() {
      return this.headers.filter(
        (header) => header.value !== "document" || this.showDocuments
      );
    },
  },
  apollo: {
    smartUnits: {
      query: SMART_UNITS,
      variables() {
        return { id: this.currentFacilityId };
      },
      update({ smartUnits }) {
        return smartUnits
          .reduce((acc, unit) => {
            const unitTypeName = displayUnitType(unit?.unitType, "type");
            const unitTypeSize = displayUnitType(unit?.unitType, "size");

            const responderFirstName = unit.responders?.length
              ? unit.responders[0]?.user.firstName
              : "(no recipient)";
            const responderLastName = unit.responders?.length
              ? unit.responders[0]?.user.lastName
              : "";

            const billingAccount =
              unit?.billingAccounts?.length && unit.billingAccounts[0];

            if (billingAccount?.status !== "ended_service") {
              // find the esign document corresponding to the unit
              const document = smartUnitDocument(
                billingAccount.documents,
                unit.id,
                this.hasPMS
              );
              acc.push({
                ...unit,
                unitTypeName,
                unitTypeSize,
                responderFirstName,
                responderLastName,
                billingAccount,
                document,
                ...extractDevicesFields(unit.devices),
              });
            }
            return acc;
          }, [])
          .sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          );
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `organization`
      let _search = search.toLowerCase();

      // Filter on `name`
      if (item.name.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `responder`
      if (
        item.responderFirstName &&
        item.responderFirstName.toLowerCase().indexOf(_search) >= 0
      )
        return true;

      if (
        item.responderLastName &&
        item.responderLastName.toLowerCase().indexOf(_search) >= 0
      )
        return true;

      // Filter on `devices`
      const devices = item.devicesName.join(" ").toLowerCase();
      if (devices.indexOf(_search) >= 0) return true;

      if (item.unitTypeName.toLowerCase().indexOf(_search) >= 0) return true;
      if (item.unitTypeSize.toLowerCase().indexOf(_search) >= 0) return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (_sortBy === "document") {
          const aName = a.document.status;
          const bName = b.document.status;
          return (bName < aName ? -1 : 1) * _sortDesc;
        } else if (_sortBy === "devicesName") {
          const aDevices = a.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");
          const bDevices = b.devicesName
            .sort((da, db) => stringSortCollator.compare(da, db) * _sortDesc)
            .join(" ");

          return stringSortCollator.compare(aDevices, bDevices) * _sortDesc;
        } else {
          // Default sort comparison
          if (typeof a[_sortBy] === "string") {
            return (
              stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc
            );
          }
          return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
        }
      });

      return items;
    },
    gotoFirstIncident(unit) {
      if (!unit?.incidents?.length) return;

      this.$router.push({
        name: "Incident",
        params: {
          incident_id: unit.incidents[0].id,
        },
      });
    },
    gotoUnit(unit) {
      this.$router.push({
        name: "AssignedSmartUnitDrilldown",
        params: {
          renter_id: unit.billingAccounts[0].users[0].id,
          zone_id: unit.id,
        },
      });
    },
    convertCelsiusToFahrenheit,
    smartUnitHumidityColor,
    smartUnitTemperatureColor,
  },
};
</script>
