/* eslint-disable no-console */
import { onError } from "apollo-link-error";
import { print } from "graphql/language/printer";
import * as Sentry from "@sentry/vue";

export const logApolloClientErrorsLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      logGraphqlErrors(graphQLErrors, operation);
    }
    if (networkError) {
      logNetworkError(networkError, operation);
    }
  }
);

export function logNetworkError(networkError, operation = null) {
  const status = networkError.response
    ? ` ${networkError.response.status} - ${networkError.response.statusText}`
    : networkError.statusCode || "";
  const message = networkError.bodyText || "";
  if (shouldConsoleLogErrors()) {
    console.log(
      `%c🔌[Network Error]:${status}`,
      "background: DarkRed; padding: 0 1em; font-weight: bold; color: white",
      `${message} ${
        operation?.operationName
          ? `(operation: ${operation.operationName})`
          : ""
      }`
    );
  }
  addSentryError(networkError, operation?.operationName);
}

// based on https://github.com/vuejs/apollo/tree/v4/packages/vue-apollo-util
export function logGraphqlErrors(graphQLErrors, operation) {
  const printedQuery = operation ? print(operation.query) : "";
  const operationName = operation?.operationName
    ? " " + operation.operationName
    : "";

  const allErrors = [];
  graphQLErrors.forEach(({ message, locations }) => {
    const errorWithLocations = printOperation(printedQuery, locations);
    if (shouldConsoleLogErrors()) {
      const errorMessages = [
        `%c💔[GraphQL Error]:${operationName}`,
        "background: DarkOrange; padding: 0 1em; font-weight: bold; color: white",
        message,
      ];

      if (operation) {
        console.groupCollapsed(...errorMessages);
        console.log(...errorWithLocations);
        if (Object.keys(operation.variables).length) {
          console.log(
            `with variables: ${JSON.stringify(operation.variables, null, 2)}`
          );
        }
        console.groupEnd();
      } else {
        console.log(...errorMessages);
      }
    }
    addSentryError(message, operationName, errorWithLocations);
    allErrors.push(errorWithLocations);
  });
}

function printOperation(printedQuery, locations) {
  const errorLines = {};
  if (locations) {
    locations.forEach(({ line, column }) => {
      errorLines[line - 1] = column;
    });
  }

  return printedQuery.split("\n").reduce((acc, line, index) => {
    if (line.trim() && line.trim() !== "__typename") {
      acc.push(line + "\n");
    }
    if (errorLines[index]) {
      acc.push("🔺".padStart(errorLines[index], " ") + "\n");
    }
    return acc;
  }, []);
}

function shouldConsoleLogErrors() {
  return (
    import.meta.env.MODE === "development" ||
    import.meta.env.VUE_APP_CONSOLE_ERRORS === "yes"
  );
}

function addSentryError(err, operationName, query = "") {
  Sentry.withScope((scope) => {
    if (["not_authorized", "not_authenticated"].includes(err)) {
      scope.setLevel("warning");
    }
    scope.setTransactionName(`GraphQL: ${operationName}`);
    scope.setFingerprint(["{{ default }}", operationName]);
    scope.setExtra("query", query);
    if (err.path) {
      // We can also add the path as breadcrumb
      scope.addBreadcrumb({
        category: "query-path",
        message: err.path.join(" > "),
        level: "debug",
      });
    }
    Sentry.captureException(err);
  });
}
