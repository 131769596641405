<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row justify="end">
        <v-col md="2">
          <v-select
            v-model="weekCount"
            label="Historical Weeks"
            :items="weekOptions"
            small
            dense
          />
        </v-col>
        <v-col md="4">
          <v-btn small depressed outlined tile @click="zoomIn">
            <v-icon small left color="grey darken-1">
              fas fa-search-plus
            </v-icon>
            Zoom In
          </v-btn>
          <v-btn class="ml-2" small depressed outlined tile @click="zoomOut">
            <v-icon small left color="grey darken-1">
              fas fa-search-minus
            </v-icon>
            <v-spacer />
            Zoom Out
          </v-btn>
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader
        :loading="loading || !chartDataInitialized"
        type="image, image"
        height="300px"
      >
        <IncidentWeeklyAverageReportChart
          :chart-data="chartData"
          :max="chartMax"
          :min="chartMin"
          :buckets-per-day="bucketsPerDay"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from "luxon";
import { getDaysAgo } from "@tod-ui/helpers/datetime";
import IncidentWeeklyAverageReportChart from "./IncidentWeeklyAverageReportChart.vue";
import useFacility from "../authentication/useFacility";
import { FACILITY_REPORT_INCIDENTS } from "./graphql";

// 20 Unique Colors from the Material Design color pallette
const LINE_COLORS = [
  "#F44336",
  "#673AB7",
  "#03A9F4",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#E91E63",
  "#3F51B5",
  "#00BCD4",
  "#8BC34A",
  "#FFC107",
  "#795548",
  "#9C27B0",
  "#2196F3",
  "#009688",
  "#CDDC39",
  "#FF9800",
  "#607D8B",
  "#9E9E9E",
  "#000000",
];
export default {
  name: "WeeklyAverageIncidentReport",
  components: {
    IncidentWeeklyAverageReportChart,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    incidents: {},
    chartData: {},
    rangeSelector: 0,
    chartMin: 0,
    chartMax: 8,
    bucketsPerDay: 1,
    selectedOption: 0,
    bucketOptions: [1, 6, 12, 24],
    weekCount: 4,
    weekOptions: [2, 3, 4, 6, 8, 10, 14, 16, 18, 20],
    chartDataInitialized: false,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.incidents.loading;
    },
  },
  apollo: {
    incidents: {
      // This is already filtering for only "consumer" users
      query: FACILITY_REPORT_INCIDENTS,
      variables() {
        return {
          parentId: this.currentFacilityId,
          after: getDaysAgo(this.weekCount * 7),
        };
      },
      update({ incidents }) {
        this.formatIncidents(incidents);
        return incidents;
      },
    },
  },
  methods: {
    async zoomIn() {
      if (this.selectedOption + 1 < this.bucketOptions.length) {
        this.chartDataInitialized = false;
        this.selectedOption += 1;
        this.bucketsPerDay = this.bucketOptions[this.selectedOption];
        this.formatIncidents(this.incidents);
        // await this.$apollo.queries.incidents.refetch();
      }
    },
    async zoomOut() {
      if (this.selectedOption - 1 >= 0) {
        this.chartDataInitialized = false;
        this.selectedOption -= 1;
        this.bucketsPerDay = this.bucketOptions[this.selectedOption];
        this.formatIncidents(this.incidents);
        // await this.$apollo.queries.incidents.refetch();
      }
    },
    formatIncidents(incidents) {
      // Track the max event count for the chart scale/boundary
      let date = DateTime.local();
      let startOfWeek = date.startOf("week");
      // Convert/Map the events into chart datasets
      let week_arrays = this.initializeDataArray(0);
      for (let incident of incidents) {
        const incStart = DateTime.fromISO(incident.startedAt);
        let weekOffset = 0;
        if (incStart.ordinal < startOfWeek.ordinal) {
          weekOffset = 1;
        }
        let ts = this.formatDateTime(incident.startedAt);
        const dataElem = week_arrays[weekOffset].find(({ x }) => x == ts);
        if (dataElem) {
          dataElem.y += 1;
        }
        weekOffset = 0;
      }
      week_arrays[1] = this.averageWeek(week_arrays[1]);
      this.findMax(week_arrays);
      let datasets = [];
      datasets.push({
        label: `This Week`,
        borderColor: LINE_COLORS[0],
        pointBackgroundColor: LINE_COLORS[0],
        pointBorderColor: LINE_COLORS[0],
        data: week_arrays[0],
        showLine: true,
      });
      datasets.push({
        label: `Historical Average`,
        borderColor: LINE_COLORS[1],
        pointBackgroundColor: LINE_COLORS[1],
        pointBorderColor: LINE_COLORS[1],
        data: week_arrays[1],
        showLine: true,
      });
      this.chartData = { datasets: datasets };
      this.$nextTick(() => {
        // Add the component back in
        this.chartDataInitialized = true;
      });
    },
    averageWeek(week_array) {
      //Average the value of the 'old' week.
      const weekCount = this.weekCount;
      for (let i = 0; i < week_array.length; i++) {
        if (week_array[i].y > 0) {
          week_array[i].y = Math.ceil(week_array[i].y / weekCount);
        }
      }
      return week_array;
    },
    findMax(week_arrays) {
      let maxCount = 0;
      for (let i = 0; i < week_arrays.length; i++) {
        let max = Math.max.apply(
          Math,
          week_arrays[i].map(function (o) {
            return o.y;
          })
        );
        if (max > maxCount) {
          maxCount = max;
        }
      }
      if (maxCount == 0) {
        maxCount = 1;
      }
      this.chartMax = maxCount + Math.ceil(maxCount / 20);
    },
    formatDateTime(datetime) {
      if (!datetime) return datetime;
      const dt = DateTime.fromISO(datetime, {
        zone: this.facilityTimeZone,
      });
      let weekday = dt.weekday;
      if (weekday == 0) {
        weekday == 6;
      } else {
        weekday = weekday - 1;
      }
      let hourOfWeek = weekday * 24 + dt.hour;
      return Math.floor(hourOfWeek / (24 / this.bucketsPerDay));
    },
    initializeDataArray(initialValue) {
      let week_arrays = [];
      //Week 1
      let init_array = [];
      for (let i = 0; i < this.bucketsPerDay * 7; i++) {
        init_array.push({ x: i, y: initialValue });
      }
      week_arrays.push(JSON.parse(JSON.stringify(init_array)));
      week_arrays.push(JSON.parse(JSON.stringify(init_array)));
      return week_arrays;
    },
  },
};
</script>
