<template>
  <v-simple-table>
    <thead>
      <tr>
        <th>Date, Time</th>
        <th>Changes</th>
        <th width="40%">Cause</th>
      </tr>
    </thead>
    <tbody v-if="billingAccountEventsLoading">
      <tr>
        <td colspan="3">
          <v-banner>
            <em>Loading Account Events</em>
          </v-banner>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="billingAccountEvents.length === 0">
      <tr>
        <td colspan="3">
          <v-banner>
            <em>No Account Updates Found</em>
          </v-banner>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="event in billingAccountEvents" :key="event.id">
        <td>
          {{ facilityFormatDateTime4Humans(event.timestamp, false, false) }}
        </td>
        <td>{{ event.changes }}</td>
        <td>{{ event.cause }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
import { BILLING_ACCOUNT_EVENTS } from "./graphql";
import useFacility from "../authentication/useFacility";

export default {
  name: "AccountHistoryTable",
  props: {
    billingAccountId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { facilityFormatDateTime4Humans } = useFacility();
    return { facilityFormatDateTime4Humans };
  },
  data: () => ({
    billingAccountEvents: [],
  }),
  computed: {
    billingAccountEventsLoading() {
      return this.$apollo.queries.billingAccountEvents.loading;
    },
  },
  apollo: {
    billingAccountEvents: {
      query: BILLING_ACCOUNT_EVENTS,
      variables() {
        return {
          billingAccountId: this.billingAccountId,
          name: "account_updated",
        };
      },
      update({ billingAccountEvents }) {
        return billingAccountEvents.map((event) => {
          let statusText = false;
          switch (JSON.parse(event.data)?.changes?.status) {
            case "disabled_lien":
              statusText = "Disable for Non-Payment";
              break;
            case "disabled_maintenance":
              statusText = "Disable for Maintenance";
              break;
            case "disabled_device_upgrade":
              statusText = "Disable for Device Upgrade";
              break;
            case "disabled":
              statusText = "Disable";
              break;
            case "active":
              statusText = "Enable";
              break;
          }
          const changes = statusText ? `Set to "${statusText}"` : "";
          return {
            ...event,
            changes,
          };
        });
      },
    },
  },
};
</script>

<style></style>
