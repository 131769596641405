<template>
  <CardLayoutV2 title="Average # of Visits (MTD)" :loading="loading">
    <FacilityVisitsChart
      :chart-data="chartData"
      @quadrantData="goToFacilityVisitsReport"
    />
  </CardLayoutV2>
</template>

<script>
import { VISITS_FREQUENCY } from "@tod-ui/constants/dashboard";
import FacilityVisitsChart from "./FacilityVisitsChart.vue";
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import { CHART_COLORS } from "@tod-ui/constants/colors";

export default {
  components: {
    FacilityVisitsChart,
    CardLayoutV2,
  },
  props: {
    facilityVisits: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    groupedFacilityVisits() {
      const groups = {
        [VISITS_FREQUENCY.LESS_THAN_1]: 0,
        [VISITS_FREQUENCY.BETWEEN_1_AND_20]: 0,
        [VISITS_FREQUENCY.BETWEEN_20_AND_40]: 0,
        [VISITS_FREQUENCY.GREATER_THAN_40]: 0,
      };

      this.facilityVisits.forEach((facility) => {
        if (facility.visitsNumber > 40) {
          groups[VISITS_FREQUENCY.GREATER_THAN_40]++;
        } else if (facility.visitsNumber >= 20 && facility.visitsNumber <= 40) {
          groups[VISITS_FREQUENCY.BETWEEN_20_AND_40]++;
        } else if (facility.visitsNumber >= 1 && facility.visitsNumber < 20) {
          groups[VISITS_FREQUENCY.BETWEEN_1_AND_20]++;
        } else if (facility.visitsNumber < 1) {
          groups[VISITS_FREQUENCY.LESS_THAN_1]++;
        }
      });

      const sortedGroups = Object.entries(groups)
        .map(([key, value]) => ({ label: key, value }))
        .sort((a, b) => b.value - a.value);

      return sortedGroups;
    },
    chartData() {
      return {
        data: this.groupedFacilityVisits,
        backgroundColors: {
          [VISITS_FREQUENCY.GREATER_THAN_40]: CHART_COLORS[0],
          [VISITS_FREQUENCY.BETWEEN_20_AND_40]: CHART_COLORS[1],
          [VISITS_FREQUENCY.BETWEEN_1_AND_20]: CHART_COLORS[2],
          [VISITS_FREQUENCY.LESS_THAN_1]: CHART_COLORS[3],
        },
      };
    },
  },
  methods: {
    goToFacilityVisitsReport(quadrant) {
      this.$router.push({
        name: "FacilityVisitsReport",
        query: {
          highlight_column: "visitsNumber",
          select_option: quadrant.label,
        },
      });
    },
  },
};
</script>
