import gql from "graphql-tag";
import { incidentFields, deviceFields } from "../fragments";

export const getDetailedIncidents = gql`
  query incidents(
    $parentId: ID!
    $dateTimeFilter: DateTimeFilter!
    $status: String
    $limit: Int
    $sort: String
    $resolution: String
    $type: String
    $organizationSubtype: String
  ) {
    incidents(
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      status: $status
      limit: $limit
      sort: $sort
      resolution: $resolution
      type: $type
      organizationSubtype: $organizationSubtype
    ) {
      ...IncidentFields
      responders {
        id
        name
        type
        escalationLevel
        contactMethod {
          id
          type
          value
        }
      }
      survey
      organization {
        name
        id
        type
        subtype
      }
    }
  }
  ${incidentFields}
`;

export const leanUnhealthyDevices = gql`
  query organizationDevices(
    $parentId: ID!
    $disposition: String
    $status: String!
  ) {
    organizationDevices(
      parentId: $parentId
      disposition: $disposition
      status: $status
    ) {
      id
      organization {
        id
        type
        responders {
          id
        }
      }
    }
  }
`;

export const listDevices = gql`
  query organizationDevices(
    $parentId: ID!
    $status: String!
    $disposition: String
  ) {
    organizationDevices(
      parentId: $parentId
      status: $status
      disposition: $disposition
    ) {
      ...DeviceFields
      organization {
        id
        name
        type
        devices {
          ...DeviceFields
        }
        incidents(sort: "closedAt desc", status: "closed", limit: 1) {
          id
        }
        responders {
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${deviceFields}
`;

export const leanListDevices = gql`
  query organizationDevices($parentId: ID!) {
    organizationDevices(parentId: $parentId) {
      id
    }
  }
`;
