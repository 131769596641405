<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline">
        <span class="red--text">WARNING</span>
        <v-spacer />
        <v-btn icon @click="show = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        Are you sure you want to remove Device
        <strong>{{ deviceName }}</strong> from Unit
        <strong>{{ unitName }}</strong
        >?
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = false">Cancel</v-btn>
        <v-btn color="primary" text @click="removeDevice"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed } from "vue";
import useDevice from "../useDevice";

export default {
  name: "DialogRemoveDevice",
  props: {
    device: {
      type: Object,
      required: true,
    },
    unitName: {
      type: String,
      default: "",
    },
    value: {
      validator: (val) =>
        val === null || (typeof val === "string" && val.length > 0),
      default: null,
    },
  },
  setup(props, { emit }) {
    const { moveDevice, deviceName } = useDevice(props.device);
    const show = computed({
      get: () => props.value === "confirm-remove" && props.unitName,
      set: (value) => emit("input", value ? "confirm-remove" : null),
    });
    const { addAlert } = useAlerts();
    return { moveDevice, deviceName, show, addAlert };
  },
  methods: {
    async removeDevice() {
      if (!this.value || !this.unitName) return;
      try {
        const successMessage = await this.moveDevice(this.$apollo);
        if (successMessage) {
          this.addAlert({
            type: "success",
            message: successMessage,
            timeout: 10,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
          timeout: 15,
        });
      }
      this.show = false;
    },
  },
};
</script>
