<template>
  <CardLayout title="Incident History" :loading="loading">
    <template #header-actions>
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['1m', '2m', '3m']"
        default="1m"
        max-range="6m"
        :min="startRentingTimestamp"
        inverted
        custom
      />
    </template>

    <v-data-table
      :loading="incidentsLoading"
      :headers="headers"
      :items="incidents"
      :no-data-text="`No incidents recorded for the current ${
        renterId ? 'renter' : 'unit'
      }`"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
    >
      <template #item.actions="{ item }">
        <ButtonView @click="viewIncident(item)" />
      </template>

      <!-- <template #item.device="{ item }"> -->
      <!-- Don't show device until we have the alias/shortId -->
      <!-- </template> -->

      <template #item.startedAt="{ item }">
        {{ facilityFormatDateTime4Humans(item.startedAt) }}
      </template>

      <template #item.duration="{ item }">
        {{ durationInWords(item.startedAt, item.endedAt) }}
      </template>

      <template #item.status="{ item }">
        <IconIncidentStatus left small :incident="item" />
        {{ incidentStatus(item) }}
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import IconIncidentStatus from "@atoms/IconIncidentStatus.vue";
import ButtonView from "@atoms/ButtonView.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { SMART_UNIT_INCIDENTS, USER_BILLING_ACCOUNT_EVENTS } from "./graphql";
import { diff, durationInWords } from "@tod-ui/helpers/datetime";
import { incidentStatus } from "../common/helpers/incident";
import { titleCase } from "@tod-ui/helpers/strings";
import useFacility from "../authentication/useFacility";

export default {
  name: "CardIncidentHistory",
  components: {
    CardLayout,
    IconIncidentStatus,
    ButtonView,
    DateTimeFilter,
  },
  props: {
    unitId: {
      type: String,
      required: true,
    },
    renterId: {
      type: [String, Boolean],
      default: false,
    },
  },
  setup() {
    const { facilityFormatDateTime4Humans, facilityTimeZone } = useFacility();
    return { facilityFormatDateTime4Humans, facilityTimeZone };
  },
  data: () => ({
    incidents: [],
    startRentingTimestamp: "",
    dateRange: { after: undefined },
  }),
  computed: {
    headers() {
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          value: "actions",
        },
        // { text: "Device #", value: "device"} ,
        { text: "Start Time", value: "startedAt" },
        { text: "Duration", value: "duration" },
        { text: "Status", value: "status" },
      ];
    },
    loading() {
      return !this.unitId;
    },
    incidentsLoading() {
      return (
        this.$apollo.queries.startRentingTimestamp.loading ||
        this.$apollo.queries.incidents.loading
      );
    },
  },
  apollo: {
    startRentingTimestamp: {
      query: USER_BILLING_ACCOUNT_EVENTS,
      variables() {
        return {
          organizationId: this.unitId,
          renterId: this.renterId,
        };
      },
      update({ userBillingAccounts }) {
        const billingAccountEvents =
          userBillingAccounts[0].billingAccount.events;

        const startRentingEvent = billingAccountEvents.find((event) => {
          const eventData = JSON.parse(event.data);
          //query results already sorted by "timestamp desc"
          return (
            event.name === "organization_added" &&
            eventData.organization.id === this.$route.params.zone_id
          );
        });
        return startRentingEvent?.timestamp;
      },
      skip() {
        return !this.renterId;
      },
    },
    incidents: {
      query: SMART_UNIT_INCIDENTS,
      variables() {
        return {
          organizationId: this.unitId,
          dateTimeFilter: this.dateRange,
          assigned: this.renterId ? true : undefined,
          sort: "startedAt desc",
        };
      },
      skip() {
        return this.renterId && !this.startRentingTimestamp;
      },
      update({ incidents }) {
        return incidents.map((incident) => {
          const endedAt =
            incident.lastUntriggeredAt ||
            incident.closedAt ||
            incident.resolvedAt;
          return {
            ...incident,
            endedAt,
            duration: diff(incident.startedAt, endedAt).as("minutes"),
          };
        });
      },
    },
  },
  methods: {
    deviceId({ devices }) {
      const device = devices[0];
      if (!device) return device;
      return device.shortId || device.externalId;
    },
    durationInWords(startedAt, endedAt) {
      return durationInWords(startedAt, endedAt);
    },
    incidentStatus,
    titleCase,
    viewIncident(incident) {
      const { id: incidentId } = incident;

      this.$router.push({
        name: "Incident",
        params: {
          incident_id: incidentId,
        },
      });
    },
  },
};
</script>
