<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },

      legend: {
        position: "top",
      },
      onClick: this.handleClick, // Registering click event handler
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (items.length > 0) {
        // Extract the data that was clicked
        const clickedData = this.chartData.labels[items[0]._index];
        console.log(clickedData);
        // Navigate to another page with the clicked data
        // this.$router.push({ name: 'details', params: { data: clickedData }});
      }
    },
  },
};
</script>
