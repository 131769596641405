<template>
  <CardLayoutV2 title="Move-Out Hygiene Compliance">
    <v-skeleton-loader
      v-if="$apollo.queries.facilityVacantUnitHygieneStats.loading"
      class="mt-4"
      type="image, image"
      height="380"
    />
    <div v-else>
      <CardDashboardTile
        top-title="Vacant Unit Unclean Score"
        :top-value="totalUncleanScore + '%'"
        left-title="Total Uncleaned Units"
        :left-value="totalUncleanedUnits.toString()"
        right-title="Average Cleaning Days"
        :right-value="averageCleaningDays.toString()"
      />
      <h3 style="text-align: center; margin-top: 20px">
        Move-Out Hygiene Compliance
      </h3>
      <div style="cursor: pointer">
        <PieChart :chart-data="formattedChartData" :redirect-value="7" />
      </div>
    </div>
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import useDashboard from "../useDashboard";
import CardDashboardTile from "./CardDashboardTile.vue";
import { roundValue } from "@tod-ui/helpers/math";
import PieChart from "./PieChart.vue";
import { FACILITY_VACANT_UNIT_HYGIENE_STATS } from "./graphql";

export default {
  name: "CardMoveOutHygieneCompliance",
  components: {
    CardLayoutV2,
    CardDashboardTile,
    PieChart,
  },
  setup() {
    const { selectedFacilities } = useDashboard();
    return {
      selectedFacilities,
    };
  },
  data() {
    return {
      chartData: null,
    };
  },
  computed: {
    formattedChartData() {
      if (!this.chartData) {
        return null;
      }
      const cleanedUnits = this.chartData.reduce(
        (sum, item) => sum + item.cleanedVacantUnits,
        0
      );
      const uncleanedUnits = this.chartData.reduce(
        (sum, item) => sum + item.uncleanedVacantUnits,
        0
      );

      return {
        labels: [
          "Units Cleaned Within 7 Days",
          "Units Uncleaned Within 7 Days",
        ],
        datasets: [
          {
            label: "Move-Out Hygiene Compliance",
            data: [cleanedUnits, uncleanedUnits],
            backgroundColor: ["rgb(119,93,208)", "rgb(255,69,96)"],
          },
        ],
      };
    },
    totalUncleanScore() {
      if (!this.chartData) return 0;

      const totalUncleanedUnits = this.chartData.reduce(
        (sum, item) => sum + item.uncleanedVacantUnits,
        0
      );

      const totalVacantUnits = this.chartData.reduce(
        (sum, item) =>
          sum + (item.uncleanedVacantUnits + item.cleanedVacantUnits),
        0
      );

      return roundValue((totalUncleanedUnits / totalVacantUnits) * 100);
    },
    totalUncleanedUnits() {
      if (!this.chartData) return 0;
      return this.chartData.reduce(
        (sum, item) => sum + item.uncleanedVacantUnits,
        0
      );
    },
    averageCleaningDays() {
      if (!this.chartData) return 0;
      const totalDays = this.chartData.reduce(
        (sum, item) => sum + item.averageCleaningDays,
        0
      );
      return roundValue(totalDays / this.chartData.length); // Return average
    },
  },
  apollo: {
    facilityVacantUnitHygieneStats: {
      query: FACILITY_VACANT_UNIT_HYGIENE_STATS,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          cleanedWithinDays: 7,
        };
      },
      result({ data }) {
        if (data?.facilityVacantUnitHygieneStats) {
          this.chartData = data.facilityVacantUnitHygieneStats;
        }
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
};
</script>
