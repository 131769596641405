<template>
  <div v-if="smartUnits !== undefined">
    <span
      :title="`${countKindTitled} Smart Units: ${smartUnitsPercent}`"
      :class="color"
      class="cursor-title"
      >{{ smartUnits }}
    </span>
    <template v-if="!countOnlySmartUnits">
      <span> / </span>
      <span
        v-if="allUnits !== undefined"
        :title="`${countKindTitled} Units: ${allUnitsPercent}`"
        class="cursor-title"
        >{{ allUnits }}
      </span>
      <span v-else title="Unable to query unit count for this type">--</span>
    </template>
  </div>
  <span v-else> - </span>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "NumberOfUnits",
  props: {
    smartUnits: {
      type: Number,
      required: true,
    },
    allUnits: {
      type: Number,
      default: undefined,
    },
    total: {
      type: Number,
      required: true,
    },
    countKind: {
      validator: (val) =>
        val === "occupied" || val === "vacant" || val === "total",
      default: "total",
    },
    countOnlySmartUnits: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    smartUnitsPercent() {
      return percentOfTotal(this.smartUnits, this.total);
    },
    allUnitsPercent() {
      return percentOfTotal(this.allUnits, this.total);
    },
    color() {
      switch (this.countKind) {
        case "occupied":
          return "info--text";
        case "vacant":
          return "success--text";
      }
      return undefined;
    },
    countKindTitled() {
      return titleCase(this.countKind);
    },
  },
};

function percentOfTotal(val, total) {
  return total
    ? `${((val / total) * 100).toLocaleString("en-US", {
        maximumFractionDigits: 1,
      })}%`
    : "";
}
</script>

<style scoped>
.cursor-title {
  cursor: help;
}
</style>
