<template>
  <v-card tile>
    <v-card-text>
      <v-row>
        <v-col md="12">
          <v-row
            v-for="responder in organizationResponders"
            :key="responder.id"
          >
            <v-col cols="3">
              <div class="font-weight-normal">
                <strong>
                  {{ titleCase(responder.organization.type) }} Responder
                </strong>
              </div>
              <div>
                {{ responder.user.firstName }}
                {{ responder.user.lastName }} at
                {{ responder.organization.name }}
              </div>
            </v-col>
            <v-col cols="2">
              <div class="font-weight-normal">
                <strong> Responder Contact Method </strong>
              </div>
              <div>
                <!-- {{ titleCase(responder.contactMethod.type) }} -->
                <div v-if="responder.contactMethod.type == 'phone'">
                  <v-icon small dense> fas fa-mobile-alt </v-icon>
                  {{ formatPhoneNumber(responder.contactMethod.value) }}
                </div>
                <div v-else>
                  <v-icon small dense> fas fa-envelope </v-icon>
                  {{ responder.contactMethod.value }}
                </div>
              </div>
            </v-col>
            <v-col cols="2">
              <div class="font-weight-normal">
                <strong> Escalation Types </strong>
              </div>
              <div>
                <div v-for="type in responder.incidentTypes" :key="type">
                  {{ formatIncidentType(type) }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { organizationResponders } from "../../graphql/organizations/index";

import { titleCase } from "@tod-ui/helpers/strings";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { formatIncidentType } from "../common/helpers/incident";
import useFacility from "../authentication/useFacility";

export default {
  name: "FacilityResponders",
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    organizationResponders: [],
  }),
  apollo: {
    organizationResponders: {
      query: organizationResponders,
      variables() {
        return {
          id: this.currentFacilityId,
          includeAncestors: true,
        };
      },
    },
  },
  methods: {
    titleCase,
    formatPhoneNumber,
    formatIncidentType,
  },
};
</script>
