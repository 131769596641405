<template>
  <CardLayout title="Incident Report" :loading="loading">
    <dl class="property-list my-4">
      <template v-for="item in report">
        <dt :key="item.key">{{ item.text }}</dt>
        <dd :key="item.key + '-val'">
          <div v-if="Array.isArray(item.value)">
            <div v-for="detail in item.value" :key="detail.text">
              {{ detail.text }}: <strong>{{ detail.value }}</strong>
            </div>
          </div>
          <span v-else>{{ item.value }}</span>
        </dd>
      </template>
    </dl>
  </CardLayout>
</template>

<script>
import { computed } from "vue";
import CardLayout from "@layout/CardLayout.vue";
import useIncident from "./useIncident";
import { titleCase } from "@tod-ui/helpers/strings";

const REPORT_FIELDS = {
  user: "Report Submitted By",
  insertedAt: "Report Submitted At",
  visualInspection: "Visual Inspection",
  breakInDetected: "Break In Detected",
  gateAccessCode: "Gate Access Code Used",
  damagedLock: "Damage to lock",
  damagedDoor: "Damage to Door",
  footageInspection: "Video Footage Inspection",
};

const FIELDS_TO_TITLE_CASE = [
  "visualInspection",
  "breakInDetected",
  "gateAccessCode",
  "damagedLock",
  "damagedDoor",
  "footageInspection",
];

export default {
  name: "CardIncidentReport",
  components: { CardLayout },
  setup() {
    const { incident, loading } = useIncident();
    const report = computed(() => {
      const survey = incident.value?.survey;
      if (!survey) return [];
      return Object.entries(REPORT_FIELDS).reduce((acc, [key, text]) => {
        if (survey[key]) {
          let value = FIELDS_TO_TITLE_CASE.includes(key)
            ? titleCase(survey[key])
            : survey[key];
          if (key === "visualInspection" || key === "footageInspection") {
            value = [{ text: "Completed", value }];
            if (survey[key + "Descr"]) {
              value.push({ text: "Details", value: survey[key + "Descr"] });
            }
          }
          acc.push({ key, text, value });
        }
        return acc;
      }, []);
    });
    return { report, loading };
  },
};
</script>
