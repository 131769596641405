import { computed, unref, ref, onUnmounted } from "vue";
import gql from "graphql-tag";
import { apolloWatchQuery } from "../../apollo-client/helper";
import { titleCase } from "@tod-ui/helpers/strings";
import { durationInWords, utcNow } from "@tod-ui/helpers/datetime";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";

const $syncSummary = ref({});
const $unitsCompleted = computed(() => $syncSummary.value?.unitCount);
const $unitsRemain = computed(() => $syncSummary.value?.newOutstandingUnits);

export default function usePMS(allowToFetchSyncSummary = false) {
  const { currentFacility } = useFacility();
  const { addAlert } = useAlerts();

  const propertyManagementSystem = computed(() => {
    const currentPMS = currentFacility.value?.profile?.propertyManagementSystem;
    switch (currentPMS) {
      case "storEDGE":
        return currentPMS;
      case "ssm":
        return currentPMS.toUpperCase();
    }
    return titleCase(currentPMS);
  });
  const hasPMS = computed(() => !!propertyManagementSystem.value);

  const pmsSiteId = computed(
    () => hasPMS.value && currentFacility.value?.externalId
  );
  const pmsServiceHasSiteSummary = computed(() =>
    ["Sitelink"].includes(propertyManagementSystem.value)
  );
  const pmsServiceHasSync = computed(() =>
    // ["Sitelink", "storEDGE", "SSM"].includes(propertyManagementSystem.value)
    ["Sitelink", "SSM"].includes(propertyManagementSystem.value)
  );
  const pmsServiceHasSyncQuotas = computed(() =>
    ["Sitelink", "storEDGE", "SSM"].includes(propertyManagementSystem.value)
  );
  const pmsServiceHasDocuments = computed(
    () => hasPMS.value && !!currentFacility.value.profile?.tenantAddendumName
  );
  const facilityHasNonPaymentAutomation = computed(
    () =>
      hasPMS.value &&
      currentFacility.value.profile?.accountNonPaymentLockOn &&
      currentFacility.value.profile.accountNonPaymentLockOn !== "none"
  );
  const pmsServiceHasUnitRenterSync = computed(() =>
    ["Sitelink"].includes(propertyManagementSystem.value)
  );
  const syncCompleted = computed(
    () =>
      !pmsServiceHasSiteSummary.value ||
      $syncSummary.value?.newOutstandingUnits === 0
  );
  const isDeviceControlledEnrollment = computed(
    () =>
      hasPMS.value &&
      ["set_amenity_for_device", "device_assignment"].includes(
        currentFacility.value.profile?.unitEnrollmentMode
      )
  );

  const requestCredit = ref({});
  const requestCreditQuery = ref();
  const resetQuotaTime = computed(
    () =>
      requestCredit.value.nextQuotaRestoredTimestamp &&
      durationInWords(utcNow(), requestCredit.value.nextQuotaRestoredTimestamp)
  );
  const availablePMSQuota = computed(
    () => requestCredit.value?.availableQuota ?? "--"
  );
  const totalPMSQuota = computed(() => requestCredit.value?.totalQuota || "--");

  const pmsUnitTypeStats = ref([]);

  function getPMSClient(apolloProvider) {
    switch (propertyManagementSystem.value) {
      case "Sitelink":
        return apolloProvider.clients.apolloSitelinkServiceClient;
      case "storEDGE":
        return apolloProvider.clients.apolloStoredgeServiceClient;
      case "SSM":
        return apolloProvider.clients.apolloSsmServiceClient;
    }
    return null;
  }

  function renterHasNonPaymentAutomation(renter) {
    return facilityHasNonPaymentAutomation.value && !!unref(renter)?.externalId;
  }

  let siteSummaryQuery = null;
  function fetchSyncSummary(apolloProvider) {
    if (hasPMS.value && pmsSiteId.value && pmsServiceHasSiteSummary.value) {
      siteSummaryQuery = apolloWatchQuery(
        getPMSClient(apolloProvider),
        {
          query: gql`
            query SiteSummary($siteId: String!) {
              siteSummary(siteId: $siteId) {
                tenantCount
                unitCount
                newOutstandingUnits
              }
            }
          `,
          variables: { siteId: pmsSiteId.value },
          fetchPolicy: "no-cache",
          pollInterval: 12 * 60 * 60 * 1000, //12 hours
        },
        (data) => {
          if (data) {
            $syncSummary.value = data.siteSummary;
          }
        }
      );
    }
  }

  function fetchPMSQuotas(apolloProvider) {
    if (hasPMS.value && pmsSiteId.value && pmsServiceHasSyncQuotas.value) {
      requestCreditQuery.value = apolloWatchQuery(
        getPMSClient(apolloProvider),
        {
          query: gql`
            query GetRequestCredit($siteId: String!) {
              getRequestCredit(siteId: $siteId) {
                availableQuota
                totalQuota
                usedQuota
                nextQuotaRestoredTimestamp
              }
            }
          `,
          variables: { siteId: pmsSiteId.value },
          fetchPolicy: "no-cache",
        },
        (data) => {
          if (data) {
            requestCredit.value = data.getRequestCredit;
          }
        }
      );
    }
  }

  async function triggerPMSSync(apolloProvider, currentPage, variable) {
    if (hasPMS.value && pmsSiteId.value) {
      try {
        let mutation = gql`
          mutation SyncChangesNow($siteId: String!) {
            syncChangesNow(siteId: $siteId) {
              status
            }
          }
        `;
        let variables = {
          siteId: pmsSiteId.value,
        };
        if (pmsServiceHasUnitRenterSync.value) {
          switch (currentPage) {
            case "unit":
              mutation = gql`
                mutation SyncUnits($siteId: ID!, $unitIds: [ID]!) {
                  syncUnits(siteId: $siteId, unitIds: $unitIds) {
                    syncedIds
                  }
                }
              `;
              variables.unitIds = variable;
              break;
            case "renter":
              mutation = gql`
                mutation SyncTenants($siteId: ID!, $tenantIds: [ID]!) {
                  syncTenants(siteId: $siteId, tenantIds: $tenantIds) {
                    syncedIds
                  }
                }
              `;
              variables.tenantIds = variable;
              break;
          }
        }
        const { data } = await getPMSClient(apolloProvider).mutate({
          mutation,
          variables,
        });
        if (
          (currentPage === "unit" && data.syncUnits.syncedIds) ||
          (currentPage === "renter" && data.syncTenants.syncedIds) ||
          data.syncChangesNow?.status === "started"
        ) {
          requestCreditQuery.value?.refetch();
          return true;
        }
      } catch (error) {
        // error should be logged
        // console.error(error.message);
        if (error.message.endsWith("Quota Exceeded")) {
          addAlert({
            type: "error",
            message: `No more ${propertyManagementSystem.value} request available now!`,
            timeout: 15,
          });
        }
      }
    }
    return false;
  }

  function getFieldHint(fieldName) {
    if (
      propertyManagementSystem.value &&
      FIELD_HINTS[propertyManagementSystem.value]
    ) {
      return FIELD_HINTS[propertyManagementSystem.value][fieldName] || "";
    }
    return "";
  }

  if (allowToFetchSyncSummary) {
    onUnmounted(() => {
      if (siteSummaryQuery) {
        siteSummaryQuery.stopPolling();
      }
    });
  }

  function fetchPMSUnitTypesStats(apolloProvider) {
    if (hasPMS.value && pmsSiteId.value) {
      apolloWatchQuery(
        getPMSClient(apolloProvider),
        {
          query: gql`
            query SiteUnitTypeStats($siteId: String!) {
              siteUnitTypeStats(siteId: $siteId) {
                internalId
                totalUnits
                occupiedUnits
              }
            }
          `,
          variables: { siteId: pmsSiteId.value },
        },
        (data) => {
          if (data) {
            pmsUnitTypeStats.value = data.siteUnitTypeStats;
          }
        }
      );
    }
  }

  return {
    propertyManagementSystem,
    hasPMS,
    pmsSiteId,
    pmsServiceHasSync,
    pmsServiceHasDocuments,
    pmsServiceHasUnitRenterSync,
    isDeviceControlledEnrollment,
    syncCompleted,
    renterHasNonPaymentAutomation,
    $unitsCompleted,
    $unitsRemain,
    fetchSyncSummary: allowToFetchSyncSummary
      ? fetchSyncSummary
      : () =>
          console.error("Call to fetchSyncSummary is not explicitly allowed"),
    fetchPMSQuotas,
    triggerPMSSync,
    resetQuotaTime,
    availablePMSQuota,
    totalPMSQuota,
    getFieldHint,
    pmsUnitTypeStats,
    fetchPMSUnitTypesStats,
  };
}

const FIELD_HINTS = {
  Sitelink: {
    primaryPhoneMethod: `"Mobile Phone" field used as the primary phone.`,
  },
  storEDGE: {
    primaryPhoneMethod: `Primary phone number of type "mobile" is used here.`,
  },
};
