<template>
  <div>
    <DialogLayout v-model="isOpen" title="Zone Climate Chart" width="1000px">
      <ZoneEnvironmentReport
        :external-date-time-filter="dateFilter"
        :external-zone-id="selectedZoneId"
      />
    </DialogLayout>
    <div class="text-h6">
      {{ titleCase(category) }} {{ titleCase(climateType) }} Chart
    </div>
    <v-skeleton-loader :loading="localLoading" type="image">
      <ChartClimateComprehensive
        :chart-data="chartData"
        @chartClicked="handleChartClick"
      />
    </v-skeleton-loader>
  </div>
</template>
<script>
import { ref } from "vue";
import ChartClimateComprehensive from "./ChartClimateComprehensive.vue";
import { FACILITY_CLIMATE_STATS } from "./graphql";
import { extractDateFromDateTimeFilter } from "@tod-ui/helpers/datetime";
import { titleCase } from "@tod-ui/helpers/strings";
import ZoneEnvironmentReport from "@components/reports/ZoneEnvironmentReport.vue";
import DialogLayout from "@layout/DialogLayout.vue";
export default {
  name: "ClimateComprehensiveChartData",
  components: {
    ChartClimateComprehensive,
    ZoneEnvironmentReport,
    DialogLayout,
  },
  props: {
    climateType: {
      type: String,
      default: null,
    },
    dateFilter: {
      type: Object,
      default: () => ({
        after: null,
        before: null,
      }),
    },
    facilityIds: {
      type: Array,
      default: () => [],
    },
    reportInterval: {
      type: String,
      default: null,
    },
    threshold: {
      type: Number,
      default: 0,
    },
    category: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const climateStats = ref([]);
    const chartData = ref({});
    const selectedZoneId = ref(null);
    const isOpen = ref(false);

    return { climateStats, chartData, titleCase, selectedZoneId, isOpen };
  },
  computed: {
    localLoading() {
      return this.loading || this.$apollo.queries.climateStats.loading;
    },
  },
  apollo: {
    climateStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: this.climateType,
          dateFilter: extractDateFromDateTimeFilter(this.dateFilter),
          facilityIds: this.facilityIds,
          reportInterval: this.reportInterval,
          numberOfIncidents: this.threshold,
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        this.climateStats = stats;
        if (!stats) return [];
        const threshold = this.threshold;
        const filteredStats = stats.filter((s) => s.incidentCount >= threshold);
        // Group by date and facility
        const dateGroups = {};
        filteredStats.forEach((stat) => {
          const date = stat.startDate;
          if (!dateGroups[date]) {
            dateGroups[date] = {};
          }
          if (!dateGroups[date][stat.facilityName]) {
            dateGroups[date][stat.facilityName] = [];
          }
          dateGroups[date][stat.facilityName].push({
            zoneName: stat.zoneName,
            incidentCount: stat.incidentCount,
          });
        });
        // Get unique dates and facilities
        const dates = Object.keys(dateGroups).sort();
        const facilities = Array.from(
          new Set(filteredStats.map((s) => s.facilityName))
        );
        // Create labels combining date and facility
        const labels = dates.flatMap((date) =>
          facilities.map((facility) => ({
            date,
            facility,
            label: date,
          }))
        );
        // Get all unique zones
        const allZones = Array.from(
          new Set(filteredStats.map((s) => s.zoneName))
        );
        // Create datasets for each zone
        const datasets = allZones.map((zoneName) => ({
          label: zoneName,
          data: labels.map(({ date, facility }) => {
            const facilityData = dateGroups[date]?.[facility] || [];
            const zoneData = facilityData.find((z) => z.zoneName === zoneName);
            return zoneData ? zoneData.incidentCount : 0;
          }),
          backgroundColor: this.getRandomColor(),
          stack: "stack1",
        }));
        this.chartData = {
          labels: labels.map((l) => l.label),
          datasets: datasets,
        };
        return filteredStats;
      },
      skip() {
        return (
          !this.facilityIds.length ||
          !this.dateFilter?.after ||
          !this.climateType ||
          !this.reportInterval
        );
      },
    },
  },
  methods: {
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    handleChartClick(clickedData) {
      const stat = this.climateStats.find(
        (s) =>
          s.startDate === clickedData.date &&
          s.zoneName === clickedData.zoneName
      );
      if (stat) {
        this.selectedZoneId = stat.zoneId;
        this.isOpen = true;
      }
    },
  },
};
</script>
