import PageNotifications from "./PageNotifications.vue";
import NotificationResolution from "./NotificationResolution.vue";

const routes = {
  organization: [
    {
      path: "notifications/:id?",
      name: "Notifications",
      component: PageNotifications,
    },
  ],
  navigation: [
    {
      path: "/notifications",
      name: "UserNotifications",
      component: PageNotifications,
    },
    {
      path: "/resolve_notification/:id",
      name: "NotificationResolution",
      component: NotificationResolution,
    },
  ],
};
export default routes;
