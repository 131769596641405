<template>
  <v-autocomplete
    ref="orgAutocomplete"
    v-model="model"
    :items="items"
    :search-input.sync="search"
    :filter="() => true"
    :hide-no-data="!$attrs['no-data-text']"
    hide-details
    item-text="name"
    item-value="id"
    :label="_label"
    :prepend-icon="icon"
    return-object
    :style="{ maxWidth: maxWidth }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #item="{ item }">
      <v-list-item-icon class="mr-2">
        <OrganizationIcon :organization="item" small />
      </v-list-item-icon>
      <v-list-item-content>
        {{ item.name }}
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { LIST_ORGANIZATION_DESCENDANTS } from "../../graphql/organizations";

import OrganizationIcon from "./OrganizationIcon.vue";
import { debounce } from "@tod-ui/helpers/utils";
import gql from "graphql-tag";

const FIND_ORGANIZATION = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      id
      name
      type
      subtype
    }
  }
`;

export default {
  name: "OrganizationAutocomplete",
  components: { OrganizationIcon },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    organization: {
      type: Object,
      required: true,
    },
    value: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: "mdi-magnify",
    },
    label: {
      type: String,
      default: "",
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: "25%",
    },
  },
  data() {
    return {
      items: [],
      descendants: null,
      initialValue: null,
      search: "",
      minSearchLength: 3,
    };
  },
  computed: {
    searchParams() {
      const params = {
        ...this.filter,
      };

      delete params.exclude;
      for (let k in params) {
        if (params[k] === null) {
          delete params[k];
        }
      }

      return params;
    },
    exclude() {
      return this.filter?.exclude;
    },
    _label() {
      return this.label || `Search ${this.organization.name} organizations`;
    },
    model: {
      get() {
        return this.initialValue;
      },
      set(val) {
        if (val) {
          this.initialValue = val;
          this.$emit("input", val);
        }
      },
    },
  },
  watch: {
    search(value) {
      const searchText = value?.trim();
      if (
        !searchText ||
        (!this.prefetch && searchText.length < this.minSearchLength) ||
        searchText === this.initialValue?.name
      )
        return;

      this.debouncedSearch(searchText);
    },
  },
  beforeMount() {
    if (this.value?.id) {
      this.setInitialOrganization();
    }
    if (this.prefetch) {
      this.searchOrganizationDescendants("");
    }
    this.debouncedSearch = debounce(this.searchOrganizationDescendants);
  },
  beforeDestroy() {
    this.debouncedSearch.cancel();
  },
  methods: {
    async searchOrganizationDescendants(searchText) {
      if (this.prefetch && this.descendants !== null) {
        this.items =
          searchText === ""
            ? this.descendants
            : this.descendants.filter((org) =>
                org.name.toLowerCase().includes(searchText.toLowerCase())
              );
      } else {
        try {
          const {
            data: { organizationDescendants },
          } = await this.$apollo.query({
            query: LIST_ORGANIZATION_DESCENDANTS,
            variables: {
              id: this.organization.id,
              searchText,
              ...this.searchParams,
            },
          });

          this.descendants = organizationDescendants
            .filter((org) =>
              this.exclude && Array.isArray(this.exclude)
                ? !this.exclude.includes(org.id)
                : true
            )
            .map((org) => ({
              id: org.id,
              name: org.name,
              type: org.type,
            }));

          this.items = this.descendants;
          if (this.prefetch) {
            this.$refs.orgAutocomplete.isMenuActive = true;
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    async setInitialOrganization() {
      let organization = {};
      if (this.value?.id && !this.value?.name) {
        try {
          const result = await this.$apollo.query({
            query: FIND_ORGANIZATION,
            variables: {
              id: this.value.id,
            },
          });
          organization = result.data.organization;
        } catch (error) {
          console.error(error);
        }
      } else {
        organization = this.value;
      }

      this.initialValue = {
        id: organization.id,
        name: organization.name,
        type: organization.type,
      };
      this.items = [this.initialValue];
    },
  },
};
</script>
