<template>
  <v-card dense>
    <v-card-text class="node-content" @click="toggleNode">
      <v-icon v-if="hasChildren">{{
        expanded ? "mdi-minus" : "mdi-chevron-down"
      }}</v-icon>
      <v-checkbox v-model="isChecked" hide-details dense />
      <span>{{ node.name }}</span>
    </v-card-text>
    <v-expand-transition>
      <div v-show="expanded" class="children">
        <tree-node
          v-for="childNode in node.children"
          :key="childNode.id"
          :node="childNode"
          :checked-nodes="checkedNodes"
          @update:checked-nodes="handleChildUpdate"
        />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "TreeNode",
  props: {
    node: {
      type: Object,
      required: true,
    },
    checkedNodes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
    isChecked: {
      get() {
        return this.checkedNodes.some((n) => n.id === this.node.id);
      },
      set(value) {
        const isChecked = this.checkedNodes.some((n) => n.id === this.node.id);

        if (value !== isChecked) {
          if (value) {
            this.$emit("update:checked-nodes", [
              ...this.checkedNodes,
              { name: this.node.name, id: this.node.id },
            ]);
          } else {
            const updatedNodes = this.checkedNodes.filter(
              (n) => n.id !== this.node.id
            );
            this.$emit("update:checked-nodes", updatedNodes);
          }

          this.updateChildrenNodes(this.node, value);
        }
      },
    },
  },
  methods: {
    toggleNode() {
      this.expanded = !this.expanded;
    },
    handleChildUpdate(updatedNodes) {
      this.$emit("update:checked-nodes", updatedNodes);
    },
    updateChildrenNodes(parentNode, selected) {
      const updatedNodes = [...this.checkedNodes];
      this.updateNodeSelection(parentNode, selected, updatedNodes);
      this.$emit("update:checked-nodes", updatedNodes);
    },
    updateNodeSelection(node, selected, updatedNodes) {
      const index = updatedNodes.findIndex((n) => n.id === node.id);
      if (selected && index === -1) {
        updatedNodes.push({ name: node.name, id: node.id });
      } else if (!selected && index !== -1) {
        updatedNodes.splice(index, 1);
      }

      if (node.children) {
        node.children.forEach((childNode) => {
          this.updateNodeSelection(childNode, selected, updatedNodes);
        });
      }
    },
  },
};
</script>

<style scoped>
.node-content {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.children {
  padding-left: 35px;
}
</style>
