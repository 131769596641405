<template>
  <CardLayoutV2 title="HBS (Human Behavior Summary)" :loading="loading">
    <div class="d-flex justify-end">
      <DateTimeFilter
        v-model="dateTime"
        :ranges="['Today', '2d', '1m']"
        default="1m"
        custom
        inverted
      />
    </div>
    <v-skeleton-loader
      v-if="$apollo.queries.incidentsSummary.loading"
      class="mt-4"
      type="image, image"
      height="380"
    />

    <StackedChart
      v-else-if="chartData"
      :chart-data="chartData"
      @chartClicked="chartClicked"
    />
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import useDashboard from "../useDashboard";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { formatToISODate, getMonth } from "@tod-ui/helpers/datetime";
import useUser from "@components/authentication/useUser";
import { computed, ref } from "vue";
import { HUMAN_BEHAVIOR_SUMMARY } from "./graphql";
import { DateTime } from "luxon";
import StackedChart from "./StackedChart.vue";

export default {
  name: "CardHumanBehaviorSummary",
  components: {
    CardLayoutV2,
    StackedChart,
    DateTimeFilter,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { selectedFacilities } = useDashboard();

    const currentMonth = computed(() => {
      return getMonth(0, 0, defaultOrganization.value.timeZone);
    });
    const currentDate = computed(() => {
      const date = new Date().toISOString();
      return formatToISODate(date, defaultOrganization.value.timeZone);
    });
    const dateTime = ref({
      after: currentMonth.value,
      before: currentDate.value,
    });
    const formattedDate = computed(() => ({
      after: formatToISODate(
        dateTime.value.after,
        defaultOrganization.value.timeZone
      ),
      before: formatToISODate(
        dateTime.value.before,
        defaultOrganization.value.timeZone
      ),
    }));

    return {
      selectedFacilities,
      dateTime,
      formattedDate,
    };
  },
  data() {
    return {
      selectedReportBy: "DAYS",
      chartData: null,
      incidentsSummary: null,
      filter: {},
    };
  },
  apollo: {
    incidentsSummary: {
      query: HUMAN_BEHAVIOR_SUMMARY,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          dateFilter: this.formattedDate,
          reportInterval: this.selectedReportBy,
          ...this.filter,
        };
      },
      result({ data }) {
        if (data?.incidentsSummary) {
          this.incidentsSummary = data.incidentsSummary;
        }
      },
      skip() {
        return (
          !this.selectedFacilities.length ||
          !this.dateTime.after ||
          !this.dateTime.before
        );
      },
    },
  },
  watch: {
    incidentsSummary: {
      handler(newSummary) {
        if (newSummary) this.chartData = this.formatChartData(newSummary);
      },
      immediate: true,
    },
  },
  methods: {
    applyFilter(filter) {
      // translate to incident query args here
      const _filter = { ...filter };

      _filter.organizationSubtypes = _filter.locations;
      delete _filter.locations;

      if (_filter.statuses?.length) {
        _filter.resolutions = [];

        if (_filter.statuses.includes("authorized")) {
          _filter.resolutions.push("claimed");
        }

        if (_filter.statuses.includes("unauthorized")) {
          _filter.resolutions.push("denied");
          _filter.resolutions.push("surveyed");
        }

        if (_filter.statuses.includes("unacknowledged")) {
          _filter.resolutions.push("unexplained");
        }
      }
      delete _filter.statuses;

      // ignore empty/null filters
      for (let k in _filter) {
        if (_filter[k] === null) {
          delete _filter[k];
        } else if (typeof _filter[k] === "boolean" && !_filter[k]) {
          delete _filter[k];
        } else if (Array.isArray(_filter[k]) && !_filter[k].length) {
          delete _filter[k];
        }
      }

      if (_filter.afterHours) {
        _filter["duringAccessHours"] =
          _filter.afterHours === "after-hours" ? false : undefined;
      }

      this.filter = _filter;
    },
    formatChartData(incidentsSummary) {
      const aggregatedData = incidentsSummary.reduce((acc, item) => {
        const dateKey = item.reportDateTime;
        if (!acc[dateKey]) {
          acc[dateKey] = {
            reportDateTime: item.reportDateTime,
            authorized: 0,
            unAuthorized: 0,
            unAcknowledged: 0,
            afterHoursVacant: 0,
            afterHoursOccupied: 0,
          };
        }

        acc[dateKey].authorized += item.authorized;
        acc[dateKey].unAuthorized += item.unAuthorized;
        acc[dateKey].unAcknowledged += item.unAcknowledged;
        acc[dateKey].afterHoursVacant += item.afterHoursVacant;
        acc[dateKey].afterHoursOccupied += item.afterHoursOccupied;

        return acc;
      }, {});

      const aggregatedArray = Object.values(aggregatedData);

      const labels = aggregatedArray.map((item) => {
        const date = new Date(item.reportDateTime);
        return `${date.toLocaleString("en", {
          month: "short",
        })} ${date.getDate()}`;
      });

      const datasetConfig = [
        { label: "Authorized", field: "authorized", color: "#0AC73C" },
        {
          label: "Unauthorized",
          field: "unAuthorized",
          color: "#DF2127",
        },
        {
          label: "Not Acknowledged",
          field: "unAcknowledged",
          color: "#A4A4A4",
        },
        {
          label: "After Hours - Vacant",
          field: "afterHoursVacant",
          color: "#FD8C07",
        },
        {
          label: "After Hours - Occupied",
          field: "afterHoursOccupied",
          color: "#990000",
        },
      ];

      const datasets = datasetConfig.map(({ label, field, color }) => ({
        label,
        backgroundColor: color,
        data: aggregatedArray.map((item) => item[field]),
      }));

      return { labels, datasets };
    },

    chartClicked(data) {
      const clickedDate = DateTime.fromFormat(data.date, "MMM d")
        .set({ year: DateTime.now().year })
        .toFormat("yyyy-MM-dd");
      this.$router.push(
        `/operation_and_security/human_behavior_summary_report/${clickedDate}/${data.datasetLabel}`
      );
    },
  },
};
</script>
