<template>
  <v-skeleton-loader :loading="loading" type="card">
    <v-card class="tile-card pa-1 black-border">
      <div v-if="heading" class="mb-2">
        {{ heading }}
      </div>
      <div v-if="headValue" class="text-h4 cursor-pointer">
        {{ headValue }}
      </div>
      <div class="flex-container">
        <div class="value-container">
          <div v-if="topTitle">
            {{ topTitle }}
            <v-tooltip v-if="topTooltip" bottom color="black">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon small>mdi-information</v-icon>
                </span>
              </template>
              <span>{{ topTooltip }}</span>
            </v-tooltip>
          </div>

          <div class="arrowAlign" @click="emitClickEvent('top')">
            <div
              v-if="topTitle"
              :style="{ color: topColor }"
              class="text-h6 cursor-pointer py-2"
            >
              {{ topValueWithUnit }}
            </div>
            <div v-if="topValueChangePercent === 0">0%</div>
            <div v-else>
              <div v-if="topValueChange" class="ml-3">
                <IconArrow
                  :color="topValueChange === 'up' ? INCLINE_GREEN : DECLINE_RED"
                  :direction="topValueChange"
                >
                  <span v-if="topValueChangePercent">
                    {{ Math.abs(topValueChangePercent) }}%
                  </span>
                </IconArrow>
              </div>
              <div v-else>--</div>
            </div>
          </div>
        </div>
        <div class="value-container">
          <div v-if="bottomTitle">
            {{ bottomTitle }}
            <v-tooltip v-if="bottomTooltip" bottom color="black">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon small>mdi-information</v-icon>
                </span>
              </template>
              <span>{{ bottomTooltip }}</span>
            </v-tooltip>
          </div>
          <div class="arrowAlign" @click="emitClickEvent('bottom')">
            <div
              v-if="bottomTitle"
              :style="{ color: bottomColor }"
              class="text-h6 cursor-pointer py-2"
            >
              {{ bottomValueWithUnit }}
            </div>
            <div v-if="bottomValueChangePercent === 0">0%</div>
            <div v-else>
              <div v-if="bottomValueChange" class="ml-3">
                <IconArrow
                  :color="
                    bottomValueChange === 'up' ? INCLINE_GREEN : DECLINE_RED
                  "
                  :direction="bottomValueChange"
                >
                  <span v-if="bottomValueChangePercent">
                    {{ Math.abs(bottomValueChangePercent) }}%
                  </span>
                </IconArrow>
              </div>
              <div v-else>--</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="headValue"
        :style="{ height: '10px', width: '100%', backgroundColor: avatarColor }"
      ></div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
import IconArrow from "@atoms/IconArrow.vue";
import { DECLINE_RED, INCLINE_GREEN } from "@tod-ui/constants/colors";
import { thousandSeparator } from "@tod-ui/helpers/strings";
export default {
  name: "DashboardTileV2",
  components: {
    IconArrow,
  },
  props: {
    heading: {
      type: String,
      default: "",
    },
    headValue: {
      type: [String, Number],
      default: "",
    },
    topTitle: {
      type: String,
      default: "",
    },
    topColor: {
      type: String,
      default: "black",
    },
    bottomColor: {
      type: String,
      default: "black",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: 0,
    },
    bottomValue: {
      type: [String, Number],
      default: 0,
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },
    topValueChange: {
      type: String,
      default: "",
    },
    topValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    bottomValueChange: {
      type: String,
      default: "",
    },
    bottomValueChangePercent: {
      type: [String, Number],
      default: "",
    },
    headClickEvent: {
      type: String,
      default: "",
    },
    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      INCLINE_GREEN,
      DECLINE_RED,
    };
  },
  computed: {
    topValueWithUnit() {
      return this.topUnit === "$"
        ? `${this.topUnit} ${thousandSeparator(this.topValue)}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${thousandSeparator(this.bottomValue)}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },
  },
  methods: {
    emitClickEvent(type) {
      let value = "";
      if (type === "top") {
        value = this.topTitle ? this.topTitle : this.heading;
      } else if (type === "bottom") {
        value = this.bottomTitle;
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("valueClick", value);
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.tile-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
</style>
