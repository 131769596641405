import { ref, computed, watch } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import useUser from "../authentication/useUser";
import {
  DISMISS_NOTIFICATION,
  MARK_NOTIFICATION_READ,
  NOTIFICATION_DETAILS,
  NOTIFICATION_FROM_SIBLING,
  NOTIFICATIONS,
} from "./graphql";
import { notificationResolution, notificationDismissible } from "./helper";
import useFacility from "@components/authentication/useFacility";
import { useRouter } from "vue-router/composables";

const DISPLAYED_STATUSES = ["published", "read"];

const notifications = ref([]);
const loading = ref(false);

const displayedNotifications = computed(() =>
  notifications.value.filter((n) => DISPLAYED_STATUSES.includes(n.status))
);
const unreadNotifications = computed(() =>
  displayedNotifications.value.filter((n) => n.status === "published")
);

const errorNotifications = computed(() =>
  displayedNotifications.value.filter((n) => n.severity === "error")
);
const unreadErrorNotifications = computed(() =>
  unreadNotifications.value.filter((n) => n.severity === "error")
);

let notificationObservableQuery = null;

export default function useNotifications() {
  const $router = useRouter();
  const { currentUser } = useUser();
  const { currentFacilityId } = useFacility();
  const userNotifications = ref({});

  const totalUnreadUserNotifications = computed(() => {
    return Object.fromEntries(
      Object.entries(userNotifications.value).map(([userId, notifications]) => [
        userId,
        countUnreadNotifications(notifications),
      ])
    );
  });

  function fetchNotifications(apolloClient, userId = null) {
    loading.value = true;
    const observableQuery = apolloWatchQuery(
      apolloClient,
      {
        query: NOTIFICATIONS,
        variables: {
          userId: userId || currentUser.value.id,
          organizationId: currentFacilityId.value || undefined,
          statuses: DISPLAYED_STATUSES,
          types: ["application"],
        },
        fetchPolicy: userId ? "cache-and-network" : "network-first",
        //only for current user
        pollInterval: userId ? undefined : 1000 * 60, // 1 min interval,
      },
      (data, queryLoading) => {
        if (data?.userNotifications) {
          const quiredNotifications =
            data.userNotifications.map(enrichNotification);
          if (userId) {
            userNotifications.value = {
              ...userNotifications.value,
              [userId]: quiredNotifications,
            };
          } else {
            notifications.value = quiredNotifications;
          }
        }
        loading.value = queryLoading;
      }
    );

    if (!userId) {
      notificationObservableQuery = observableQuery;
    }
  }

  watch(currentFacilityId, (facilityId) => {
    notificationObservableQuery.refetch({
      organizationId: facilityId || undefined,
    });
  });

  const notificationDetails = ref(null);
  const loadingNotificationDetails = ref(false);
  function fetchNotificationDetails(apolloClient, notificationId) {
    console.debug(notificationId);
    if (!notificationId) return;
    loadingNotificationDetails.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: NOTIFICATION_DETAILS,
        variables: {
          id: notificationId,
          markAsRead: false,
        },
        fetchPolicy: "cache-and-network",
      },
      (data, queryLoading) => {
        loadingNotificationDetails.value = queryLoading;
        if (data?.userNotification) {
          notificationDetails.value = enrichNotification(data.userNotification);
          loadingNotificationDetails.value = false;
        }
      }
    );
  }

  function markNotificationRead(apolloClient, notification) {
    if (!notification.id) return;
    apolloWatchQuery(
      apolloClient,
      {
        query: MARK_NOTIFICATION_READ,
        variables: { id: notification.id },
      },
      (_data) => {}
    );
  }

  function dismissNotification(apolloClient, notification) {
    if (!notification.id) return;
    try {
      apolloClient.mutate({
        mutation: DISMISS_NOTIFICATION,
        variables: { id: notification.id },
      });
    } catch (error) {
      //console.debug(error)
    }
  }

  function redirectToNotification(apolloClient, notificationId) {
    if (!notificationId) return;
    loading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: NOTIFICATION_FROM_SIBLING,
        variables: {
          id: notificationId,
        },
      },
      (data, queryLoading) => {
        loading.value = queryLoading;
        if (data?.userNotification) {
          notificationDetails.value = enrichNotification(
            data.userNotification.notificationSiblings.length > 0
              ? data.userNotification.notificationSiblings[0]
              : data.userNotification
          );
          loading.value = false;
          redirectAndMarkAsRead(apolloClient, notificationDetails.value);
        }
      }
    );
  }

  function redirectAndMarkAsRead(
    apolloClient,
    notification,
    shouldMarkAsRead = true
  ) {
    const redirect = notification.resolutionRoute;
    if (!redirect) return;
    if (redirect.name === "Notifications") {
      $router.push(redirect);
    } else {
      $router.push(redirect, () => {
        if (shouldMarkAsRead) {
          markNotificationRead(apolloClient, notification);
        }
      });
    }
  }

  return {
    notifications: displayedNotifications,
    loading,
    unreadNotifications,
    errorNotifications,
    unreadErrorNotifications,
    userNotifications,
    totalUnreadUserNotifications,
    fetchNotifications,
    notificationDetails,
    loadingNotificationDetails,
    fetchNotificationDetails,
    markNotificationRead,
    dismissNotification,
    redirectAndMarkAsRead,
    redirectToNotification,
  };
}

function enrichNotification(notification) {
  const resolutionRoute = notificationResolution(notification);
  return {
    ...notification,
    resolutionRoute,
    hasResolution: resolutionRoute.name !== "Notifications",
    isDismissible: notificationDismissible(notification),
  };
}

function countUnreadNotifications(notifications) {
  return notifications?.filter((n) => n.status === "published")?.length || 0;
}
