<template>
  <v-card flat tile class="black-border">
    <v-card-title class="title" style="font-weight: 500">
      {{ title }}
    </v-card-title>
    <v-progress-linear :active="loading" absolute height="2" indeterminate />
    <div class="d-flex justify-end">
      <slot name="header-actions"></slot>
    </div>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashboardLayout",
  props: {
    title: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
