<template>
  <v-icon :size="size" :color="humidityColor">fas fa-raindrops</v-icon>
</template>

<script>
export default {
  props: {
    size: {
      type: [Number, String],
      default: "",
    },
    humidity: {
      type: [Number, String],
      default: 0,
    },
    lowThreshold: {
      type: [Number, String],
      default: 0,
    },
    highThreshold: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    humidityColor() {
      if (this.humidity < this.lowThreshold) {
        return "blue lighten-3";
      } else if (this.humidity > this.highThreshold) {
        return "error";
      }
      return "success";
    },
  },
};
</script>
