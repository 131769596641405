<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="model"
    :items="users"
    :label="label"
    item-text="text"
    item-value="value"
    :loading="isLoading"
    :disabled="!organizationId"
    :placeholder="placeholder"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { FACILITY_USERS } from "@components/authentication/graphql";
import useUser from "@components/authentication/useUser";

export default {
  name: "SelectOrganizationUsers",
  props: {
    value: {
      validator: (prop) =>
        typeof prop === "string" ||
        typeof prop === "undefined" ||
        prop === null,
      required: true,
    },
    organizationId: {
      type: String,
      default: null,
    },
  },
  setup() {
    const { displayUserName } = useUser();
    return {
      displayUserName,
    };
  },
  data() {
    return {
      users: [],
      isLoading: false,
    };
  },
  computed: {
    label() {
      return this.organizationId ? "Select User" : "Select a unit first";
    },
    placeholder() {
      if (!this.organizationId) return "Please select a unit";
      if (this.isLoading) return "Loading users...";
      return "Select a user";
    },
    model: {
      get() {
        return this.value;
      },
      set(userId) {
        this.$emit("input", userId);
      },
    },
  },
  watch: {
    organizationId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fetchFacilityUsers(newValue);
        } else {
          this.users = [];
        }
      },
    },
  },
  methods: {
    async fetchFacilityUsers(organizationId) {
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.query({
          query: FACILITY_USERS,
          variables: {
            parentId: organizationId,
            roles: ["manager", "admin"],
          },
        });

        if (data && data.organizationUsers) {
          this.users = data.organizationUsers.map((user) => ({
            text: this.displayUserName(user),
            value: user.id,
          }));
        }
      } catch (error) {
        this.users = [];
      } finally {
        this.isLoading = false;
      }
    },
    focus() {
      this.$refs.autocomplete.focus();
    },
  },
};
</script>
