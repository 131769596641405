<template>
  <LayoutPage title="Audit Logs" :loading="loading">
    <template #actions>
      <SelectUser ref="userId" v-model="filter.userId" class="mr-4 mt-4" />

      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['1m']"
        default="1m"
        max-range="1m"
        custom
        inverted
        class="mr-4"
      />
      <div class="mr-4" style="min-width: 340px">
        <SearchField v-model="tableState.search" />
      </div>
    </template>

    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :headers="headers"
        :items="auditLogs"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actorName="{ item }">
          <span v-if="sdServiceRoles.includes(item.role)"
            >StorageDefender Automation</span
          >
          <span v-else>
            {{ `${item.actorName}(${item.role})` }}
          </span>
        </template>

        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="auditLogs"
            :headers="headers"
            filename="SD_AuditLogs_History"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import SelectUser from "../common/SelectUser.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { AUDIT_LOGS } from "./graphql";
import useDataTable from "../common/useDataTable";
import useFacility from "../authentication/useFacility";
import { formatLogDescription } from "@tod-ui/helpers/auditLog";
import { stringSortCollator } from "@tod-ui/helpers/strings";

export default {
  name: "PageAuditLog",
  components: {
    LayoutPage,
    SearchField,
    SelectUser,
    ButtonPrint,
    ButtonExportCSV,
    DateTimeFilter,
  },
  setup() {
    const {
      currentFacilityId,
      facilityTimeZone,
      facilityFormatDateTime4Humans,
    } = useFacility();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
      showSmartRenters: true,
    });

    const sdServiceRoles = ["service"];

    return {
      sdServiceRoles,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      currentFacilityId,
      facilityTimeZone,
      auditDateTime: facilityFormatDateTime4Humans,
    };
  },
  data: () => ({
    dateRange: { after: undefined, before: undefined },
    auditLogs: [],
    filter: {},
  }),
  computed: {
    loading() {
      return this.$apollo.queries.auditLogs.loading;
    },
    headers() {
      return [
        {
          text: "Timestamp",
          value: "timestamp",
          width: 220,
        },
        { text: "User", value: "actorName", width: 200 },
        { text: "Description", value: "description", width: 600 },
      ];
    },
  },
  apollo: {
    auditLogs: {
      query: AUDIT_LOGS,
      variables() {
        return {
          facilityId: this.currentFacilityId,
          dateTimeFilter: this.dateRange,
          ...this.filter,
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ auditLogs }) {
        return auditLogs.map((log) => {
          return {
            ...log,
            description: formatLogDescription(log),
            timestamp: this.auditDateTime(log.insertedAt),
          };
        });
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `auditLog`
      const _search = search.toLowerCase();
      const match = [
        item.actorName.toLowerCase(),
        item.description.toLowerCase(),
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        // Default sort comparison
        if (typeof a[_sortBy] === "string") {
          return stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc;
        }
        return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
      });

      return items;
    },
  },
};
</script>
