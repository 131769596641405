import gql from "graphql-tag";
import { organizationFields } from "../fragments";

export const zoneEntryCountingEvents = gql`
  query ZoneEntryCountingEvents(
    $id: ID
    $parentId: ID
    $dateTimeFilter: DateTimeFilter!
    $aggregate: String!
    $sort: String
  ) {
    zoneEntry: zoneEntryCountingEvents(
      id: $id
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      sort: $sort
      aggregate: $aggregate
    ) {
      count
      name
      type
      timestamp
      organization {
        ...OrganizationFields
      }
    }
  }
  ${organizationFields}
`;

export const zonePeopleCountingEvents = gql`
  query ZonePeopleCountingEvents(
    $id: ID
    $parentId: ID
    $dateTimeFilter: DateTimeFilter!
    $aggregate: String!
    $sort: String
  ) {
    zoneTraffic: zonePeopleCountingEvents(
      id: $id
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      sort: $sort
      aggregate: $aggregate
    ) {
      count
      name
      type
      timestamp
      organization {
        ...OrganizationFields
      }
    }
  }
  ${organizationFields}
`;

export const zoneServiceRequestCountingEvents = gql`
  query ZoneServiceRequestCountingEvents(
    $id: ID
    $parentId: ID
    $dateTimeFilter: DateTimeFilter!
    $aggregate: String!
    $sort: String
  ) {
    zoneServiceRequests: zoneServiceRequestCountingEvents(
      id: $id
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      sort: $sort
      aggregate: $aggregate
    ) {
      count
      name
      type
      timestamp
      organization {
        ...OrganizationFields
      }
    }
  }
  ${organizationFields}
`;
