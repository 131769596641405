<template>
  <span>
    <v-menu bottom left>
      <template #activator="{ on }">
        <v-btn icon :disabled="devicePending" v-on="on">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
      <v-list dense>
        <v-list-item v-if="unitName" @click="dialog = 'confirm-remove'">
          <v-list-item-icon class="mr-4">
            <v-icon color="error">far fa-inbox-out fa-flip-vertical</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Remove Device {{ deviceName }} from Unit
            {{ unitName }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="unitName"
          :disabled="device.status !== 'ok' || device.rmaRequest"
          @click="dialog = 'transfer-device'"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">far fa-retweet-alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Transfer Device {{ deviceName }} to another Unit
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="!unitName && device.disposition == 'missing'"
          @click="dialog = 'found-device'"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="success">mdi-shield-check</v-icon>
          </v-list-item-icon>
          <v-list-item-content> Mark device as Found </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="!unitName && device.disposition !== 'missing'"
          @click="dialog = 'missing-device'"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="warning">mdi-shield-search</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            Mark
            {{
              device.rmaRequest
                ? "Pre-approved Return "
                : device.status == "lost_contact"
                ? "Unhealthy "
                : ""
            }}Device as Missing
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <DialogUpdateDevice v-model="dialog" :device="device" />
    <DialogRemoveDevice
      v-model="dialog"
      :device="device"
      :unit-name="unitName"
    />
    <DialogTransferDevice
      v-model="dialog"
      :device="device"
      :unit-name="unitName"
    />
  </span>
</template>

<script>
import DialogUpdateDevice from "./DialogUpdateDevice.vue";
import { ref } from "vue";
import useDevice from "../useDevice";
import DialogRemoveDevice from "./DialogRemoveDevice.vue";
import DialogTransferDevice from "./DialogTransferDevice.vue";

export default {
  name: "MenuManageDevice",
  components: { DialogUpdateDevice, DialogRemoveDevice, DialogTransferDevice },
  props: {
    device: {
      type: Object,
      required: true,
    },
    unitName: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { deviceName, devicePending } = useDevice(props.device);
    const dialog = ref(null);

    return { deviceName, devicePending, dialog };
  },
};
</script>
