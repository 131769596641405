<template>
  <v-dialog v-model="show" max-width="570px">
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        title="This contact method is failing"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small color="warning">far fa-exclamation-triangle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Failure History of {{ formattedValue }}
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-list dense>
          <template v-if="contactMethodLogs.length">
            <v-list-item v-for="logRow in contactMethodLogs" :key="logRow.id">
              <v-list-item-icon>
                <v-icon small>far fa-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="one-row">
                <span>{{ logRow.receivedAt }}</span>
                <strong class="ml-auto">{{ logRow.status }}</strong>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-skeleton-loader v-else-if="loading" type="list-item-avatar@3" />
          <v-list-item v-else>
            <v-list-item-content>
              <v-list-item-title>
                <em>No failure history found</em>
              </v-list-item-title>
              <v-list-item-subtitle> &nbsp; </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import gql from "graphql-tag";
import { computed, ref, unref } from "vue";
import { formatDateTime4Humans, getDaysAgo } from "../helpers/datetime";
import { titleCase } from "../helpers/strings";
import { formatPhoneNumber } from "../helpers/phone";

export default {
  name: "ContactMethodWarning",
  props: {
    contactMethod: {
      type: Object,
      required: true,
    },
    contactLogTimeZone: {
      type: String,
      default: "America/Chicago",
    },
  },
  setup(props) {
    const show = ref(false);
    const formattedValue = computed(() => {
      const cm = unref(props.contactMethod);
      return ["phone", "mobile"].includes(cm.type)
        ? formatPhoneNumber(cm.value)
        : cm.value;
    });

    return { show, formattedValue };
  },
  data: () => ({
    contactMethodLogs: [],
  }),
  computed: {
    loading() {
      return this.$apollo.queries.contactMethodLogs.loading;
    },
  },
  apollo: {
    contactMethodLogs: {
      query: gql`
        query ContactMethodLogs(
          $contactMethodValue: String!
          $dateTimeFilter: DateTimeFilter
        ) {
          contactMethodLogs(
            contactMethodValue: $contactMethodValue
            dateTimeFilter: $dateTimeFilter
            limit: 10
            sort: "inserted_at desc"
          ) {
            id
            contactMethodValue
            contactMethodType
            insertedAt
            logBody
            logType
          }
        }
      `,
      variables() {
        return {
          contactMethodValue: this.contactMethod.value,
          dateTimeFilter: {
            after: getDaysAgo(30),
          },
        };
      },
      skip() {
        return !this.show;
      },
      update({ contactMethodLogs }) {
        return contactMethodLogs?.map((logRow) => ({
          ...logRow,
          status: titleCase(
            logRow?.logBody?.status_description ||
              logRow?.logBody?.status ||
              logRow.logType
          ),
          receivedAt: formatDateTime4Humans(
            logRow?.logBody?.received_at || logRow.insertedAt,
            this.contactLogTimeZone,
            false,
            false
          ),
        }));
      },
    },
  },
};
</script>

<style scoped>
.one-row > * {
  flex: 0 1 auto;
}
</style>
