<template>
  <LayoutPage
    :title="['Temperature & Humidity History', deviceShortId]"
    :loading="loading"
  >
    <CardLayout title="Temperature & Humidity History" :loading="loading">
      <template #header-actions>
        <DateTimeFilter
          v-model="dateRange"
          :time-zone="facilityTimeZone"
          :ranges="['Today', '1w']"
          default="1w"
          max-range="1m"
          custom
          inverted
        />
      </template>
      <v-data-table :headers="tableData.headers" :items="tableData.items">
        <template #item.date="{ item }">
          {{ formatDateTime(item.timestamp) }}
        </template>
        <template #item.temperature="{ item }">
          <div
            v-if="item.data && JSON.parse(item.data)?.temp_celcius"
            :class="`${smartUnitTemperatureColor(
              JSON.parse(item.data)?.temp_celcius
            )}--text`"
          >
            {{
              convertCelsiusToFahrenheit(JSON.parse(item.data).temp_celcius)
            }}°F
          </div>
          <div v-else class="grey--text">N/A</div>
        </template>

        <template #item.humidity="{ item }">
          <div
            v-if="item.data && JSON.parse(item.data)?.humid_pcnt"
            :class="`${smartUnitHumidityColor(
              JSON.parse(item.data)?.humid_pcnt
            )}--text`"
          >
            {{ roundValue(JSON.parse(item.data).humid_pcnt) }}%
          </div>
          <div v-else class="grey--text">N/A</div>
        </template>

        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="tableData.items"
            :headers="tableData.headers"
            filename="SD_Temperature_and_humidity_history"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </CardLayout>
  </LayoutPage>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import { deviceTelemetryEvents } from "../../graphql/organizations/zone";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import useDataTable from "@components/common/useDataTable";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import useFacility from "@components/authentication/useFacility";
import { formatDateTime4Humans } from "@tod-ui/helpers/datetime";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import { roundValue } from "@tod-ui/helpers/math";
import {
  smartUnitHumidityColor,
  smartUnitTemperatureColor,
} from "@components/smart_units/helper";

export default {
  components: {
    LayoutPage,
    CardLayout,
    DateTimeFilter,
    ButtonExportCSV,
    ButtonPrint,
  },
  setup() {
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["startedAt"],
      sortDesc: [true],
    });

    const { facilityTimeZone } = useFacility();

    return {
      tableState,
      showAllItems,
      restoreItemsPerPage,
      facilityTimeZone,
      roundValue,
    };
  },
  data() {
    return {
      dateRange: { after: undefined, before: undefined },
      deviceEvents: [],
    };
  },
  computed: {
    deviceShortId() {
      return this.$route.query.device_short_id;
    },
    deviceId() {
      return this.$route.params.device_id;
    },
    tableData() {
      return {
        headers: [
          { text: "Date & Time", value: "date" },
          { text: "Temperature °F", value: "temperature" },
          { text: "Humidity", value: "humidity" },
        ],
        items: this.deviceEvents,
      };
    },
    loading() {
      return this.$apollo.queries.deviceEvents.loading;
    },
  },
  apollo: {
    deviceEvents: {
      query: deviceTelemetryEvents,
      variables() {
        return {
          deviceId: this.deviceId,
          dateTimeFilter: this.dateRange,
        };
      },
      skip() {
        return !this.dateRange.after;
      },
      update({ events }) {
        return events;
      },
    },
  },
  methods: {
    formatDateTime(dateTime) {
      return formatDateTime4Humans(dateTime, this.facilityTimeZone);
    },
    convertCelsiusToFahrenheit,
    smartUnitTemperatureColor,
    smartUnitHumidityColor,
  },
};
</script>
