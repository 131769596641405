<template>
  <v-card v-if="!loading" outlined flat tile>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      Zone Traffic
      <v-spacer />
      <DateTimeFilter v-model="dateRange" :ranges="['1w', '2w', '1m']" />
    </v-card-title>

    <v-card-text>
      <ZoneTrafficChart
        :chart-data="zoneTraffic"
        :max="chartMax"
        :min="chartMin"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ZoneTrafficChart from "./ZoneTrafficChart.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import { convertToLuxonDT } from "@tod-ui/helpers/datetime";
import useFacility from "@components/authentication/useFacility";

const BASE_DATASET = {
  label: null,
  borderColor: null,
  borderWidth: 2,
  data: [],
  lineTension: 0.2,
  pointBackgroundColor: null,
  pointBorderColor: null,
  showLine: true,
};

// 20 Unique Colors from the Material Design color pallette
const LINE_COLORS = [
  "#F44336",
  "#673AB7",
  "#03A9F4",
  "#4CAF50",
  "#FFEB3B",
  "#FF5722",
  "#E91E63",
  "#3F51B5",
  "#00BCD4",
  "#8BC34A",
  "#FFC107",
  "#795548",
  "#9C27B0",
  "#2196F3",
  "#009688",
  "#CDDC39",
  "#FF9800",
  "#607D8B",
  "#9E9E9E",
  "#000000",
];

export default {
  name: "ZoneTrafficGraph",
  components: {
    ZoneTrafficChart,
    DateTimeFilter,
  },
  props: {
    color: {
      type: String,
      default: "#aaaaaa",
    },
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    zoneTraffic: [],
    chartMin: 0,
    chartMax: 500,
    dateRange: { after: undefined },
  }),
  computed: {
    loading() {
      return this.$apollo.queries.zoneTraffic.loading;
    },
  },
  apollo: {
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      variables() {
        return {
          parentId: this.currentFacilityId,
          aggregate: "hour",
          dateTimeFilter: this.dateRange,
          sort: "timestamp",
        };
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ zoneTraffic }) {
        let datasets = [];

        if (zoneTraffic.length) {
          // Track the max event count for the chart scale/boundary
          let maxCount = 0;

          //Info for initializing data buckets.
          const startTime = convertToLuxonDT(
            zoneTraffic[0].timestamp,
            this.facilityTimeZone
          );
          const endTime = convertToLuxonDT(
            zoneTraffic[zoneTraffic.length - 1].timestamp,
            this.facilityTimeZone
          );
          const bucketCount =
            Math.ceil(endTime.diff(startTime, ["days"]).values.days) * 4;
          // Convert/Map the events into chart datasets
          datasets = zoneTraffic.reduce((datasets, e) => {
            const { id, name } = e.organization;
            const ds = datasets.find(({ zoneId }) => zoneId === id);

            // Update the maxCount
            if (e.count > maxCount) {
              maxCount = e.count;
            }

            if (ds) {
              const ts = this.formatDateTime(e.timestamp);
              const dataElem = ds.data.find(({ x }) => x == ts);
              if (dataElem) {
                dataElem.y += e.count;
                if (dataElem.y > maxCount) {
                  maxCount = dataElem.y;
                }
              }
            } else {
              datasets.push({
                ...BASE_DATASET,
                borderColor: LINE_COLORS[datasets.length],
                pointBackgroundColor: LINE_COLORS[datasets.length],
                pointBorderColor: LINE_COLORS[datasets.length],
                zoneId: id,
                label: name,
                data: this.initializeDataArray(bucketCount, startTime, e.count),
              });
            }

            return datasets;
          }, []);

          this.chartMax = maxCount + Math.ceil(maxCount / 20);
        }

        return {
          datasets,
          labels: [],
        };
      },
    },
  },
  methods: {
    formatDateTime(datetime) {
      if (!datetime) return datetime;

      const dt = convertToLuxonDT(datetime, this.facilityTimeZone);
      // return dt.startOf("day").toISO();

      let start_of_day = dt.startOf("day");
      const hour_of_day = Math.floor((dt - start_of_day) / 3600000);
      if (hour_of_day >= 0 && hour_of_day <= 6) {
        return start_of_day.plus({ hours: 6 }).toISO();
      } else if (hour_of_day > 6 && hour_of_day <= 12) {
        return start_of_day.plus({ hours: 12 }).toISO();
      } else if (hour_of_day > 12 && hour_of_day <= 18) {
        return start_of_day.plus({ hours: 18 }).toISO();
      } else {
        return start_of_day.plus({ hours: 24 }).toISO();
      }
    },
    initializeDataArray(bucketCount, startTime, initialValue) {
      let bucket_time = startTime.startOf("day");
      let init_array = [];
      for (let i = 0; i < bucketCount; i++) {
        if (i == 0) {
          init_array.push({
            x: bucket_time.toISO(),
            y: initialValue,
          });
          bucket_time = bucket_time.plus({ hours: 6 });
        } else {
          init_array.push({
            x: bucket_time.toISO(),
            y: 0,
          });
          bucket_time = bucket_time.plus({ hours: 6 });
          //I hate this. Done to get around daylight savings problems.
          if (i % 4 == 0) {
            bucket_time = bucket_time.startOf("day");
            bucket_time = bucket_time.plus({ hours: 6 });
          }
        }
      }
      return init_array;
    },
  },
};
</script>
