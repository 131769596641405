<template>
  <LayoutPage
    :title="['Smart Zones', organization.name]"
    :loading="smartZoneLoading"
  >
    <DialogLayout
      v-model="isOpen"
      title="Viewing Video Clip"
      width="900px"
      @close="closeDialog"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="loader"
      ></v-progress-circular>
      <PopupVideoView :video="video" />
    </DialogLayout>
    <v-row>
      <v-col lg="12">
        <CardLayout
          v-model="editingZone"
          title="Smart Zone Information"
          header-type="edit"
          :loading="smartZoneLoading"
          icon-type="cog"
        >
          <template #edit>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(save)">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" xs="12" md="3">
                      <ValidatedTextField
                        v-model="organization.name"
                        autofocus
                        name="Smart Zone Name"
                        rules="required|min:2|max:250"
                      />
                    </v-col>
                  </v-row>
                  <v-btn depressed tile small color="success" type="submit">
                    Save Smart Zone
                  </v-btn>
                </v-card-text>
              </v-form>
            </ValidationObserver>
          </template>

          <v-row>
            <v-col cols="12" xs="12" md="3">
              <dl>
                <dt>Smart Zone : {{ organization.name }}</dt>
              </dl>
            </v-col>
          </v-row>
        </CardLayout>
      </v-col>
    </v-row>
    <v-row v-if="isVideoCameraEnabled">
      <v-col>
        <CardLayout title="Video">
          <v-carousel
            v-model="currentSlide"
            hide-delimiters
            :show-arrows="true"
            style="height: 350px"
            @change="handleSlideChange"
          >
            <v-carousel-item
              v-for="(chunk, index) in chunkedEvents"
              :key="index"
            >
              <v-row>
                <v-col v-for="event in chunk" :key="event.id" cols="12" md="4">
                  <v-card
                    @click="
                      fetchVideo(
                        event.actorId,
                        event.startTimestamp,
                        currentFacilityId
                      )
                    "
                  >
                    <v-img
                      :src="'data:image/jpeg;base64,' + event.thumbnail"
                      style="width: 450px; height: auto"
                    />
                    <v-card-title
                      ><div class="subtitle-1">
                        Last Incident On :
                        <a class="clickable-link" @click="isOpen = true">
                          {{ formatTimestamp(event.startTimestamp) }}
                        </a>
                      </div></v-card-title
                    >
                  </v-card>
                </v-col>
              </v-row>
            </v-carousel-item>
            <template #prev>
              <v-btn icon :disabled="currentSlide === 0" @click="prevSlide">
                <v-icon class="blue-arrow">mdi-chevron-left</v-icon>
              </v-btn>
            </template>
            <template #next>
              <v-btn
                icon
                :disabled="currentSlide === chunkedEvents.length - 1"
                @click="nextSlide"
              >
                <v-icon class="blue-arrow">mdi-chevron-right</v-icon>
              </v-btn>
            </template>
          </v-carousel>
        </CardLayout>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CardSensors :devices="devices" :loading="smartZoneLoading" />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12">
        <CardLayout
          v-model="editingSensorsConfigs"
          title="Smart Zone Sensors Settings"
          header-type="edit"
          :loading="smartZoneLoading"
          icon-type="cog"
        >
          <template #edit>
            <ValidationObserver v-slot="{ handleSubmit }">
              <v-form @submit.prevent="handleSubmit(save)">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-row>
                        <v-col v-if="entryConfig" cols="12" xs="12" md="4">
                          <EntryConfigWidget :config="entryConfig" editing />
                        </v-col>
                        <v-col
                          v-if="serviceRequestConfig"
                          cols="12"
                          xs="12"
                          md="4"
                        >
                          <ServiceRequestConfigWidget
                            :config="serviceRequestConfig"
                            editing
                          />
                        </v-col>
                        <v-col v-if="motionConfig" cols="12" xs="12" md="4">
                          <MotionConfigWidget :config="motionConfig" editing />
                        </v-col>
                        <v-col v-if="trafficConfig" cols="12" xs="12" md="4">
                          <PeopleCountingConfigWidget
                            v-model="trafficConfig"
                            editing
                          />
                        </v-col>
                        <v-col
                          v-if="organization?.profile?.climateConfig"
                          cols="12"
                          xs="12"
                          md="4"
                        >
                          <ClimateConfigWidget
                            v-model="organization.profile.climateConfig"
                            editing
                          />
                        </v-col>

                        <v-col
                          v-if="isVideoCameraEnabled"
                          cols="12"
                          xs="12"
                          md="4"
                        >
                          <VideoConfigWidget
                            :devices="isVideoCameraEnabled"
                            :config="videoConfig"
                            :currentFacilityId="currentFacilityId"
                            editing
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-btn depressed tile small color="success" type="submit">
                    Save Settings
                  </v-btn>
                </v-card-text>
              </v-form>
            </ValidationObserver>
          </template>

          <v-row>
            <v-col>
              <v-row>
                <v-col v-if="entryConfig" cols="12" xs="12" md="4">
                  <EntryConfigWidget :config="entryConfig" />
                </v-col>
                <v-col v-if="serviceRequestConfig" cols="12" xs="12" md="4">
                  <ServiceRequestConfigWidget :config="serviceRequestConfig" />
                </v-col>
                <v-col v-if="motionConfig" cols="12" xs="12" md="4">
                  <MotionConfigWidget :config="motionConfig" />
                </v-col>
                <v-col v-if="trafficConfig" cols="12" xs="12" md="4">
                  <PeopleCountingConfigWidget :value="trafficConfig" />
                </v-col>
                <v-col
                  v-if="organization?.profile?.climateConfig"
                  cols="12"
                  xs="12"
                  md="4"
                >
                  <ClimateConfigWidget
                    :value="organization.profile.climateConfig"
                  />
                </v-col>

                <v-col v-if="isVideoCameraEnabled" cols="12" xs="12" md="4">
                  <VideoConfigWidget
                    :devices="isVideoCameraEnabled"
                    :config="videoConfig"
                    :currentFacilityId="currentFacilityId"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </CardLayout>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="mt-2">
        <v-tabs v-model="activeTab">
          <v-tab>Zone Incidents</v-tab>
          <v-tab-item>
            <CardZoneIncidentTable
              :incidents="incidents || []"
              :loading="incidentsLoading"
            />
          </v-tab-item>

          <v-tab>Zone Graphs</v-tab>
          <v-tab-item v-if="!smartZoneLoading">
            <template v-if="hasDevices">
              <ZoneIncidentReport
                :organization-id="currentSmartZoneId"
                :date-time="dateRange"
              />
            </template>
            <template v-else>
              No Devices Assigned to this Smart Zone.
            </template>
          </v-tab-item>

          <v-tab v-if="isVideoCameraEnabled">Video Incidents</v-tab>
          <v-tab-item v-if="isVideoCameraEnabled">
            <v-row>
              <v-col class="mt-2">
                <v-tabs>
                  <v-tab v-if="isVideoCameraEnabled">Incidents</v-tab>
                  <v-tab-item v-if="!smartZoneLoading">
                    <template v-if="isVideoCameraEnabled">
                      <VideoCameraEvents
                        :devices="isVideoCameraEnabled"
                        :config="videoConfig"
                        :currentFacilityId="currentFacilityId"
                      />
                    </template>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-spacer />
          <template v-if="activeTab !== 2">
            <DateTimeFilter
              v-model="dateRange"
              :time-zone="facilityTimeZone"
              :ranges="['Today', '1w', '1m']"
              default="1w"
              max-range="6m"
              custom
            />
          </template>
        </v-tabs>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { ValidationObserver } from "vee-validate";

import {
  leanOrganization,
  zoneIncidents,
} from "../../graphql/organizations/zone";

import LayoutPage from "@layout/LayoutPage.vue";
import ClimateConfigWidget from "../fixtures/ClimateConfigWidget.vue";
import EntryConfigWidget from "../fixtures/EntryConfigWidget.vue";
import MotionConfigWidget from "../fixtures/MotionConfigWidget.vue";
import PeopleCountingConfigWidget from "../fixtures/PeopleCountingConfigWidget.vue";
import ServiceRequestConfigWidget from "../fixtures/ServiceRequestConfigWidget.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import VideoConfigWidget from "../fixtures/VideoConfigWidget.vue";
import CardLayout from "@layout/CardLayout.vue";

import { celsiusToFahrenheit } from "../utils/converters";
import { inputifyProfile } from "../utils/organizations";
import { titleCase } from "@tod-ui/helpers/strings";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { UPDATE_SMART_ZONE, EEN_EVENTS, EEN_VIDEO_FEED } from "./graphql";
import CardSensors from "./CardSensors.vue";
import CardZoneIncidentTable from "./CardZoneIncidentTable.vue";
import VideoCameraEvents from "./VideoCameraEvents.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import {
  formatIncidentType,
  incidentStatus,
} from "@components/common/helpers/incident";
import { diff } from "@tod-ui/helpers/datetime";
import ZoneIncidentReport from "@components/reports/ZoneIncidentReport.vue";
import DialogLayout from "@components/smart_zones/DialogLayout.vue";
import PopupVideoView from "./PopupVideoView.vue";

export default {
  name: "PageSmartZoneDrilldown",
  components: {
    ClimateConfigWidget,
    EntryConfigWidget,
    MotionConfigWidget,
    PeopleCountingConfigWidget,
    ServiceRequestConfigWidget,
    ValidationObserver,
    ValidatedTextField,
    VideoConfigWidget,
    LayoutPage,
    CardLayout,
    CardSensors,
    CardZoneIncidentTable,
    VideoCameraEvents,
    DateTimeFilter,
    ZoneIncidentReport,
    DialogLayout,
    PopupVideoView,
  },
  setup() {
    const {
      facilityTimeZone,
      currentFacilityId,
      facilityFormatDateTime4Humans,
    } = useFacility();
    const { addAlert } = useAlerts();
    return {
      facilityTimeZone,
      addAlert,
      currentFacilityId,
      facilityFormatDateTime4Humans,
    };
  },
  data: () => ({
    organization: {},
    incidents: [],
    dateRange: { after: undefined },
    editingZone: "",
    editingSensorsConfigs: "",
    isOpen: false,
    eenEvents: [],
    video: null,
    currentSlide: 0,
    loading: false,
    activeTab: 0,
  }),
  computed: {
    chunkedEvents() {
      const chunkSize = 3;
      const chunks = [];
      for (let i = 0; i < this.eenEvents.length; i += chunkSize) {
        chunks.push(this.eenEvents.slice(i, i + chunkSize));
      }
      return chunks;
    },
    devices() {
      return this.organization.devices;
    },
    smartZoneLoading() {
      if (!Object.keys(this.organization).length) return true;
      return this.$apollo.queries.organization.loading;
    },
    hasDevices() {
      return this.organization?.devices?.length;
    },
    incidentsLoading() {
      if (!this.incidents) return true;
      return this.$apollo.queries.incidents.loading;
    },
    climateConfigEnabled() {
      return (
        this.organization?.profile?.climateConfig &&
        !this.organization.profile.climateConfig.disabled
      );
    },
    currentHumidity() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      const devices = this.organization.devices
        .filter((d) => d.humidity > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) return devices[0].humidity;
      return null;
    },
    currentTemp() {
      if (!this.climateConfigEnabled) return null;
      if (!this.hasDevices) return null;

      const devices = this.organization.devices
        .filter((d) => d.temperature > 0)
        .sort(
          (a, b) =>
            Date.parse(b.lastContactedAt) - Date.parse(a.lastContactedAt)
        );

      if (devices.length) {
        const temp = devices[0].temperature;
        return Math.round(celsiusToFahrenheit(temp));
      }
      return null;
    },
    configProfile() {
      return this.organization?.profile;
    },
    entryConfig() {
      return this.organization?.profile?.entryConfig;
    },
    motionConfig() {
      const motionConfigs = this.organization?.profile?.motionConfigs;
      if (motionConfigs?.length) {
        return motionConfigs.find((c) => c.mode === "motion");
      }

      return null;
    },
    serviceRequestConfig() {
      return this.organization?.profile?.serviceRequestConfig;
    },
    trafficConfig: {
      get() {
        const motionConfigs = this.organization?.profile?.motionConfigs;
        if (motionConfigs?.length) {
          return motionConfigs.find((c) => c.mode === "people_counting");
        }

        return null;
      },
      set(value) {
        const configIndex =
          this.organization?.profile?.motionConfigs?.findIndex(
            (c) => c.mode === "people_counting"
          );
        if (configIndex !== undefined && configIndex !== -1) {
          this.organization.profile.motionConfigs[configIndex] = value;
        }
      },
    },
    videoConfig() {
      return this.organization?.profile?.videoConfig;
    },
    isVideoCameraEnabled() {
      return this.organization?.devices?.find(
        (device) => device.type === "een" && device.subtype === "video_camera"
      );
    },
    currentSmartZoneId() {
      return this.$route.params.smart_zone_id;
    },
    showDemo() {
      return [
        "dc17ec00-e95c-4de5-a4cf-3b5786ad165a",
        "7a10ad54-a6f8-4d25-b9b8-82b1efa89f1b",
      ].includes(this.currentFacilityId);
    },
  },
  apollo: {
    organization: {
      query: leanOrganization,
      // ensure we have the latest data, or allow refresh to cancel changes
      fetchPolicy: "no-cache",
      variables() {
        return { id: this.currentSmartZoneId };
      },
      update({ organization }) {
        return organization;
      },
    },
    incidents: {
      query: zoneIncidents,
      variables() {
        return {
          organizationId: this.currentSmartZoneId,
          dateTimeFilter: this.dateRange,
          sort: "startedAt desc",
        };
      },
      update({ incidents }) {
        return incidents.map((incident) => {
          const endedAt =
            incident.lastUntriggeredAt ||
            incident.closedAt ||
            incident.resolvedAt;

          return {
            ...incident,
            zoneName: incident.organization.name,
            incidentType: formatIncidentType(incident),
            startedAt: incident.startedAt,
            endedAt,
            duration: diff(incident.startedAt, endedAt).as("minutes"),
            statusText: incidentStatus(incident),
            deviceLocation: incident?.devices[0]?.location,
          };
        });
      },
      skip() {
        return !this.dateRange?.after;
      },
    },
    eenEvents: {
      query: EEN_EVENTS,
      fetchPolicy: "no-cache",
      variables() {
        return {
          organizationId: this.currentFacilityId,
          actor: "camera:" + this.isVideoCameraEnabled?.externalId,
          eventType: "een.personDetectionEvent.v1",
        };
      },
      update({ eenEvents }) {
        return eenEvents;
      },
      skip() {
        return (
          !this.isVideoCameraEnabled?.externalId && this.isVideoCameraEnabled
        );
      },
    },
  },

  watch: {
    async editingZone(newVal, oldVal) {
      await this.handleEditingChange(newVal, oldVal);
    },
    async editingSensorsConfigs(newVal, oldVal) {
      await this.handleEditingChange(newVal, oldVal);
    },
    organization() {
      // this has to be removed after trade-show
      if (this.showDemo) {
        this.organization.devices.push({
          __typename: "Device",
          batteryLevel: "77.88",
          disposition: "in_service",
          entryState: "water_detected",
          externalId: "78-94-e8-01-00-00-25-00",
          humidity: null,
          id: "7178c43b-24a8-49b4-a7e7-48100d4c7c46",
          lastContactedAt: "2024-08-27T13:42:14.457656Z",
          motionStartedAt: "2024-08-28T13:52:39.000000Z",
          motionState: null,
          name: "Rb #RB00114",
          parkingState: null,
          rmaRequest: null,
          serviceRequestState: null,
          shortId: "RB00114",
          signalStrength: "5",
          status: "ok",
          subtype: "defender",
          temperature: null,
          type: "flood",
          specs: {
            location: "Front Gate",
          },
        });
      }
    },
  },
  methods: {
    async closeDialog() {
      this.isOpen = false;
      if (this.$refs.popupVideoView) {
        this.$refs.popupVideoView.pauseVideo();
      }
      this.video = null;
    },
    async save() {
      const { id, name, subtype, timeZone } = this.organization;
      const profile = JSON.parse(JSON.stringify(this.organization.profile));

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_SMART_ZONE,
          variables: {
            id,
            name,
            subtype,
            timeZone,
            profile: inputifyProfile(profile),
          },
        });

        this.addAlert({
          type: "success",
          message: "Smart Zone updated successfully",
          timeout: 15,
        });
        this.editingZone = "";
        this.editingSensorsConfigs = "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error updating the Smart Zone Profile",
        });
      }
    },
    async handleEditingChange(newVal, oldVal) {
      if (newVal === "" && oldVal === "editing") {
        await this.$apollo.queries.organization.refresh();
      }
    },
    async fetchVideo(deviceId, fromTimeStamp, organization_id) {
      try {
        this.video = null;
        this.isOpen = true;
        this.loading = true;

        const response = await this.$apollo.query({
          query: EEN_VIDEO_FEED,
          variables: { fromTimeStamp, deviceId, organization_id },
        });
        this.video = response?.data?.eenVideoFeed?.video ?? "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error fetching the video",
        });
      } finally {
        this.loading = false;
      }
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      date.setHours(date.getHours() - 1);
      return this.facilityFormatDateTime4Humans(date.toISOString());
    },
    getSubEvents(index) {
      const start = index * 3;
      return this.eenEvents.slice(start, start + 3);
    },
    prevSlide() {
      if (this.currentSlide > 0) {
        this.currentSlide--;
      }
    },
    nextSlide() {
      if (this.currentSlide < this.chunkedEvents.length - 1) {
        this.currentSlide++;
      }
    },
    handleSlideChange(newSlide) {
      this.currentSlide = newSlide;
    },
    titleCase,
  },
};
</script>

<style scoped>
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel-arrow {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.v-carousel__controls {
  display: none;
}

.blue-arrow {
  color: blue;
}

.clickable-link {
  cursor: pointer;
}
</style>
