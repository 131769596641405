<template>
  <div>
    <DialogLayout v-model="isDialogOpen" title="Add New Task">
      <AlertsAnimated :alerts="localAlerts" />
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(submitTask)">
          <v-row dense>
            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Organization"
                rules="required"
              >
                <UnitAutocomplete
                  v-model="taskForm.organizationId"
                  :error-messages="errors"
                  @input="handleOrganizationSelection"
                  @parentFacilityId="handleParentFacilityChange"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Task Name"
                rules="required|min:2|max:250"
              >
                <v-text-field
                  v-model="taskForm.title"
                  label="Task Name"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Priority"
                rules="required"
              >
                <v-select
                  v-model="taskForm.priority"
                  label="Priority"
                  :items="priorityList"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>

            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Task Type"
                rules="required"
              >
                <v-select
                  v-model="taskForm.taskTypeId"
                  label="Task Type"
                  :items="taskTypes"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                :nudge-right="0"
                :nudge-bottom="0"
              >
                <template #activator="{ on, attrs }">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Due Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="taskForm.dueAt"
                      :error-messages="errors"
                      label="Due Date"
                      readonly
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </ValidationProvider>
                </template>
                <v-date-picker
                  v-model="taskForm.dueAt"
                  no-title
                  @input="dateMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="6">
              <ValidationProvider
                v-slot="{ errors }"
                name="Assignee"
                rules="required"
              >
                <SelectOrganizationUsers
                  v-model="taskForm.assigneeId"
                  :error-messages="errors"
                  :organization-id="parentFacilityId"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="6">
              <ValidationProvider v-slot="{ errors }" name="File">
                <v-file-input
                  v-model="taskForm.file"
                  :error-messages="errors"
                  label="Upload File"
                  accept="application/pdf, image/jpeg, image/png"
                  :rules="[
                    (v) =>
                      !v ||
                      v.size <= 2 * 1024 * 1024 ||
                      'File size should be less than 2MB',
                  ]"
                  @change="handleFileChange"
                />
              </ValidationProvider>
            </v-col>
            <!-- Uncomment this when escalation matrix is ready -->
            <!-- <v-row v-if="taskForm.taskTypeId === TASK_TYPES.INCIDENT_RESOLUTION">
              <v-col cols="6">
                <ValidatedTextField
                  v-model="taskForm.incidentId"
                  name="Incident ID"
                  rules="required|numeric"
                />
              </v-col>
            </v-row> -->

            <v-col cols="12">
              <ValidationProvider
                v-slot="{ errors }"
                name="Task Description"
                rules="required"
              >
                <v-textarea
                  v-model="taskForm.description"
                  label="Task Description"
                  rows="3"
                  :error-messages="errors"
                  :maxlength="250"
                  counter="250"
                  :rules="[(v) => v.length <= 250 || 'Max 250 characters']"
                />
              </ValidationProvider>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn color="primary" text @click="closeDialog">Cancel</v-btn>
              <v-btn color="primary" text type="submit" :loading="loading"
                >Add Task</v-btn
              >
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </DialogLayout>
    <v-btn color="success" @click="openDialog">Add New Task</v-btn>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import { required, min, max, numeric } from "vee-validate/dist/rules";
import DialogLayout from "@layout/DialogLayout.vue";
import {
  PRIORITIES,
  RESOLUTION_STATUS,
} from "@tod-ui/constants/task_management";
import { ref, reactive } from "vue";
import { CREATE_TASK, TASK_TYPES } from "./graphql";
import { titleCase } from "@tod-ui/helpers/strings";
import useUser from "@components/authentication/useUser";
import { formatToISO } from "@tod-ui/helpers/datetime";
import UnitAutocomplete from "@components/utils/UnitAutocomplete.vue";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import SelectOrganizationUsers from "@components/common/SelectOrganizationUsers.vue";

extend("required", required);
extend("min", min);
extend("max", max);
extend("numeric", numeric);

export default {
  name: "AddTaskButton",
  components: {
    DialogLayout,
    UnitAutocomplete,
    AlertsAnimated,
    SelectOrganizationUsers,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { addLocalAlert, clearAllAlerts, localAlerts, addGlobalAlert } =
      useAlerts();

    const isDialogOpen = ref(false);
    const dateMenu = ref(false);
    const loading = ref(false);
    const selectedOrganizationsIds = ref({});
    const parentFacilityId = ref("");

    const taskForm = reactive({
      title: "",
      description: "",
      assigneeId: "",
      priority: "",
      taskTypeId: "",
      dueAt: "",
      resolution: RESOLUTION_STATUS.TODO,
      organizationId: "",
      file: null,
    });

    const priorityList = ref([
      { value: PRIORITIES.LOW, text: "Low" },
      { value: PRIORITIES.MODERATE, text: "Moderate" },
      { value: PRIORITIES.SEVERE, text: "Severe" },
      { value: PRIORITIES.CRITICAL, text: "Critical" },
    ]);
    const taskTypes = ref([]);

    return {
      isDialogOpen,
      dateMenu,
      loading,
      priorityList,
      taskTypes,
      taskForm,
      TASK_TYPES,
      defaultOrganization,
      addLocalAlert,
      localAlerts,
      addGlobalAlert,
      clearAllAlerts,
      selectedOrganizationsIds,
      parentFacilityId,
    };
  },
  apollo: {
    taskTypes: {
      query: TASK_TYPES,
      variables() {
        return {
          sort: "name",
          status: "active",
        };
      },
      update(data) {
        return (this.taskTypes = data.taskTypes.map((type) => ({
          text: titleCase(type.name),
          value: type.id,
        })));
      },
    },
  },
  methods: {
    handleParentFacilityChange(facilityId) {
      this.parentFacilityId = facilityId;
    },
    openDialog() {
      this.isDialogOpen = true;
    },
    closeDialog() {
      this.resetForm();
      this.isDialogOpen = false;
    },
    async submitTask() {
      this.clearAllAlerts();
      try {
        this.loading = true;
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_TASK,
          variables: {
            ...this.taskForm,
            dueAt: new Date(this.taskForm?.dueAt).toISOString(),
          },
        });

        this.resetForm();
        this.closeDialog();
        this.addGlobalAlert({
          type: "success",
          message: "Task created successfully",
          timeout: 15,
        });

        this.$emit("task-added");
      } catch (error) {
        this.addLocalAlert({
          type: "error",
          message: "Error creating task",
          timeout: 15,
        });
      } finally {
        this.loading = false;
      }
    },

    resetForm() {
      Object.assign(this.taskForm, {
        title: "",
        description: "",
        assigneeId: "",
        priority: "",
        taskTypeId: "",
        dueAt: "",
        organizationId: "",
        resolution: RESOLUTION_STATUS.TODO,
        file: null,
      });
    },
    handleOrganizationSelection(organizationId) {
      this.taskForm.organizationId = organizationId;
      this.taskForm.assigneeId = ""; // Reset assignee when organization changes
    },
    handleFileChange(file) {
      if (file && file.size > 10 * 1024 * 1024) {
        this.addLocalAlert({
          type: "error",
          message: "File is too large. Maximum size is 10MB.",
          timeout: 15,
        });
        this.taskForm.file = null;
      }
    },
  },
};
</script>
