import * as Sentry from "@sentry/vue";
import { ref, computed, readonly } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { clearToken } from "../../apollo-client/apollo-token";
import { resetCache } from "../../apollo-client/vue-apollo";
import { setLastRoute, $lastRoute } from "../../router/auth-hooks";
import { AUTHENTICATE_USER, CHANGE_PASSWORD, UPDATE_PASSWORD } from "./graphql";
import useFacility from "./useFacility";
import useDashboard from "@components/dashboard/useDashboard";

const currentUser = ref(null);
const inProcess = ref(false);
const authenticated = computed(() => !!currentUser.value);
const defaultOrganization = computed(
  () => currentUser.value?.defaultOrganization
);
const isFacilityManager = computed(
  () => defaultOrganization.value?.type === "facility"
);

const defaultPage = computed(() =>
  !authenticated.value
    ? { name: "Login" }
    : isFacilityManager.value
    ? {
        name: "FacilityDashboard",
        params: { organization_id: defaultOrganization.value.id },
      }
    : { name: "ManagementDashboard" }
);

function useUser() {
  const $router = useRouter();
  const $route = useRoute();

  const { selectFacility, clearCurrentFacility, currentFacility } =
    useFacility();

  const { clearDashboardData } = useDashboard();

  async function login(dollarApollo, username, password) {
    inProcess.value = true;
    let logged = false;
    let message = "";
    try {
      const response = await dollarApollo.mutate({
        mutation: AUTHENTICATE_USER,
        variables: {
          username,
          password,
        },
        fetchPolicy: "no-cache",
      });

      setCurrentUser(response.data.login.user);
      // console.log("logged, last route:", $lastRoute.value);
      const facility = isFacilityManager.value
        ? defaultOrganization.value
        : $lastRoute.value?.params?.organization_id
        ? { id: $lastRoute.value?.params?.organization_id }
        : null;
      if (facility) {
        await selectFacility(dollarApollo, facility);
      }

      logged = true;
    } catch (error) {
      currentUser.value = null;

      if (error.message.includes("not_authorized")) {
        message =
          "Oops! It looks like you don't have the permissions to access this app";
      } else {
        message = "Login failed, please try again";
      }

      console.error(error);
    }
    inProcess.value = false;
    if (logged) {
      $router.replace($lastRoute.value || defaultPage.value);
    }
    return { logged, message };
  }

  async function logout(dollarApollo) {
    await clearUser(dollarApollo);
  }

  async function requestPasswordReset(dollarApollo, username) {
    inProcess.value = true;
    let success = false;
    let message = "";

    try {
      const response = await dollarApollo.mutate({
        mutation: CHANGE_PASSWORD,
        variables: {
          username,
        },
        fetchPolicy: "no-cache",
      });
      success = response.data?.changePassword?.status === "ok";
      message = response.data?.changePassword?.message;
    } catch (error) {
      // console.error(error);
      message = error.message?.replace("GraphQL", "Forgot Password");
    }
    inProcess.value = false;
    return [success, message];
  }

  async function resetPassword(dollarApollo, newPassword) {
    const token = $route.query?.token;

    if (!token) {
      return false;
    }

    inProcess.value = true;
    let success = false;
    try {
      const response = await dollarApollo.mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          password: newPassword,
          token,
        },
      });
      success = response.data.updatePassword.status === "ok";
    } catch (error) {
      // console.error(error);
    }
    inProcess.value = false;
    return success;
  }

  async function clearUser(dollarApollo) {
    clearCurrentFacility();
    clearDashboardData();
    clearCurrentUser();
    clearToken();
    await resetCache(dollarApollo.provider.defaultClient);
  }

  function displayUserName(user) {
    return `${user?.firstName} ${user?.lastName || ""}`;
  }

  return {
    inProcess: readonly(inProcess),
    currentUser: readonly(currentUser),
    authenticated,
    defaultOrganization,
    isFacilityManager,
    login,
    logout,
    requestPasswordReset,
    resetPassword,
    defaultPage,
    clearUser,
    restoreCurrentUser,
    displayUserName,
  };
}
export default useUser;

const STORE_USER = "currentUser";
function setCurrentUser(user) {
  currentUser.value = user;
  window.localStorage.setItem(STORE_USER, JSON.stringify(user));
  Sentry.setUser({
    id: user.id,
    username: user.username,
    segment: user?.defaultOrganization.name,
  });

  if (typeof _kmq !== "undefined" && _kmq) {
    _kmq.push(["identify", user.username]);
    _kmq.push([
      "set",
      {
        "User Id": user.id,
        "User Name": user.username,
        "Region/Facility": user?.defaultOrganization.name,
      },
    ]);
  }
}

function restoreCurrentUser() {
  const user = window.localStorage.getItem(STORE_USER);
  if (user) {
    currentUser.value = JSON.parse(user);
    Sentry.setUser({
      id: currentUser.value.id,
      username: currentUser.value.username,
      segment: currentUser.value?.defaultOrganization.name,
    });
    return currentUser.value;
  }
  return null;
}

function clearCurrentUser() {
  window.localStorage.removeItem(STORE_USER);
  currentUser.value = null;
  Sentry.setUser(null);
}
