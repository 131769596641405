// Composes complex renter workflows

import gql from "graphql-tag";
import { unref } from "vue";

import { organizationResponders } from "../../graphql/organizations/index";

import { addAccountOrganizations } from "../../graphql/organizations/zone";

import {
  userBillingAccounts,
  updateAccountUser,
  updateUser,
  userResponders,
} from "../../graphql/users/index";
import {
  ADD_USER_CONTACT_METHOD,
  GET_USER_CONTACT_METHODS,
  ADD_UNIT_RESPONDERS,
  REMOVE_UNIT_RESPONDER,
  UPDATE_UNIT_RESPONDER,
  GET_ASSIGNED_UNITS,
  AVAILABLE_SMART_UNITS,
} from "./graphql";
import { GET_SMART_UNIT } from "../smart_units/graphql";

import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useFacility from "../authentication/useFacility";

export async function assignZoneToRenter(
  apolloClient,
  zone,
  user,
  bau,
  parentOrganizationId = null
) {
  const { id: zoneId } = zone;
  const { id: userId } = user;
  const { id: billingAccountId } = bau.billingAccount;
  parentOrganizationId = parentOrganizationId || zone.parentId;

  // Enable alerts and notifications for the user
  await apolloClient.mutate({
    mutation: updateUser,
    variables: {
      id: userId,
      firstName: user.firstName,
      lastName: user.lastName,
      muteAlerts: false,
      muteNotifications: false,
    },
  });

  // Ensure the user is an account owner to trigger Tenant Addendum
  await apolloClient.mutate({
    mutation: updateAccountUser,
    variables: {
      accountUserId: bau.id,
      isOwner: true,
    },
    refetchQueries: [
      {
        query: userBillingAccounts,
        variables: { userId, parentOrganizationId },
      },
    ],
  });

  // Assign the zone/unit to the renter's account
  await apolloClient.mutate({
    mutation: addAccountOrganizations,
    variables: {
      billingAccountId,
      organizations: [{ id: zoneId }],
    },
    refetchQueries: [
      // assignedZones
      {
        query: GET_ASSIGNED_UNITS,
        variables: { userId, parentOrganizationId },
      },
      // availableZones
      {
        query: AVAILABLE_SMART_UNITS,
        variables: {
          id: parentOrganizationId,
        },
      },
    ],
  });

  // add the user as zone responders
  const responders = [];

  const primaryPhoneMethod = user.contactMethods.find(
    (cm) => cm.type === "mobile" && cm.isDefault
  );

  if (primaryPhoneMethod) {
    responders.push({
      userId,
      contactMethodId: primaryPhoneMethod.id,
      escalationLevel: 1,
    });
  }

  const secondaryPhoneMethod = user.contactMethods.find(
    (cm) => cm.type === "mobile" && !cm.isDefault
  );

  if (secondaryPhoneMethod) {
    responders.push({
      userId,
      contactMethodId: secondaryPhoneMethod.id,
      escalationLevel: 2,
    });
  }

  await apolloClient.mutate({
    mutation: ADD_UNIT_RESPONDERS,
    variables: {
      id: zoneId,
      responders,
    },
    refetchQueries: [
      {
        query: userResponders,
        variables: {
          userId,
          parentOrganizationId,
        },
      },
    ],
  });
}

export async function addUpdateRemoveSecondaryResponder(
  apolloClient,
  unitId,
  renterId,
  selectedContactMethodId,
  existingSecondaryResponder = null,
  newSecondary = null,
  existingContactMethods = []
) {
  if (!selectedContactMethodId) return;

  //do we need to add a new contact method?
  if (selectedContactMethodId === "add" && newSecondary) {
    const newSecondaryNormalized = formatPhoneNumber(newSecondary, true);
    const existingContact =
      newSecondaryNormalized &&
      existingContactMethods.find((cm) => cm.value === newSecondaryNormalized);

    if (!existingContact) {
      //new contact method needs to be added
      try {
        const response = await apolloClient.mutate({
          mutation: ADD_USER_CONTACT_METHOD,
          variables: {
            userId: renterId,
            type: "mobile",
            value: newSecondaryNormalized,
            isDefault: false,
          },
        });
        selectedContactMethodId = response.data.addUserContactMethod.id;
      } catch (error) {
        throw `There was an error adding ${formatPhoneNumber(
          newSecondary
        )} as secondary contact`;
      }
    } else if (!existingContact.isDefault) {
      //entered phone is already in existing contact methods
      selectedContactMethodId = existingContact.id;
    } else {
      // No-op, warn don't use the primary as a secondary
      throw "The primary and secondary should not be the same phone number";
    }
  }

  //is there an existing secondary responder?
  if (existingSecondaryResponder?.id) {
    if (selectedContactMethodId === "none") {
      //we need to remove secondary responder
      try {
        await apolloClient.mutate({
          mutation: REMOVE_UNIT_RESPONDER,
          variables: {
            id: existingSecondaryResponder.id,
            organizationId: unitId,
          },
          refetchQueries: [
            {
              query: GET_SMART_UNIT,
              variables: { id: unitId },
            },
            {
              query: GET_USER_CONTACT_METHODS,
              variables: { id: renterId },
            },
          ],
        });
        return "The secondary contact was removed successfully";
      } catch (error) {
        throw "There was an error removing the existing secondary contact";
      }
    } else if (
      selectedContactMethodId !== existingSecondaryResponder.contactMethod.id
    ) {
      //we need to update secondary responder
      try {
        await apolloClient.mutate({
          mutation: UPDATE_UNIT_RESPONDER,
          variables: {
            id: existingSecondaryResponder.id,
            escalationLevel: existingSecondaryResponder.escalationLevel,
            contactMethodId: selectedContactMethodId,
          },
          refetchQueries: [
            {
              query: GET_SMART_UNIT,
              variables: { id: unitId },
            },
          ],
        });
        return "The secondary contact was updated successfully";
      } catch (error) {
        throw "There was an error updating the secondary contact";
      }
    }
  } else if (
    selectedContactMethodId &&
    !["none", "add"].includes(selectedContactMethodId)
  ) {
    //add new responder
    try {
      await apolloClient.mutate({
        mutation: ADD_UNIT_RESPONDERS,
        variables: {
          id: unitId,
          responders: [
            {
              contactMethodId: selectedContactMethodId,
              escalationLevel: 2,
              userId: renterId,
            },
          ],
        },
        refetchQueries: [
          {
            query: GET_SMART_UNIT,
            variables: { id: unitId },
          },
        ],
      });
    } catch (error) {
      throw "There was an error adding the secondary contact";
    }
    return "The secondary contact was added successfully";
  }

  // return nothing
  return;
}

export async function maybeAddSecondaryResponder(
  apolloClient,
  smartUnit,
  renterId,
  newContactMethodId
) {
  let existingResponders = smartUnit.responders;
  if (!existingResponders) {
    const response = await apolloClient.query({
      query: organizationResponders,
      variables: { id: smartUnit.id },
    });
    existingResponders = response.data.organizationResponders;
  }
  if (existingResponders?.length === 1) {
    return (await addUpdateRemoveSecondaryResponder(
      apolloClient,
      smartUnit.id,
      renterId,
      newContactMethodId
    ))
      ? smartUnit.name
      : null;
  }
}

export async function createDemoMessage(
  apolloClient,
  { firstName, lastName, primaryPhone }
) {
  const name = `${firstName} ${lastName}`;
  const { currentFacilityId: currentFacilityIdRef } = useFacility();
  const currentFacilityId = unref(currentFacilityIdRef);

  return await apolloClient.mutate({
    mutation: gql`
      mutation CreateDemoMessage(
        $name: String!
        $phoneNumber: String!
        $organizationId: ID!
      ) {
        createDemoMessage(
          name: $name
          phoneNumber: $phoneNumber
          organizationId: $organizationId
        ) {
          id
          name
          phoneNumber
          type
        }
      }
    `,
    variables: {
      name,
      phoneNumber: formatPhoneNumber(primaryPhone, true),
      organizationId: currentFacilityId,
    },
  });
}
