<template>
  <div v-if="enabled">
    <slot></slot>
  </div>
  <div v-else>
    <slot name="disabled"></slot>
  </div>
</template>

<script>
import { computed } from "vue";
import useFacility from "../authentication/useFacility";

export default {
  name: "FeatureWrapper",
  props: {
    feature: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { facilityFeatureEnabled } = useFacility();
    const enabled = computed(() => facilityFeatureEnabled(props.feature));
    return { enabled };
  },
};
</script>
