import PageManageTasks from "./PageManageTasks.vue";

const routes = {
  navigation: [
    {
      path: "/manage-tasks",
      name: "ManageTasks",
      component: PageManageTasks,
    },
  ],
};
export default routes;
