import { ref, unref, computed, readonly } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import useFacility from "../authentication/useFacility";
import {
  deviceLastMotion,
  extractDevicesFields,
  formatDeviceName,
  unitWithAvailableDevices,
} from "../common/helpers/devices";
import { demoResponder } from "../renters/helper";
import {
  INVENTORY_SMART_UNITS,
  ENDED_SERVICE_SMART_UNITS,
  ORGANIZATION_DEVICES,
} from "./graphql";
import { stringSortCollator } from "@tod-ui/helpers/strings";
import { displayUnitType } from "@tod-ui/helpers/unitType";

const inventoryUnits = ref([]);
const endedServiceUnits = ref([]);
const facilityDevices = ref([]);
const devicesLoading = ref(true);
const unitsLoading = ref(true);
const loading = computed(() => devicesLoading.value || unitsLoading.value);
let devicesObservable = null;
let unitsObservable = null;
let endedServiceObservable = null;

const healthyFacilityDevices = computed(() =>
  facilityDevices.value.filter(
    (device) =>
      device.disposition === "in_service" &&
      device.status === "ok" &&
      !device.rmaRequest
  )
);

const unavailableFacilityDevices = computed(() =>
  facilityDevices.value.filter(
    (device) =>
      (device.disposition === "in_service" && device.status !== "ok") ||
      device.disposition !== "in_service" ||
      device.rmaRequest
  )
);

const availableInventory = computed(() => {
  if (loading.value) return [];

  const availableUnits = inventoryUnits.value.filter((unit) =>
    unitWithAvailableDevices(unit)
  );
  return [
    ...devicesInUnits(endedServiceUnits, true),
    ...devicesInUnits(availableUnits),
    ...healthyFacilityDevices.value.map(deviceAsInventoryRow),
  ];
});

const unavailableInventory = computed(() => {
  if (loading.value) return [];

  const unavailableUnits = inventoryUnits.value.filter(
    (unit) => !unitWithAvailableDevices(unit)
  );
  return [
    ...devicesInUnits(unavailableUnits),
    ...unavailableFacilityDevices.value.map(deviceAsInventoryRow),
  ];
});

function cleanUpInventoryRefs() {
  inventoryUnits.value = [];
  facilityDevices.value = [];
  devicesLoading.value = true;
  unitsLoading.value = true;
  devicesObservable = null;
  unitsObservable = null;
  endedServiceObservable = null;
}

export default function useInventory() {
  const { currentFacilityId } = useFacility();

  function fetchFacilityDevices(apolloClient) {
    devicesObservable = apolloWatchQuery(
      apolloClient,
      {
        query: ORGANIZATION_DEVICES,
        variables: {
          id: currentFacilityId.value,
          dispositions: [
            "missing",
            "shipping",
            "under_maintenance",
            "retired",
            "in_service",
            "waiting",
          ],
        },
        fetchPolicy: "network-only",
      },
      (data, loading) => {
        devicesLoading.value = loading;
        if (data?.organizationDevices) {
          facilityDevices.value = data.organizationDevices
            .map((device) => ({
              ...device,
              name: formatDeviceName(device),
              lastMotion: deviceLastMotion(device),
            }))
            .sort((a, b) => stringSortCollator.compare(a.name, b.name));
        }
      }
    );
    return devicesObservable;
  }

  function fetchInventory(apolloClient) {
    fetchFacilityDevices(apolloClient);

    unitsObservable = apolloWatchQuery(
      apolloClient,
      {
        query: INVENTORY_SMART_UNITS,
        variables: {
          id: currentFacilityId.value,
        },
        fetchPolicy: "network-only",
      },
      (data, loading) => {
        unitsLoading.value = loading;
        if (data?.inventorySmartUnits) {
          inventoryUnits.value = data.inventorySmartUnits.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          );
        }
      }
    );

    endedServiceObservable = apolloWatchQuery(
      apolloClient,
      {
        query: ENDED_SERVICE_SMART_UNITS,
        variables: {
          id: currentFacilityId.value,
        },
        fetchPolicy: "network-only",
      },
      (data, loading) => {
        unitsLoading.value = loading;
        if (data?.endedServiceSmartUnits) {
          endedServiceUnits.value = data.endedServiceSmartUnits.sort((a, b) =>
            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
          );
        }
      }
    );

    return [devicesObservable, unitsObservable, endedServiceObservable];
  }

  function refetchInventory() {
    if (devicesObservable) {
      devicesObservable.refetch();
    }
    if (unitsObservable) {
      unitsObservable.refetch();
    }
    if (endedServiceObservable) {
      endedServiceObservable.refetch();
    }
  }

  return {
    devicesLoading,
    loading,
    inventoryUnits: readonly(inventoryUnits),
    healthyFacilityDevices,
    unavailableFacilityDevices,
    availableInventory,
    unavailableInventory,
    endedServiceUnits: readonly(endedServiceUnits),
    fetchFacilityDevices,
    fetchInventory,
    refetchInventory,
    cleanUpInventoryRefs,
  };
}

function devicesInUnits(inventoryUnits, areEndedServiceUnits = false) {
  return unref(inventoryUnits).reduce(function (acc, unit) {
    // Has to have devices to be a Smart Unit
    if (unit.devices?.length > 0) {
      const responder =
        areEndedServiceUnits && unit.responders?.length > 0
          ? { ...unit.responders[0], status: "ended_service" }
          : demoResponder(unit);

      acc.push({
        ...unit,
        ...extractDevicesFields(unit.devices),
        type: "unit",
        unitTypeName: displayUnitType(unit?.unitType, "type"),
        unitTypeSize: displayUnitType(unit?.unitType, "size"),
        responder,
        endedServiceResponder:
          areEndedServiceUnits &&
          unit.responders?.length > 0 &&
          unit.responders[0],
      });
    }

    return acc;
  }, []);
}

function deviceAsInventoryRow(device) {
  return {
    ...extractDevicesFields(device),
    id: device.id,
    name: " - ",
    type: "device",
    unitTypeName: "",
  };
}
