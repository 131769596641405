import { ref, readonly } from "vue";

/**
 * Adding alert object
 * @typedef {Object} Alert
 * @prop {string} message A message to display
 * @prop {"error"|"success"|"warning"} type A type (color) of the alert
 * @prop {number} [timeout] A time in sec to display the alert, if `undefined` then alert will not be automatically hidden
 * @prop {string} [title] A header for the alert
 * @prop {string} [icon] An icon to be used if different from type
 * @prop {{}} [item] id of an object that cased alert (e.g. notification id)
 * @prop {Object.<string, string>} [actions] A map of key/value pairs, where key is the name of the event to be emitted and value is the alert button text
 */
/**
 * @typedef {Alert} AlertWithId
 * @prop {Symbol} id alert id
 */

const globalAlerts = ref(/** @type {AlertWithId[]} */ []);
const sectionAlerts = ref(/** @type {AlertWithId[]} */ []);

export default function useAlerts() {
  const localAlerts = ref(/** @type {AlertWithId[]} */ []);

  return {
    globalAlerts: readonly(globalAlerts),
    sectionAlerts: readonly(sectionAlerts),
    localAlerts: readonly(localAlerts),

    /**
     * @param {Alert} alert Ads a global alert that stays until removed
     * @returns {Symbol} created alert id
     */
    addGlobalAlert(alert) {
      return addStoreAlert(globalAlerts, alert);
    },
    /**
     * @param {Symbol} id An alert key to update
     * @param {Alert} alert updated global alert
     * @returns {Symbol|false} updated alert id or false
     */
    updateGlobalAlert(id, updatedAlert) {
      return updateStoreAlert(globalAlerts, id, updatedAlert);
    },
    /**
     * @param {Symbol} id An alert key to delete
     * @returns {Symbol|false} deleted alert id or false
     */
    clearGlobalAlert(id) {
      return clearStoreAlert(globalAlerts, id);
    },
    clearAllGlobalAlerts() {
      return clearAllStoreAlerts(globalAlerts);
    },
    /**
     * @returns {Boolean}
     */
    hasGlobalAlerts() {
      return hasStoreAlerts(globalAlerts);
    },

    /**
     * @param {Alert} alert A message to display for the section
     * @returns {Symbol} created alert id
     */
    addAlert(alert) {
      return addStoreAlert(sectionAlerts, alert);
    },
    /**
     * @param {Symbol} id An alert key to update
     * @param {Alert} alert updated alert for the section
     * @returns {Symbol|false} updated alert id or false
     */
    updateAlert(id, updatedAlert) {
      return updateStoreAlert(sectionAlerts, id, updatedAlert);
    },
    /**
     * @param {Symbol} id An alert key to delete
     * @returns {Symbol|false} deleted alert id or false
     */
    clearAlert(id) {
      return clearStoreAlert(sectionAlerts, id);
    },
    clearAllAlerts() {
      return clearAllStoreAlerts(sectionAlerts);
    },
    /**
     * @returns {Boolean}
     */
    hasAlerts() {
      return hasStoreAlerts(sectionAlerts);
    },

    /**
     * @param {Alert} alert A message to display in the component
     * @returns {Symbol} created alert id
     */
    addLocalAlert(alert) {
      return addStoreAlert(localAlerts, alert);
    },
    /**
     * @param {Symbol} id An alert key to update
     * @param {Alert} alert updated local alert
     * @returns {Symbol|false} updated alert id or false
     */
    updateLocalAlert(id, updatedAlert) {
      return updateStoreAlert(localAlerts, id, updatedAlert);
    },
    /**
     * @param {Symbol} id An alert key to delete
     * @returns {Symbol|false} deleted alert id or false
     */
    clearLocalAlert(id) {
      return clearStoreAlert(localAlerts, id);
    },
    clearAllLocalAlerts() {
      return clearAllStoreAlerts(localAlerts);
    },
    /**
     * @returns {Boolean}
     */
    hasLocalAlerts() {
      return hasStoreAlerts(localAlerts);
    },
  };
}

/**
 * @param {ref} store a store for adding an alert
 * @param {Alert} alert A message to display
 * @returns {Symbol} created alert id
 */
function addStoreAlert(
  store,
  {
    message,
    type = "error",
    timeout = undefined,
    title = undefined,
    icon = undefined,
    item = {},
    actions = undefined,
  }
) {
  const key = Symbol();
  store.value = [
    ...store.value,
    { id: key, message, type, title, icon, item, actions },
  ];

  if (timeout && timeout > 0) {
    setTimeout(() => {
      clearStoreAlert(store, key);
    }, timeout * 1000);
  }
  return key;
}

function updateStoreAlert(store, key, updatedAlert) {
  const index = store.value.findIndex((a) => a.id === key);
  if (index !== -1) {
    store.value = [
      ...store.value.slice(0, index),
      { ...store.value[index], ...updatedAlert },
      ...store.value.slice(index + 1),
    ];
    return key;
  }
  return false;
}

function clearStoreAlert(store, key) {
  const index = store.value.findIndex((a) => a.id === key);
  if (index !== -1) {
    store.value = [
      ...store.value.slice(0, index),
      ...store.value.slice(index + 1),
    ];
    return key;
  }
  return false;
}

function clearAllStoreAlerts(store) {
  store.value = [];
}

function hasStoreAlerts(store) {
  return store.value.length > 0;
}
