import PageAuditLog from "./PageAuditLog.vue";

const routes = {
  organization: [
    {
      path: "audit-logs",
      name: "AuditLog",
      component: PageAuditLog,
    },
  ],
};

export default routes;
