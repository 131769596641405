import { stringSortCollator } from "./strings";
import { getObjectValueByPath } from "./utils";

export function sortItems(items, sortBy, sortDesc, _locale, customSorters) {
  // console.log(sortBy, sortDesc)
  return items.sort((itemA, itemB) =>
    sortBy.reduce((prev, currentSortBy, index) => {
      if (prev) return prev; //if already sorted by prev sortBy

      const sortOrder = sortDesc[index] ? -1 : 1;

      return typeof customSorters[currentSortBy] === "function"
        ? customSorters[currentSortBy](itemA, itemB, currentSortBy, sortOrder) *
            sortOrder
        : defaultSort(itemA, itemB, currentSortBy, sortOrder);
    }, 0)
  );
}

function defaultSort(itemA, itemB, sortBy, sortOrder) {
  const valueA = getObjectValueByPath(itemA, sortBy);
  const valueB = getObjectValueByPath(itemB, sortBy);

  if (valueA === undefined || valueA === null) return 1;
  if (valueB === undefined || valueB === null) return -1;

  if (typeof valueA === "string" || typeof valueB === "string") {
    return stringSortCollator.compare(valueA, valueB) * sortOrder;
  }
  return valueA == valueB ? 0 : (valueA < valueB ? -1 : 1) * sortOrder;
}
