<template>
  <LayoutPage title="Monthly Trends">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegions"
          :items="['Three by Three Storage', 'East Region', 'West Region']"
          label="Select Regions"
          item-text="name"
          item-value="id"
          multiple
          dense
          outlined
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedRegions.length - 1 }} others)</span
            >
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          :items="[
            '3x3 Self Storage - Denton',
            '3x3 Self Storage - Fort Worth',
            '3x3 Self Storage - Waco',
            '3x3 Self Storage - lewisville',
            '3x3 Self Storage - Abllene',
          ]"
          label="Select Facilities"
          multiple
          clearable
          item-text="name"
          item-value="id"
          dense
          outlined
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0" class="text-truncate">{{ item }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedTableName"
          :items="tableNames"
          item-text="name"
          dense
          outlined
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedTrends"
          :items="trends"
          item-text="name"
          dense
          outlined
        />
      </v-col>
    </v-row>
    <div class="table-container">
      <v-data-table
        :headers="conversionTable.headers"
        :items="conversionTable.body"
        :items-per-page="5"
        class="elevation-1"
      >
        <template #body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.facilityName">
              <template v-for="(value, key) in item">
                <CustomTableCell
                  :key="key"
                  :value="value"
                  :is-highlighted="isHighlighted(key)"
                  :is-highlight-color="true"
                  :unit="getUnit(key)"
                />
              </template>
            </tr>
            <tr class="total">
              <td :class="{ highlighted: isHighlighted('qwe') }">Total</td>
              <td
                :class="{ highlighted: isHighlighted('newSubscriptionsMTD') }"
              >
                69
              </td>
              <td
                :class="{ highlighted: isHighlighted('newSubscriptionsMTD') }"
              >
                99
              </td>
              <td :class="{ highlighted: isHighlighted('totalConversion') }">
                70%
              </td>
              <template v-if="selectedTrends === 'byTrends'">
                <td :class="{ highlighted: isHighlighted('test') }"></td>
                <td :class="{ highlighted: isHighlighted('test') }"></td>
                <td :class="{ highlighted: isHighlighted('test') }">77%</td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CustomTableCell from "./CustomTableCell.vue";
import useUser from "@components/authentication/useUser";
import { facilityData } from "./data";
import useDashboard from "./useDashboard";
import { useRoute } from "vue-router/composables";
import { computed, ref, watch, watchEffect } from "vue";

export default {
  name: "ConversionTable",
  components: {
    LayoutPage,
    CustomTableCell,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      dashboardData,
    } = useDashboard();

    const route = useRoute();

    const selectedPeriod = ref("last12Months");
    const selectedTrends = ref("byMonths");

    const highlightCol = computed(() => {
      return route.params.highlightColumn;
    });

    const highlightColumn = computed(() => {
      if (highlightCol.value === "august2024") {
        return "june2024";
      } else if (highlightCol.value === "september2023") {
        return "july2023";
      } else if (highlightCol.value === "conversion") {
        return "conversion";
      } else {
        return "conversionPercentage";
      }
    });

    const isHighlighted = (column) => {
      if (highlightColumn.value === "conversion") {
        return ["newSubscriptionsMTD", "totalMoveInMTD"].includes(column);
      } else if (highlightColumn.value === "conversionPercentage") {
        return ["conversion", "totalConversion"].includes(column);
      }
      return column === highlightColumn.value;
    };

    const getUnit = (column) => {
      const columnsWithPercentage = [
        "conversion",
        "lastMonthConversion",
        "previousMonthConversion",
        "threeMonthsRollingAverage",
        "june2024",
        "may2024",
        "april2024",
        "march2024",
        "february2024",
        "january2024",
        "december2023",
        "november2023",
        "october2023",
        "september2023",
        "august2023",
        "july2023",
      ];
      return columnsWithPercentage.includes(column) ? "%" : "";
    };

    watchEffect(() => {
      if (
        highlightColumn.value === "conversionPercentage" ||
        highlightColumn.value === "conversion"
      ) {
        selectedTrends.value = "byTrends";
        selectedPeriod.value = "customPeriod";
      }
    });

    const getColor = (column, value) => {
      return "rgba(10,1,131,0.8)";
    };

    const conversionTable = computed(() => {
      // Determine which columns to show based on the selected period
      let headersToShow = [
        { text: "Facility Name", value: "facilityName" },
        {
          text: "Added Subscriptions (MTD)",
          value: "newSubscriptionsMTD",
        },
        { text: "Total Move-Ins (MTD)", value: "totalMoveInMTD" },
        { text: "Conversion % (MTD)", value: "conversion" },
      ];

      if (selectedTrends.value === "byTrends") {
        headersToShow.push(
          ...[
            { text: "vs. August 2024 (%)", value: "june2024Conversion" },
            {
              text: "vs. September 2023 (%)",
              value: "previousMonthConversion",
            },
            {
              text: "3-Month Rolling Average (%)",
              value: "threeMonthsRollingAverage",
            },
          ]
        );
      }

      if (selectedPeriod.value === "last12Months") {
        headersToShow.push(
          ...[
            { text: "vs. August 2024 (%)", value: "june2024" },
            { text: "vs. July 2024 (%)", value: "may2024" },
            { text: "vs. June 2024 (%)", value: "april2024" },
            { text: "vs. May 2024 (%)", value: "march2024" },
            { text: "vs. April 2024 (%)", value: "february2024" },
            { text: "vs. March 2024 (%)", value: "january2024" },
            { text: "vs. February 2024 (%)", value: "december2023" },
            { text: "vs. January 2024 (%)", value: "november2023" },
            { text: "vs. December 2023 (%)", value: "october2023" },
            { text: "vs. November 2023 (%)", value: "september2023" },
            { text: "vs. October 2023 (%)", value: "august2023" },
            {
              text: "vs. September 2023 (%)",
              value: "july2023Conversion",
            },
          ]
        );
      }

      return {
        headers: headersToShow,
        body: dashboardData.value.map((facilityData) => {
          let rowData = {
            facilityName: facilityData.name,
            newSubscriptionsMTD: String(
              facilityData.currentActiveSubscriptionMTD
            ),
            totalMoveInMTD: String(facilityData.totalMoveInMTD),
            conversion: String(facilityData.conversion),
          };
          if (selectedTrends.value === "byTrends") {
            Object.assign(rowData, {
              lastMonthConversion: facilityData.june2024Conversion,
              previousMonthConversion: facilityData.july2023Conversion,
              threeMonthsRollingAverage: String(
                facilityData.threeMonthsRollingAverageConversion
              ),
            });
          }

          if (selectedPeriod.value === "last12Months") {
            Object.assign(rowData, {
              june2024: facilityData.june2024Conversion,
              may2024: facilityData.may2024Conversion,
              april2024: facilityData.april2024Conversion,
              march2024: facilityData.march2024Conversion,
              february2024: facilityData.february2024Conversion,
              january2024: facilityData.january2024Conversion,
              december2023: facilityData.december2023Conversion,
              november2023: facilityData.november2023Conversion,
              october2023: facilityData.october2023Conversion,
              september2023: facilityData.september2023Conversion,
              august2023: facilityData.august2023Conversion,
              july2023: facilityData.july2023Conversion,
            });
          }

          return rowData;
        }),
      };
    });

    watch(selectedTrends, (value) => {
      if (value === "byTrends") {
        selectedPeriod.value = "customPeriod";
      } else if (value === "byMonths") {
        selectedPeriod.value = "last12Months";
      }
    });

    return {
      fetchOrganizationDescendants,
      selectedFacilityIds,
      filteredFacilities,
      defaultOrganization,
      dashboardData,
      isHighlighted,
      getColor,
      highlightColumn,
      conversionTable,
      selectedPeriod,
      selectedTrends,
      getUnit,
    };
  },

  data() {
    return {
      selectedRegions: ["Three by Three Storage"],
      selectedFacilities: [
        "3x3 Self Storage - Denton",
        "3x3 Self Storage - Fort Worth",
        "3x3 Self Storage - Waco",
        "3x3 Self Storage - lewisville",
        "3x3 Self Storage - Abllene",
      ],
      selectedDate: {
        before: "2024-06-05T12:14:10Z",
        after: "2024-05-05T12:14:10Z",
      },
      selectedTableName: "conversion",
      tableNames: [
        { name: "Conversion", value: "conversion" },
        { name: "Revenue", value: "revenue" },
      ],
      trends: [
        { name: "By Trends", value: "byTrends" },
        { name: "By Months", value: "byMonths" },
      ],
      period: [
        { name: "Last 12 Months", value: "last12Months" },
        { name: "Custom Period", value: "customPeriod" },
      ],
      selectedRegion: "Three by Three Storage",
      selectedFacility: "3x3 Self Storage - Denton",
    };
  },
  watch: {
    filteredFacilities(newFacilities) {
      this.selectedFacilityIds = newFacilities.map((facility) => facility.id);
    },
    selectedFacilityIds(facilityIds) {
      this.dashboardData = facilityData.filter((facility) =>
        facilityIds.includes(facility.id)
      );
    },
  },
  beforeMount() {
    this.selectedFacilityIds = this.filteredFacilities.map(
      (facility) => facility.id
    );
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}

.highlighted {
  background-color: rgba(14, 59, 110, 0.2); /* Highlight color */
}
</style>
