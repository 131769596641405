<template>
  <dl class="property-list">
    <dt v-if="schedule.sunday" class="mb-2">Sun</dt>
    <dd v-if="schedule.sunday" class="mb-2">
      <TimeRange :schedule-window="schedule.sunday" />
    </dd>

    <dt v-if="schedule.monday">Mon</dt>
    <dd v-if="schedule.monday">
      <TimeRange :schedule-window="schedule.monday" />
    </dd>

    <dt v-if="schedule.tuesday">Tue</dt>
    <dd v-if="schedule.tuesday">
      <TimeRange :schedule-window="schedule.tuesday" />
    </dd>

    <dt v-if="schedule.wednesday">Wed</dt>
    <dd v-if="schedule.wednesday">
      <TimeRange :schedule-window="schedule.wednesday" />
    </dd>

    <dt v-if="schedule.thursday">Thu</dt>
    <dd v-if="schedule.thursday">
      <TimeRange :schedule-window="schedule.thursday" />
    </dd>

    <dt v-if="schedule.friday">Fri</dt>
    <dd v-if="schedule.friday">
      <TimeRange :schedule-window="schedule.friday" />
    </dd>

    <dt v-if="schedule.saturday" class="mt-2">Sat</dt>
    <dd v-if="schedule.saturday" class="mt-2">
      <TimeRange :schedule-window="schedule.saturday" />
    </dd>
  </dl>
</template>

<script>
import TimeRange from "./TimeRange.vue";

export default {
  name: "WeeklySchedule",
  components: { TimeRange },
  props: {
    schedule: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
dl.property-list {
  grid-template-columns: 4em auto;
}
</style>
