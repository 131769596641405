<template>
  <v-card v-if="editing" outlined tile>
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left>fas fa-cctv</v-icon>
      Zone Video Configuration
      <v-spacer />
      <v-switch
        v-model="config.disabled"
        title="Enable/Disable"
        hide-details
        inset
        :false-value="true"
        :true-value="false"
      />
    </v-toolbar>
    <v-divider></v-divider>

    <v-col md="4">
      <div class="d-flex">
        <v-select
          v-model="config.videoCameraEvents"
          multiple
          :items="een_Camera_Events"
          item-text="displayName"
          item-value="typeOfEvents"
          label="Select Events to Notify"
        />
      </div>
    </v-col>
  </v-card>

  <v-card v-else outlined tile :disabled="config.disabled">
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left :color="config.disabled ? null : 'success'"
        >fas fa-cctv</v-icon
      >
      Zone Video Configuration
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!config.disabled" class="text-center">
      <v-select
        v-model="config.videoCameraEvents"
        multiple
        :items="een_Camera_Events"
        item-text="displayName"
        item-value="typeOfEvents"
        label="Select Events to Notify"
        disabled
      />
    </v-card-text>
    <v-card-text v-else> Disabled </v-card-text>
  </v-card>
</template>

<script>
import ValidatedTextField from "../utils/ValidatedTextField";
import VideoViewer from "./VideoViewer";
import { EEN_VIDEO_CAMERA_CONFIG } from "../smart_zones/graphql";

export default {
  name: "VideoConfigWidget",
  components: {
    ValidatedTextField,
    VideoViewer,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
    devices: {
      type: Object,
      required: false,
    },
    currentFacilityId: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    een_Camera_Events: [],
  }),
  apollo: {
    een_Camera_Events: {
      query: EEN_VIDEO_CAMERA_CONFIG,
      fetchPolicy: "no-cache",
      variables() {
        return {
          organizationId: this.currentFacilityId,
          cameraId: "camera:" + this.devices?.externalId,
        };
      },
      update: (data) => {
        if (!data || !data.eenVideoCameraConfig) {
          return [];
        }
        const events = data.eenVideoCameraConfig.typeOfEvents;
        const filteredEvents = events.filter((event) =>
          [
            "een.personDetectionEvent.v1",
            "een.deviceCloudStatusUpdateEvent.v1",
            "een.vehicleDetectionEvent.v1",
            "een.motionDetectionEvent.v1",
          ].includes(event)
        );
        return filteredEvents.map((event) => {
          switch (event) {
            case "een.personDetectionEvent.v1":
              return { typeOfEvents: event, displayName: "Person Detection" };
            case "een.deviceCloudStatusUpdateEvent.v1":
              return {
                typeOfEvents: event,
                displayName: "Device Cloud Status Update",
              };
            case "een.vehicleDetectionEvent.v1":
              return { typeOfEvents: event, displayName: "Vehicle Detection" };
            case "een.motionDetectionEvent.v1":
              return { typeOfEvents: event, displayName: "Motion Detection" };
            default:
              return { typeOfEvents: event, displayName: event };
          }
        });
      },
      skip() {
        return !this.devices?.externalId && this.devices;
      },
    },
  },
};
</script>
