<template>
  <CardLayout title="Temperature & Humidity" :loading="loading">
    <v-data-table
      :headers="tableData.headers"
      :items="tableData.items"
      hide-default-footer
    >
      <template #item.actions="{ item }">
        <ButtonView
          v-if="deviceWithOutTempAndHumidity(item)"
          @click="goToDetailedReport(item)"
        >
          View History
        </ButtonView>
      </template>
      <template #item.temperature="{ item }">
        <div
          v-if="item.temperature"
          :class="`${smartUnitTemperatureColor(item.temperature)}--text`"
        >
          {{ convertCelsiusToFahrenheit(item.temperature) }}℉
        </div>
        <div v-else class="grey--text">N/A</div>
      </template>
      <template #item.humidity="{ item }">
        <div
          v-if="item?.humidity"
          :class="`${smartUnitHumidityColor(item.humidity)}--text`"
        >
          {{ roundValue(item.humidity) }}%
        </div>
        <div v-else class="grey--text">N/A</div>
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import ButtonView from "@atoms/ButtonView.vue";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import { roundValue } from "@tod-ui/helpers/math";
import { smartUnitHumidityColor, smartUnitTemperatureColor } from "./helper";

export default {
  components: {
    CardLayout,
    ButtonView,
  },
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    tableData() {
      return {
        headers: [
          {
            text: "Details",
            align: "start",
            filterable: false,
            sortable: false,
            class: "action-column",
            cellClass: "action-column",
            value: "actions",
          },
          { text: "Device #", value: "shortId" },
          { text: "Temperature °F", value: "temperature" },
          { text: "Humidity", value: "humidity" },
        ],
        items: this.devices,
      };
    },
  },
  methods: {
    goToDetailedReport(device) {
      this.$router.push({
        name: "ClimateHistoryReport",
        params: {
          device_id: device.id,
        },
        query: {
          device_short_id: device.shortId,
        },
      });
    },
    deviceWithOutTempAndHumidity(device) {
      return device?.temperature || device?.humidity;
    },
    convertCelsiusToFahrenheit,
    roundValue,
    smartUnitTemperatureColor,
    smartUnitHumidityColor,
  },
};
</script>
