import { readonly, ref } from "vue";

const $isPrinting = ref(false);
function usePrinting() {
  return {
    setPrinting: (val) => ($isPrinting.value = val),
    isPrinting: readonly($isPrinting),
  };
}
export default usePrinting;
