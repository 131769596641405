<template>
  <div class="d-flex">
    <template v-if="hasMotionEnabled">
      <v-icon
        class="ml-2"
        :color="hasActiveMotion ? 'error' : 'success'"
        :title="motionStateTitle"
        >{{ motionStateIcon }}</v-icon
      >
    </template>

    <template v-if="hasEntryEnabled">
      <div class="ml-2">
        <IconDoor :open="hasActiveEntry" :title="entryStateTitle" />
      </div>
    </template>

    <template v-if="hasEntryEnabled">
      <div class="position-relative mt-1">
        <v-icon
          class="ml-2 pt-1"
          :color="hasWaterEntry ? 'error' : 'light'"
          :title="waterStateTitle"
          >mdi-wave</v-icon
        >
        <v-icon
          class="ml-2 pt-1 icon-position-absolute"
          :color="hasWaterEntry ? 'error' : 'light'"
          :title="waterStateTitle"
          >mdi-wave</v-icon
        >
        <v-icon
          class="ml-2 mb-1 icon-position-absolute"
          :color="hasWaterEntry ? 'error' : 'light'"
          :title="waterStateTitle"
          :small="small"
        >
          fas fa-raindrops</v-icon
        >
      </div>
    </template>

    <template v-if="hasServiceRequestsEnabled">
      <v-icon
        class="ml-2"
        :color="hasActiveServiceRequest ? 'error' : 'success'"
        :title="serviceRequestStateTitle"
        >{{ serviceRequestStateIcon }}</v-icon
      >
    </template>
  </div>
</template>

<script>
import IconDoor from "@atoms/IconDoor.vue";

export default {
  name: "ZoneStatusCluster",
  components: {
    IconDoor,
  },
  props: {
    profile: {
      type: Object,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    incidents: {
      type: Array,
      required: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // Entry Config & State
    hasEntryEnabled() {
      const { entryConfig } = this.profile;
      return (entryConfig && !entryConfig.disabled) || false;
    },
    hasActiveEntry() {
      return this.devices.some(({ observableState }) =>
        ["door_opened"].includes(observableState)
      );
    },
    hasWaterEntry() {
      return this.devices.some(({ observableState }) =>
        ["water_detected"].includes(observableState)
      );
    },

    entryStateIcon() {
      return this.hasActiveEntry ? "fas fa-door-open" : "fas fa-door-closed";
    },
    waterStateTitle() {
      return this.hasWaterEntry ? "Water Detected" : "No Water";
    },
    entryStateTitle() {
      return this.hasActiveEntry ? "Open" : "Closed";
    },
    // Motion Config & State
    hasMotionEnabled() {
      const motion = this.profile.motionConfigs?.find(
        (c) => c.mode === "motion"
      );
      return (motion && !motion.disabled) || false;
    },
    hasActiveMotion() {
      return this.incidents.some(({ type }) => ["motion"].includes(type));
    },
    motionStateIcon() {
      return "mdi-motion-sensor";
    },
    motionStateTitle() {
      return this.hasActiveMotion ? "Motion Detected" : "No Motion";
    },
    // Service Request Config & State
    hasServiceRequestsEnabled() {
      const { serviceRequestConfig } = this.profile;
      return (serviceRequestConfig && !serviceRequestConfig.disabled) || false;
    },
    hasActiveServiceRequest() {
      return this.incidents.some(({ type }) =>
        ["service_request"].includes(type)
      );
    },
    serviceRequestStateIcon() {
      return "mdi-gesture-tap";
    },
    serviceRequestStateTitle() {
      return this.hasActiveServiceRequest
        ? "Service Requested"
        : "No Active Requests";
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}
.icon-position-absolute {
  position: absolute;
  left: 0px;
  top: -6px;
}
</style>
