import { unref, computed, ref } from "vue";

import { GET_DEVICE, UPDATE_DEVICE } from "./graphql";
import { addOrganizationDevices } from "../../graphql/organizations/index";

import { apolloWatchQuery } from "../../apollo-client/helper";

import useFacility from "../authentication/useFacility";
import useInventory from "./useInventory";

import { formatDeviceName } from "../common/helpers/devices";

const selectedUnits = ref({});

export default function useDevice(initialDevice, deviceOrganizationId = null) {
  const device = ref(initialDevice);
  const deviceName = computed(() =>
    formatDeviceName(unref(device), deviceOrganizationId)
  );
  const devicePending = computed(
    () =>
      device.value?.disposition === "shipping" ||
      device.value?.disposition === "under_maintenance" ||
      device.value?.disposition === "retired"
  );
  const { currentFacilityId } = useFacility();
  const { refetchInventory } = useInventory();

  async function fetchDevice(apolloClient, deviceId) {
    apolloWatchQuery(
      apolloClient,
      {
        query: GET_DEVICE,
        variables: { id: deviceId },
      },
      (data, loading) => {
        if (data?.device) {
          device.value = data.device;
        }
      }
    );
  }

  async function updateFacilityDeviceDisposition(apolloClient, disposition) {
    const { id, externalId, type } = unref(device);
    try {
      await apolloClient.mutate({
        mutation: UPDATE_DEVICE,
        variables: {
          id,
          externalId,
          type,
          organizationId: currentFacilityId.value,
          disposition: disposition,
        },
      });
      return `Device ${deviceName.value} updated successfully`;
    } catch (error) {
      throw `Unable to update device ${deviceName.value}`;
    }
  }

  async function moveDevice(apolloClient, unitId = null) {
    try {
      const response = await apolloClient.mutate({
        mutation: addOrganizationDevices,
        variables: {
          devices: [device.value.id],
          id: unref(unitId || currentFacilityId),
        },
      });
      selectedUnits.value[device.value.id] = undefined;
      refetchInventory();
      return `Device ${deviceName.value} was successfully ${
        unitId ? "assigned to" : "removed from"
      } the Smart Unit.`;
    } catch (error) {
      // console.error(error);
      throw `Unable to ${unitId ? "assign" : "remove"} device ${
        deviceName.value
      }.`;
    }
  }

  function setSelectedUnit(id) {
    selectedUnits.value = { ...selectedUnits.value, [device.value.id]: id };
  }
  const selectedUnit = computed(() => selectedUnits.value[device.value.id]);

  return {
    device,
    deviceName,
    devicePending,
    fetchDevice,
    updateFacilityDeviceDisposition,
    moveDevice,
    setSelectedUnit,
    selectedUnit,
  };
}

export async function updateDeviceLocation({
  apolloClient,
  id,
  externalId,
  organizationId,
  type,
  specs,
}) {
  try {
    const response = await apolloClient.mutate({
      mutation: UPDATE_DEVICE,
      variables: {
        id,
        externalId,
        organizationId,
        type,
        specs,
      },
    });
    return response;
  } catch (error) {
    throw "Unable to add the device location";
  }
}
