<template>
  <div>
    <v-menu
      v-model="menuOpen"
      bottom
      right
      offset-y
      min-width="160px"
      max-height="85vh"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          aria-label="Unread Notifications"
          v-bind="attrs"
          class="pa-7"
          v-on="on"
        >
          <v-badge
            :content="
              unreadErrorNotifications.length > 0
                ? unreadErrorNotifications.length
                : undefined
            "
            :value="unreadNotifications.length > 0"
            :color="badgeColor"
            :dot="unreadErrorNotifications.length === 0"
            overlap
          >
            <v-icon small>fas fa-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-card>
        <template v-if="unreadNotifications.length > 0">
          <v-card-title>Notifications</v-card-title>

          <v-card-text>
            <v-divider />
            <v-list dense>
              <v-list-item
                v-for="item in unreadNotifications"
                :key="item.id"
                dense
              >
                <v-icon
                  left
                  small
                  :color="severityColor(item.severity)"
                  @click="gotoNotification(item)"
                >
                  fas fa-circle
                </v-icon>
                <v-list-item-title
                  class="subtitle-2 notification"
                  @click="gotoNotification(item)"
                >
                  {{ item.title }}
                </v-list-item-title>
                <v-list-item-action>
                  <NotificationActionButtons
                    icon
                    :notification="item"
                    @redirect="redirect"
                    @dismiss="dismiss"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider />
          </v-card-text>
        </template>
        <v-card-text v-else>
          <p>No unread notifications</p>
          <v-divider />
        </v-card-text>

        <v-card-actions>
          <v-btn
            text
            router
            :to="{ name: 'Notifications' }"
            :disabled="isPageNotifications"
          >
            View all
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import NotificationActionButtons from "./NotificationActionButtons.vue";
import { viewNotification, severityColor, templateLabel } from "./helper";
import useNotifications from "./useNotifications";

export default {
  name: "TheNotificationMenu",
  components: {
    NotificationActionButtons,
  },
  setup() {
    const {
      unreadNotifications,
      unreadErrorNotifications,
      fetchNotifications,
      redirectAndMarkAsRead,
      dismissNotification,
    } = useNotifications();
    return {
      unreadNotifications,
      unreadErrorNotifications,
      fetchNotifications,
      redirectAndMarkAsRead,
      dismissNotification,
    };
  },
  data: () => ({
    menuOpen: false,
  }),
  computed: {
    badgeColor() {
      return this.unreadErrorNotifications.length > 0 ? "error" : "info";
    },
    isPageNotifications() {
      return this.$route.name === "Notifications";
    },
  },
  beforeMount() {
    this.fetchNotifications(this.$apollo);
  },
  methods: {
    async redirect(notification) {
      this.menuOpen = false;
      await this.redirectAndMarkAsRead(this.$apollo, notification);
    },
    async dismiss(notification) {
      await this.dismissNotification(this.$apollo, notification);
    },
    gotoNotification(notification) {
      const route = viewNotification(notification);
      this.$router.push(route);
    },
    severityColor,
    templateLabel,
  },
};
</script>

<style scoped>
.notification {
  cursor: pointer;
}
</style>
