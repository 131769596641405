<template>
  <v-skeleton-loader
    :loading="loading"
    type="table-thead, table-row-divider@10"
  >
    <v-data-table
      :headers="tableHeaders"
      :items="facilityVacantUnitIncidentsReport"
      :items-per-page="5"
      class="elevation-1"
    >
      <template #item.organizationName="{ item }">
        {{ getOrganizationName(item.organizationName) }}
      </template>
      <template #item.totalVacantUnits="{ item }">
        {{ item.zoneName }}
      </template>
      <template #item.movedOutOn="{ item }">
        {{ formatDate(item.movedOutOn) }}
      </template>
      <template #item.suspiciousIncidents="{ item }">
        {{ formatDate(item.incidentAfterMoveOutOn) }}
      </template>
      <template #item.purposeOfVisit="{ item }">
        {{ titleCase(item.purposeOfVisit) }}
      </template>
      <template #item.cleanedOn="{ item }">
        {{ formatDate(item.cleanedOn) }}
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";
import { FACILITY_VACANT_UNIT_INCIDENTS_REPORT } from "./graphql";

export default {
  name: "FacilityViewTable",
  props: {
    tableHeaders: {
      type: Array,
      required: true,
    },
    selectedFacilities: {
      type: Array,
      required: true,
    },
    selectedDuration: {
      type: Number,
      required: true,
      default: 30,
    },

    dateTime: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loading() {
      return this.$apollo.queries.facilityVacantUnitIncidentsReport.loading;
    },
  },
  apollo: {
    facilityVacantUnitIncidentsReport: {
      query: FACILITY_VACANT_UNIT_INCIDENTS_REPORT,
      variables() {
        return {
          cleanedWithinDays: this.selectedDuration || 30,
          dateFilter: this.dateTime,
          facilityIds: this.selectedFacilities.map((f) => f.id),
        };
      },
      skip() {
        return !this.selectedFacilities.length;
      },
    },
  },

  methods: {
    titleCase,
    getOrganizationName(fullName) {
      if (!fullName) return "";
      return fullName.split(" -> ")[0];
    },
    formatDate(date) {
      if (!date) return "";
      return new Intl.DateTimeFormat("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(date));
    },
  },
};
</script>
