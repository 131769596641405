import gql from "graphql-tag";
import {
  organizationFields,
  locationFields,
  schedulingWindowFields,
} from "../../graphql/fragments";

export const FACILITY_DETAILS = gql`
  query FacilityDetails($id: ID!) {
    facilityDetails: organization(id: $id) {
      ...OrganizationFields
      location {
        ...LocationFields
      }
      shippingAddress {
        ...LocationFields
      }
      profile {
        ... on FacilityProfile {
          email
          phoneNumber
          isCustomOfficeSchedule
          numberOfUnits
          numberOfFloors
        }
      }
      monitoringSchedule {
        sunday {
          ...SchedulingWindowFields
        }
        monday {
          ...SchedulingWindowFields
        }
        tuesday {
          ...SchedulingWindowFields
        }
        wednesday {
          ...SchedulingWindowFields
        }
        thursday {
          ...SchedulingWindowFields
        }
        friday {
          ...SchedulingWindowFields
        }
        saturday {
          ...SchedulingWindowFields
        }
        lateCustomerMargin
      }
      officeSchedule {
        sunday {
          ...SchedulingWindowFields
        }
        monday {
          ...SchedulingWindowFields
        }
        tuesday {
          ...SchedulingWindowFields
        }
        wednesday {
          ...SchedulingWindowFields
        }
        thursday {
          ...SchedulingWindowFields
        }
        friday {
          ...SchedulingWindowFields
        }
        saturday {
          ...SchedulingWindowFields
        }
        alertResumptionMargin
      }
      unitTypes {
        id
        name
        width
        length
        externalId
        units {
          id
          name
          billingAccounts(type: "consumer") {
            id
            type
            status
          }
          devices {
            id
            shortId
          }
        }
      }
      organizationDetails {
        unitDesign
      }
    }
  }
  ${organizationFields}
  ${locationFields}
  ${schedulingWindowFields}
`;

export const CREATE_UNIT_TYPE = gql`
  mutation CreateUnitType(
    $organizationId: ID!
    $name: String!
    $width: Float
    $length: Float
  ) {
    createUnitType(
      organizationId: $organizationId
      name: $name
      width: $width
      length: $length
    ) {
      id
      name
      width
      length
    }
  }
`;

export const UPDATE_UNIT_TYPES = gql`
  mutation UpdateUnitType(
    $id: ID!
    $organizationId: ID!
    $name: String!
    $width: Float
    $length: Float
  ) {
    updateUnitType(
      id: $id
      organizationId: $organizationId
      name: $name
      width: $width
      length: $length
    ) {
      id
      name
      width
      length
    }
  }
`;
