<template>
  <v-container class="d-flex justify-center">
    <div class="chart-wrapper">
      <div class="legend">
        <div
          v-for="(item, index) in chartData.data"
          :key="index"
          class="legend-item"
        >
          <div
            class="legend-color"
            :style="{ backgroundColor: chartData.backgroundColors[item.label] }"
          ></div>
          <div class="legend-label">{{ item.label }}</div>
        </div>
      </div>
      <div class="pie-chart-container">
        <div
          v-for="(quadrant, index) in quadrants"
          :key="index"
          class="quadrant"
          :style="{ backgroundColor: getQuadrantColor(quadrant.label) }"
          @mouseover="hoveredQuadrant = index"
          @mouseleave="hoveredQuadrant = -1"
          @click="emitData(quadrant)"
        >
          <div v-if="hoveredQuadrant === index" class="tooltip">
            {{ quadrant.label }} : {{ quadrant.count }}
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hoveredQuadrant: -1,
    };
  },
  computed: {
    quadrants() {
      return this.chartData.data.map((item) => ({
        label: item.label,
        count: item.value,
      }));
    },
  },
  methods: {
    getQuadrantColor(label) {
      return this.chartData.backgroundColors[label] || "";
    },
    emitData(quadrant) {
      this.$emit("quadrantData", quadrant);
    },
  },
};
</script>

<style scoped>
.chart-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.legend-color {
  width: 40px;
  height: 10px;
  margin-right: 5px;
}

.legend-label {
  font-size: 12px;
}

.pie-chart-container {
  position: relative;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
}

.quadrant {
  width: 50%;
  height: 100%;
  position: relative;
  transform-origin: center;
}

.tooltip {
  width: 80px;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 600px) {
  .pie-chart-container {
    width: 250px;
    height: 250px;
  }

  .legend-item {
    margin-right: 5px;
  }

  .legend-color {
    width: 20px;
    height: 10px;
  }

  .legend-label {
    font-size: 10px;
  }

  .tooltip {
    font-size: 10px;
  }
}
</style>
