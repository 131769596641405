<template>
  <v-row :dense="oneLine">
    <v-col v-if="icon" md="1">
      <v-icon>{{ icon }}</v-icon>
    </v-col>
    <v-col v-if="oneLine">
      {{ location.inCareOf ? `${location.inCareOf}, ` : ""
      }}{{ location.street }}, {{ location.city }} {{ location.state }},
      {{ location.postalCode }}
    </v-col>
    <v-col v-else>
      <template v-if="location.inCareOf">
        {{ location.inCareOf }}<br />
      </template>
      {{ location.street }}<br />
      {{ location.city }} {{ location.state }}, {{ location.postalCode }}
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "LocationAddress",
  props: {
    location: {
      type: Object,
      required: true,
    },
    icon: {
      type: String,
      default: "",
    },
    oneLine: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    address() {
      return (
        this.organization?.shippingAddress || this.organization?.location || {}
      );
    },
  },
};
</script>
