<template>
  <CardLayout title="Attachments">
    <v-row>
      <v-col cols="12">
        <AlertsAnimated class="mt-4" :alerts="localAlerts" />
      </v-col>
      <v-col cols="6">
        <v-file-input
          v-model="selectedFile"
          label="Upload File"
          accept="application/pdf, image/jpeg, image/png"
          :rules="[
            (v) =>
              !v ||
              v.size <= 10 * 1024 * 1024 ||
              'File size should be less than 10MB',
          ]"
          @change="handleFileChange"
        />
      </v-col>
      <v-col cols="6" class="mt-4">
        <v-btn
          color="primary"
          :disabled="!selectedFile || loading"
          small
          @click="addAttachment"
        >
          <v-icon left small>mdi-plus-circle</v-icon>
          Add
        </v-btn>
      </v-col>
      <v-row>
        <v-col v-for="attachment in attachments" :key="attachment.id" cols="2">
          <v-card class="attachment-card">
            <div class="attachment-container">
              <div
                v-if="!isImage(attachment.file)"
                class="attachment-image-wrapper file-icon"
                @mouseover="showIcon = attachment.id"
                @mouseleave="showIcon = null"
              >
                <v-icon> mdi-file-document-outline </v-icon>
                <v-icon
                  v-if="showIcon === attachment.id"
                  class="zoom-icon"
                  @click="downloadFile(attachment.fileUrl)"
                >
                  mdi-download
                </v-icon>
              </div>
              <div
                v-else
                class="attachment-image-wrapper"
                @mouseover="showIcon = attachment.id"
                @mouseleave="showIcon = null"
              >
                <v-img
                  :src="attachment.fileUrl"
                  :alt="attachment.file"
                  height="100"
                  width="100"
                  contain
                  class="attachment-image"
                />
                <v-icon
                  v-if="showIcon === attachment.id"
                  class="zoom-icon"
                  @click="openImagePreview(attachment.fileUrl)"
                >
                  mdi-magnify
                </v-icon>
              </div>
              <v-btn
                icon
                x-small
                outlined
                color="error"
                class="delete-btn"
                @click.stop="deleteAttachment(attachment.id)"
              >
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
    <DialogLayout
      v-model="isPreviewModalOpen"
      :title="'Image Preview'"
      max-width="100%"
    >
      <v-card>
        <v-card-text>
          <v-img
            :src="previewImageUrl"
            height="100%"
            width="100%"
            contain
            alt="Preview Image"
          />
        </v-card-text>
        <v-card-actions class="flex-container-end">
          <v-btn color="primary" @click="downloadFile(previewImageUrl)">
            Download
          </v-btn>
        </v-card-actions>
      </v-card>
    </DialogLayout>
  </CardLayout>
</template>

<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import DialogLayout from "@layout/DialogLayout.vue";
import { ADD_TASK_ATTACHMENT, DELETE_TASK_ATTACHMENT } from "./graphql";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import CardLayout from "@layout/CardLayout.vue";

export default {
  components: {
    AlertsAnimated,
    CardLayout,
    DialogLayout,
  },

  props: {
    taskId: {
      type: String,
      required: true,
    },
    initialAttachments: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const { addLocalAlert, clearAllAlerts, localAlerts } = useAlerts();
    return { addLocalAlert, clearAllAlerts, localAlerts };
  },

  data() {
    return {
      attachments: this.initialAttachments,
      selectedFile: null,
      loading: false,
      showIcon: null,
      isPreviewModalOpen: false,
      previewImageUrl: "",
    };
  },

  methods: {
    handleFileChange(file) {
      if (file && file.size > 10 * 1024 * 1024) {
        this.addLocalAlert({
          type: "error",
          message: "File is too large. Maximum size is 10MB.",
          timeout: 15,
        });
        this.selectedFile = null;
      }
    },
    async addAttachment() {
      if (!this.selectedFile) return;

      this.clearAllAlerts();
      this.loading = true;

      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_TASK_ATTACHMENT,
          variables: {
            taskId: this.taskId,
            file: this.selectedFile,
          },
        });

        this.attachments.push(data.addTaskAttachment);
        this.selectedFile = null;
        this.addLocalAlert({
          type: "success",
          message: "Attachment added successfully",
          timeout: 15,
        });
      } catch (error) {
        console.error("Error adding attachment:", error);
        this.addLocalAlert({
          type: "error",
          message: "Error adding attachment",
          timeout: 15,
        });
      } finally {
        this.loading = false;
      }
    },

    async deleteAttachment(attachmentId) {
      this.clearAllAlerts();
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: DELETE_TASK_ATTACHMENT,
          variables: {
            id: attachmentId,
          },
        });

        this.attachments = this.attachments.filter(
          (attachment) => attachment.id !== attachmentId
        );
        this.addLocalAlert({
          type: "success",
          message: "Attachment deleted successfully",
          timeout: 15,
        });
      } catch (error) {
        console.error("Error deleting attachment:", error);
        this.addLocalAlert({
          type: "error",
          message: "Error deleting attachment",
          timeout: 15,
        });
      } finally {
        this.loading = false;
      }
    },

    isImage(filename) {
      const imageExtensions = ["jpg", "jpeg", "png", "gif"];
      const extension = filename.split(".").pop().toLowerCase();
      return imageExtensions.includes(extension);
    },
    openImagePreview(fileUrl) {
      this.previewImageUrl = fileUrl;
      this.isPreviewModalOpen = true;
    },
    downloadFile(fileUrl) {
      fetch(fileUrl)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          const fileName = fileUrl.split("/").pop();
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Download failed", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-card {
  width: 100px;
  height: 100px;
}

.attachment-container {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dashed;
  border-radius: 10px !important;
}

.file-icon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 35px;
}

.attachment-image {
  width: 100%;
  height: 100%;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 1;
  background-color: #ffffff;
}
.zoom-icon {
  position: absolute;
  top: 30%;
  left: 30%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
  font-size: 35px;
}
.flex-container-end {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  width: 100%;
}
.attachment-image-wrapper:hover .zoom-icon {
  opacity: 1;
}
</style>
