<template>
  <LayoutPage title="Manage Tasks">
    <DialogViewTask
      v-if="isOpenViewTask"
      v-model="isOpenViewTask"
      :task-id="selectedTaskId"
    />
    <template #actions>
      <AddNewTask @task-added="refetchTasks" />
    </template>
    <v-row dense justify="end">
      <v-col v-if="!isFacilityManager" cols="12" md="6">
        <RegionAndFacilitySelect :facility-single-select="true" />
      </v-col>
      <v-col cols="12" md="3">
        <SelectOrganizationUsers
          v-model="selectedAssigneeId"
          :organization-id="defaultOrganization.id"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete
          v-model="selectedTaskStatus"
          label="Task Status"
          :items="taskStatusList"
          item-text="text"
          item-value="value"
          outlined
          dense
        />
      </v-col>
      <v-col cols="12" md="6">
        <div class="d-flex justify-end">
          <DateTimeFilter
            v-model="dateRange"
            :time-zone="timeZone"
            :ranges="['Today']"
            :max="maxDate"
            max-range="1m"
            custom
            inverted
          />
          <div class="ml-4">
            <SearchField v-model="tableState.search" />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-skeleton-loader
      :loading="tasksLoading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :headers="headers"
        :items="tableData"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 20, 50, -1] }"
      >
        <template #item="{ item }">
          <tr>
            <td>
              <ButtonView title="View" @click="openTaskDialog(item)" />
            </td>
            <td style="min-width: 150px">{{ item.taskId }}</td>
            <td style="max-width: 300px">
              <template v-if="!isFacilityManager">
                <div>
                  <strong>Organization</strong> :
                  {{ item.task_location.organization }}
                </div>
                <div class="mt-2">
                  <strong>Facility</strong> : {{ item.task_location.facility }}
                </div>
                <div class="mt-2">
                  <strong>Smart Unit / Zone</strong> :
                  {{ item.task_location.smart_unit }}
                </div>
              </template>
              <template v-else>
                {{ item.task_location.smart_unit }}
              </template>
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.type }}</td>
            <td>{{ item.assignee }}</td>
            <td>
              <div>
                {{ item.status }}
                <span v-if="item.overdue" class="overdue-text">Overdue!</span>
              </div>
            </td>
            <td>
              <SeverityChip :severity="item.priority" />
            </td>
            <td>
              {{ item.due_date }}
            </td>
          </tr>
        </template>
        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="tableData"
            :headers="csvHeaders"
            filename="Tasks Report"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import AddNewTask from "./AddNewTask.vue";
import RegionAndFacilitySelect from "@components/dashboard/RegionAndFacilitySelect.vue";
import { ref, computed } from "vue";
import SearchField from "@atoms/SearchField.vue";
import { RESOLUTION_STATUS } from "@tod-ui/constants/task_management";
import { TASKS } from "./graphql";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import useDataTable from "@components/common/useDataTable";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonView from "@atoms/ButtonView.vue";
import { titleCase } from "@tod-ui/helpers/strings";
import { formatDate } from "@tod-ui/helpers/datetime";
import useUser from "@components/authentication/useUser";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";
import DialogViewTask from "./DialogViewTask.vue";
import SelectOrganizationUsers from "@components/common/SelectOrganizationUsers.vue";
import { formatName } from "./helper";
import { stringSortCollator } from "@tod-ui/helpers/strings";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { DateTime } from "luxon";
import useDashboard from "@components/dashboard/useDashboard";

export default {
  name: "PageManageTasks",
  components: {
    LayoutPage,
    AddNewTask,
    RegionAndFacilitySelect,
    SearchField,
    ButtonExportCSV,
    ButtonPrint,
    ButtonView,
    SeverityChip,
    DialogViewTask,
    SelectOrganizationUsers,
    DateTimeFilter,
  },
  setup() {
    const { defaultOrganization, isFacilityManager } = useUser();
    const { selectedFacility } = useDashboard();

    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id"],
    });

    const isOpenViewTask = ref(false);
    const selectedTaskId = ref({});
    const selectedAssigneeId = ref(undefined);
    const dateRange = ref({ after: undefined, before: undefined });
    const maxDate = DateTime.now().plus({ years: 1 }).toISO();
    const timeZone = ref(defaultOrganization.timeZone);

    const taskStatusList = ref([
      { value: RESOLUTION_STATUS.TODO, text: "To Do" },
      { value: RESOLUTION_STATUS.IN_PROGRESS, text: "In Progress" },
      { value: RESOLUTION_STATUS.IN_REVIEW, text: "In Review" },
      { value: RESOLUTION_STATUS.DONE, text: "Done" },
      { value: RESOLUTION_STATUS.CANCELLED, text: "Cancelled" },
    ]);

    const selectedTaskStatus = ref(RESOLUTION_STATUS.TODO);
    const tableData = ref([]);

    const headers = ref([
      { text: "View Task", value: "" },
      { text: "Task ID", value: "taskId" },
      { text: "Task Location", value: "task_location", width: "150" },
      { text: "Task Name", value: "name" },
      { text: "Task Type", value: "type" },
      { text: "Assignee", value: "assignee", width: "150" },
      { text: "Task Status", value: "status" },
      { text: "Task Priority", value: "priority" },
      { text: "Task Due Date", value: "due_date", width: "150" },
    ]);

    const csvHeaders = computed(() => [
      { text: "Task ID", value: "taskId" },
      { text: "Organization", value: "organization" },
      { text: "Facility", value: "facility" },
      { text: "Smart Unit / Zone", value: "smart_unit" },
      { text: "Task Name", value: "name" },
      { text: "Task Type", value: "type" },
      { text: "Assignee", value: "assignee" },
      { text: "Task Status", value: "status" },
      { text: "Task Priority", value: "priority" },
      { text: "Task Due Date", value: "due_date" },
    ]);

    return {
      defaultOrganization,
      taskStatusList,
      selectedTaskStatus,
      tableData,
      headers,
      csvHeaders,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      isOpenViewTask,
      selectedTaskId,
      selectedAssigneeId,
      isFacilityManager,
      dateRange,
      maxDate,
      timeZone,
      selectedFacility,
    };
  },
  computed: {
    tasksLoading() {
      return this.$apollo.queries.tasks.loading;
    },
  },
  apollo: {
    tasks: {
      query: TASKS,
      variables() {
        let { after, before } = this.dateRange;

        if (after && before) {
          const [afterDate, beforeDate] = [after, before].map(DateTime.fromISO);
          if (
            afterDate.day === beforeDate.day &&
            afterDate.month === beforeDate.month &&
            afterDate.year !== beforeDate.year
          ) {
            const today = DateTime.now().startOf("day");
            [after, before] = [today.toISO(), today.endOf("day").toISO()];
          }
        }

        return {
          parentId: this.selectedFacility?.id,
          resolutions: [this.selectedTaskStatus],
          assigneeId: this.selectedAssigneeId,
          dateTimeFilter: { after, before },
        };
      },
      update({ tasks }) {
        this.tableData = tasks.map((task) => ({
          id: task.id,
          taskId: task.taskId,
          task_location: this.isFacilityManager
            ? { smart_unit: task.organization.name }
            : {
                organization: task.organization.organization[0].name,
                facility: task.organization.facility[0].name,
                smart_unit: task.organization.name,
              },
          organization: task.organization.organization[0].name,
          facility: task.organization.facility[0].name,
          smart_unit: task.organization.name,
          name: task.title,
          type: titleCase(task.taskType.name),
          assignee: formatName(task.assignee),
          status:
            task.resolution === RESOLUTION_STATUS.TODO
              ? "To Do"
              : titleCase(task.resolution),
          priority: titleCase(task.priority),
          due_date: formatDate(task.dueAt, this.defaultOrganization.timeZone),
          description: task.description,
        }));
      },
      skip() {
        return !this.selectedFacility?.id;
      },
    },
  },
  methods: {
    openTaskDialog(task) {
      this.selectedTaskId = task.id;
      this.isOpenViewTask = true;
    },
    customFilter(value, search, item) {
      if (!search) return true;
      const _search = search.toLowerCase();
      const match = [
        item.taskId,
        item.task_location.organization.toLowerCase(),
        item.task_location.facility.toLowerCase(),
        item.task_location.smart_unit.toLowerCase(),
        item.name.toLowerCase(),
        item.type.toLowerCase(),
        item.assignee.toLowerCase(),
        item.status.toLowerCase(),
        item.priority.toLowerCase(),
        item.due_date,
      ].join(" ");

      return match.indexOf(_search) >= 0;
    },
    customSort(items, sortBy, sortDesc) {
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0] ? -1 : 1;

      items.sort((a, b) => {
        if (typeof a[_sortBy] === "string") {
          return stringSortCollator.compare(a[_sortBy], b[_sortBy]) * _sortDesc;
        }
        return (b[_sortBy] < a[_sortBy] ? -1 : 1) * _sortDesc;
      });

      return items;
    },
    refetchTasks() {
      this.$apollo.queries.tasks.refetch();
    },
  },
};
</script>

<style scoped>
.overdue-text {
  color: red;
  font-weight: bold;
}
</style>
