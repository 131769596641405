import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router/composables";

export default function useRouteQuery() {
  const $route = useRoute();
  const $router = useRouter();
  const tab = ref(getQueryIntValue("t") ?? 0);
  const accordion = ref(getQueryIntValue("a") ?? undefined);

  watch(tab, (value) => {
    updateRouteQuery("t", value || undefined);
  });

  watch(accordion, (value) => {
    updateRouteQuery("a", value);
  });

  function getQueryIntValue(key) {
    const value = parseInt($route?.query[key]);
    return isNaN(value) ? null : value;
  }

  function updateRouteQuery(key, value) {
    const currentQuery = $route?.query || {};
    const updatedQuery = {
      ...currentQuery,
      [key]: typeof value === "number" ? "" + value : undefined,
    };
    if (JSON.stringify(currentQuery) !== JSON.stringify(updatedQuery)) {
      $router.replace({ query: updatedQuery });
    }
  }

  return {
    tab,
    accordion,
  };
}
