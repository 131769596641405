import { titleCase } from "@tod-ui/helpers/strings";

const avatarColors = [
  "primary",
  "secondary",
  "success",
  "error",
  "warning",
  "info",
  "purple",
  "pink",
  "indigo",
  "cyan",
  "teal",
  "orange",
  "brown",
  "blue-grey",
];

export function formatName(user) {
  if (!user) return "";
  const { firstName, lastName, roles } = user;
  const roleName = roles && roles[0] && roles[0].role ? roles[0].role.name : "";
  return `${firstName} ${lastName}${
    roleName ? ` (${titleCase(roleName)})` : ""
  }`;
}

export function formatFieldName(field) {
  if (field.toLowerCase() === "resolution") {
    return "Status";
  }
  return field
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function getTimelineColor(operation) {
  return operation === 0 ? "success" : "primary";
}

export function getOperationText(operation) {
  return operation === 0 ? "created" : "updated";
}

export function getInitials(user) {
  if (!user || !user.firstName || !user.lastName)
    return { initials: "", color: "primary" };
  const initials = `${user.firstName[0]}${user.lastName[0]}`.toUpperCase();
  const colorIndex =
    user.firstName.toLowerCase().charCodeAt(0) % avatarColors.length;
  return { initials, color: avatarColors[colorIndex] };
}
