<template>
  <v-dialog v-model="show" max-width="600px">
    <v-card>
      <v-card-title class="headline">
        Transfer device
        {{ deviceName }}
        to another Smart Unit
        <v-spacer />
        <v-btn icon @click="show = null">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        You are about to transfer the device
        <strong>{{ deviceName }}</strong> from unit
        <strong>{{ unitName }}</strong> to a new unit. <br />
        Please, make sure that you physically move the device before making this
        change in the system.
        <br />
        Select the new unit device is physically in:
        <AutocompleteInventoryUnits :device="device" :exclude="unitName" />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = null"> Cancel </v-btn>
        <v-btn color="primary" text @click="transferDevice"> Transfer </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed } from "vue";
import useDevice from "../useDevice";
import AutocompleteInventoryUnits from "./AutocompleteInventoryUnits.vue";

export default {
  name: "DialogUpdateDevice",
  components: { AutocompleteInventoryUnits },
  props: {
    device: {
      type: Object,
      required: true,
    },
    unitName: {
      type: String,
      default: "",
    },
    value: {
      validator: (val) =>
        val === null || (typeof val === "string" && val.length > 0),
      default: null,
    },
  },
  setup(props, { emit }) {
    const { moveDevice, selectedUnit, deviceName } = useDevice(props.device);
    const show = computed({
      get: () => props.value === "transfer-device",
      set: (value) => emit("input", value ? "transfer-device" : null),
    });
    const { addAlert } = useAlerts();
    return { moveDevice, deviceName, selectedUnit, show, addAlert };
  },
  methods: {
    async transferDevice() {
      if (!this.value || !this.selectedUnit || this.device.rmaRequest) return;
      try {
        const successMessage = await this.moveDevice(
          this.$apollo,
          this.selectedUnit
        );
        if (successMessage) {
          this.addAlert({
            type: "success",
            message: successMessage,
            timeout: 10,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
          timeout: 15,
        });
      }
      this.show = false;
    },
  },
};
</script>
