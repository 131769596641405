import PageSmartUnits from "./PageSmartUnits.vue";
import PageSmartUnitDrilldown from "./PageSmartUnitDrilldown.vue";
import PageSmartUnitDevices from "./PageSmartUnitDevices.vue";
import PageInventory from "./PageInventory.vue";
import PageSmartUnitAdd from "./PageSmartUnitAdd.vue";
import ClimateHistoryReport from "@components/reports/ClimateHistoryReport.vue";

const routes = {
  organization: [
    {
      path: "zones",
      name: "SmartUnits",
      component: PageSmartUnits,
    },
    {
      path: "zones/:zone_id/renters/:renter_id",
      name: "AssignedSmartUnitDrilldown",
      component: PageSmartUnitDrilldown,
    },
    {
      path: "zones/:zone_id/devices",
      name: "ManageSmartUnitDevices",
      component: PageSmartUnitDevices,
    },

    {
      path: "inventory",
      name: "Inventory",
      component: PageInventory,
    },
    {
      path: "inventory/new",
      name: "AddSmartUnit",
      component: PageSmartUnitAdd,
    },
    {
      path: "renters/:renter_id/zones/new",
      name: "AddAssignSmartUnit",
      component: PageSmartUnitAdd,
    },
    {
      path: "inventory/:zone_id",
      name: "ViewSmartUnit",
      component: PageSmartUnitDrilldown,
    },
    {
      path: "climate_history_report/:device_id",
      name: "ClimateHistoryReport",
      component: ClimateHistoryReport,
    },
  ],
};

export default routes;
