<template>
  <DialogLayout v-model="isOpen" title="New Task">
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="taskName" label="Task Name" clearable />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="assignTo"
                :items="['Helen(Facility Manager)', 'John (Facility Admin)']"
                label="Assign To"
              />
            </v-col>
            <v-col>
              <v-select
                v-model="priority"
                :items="priorities"
                label="Priority"
                :readonly="!!incidentId"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-if="!isIncidentResolutionOrCreateNew"
                v-model="type"
                :items="types"
                label="Type"
              />
              <div v-if="type === 'Incident Resolution'">
                <v-text-field
                  v-model="type"
                  label="Type"
                  style="min-width: 190px; position: relative"
                  :readonly="!!incidentId"
                />
                <v-text-field
                  v-model="searchQuery"
                  label="Incident Id"
                  style="min-width: 190px; position: relative"
                  :readonly="!!incidentId"
                />
                <v-list
                  v-if="searchResults.length > 0"
                  style="
                    position: absolute;
                    background-color: white;
                    z-index: 1;
                    border: 1px solid black;
                  "
                >
                  <v-list-item
                    v-for="(incident, index) in searchResults"
                    :key="index"
                    @click="selectIncident(incident)"
                  >
                    <v-list-item-content>
                      <div class="d-flex flex-column">
                        <strong style="font-size: 12px">{{
                          incident.id
                        }}</strong>
                        <p style="font-size: 15px; white-space: pre-line">
                          {{ incident.title }}
                        </p>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div v-if="type === 'Create New'">
                <v-text-field
                  v-model="newTypeName"
                  label="Type Name"
                  style="min-width: 190px"
                />
                <div class="d-flex justify-end">
                  <v-btn small fab @click="saveNewType">
                    <v-icon color="primary">mdi-check</v-icon>
                  </v-btn>
                  <v-btn small fab @click="cancelNewType">
                    <v-icon color="error">mdi-window-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Due Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-if="!incidentId"
                  v-model="date"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-model="comments"
                label="Task Description"
                outlined
                row="1"
              />
              <input type="file" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <div class="d-flex justify-center">
        <v-btn color="primary" @click="addTask">Save</v-btn>
      </div>
    </v-card>
  </DialogLayout>
</template>

<script>
import DialogLayout from "../DemoDialogLayout.vue";

export default {
  components: {
    DialogLayout,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    dueDate: {
      type: String,
      default: "",
    },
    severity: {
      type: String,
      default: "",
    },
    incidentId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      taskId: 3,
      taskName: "",
      searchQuery: "",
      searchResults: [],
      allIncidents: [
        {
          id: "VSU-DT-001",
          title: "Vacant Smart Unit Activity During Day in unit 101",
        },
      ],
      priorities: ["Low", "Moderate", "Severe", "Critical"],
      priority: this.severity,
      types: [
        "Lock Check/Over Lock",
        "Clean Up",
        "Maintenance",
        "Auction",
        "Incident Resolution",
        "Create New",
      ],
      type: "",
      newTypeName: "",
      assignTo: "",
      comments: "",
      date: this.dueDate ? this.dueDate : "",
      menu: false,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isIncidentResolutionOrCreateNew() {
      return this.type === "Incident Resolution" || this.type === "Create New";
    },
  },
  watch: {
    dueDate: {
      handler(newVal) {
        this.date = newVal;
      },
      deep: true,
    },
    searchQuery(newVal) {
      this.searchIncidents();
    },
  },
  mounted() {
    if (this.incidentId) {
      this.type = "Incident Resolution";
      this.searchQuery = this.incidentId;
    }
  },
  methods: {
    searchIncidents() {
      if (this.searchQuery === "") {
        // If search query is empty, display all incidents
        this.searchResults = [];
      } else {
        // Filter incidents based on search query
        this.searchResults = this.allIncidents.filter((incident) =>
          incident.title.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    selectIncident(incident) {
      this.searchResults = [];
      this.searchQuery = incident.id;
    },
    convertDateToDisplay(dateStr) {
      const [month, day, year] = dateStr.split("-");
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    },
    addTask() {
      this.isOpen = false;

      const newTask = {
        id: `INC00${this.taskId++}`,
        name: this.taskName,
        type: this.type,
        priority: this.priority,
        assignee: this.assignTo,
        dueDate: this.convertDateToDisplay(this.date),
        comments: this.comments,
      };

      // Push the new task to the tasks array
      this.$emit("add-task", newTask);

      // Reset form fields
      this.taskName = "";
      this.priority = "";
      this.assignTo = "";
      this.comments = "";
    },
    saveNewType() {
      if (this.newTypeName) {
        this.types.splice(this.types.length - 1, 0, this.newTypeName);
        this.type = this.newTypeName;
        this.newTypeName = "";
      }
    },
    cancelNewType() {
      this.type = "";
      this.newTypeName = "";
    },
  },
};
</script>

<style scoped></style>
