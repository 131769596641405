import jwt_decode from "jwt-decode";

// Name of the localStorage item
const APOLLO_AUTH_TOKEN = "apollo-token";

export function setToken(token) {
  if (typeof localStorage !== "undefined" && token) {
    // console.log(
    //   "[Debug] token exp:",
    //   new Date(jwt_decode(token)["exp"] * 1000)
    // );
    localStorage.setItem(APOLLO_AUTH_TOKEN, token);
  }
}

export function getToken() {
  if (typeof localStorage !== "undefined") {
    const token = localStorage.getItem(APOLLO_AUTH_TOKEN);
    return isTokenExpired(token) ? null : token;
  }
}

export function clearToken() {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem(APOLLO_AUTH_TOKEN);
  }
}

export function isTokenExpired(token) {
  if (token) {
    try {
      const dateInSec = Date.now() / 1000;
      const tokenExpiration = jwt_decode(token)["exp"];
      if (tokenExpiration > dateInSec) return false;
    } catch (error) {
      return true;
    }
  }
  return true;
}
