<template>
  <v-app id="app">
    <TheHeader v-if="authenticated" />
    <TheSideMenu v-if="authenticated" />
    <v-main>
      <v-container :class="{ 'fill-height': !authenticated }" fluid>
        <v-skeleton-loader v-if="pendingReload" type="heading, table-tbody" />
        <router-view v-else />
      </v-container>
    </v-main>
    <TheFooter />
  </v-app>
</template>

<script>
import "./scss/app.scss";
import TheHeader from "@layout/TheHeader.vue";
import TheFooter from "@layout/TheFooter.vue";
import TheSideMenu from "@layout/TheSideMenu.vue";
import useUser from "./components/authentication/useUser";
import useFacility from "./components/authentication/useFacility";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    TheSideMenu,
  },
  // beforeRouteUpdate(to, from, next) {
  //   console.log("on before route update");
  //   let nextRoute = null;
  //   if (!this.authenticated) {
  //     nextRoute = { name: "Login" };
  //   } else if (
  //     !to?.name ||
  //     to.name === "Login" ||
  //     (this.isFacilityManager && to.name === "ManagementDashboard")
  //   ) {
  //     nextRoute = this.lastRoute;
  //   }
  //   if (
  //     nextRoute &&
  //     ((to.name !== nextRoute.name && !nextRoute.params?.organization_id) ||
  //       nextRoute.params?.organization_id !== to.params?.organization_id)
  //   ) {
  //     next(nextRoute);
  //   } else {
  //     next();
  //   }
  // },
  setup() {
    const {
      authenticated,
      isFacilityManager,
      defaultOrganization,
      restoreCurrentUser,
    } = useUser();
    const {
      currentFacilityId,
      redirectToDashboard,
      selectFacility,
      restoreCurrentFacilityId,
    } = useFacility();

    if (!authenticated.value) {
      restoreCurrentUser();
    }

    return {
      authenticated,
      isFacilityManager,
      defaultOrganization,
      selectFacility,
      currentFacilityId,
      redirectToDashboard,
      restoreCurrentFacilityId,
    };
  },
  computed: {
    pendingReload() {
      //TODO: Make global property directly reactive
      return this.$pendingReload.value;
    },
  },
  watch: {
    redirectToDashboard(val) {
      if (val) {
        this.$router.replace({
          name: "FacilityDashboard",
          params: { organization_id: this.currentFacilityId },
        });
      }
    },
  },
  beforeMount() {
    if (this.authenticated) {
      const facility = this.isFacilityManager
        ? this.defaultOrganization
        : {
            id: this.restoreCurrentFacilityId(
              this.$route?.params?.organization_id
            ),
          };

      this.selectFacility(this.$apollo, facility);
    }
  },
};
</script>
