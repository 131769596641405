<template>
  <v-row>
    <v-col cols="12">
      <div class="text-h6">
        {{ selectedClimateType === "TEMPERATURE" ? "Temperature" : "Humidity" }}
      </div>
    </v-col>
    <v-col cols="5">
      <template v-if="loading">
        <v-skeleton-loader
          v-for="n in 5"
          :key="n"
          type="card"
          class="mb-2"
          height="80"
          :boilerplate="true"
        />
      </template>
      <template v-else-if="facilityZoneClimateSummary.length == 0">
        <em> No Data Available </em>
      </template>
      <template v-else>
        <CardZoneClimate
          v-for="zone in facilityZoneClimateSummary"
          :key="zone.zoneId"
          :zone="zone"
          :type="selectedClimateType"
          :is-selected="selectedZone?.zoneId === zone.zoneId"
          @select="handleCardZoneSelected"
        />
      </template>
    </v-col>
    <v-col cols="7">
      <template
        v-if="selectedDeviceId && selectedClimateType === 'TEMPERATURE'"
      >
        <ZoneTemperatureChart
          :device-id="selectedDeviceId"
          :date-time-filter="dateTimeFilter"
          :zone-profile="selectedZone?.organization?.profile"
        />
      </template>
      <template
        v-else-if="selectedDeviceId && selectedClimateType === 'HUMIDITY'"
      >
        <ZoneHumidityChart
          :device-id="selectedDeviceId"
          :date-time-filter="dateTimeFilter"
          :zone-profile="selectedZone?.organization?.profile"
        />
      </template>
      <template v-else>
        <em> No Data Available </em>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { computed, ref } from "vue";
import useDashboard from "../useDashboard";
import { FACILITY_ZONE_CLIMATE_SUMMARY } from "./graphql";
import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";
import {
  smartUnitHumidityColor,
  smartUnitTemperatureColor,
} from "@components/smart_units/helper";
import { roundValue } from "@tod-ui/helpers/math";
import CardZoneClimate from "./CardZoneClimate.vue";
import ZoneTemperatureChart from "./ZoneTemperatureChart.vue";
import ZoneHumidityChart from "./ZoneHumidityChart.vue";

export default {
  components: {
    CardZoneClimate,
    ZoneTemperatureChart,
    ZoneHumidityChart,
  },
  props: {
    dateTimeFilter: {
      type: Object,
      default: () => ({
        before: null,
        after: null,
      }),
    },
    selectedClimateType: {
      type: String,
      default: "TEMPERATURE",
    },
  },
  setup() {
    const { selectedFacilities } = useDashboard();
    const facilityZoneClimateSummary = ref([]);
    const selectedDeviceId = ref(null);
    const selectedZone = ref(null);

    const selectedFacilityIds = computed(() =>
      selectedFacilities.value.map((facility) => facility.id)
    );

    function handleCardZoneSelected(zone) {
      selectedDeviceId.value = zone?.organization?.devices[0]?.id;
      selectedZone.value = zone;
    }

    return {
      selectedFacilities,
      selectedFacilityIds,
      facilityZoneClimateSummary,
      convertCelsiusToFahrenheit,
      smartUnitTemperatureColor,
      roundValue,
      smartUnitHumidityColor,
      selectedDeviceId,
      selectedZone,
      handleCardZoneSelected,
    };
  },
  computed: {
    loading() {
      return (
        this.$apollo.queries.facilityZoneClimateSummary.loading ||
        this.selectedFacilityIds.length === 0
      );
    },
  },
  apollo: {
    facilityZoneClimateSummary: {
      query: FACILITY_ZONE_CLIMATE_SUMMARY,
      variables() {
        return {
          climateType: this.selectedClimateType,
          facilityIds: this.selectedFacilityIds,
          limit: 5,
          dateTimeFilter: this.dateTimeFilter,
          deviceType: "xp",
          incidentType: this.selectedClimateType.toLowerCase(),
        };
      },
      update({ facilityZoneClimateSummary }) {
        this.facilityZoneClimateSummary = facilityZoneClimateSummary;
      },
      skip() {
        return this.selectedFacilityIds.length === 0;
      },
    },
  },
  watch: {
    facilityZoneClimateSummary: {
      handler(newVal) {
        if (newVal && newVal.length > 0 && !this.selectedZone) {
          this.selectedDeviceId = newVal[0]?.organization?.devices[0]?.id;
          this.selectedZone = newVal[0];
        }
      },
      immediate: true,
    },
  },
};
</script>
