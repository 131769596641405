import gql from "graphql-tag";

export const AUDIT_LOGS = gql`
  query AuditLogs(
    $facilityId: ID!
    $userId: ID
    $dateTimeFilter: DateTimeFilter!
  ) {
    auditLogs(
      facilityId: $facilityId
      userId: $userId
      dateTimeFilter: $dateTimeFilter
      sort: "id desc"
    ) {
      auditOrganizationId
      data
      entity
      entityId
      facilityId
      facilityName
      id
      insertedAt
      operation
      organizationId
      organizationName
      organizationSubtype
      organizationType
      updatedBy
      actorName
      name
      role
      type
      status
      changes
      unit_type_change
      primary_zone_change
    }
  }
`;
