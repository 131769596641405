<template>
  <LayoutPage title="Manage Facility" :loading="loading">
    <v-tabs v-model="tab">
      <v-tab>Profile</v-tab>
      <v-tab v-if="facilityFeatureEnabled('smart_zones')">Smart Zones</v-tab>
      <v-tab>Facility Escalation</v-tab>
      <v-tab>Unit Types</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <FacilityProfile :facility="facilityDetails" :loading="loading" />
      </v-tab-item>
      <v-tab-item v-if="facilityFeatureEnabled('smart_zones')">
        <EditFacilitySmartZones :facility="facilityDetails" />
      </v-tab-item>
      <v-tab-item>
        <FacilityResponders />
      </v-tab-item>
      <v-tab-item>
        <UnitTypes :facility="facilityDetails" :loading="loading" />
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import FacilityProfile from "./FacilityProfile.vue";
import EditFacilitySmartZones from "./EditFacilitySmartZones.vue";
import FacilityResponders from "./FacilityResponders.vue";
import UnitTypes from "./UnitTypes.vue";

import { FACILITY_DETAILS } from "./graphql";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useFacility from "../authentication/useFacility";

export default {
  name: "PageManageFacility",
  components: {
    LayoutPage,
    FacilityProfile,
    EditFacilitySmartZones,
    FacilityResponders,
    UnitTypes,
  },
  setup() {
    const { currentFacilityId, facilityFeatureEnabled } = useFacility();
    return { currentFacilityId, facilityFeatureEnabled };
  },
  data: () => ({
    facilityDetails: null,
    tab: null,
  }),
  computed: {
    loading() {
      return (
        this.facilityDetails === null ||
        this.$apollo.queries.facilityDetails.loading
      );
    },
  },
  apollo: {
    facilityDetails: {
      query: FACILITY_DETAILS,
      fetchPolicy: "no-cache",
      variables() {
        return {
          id: this.currentFacilityId,
        };
      },
      update({ facilityDetails }) {
        if (facilityDetails?.profile === null) {
          facilityDetails.profile = {};
        }

        if (facilityDetails?.location === null) {
          facilityDetails.location = {};
        }
        if (facilityDetails?.organizationDetails === null) {
          facilityDetails.organizationDetails = {};
        }

        return facilityDetails;
      },
    },
  },
};
</script>
