<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <CardDashboardTile
          top-title="Humidity"
          left-title="Extensive"
          right-title="Frequent"
          :left-value="comprehensiveStats.humidity.extensive"
          :right-value="comprehensiveStats.humidity.frequent"
          :loading="humidityLoading"
          :selected-value="
            selectedClimateType === 'HUMIDITY' ? selectedValue : ''
          "
          @click-top="handleClick('humidity', 'top')"
          @click-left="handleClick('humidity', 'extensive')"
          @click-right="handleClick('humidity', 'frequent')"
        />
      </v-col>
      <v-col cols="12" md="4">
        <CardDashboardTile
          top-title="Cold Temperature"
          left-title="Extensive"
          right-title="Frequent"
          :left-value="comprehensiveStats.lowTemperature.extensive"
          :right-value="comprehensiveStats.lowTemperature.frequent"
          :loading="lowTempLoading"
          :selected-value="
            selectedClimateType === 'LOW_TEMPERATURE' ? selectedValue : ''
          "
          @click-top="handleClick('lowTemperature', 'top')"
          @click-left="handleClick('lowTemperature', 'extensive')"
          @click-right="handleClick('lowTemperature', 'frequent')"
        />
      </v-col>
      <v-col cols="12" md="4">
        <CardDashboardTile
          top-title="High Temperature"
          left-title="Extensive"
          right-title="Frequent"
          :left-value="comprehensiveStats.highTemperature.extensive"
          :right-value="comprehensiveStats.highTemperature.frequent"
          :loading="highTempLoading"
          :selected-value="
            selectedClimateType === 'HIGH_TEMPERATURE' ? selectedValue : ''
          "
          @click-top="handleClick('highTemperature', 'top')"
          @click-left="handleClick('highTemperature', 'extensive')"
          @click-right="handleClick('highTemperature', 'frequent')"
        />
      </v-col>
    </v-row>
    <div class="mt-10">
      <ClimateComprehensiveChartData
        :loading="humidityLoading || lowTempLoading || highTempLoading"
        :climate-type="selectedClimateType"
        :date-filter="dateTimeFilter"
        :facility-ids="selectedFacilities.map((facility) => facility.id)"
        :report-interval="selectedReportInterval"
        :threshold="selectedThreshold"
        :category="selectedCategory"
      />
    </div>
  </div>
</template>

<script>
import { extractDateFromDateTimeFilter } from "@tod-ui/helpers/datetime";
import CardDashboardTile from "./CardDashboardTile.vue";
import { FACILITY_CLIMATE_STATS } from "./graphql";
import useDashboard from "../useDashboard";
import { ref } from "vue";
import ClimateComprehensiveChartData from "./ClimateComprehensiveChartData.vue";

export default {
  components: {
    CardDashboardTile,
    ClimateComprehensiveChartData,
  },
  props: {
    dateTimeFilter: {
      type: Object,
      default: () => ({
        after: "",
        before: "",
      }),
    },
  },
  setup() {
    const { selectedFacilities } = useDashboard();

    const comprehensiveStats = ref({
      humidity: { extensive: 0, frequent: 0 },
      highTemperature: { extensive: 0, frequent: 0 },
      lowTemperature: { extensive: 0, frequent: 0 },
    });
    const selectedValue = ref("left");

    const getReportInterval = (type, category) => {
      const setting = localStorage.getItem(`${category}${type}Freq`);
      return setting || "DAYS";
    };

    const getIncidentThreshold = (type, category) => {
      const setting = localStorage.getItem(`${category}${type}Incidents`);
      return parseInt(setting) || 0;
    };
    const selectedClimateType = ref("HUMIDITY");
    const selectedCategory = ref("extensive");
    const selectedReportInterval = ref(getReportInterval("Hum", "extensive"));
    const selectedThreshold = ref(getIncidentThreshold("Hum", "extensive"));

    const handleClick = (type, category) => {
      // Convert type to climate type enum
      const climateTypeMap = {
        humidity: "HUMIDITY",
        lowTemperature: "LOW_TEMPERATURE",
        highTemperature: "HIGH_TEMPERATURE",
      };
      selectedClimateType.value = climateTypeMap[type];
      selectedCategory.value = category;
      selectedValue.value =
        selectedCategory.value === "extensive" ? "left" : "right";
      selectedReportInterval.value = getReportInterval(
        type === "humidity" ? "Hum" : "Temp",
        category
      );
      selectedThreshold.value = getIncidentThreshold(
        type === "humidity" ? "Hum" : "Temp",
        category
      );
    };

    return {
      selectedFacilities,
      getReportInterval,
      getIncidentThreshold,
      comprehensiveStats,
      handleClick,
      selectedClimateType,
      selectedReportInterval,
      selectedThreshold,
      selectedCategory,
      selectedValue,
    };
  },
  computed: {
    humidityLoading() {
      return (
        this.$apollo.queries.extensiveHumidityStats.loading ||
        this.$apollo.queries.frequentHumidityStats.loading ||
        this.selectedFacilities.length === 0 ||
        !this.dateTimeFilter.after
      );
    },
    lowTempLoading() {
      return (
        this.$apollo.queries.extensiveLowTempStats.loading ||
        this.$apollo.queries.frequentLowTempStats.loading ||
        this.selectedFacilities.length === 0 ||
        !this.dateTimeFilter.after
      );
    },
    highTempLoading() {
      return (
        this.$apollo.queries.extensiveHighTempStats.loading ||
        this.$apollo.queries.frequentHighTempStats.loading ||
        this.selectedFacilities.length === 0 ||
        !this.dateTimeFilter.after
      );
    },
  },
  apollo: {
    // Extensive queries
    extensiveHumidityStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "HUMIDITY",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Hum", "extensive"),
          numberOfIncidents: this.getIncidentThreshold("Hum", "extensive"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Hum", "extensive");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.humidity.extensive =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },
    extensiveHighTempStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "HIGH_TEMPERATURE",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Temp", "extensive"),
          numberOfIncidents: this.getIncidentThreshold("Temp", "extensive"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Temp", "extensive");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.highTemperature.extensive =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },
    extensiveLowTempStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "LOW_TEMPERATURE",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Temp", "extensive"),
          numberOfIncidents: this.getIncidentThreshold("Temp", "extensive"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Temp", "extensive");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.lowTemperature.extensive =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },

    // Frequent queries
    frequentHumidityStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "HUMIDITY",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Hum", "frequent"),
          numberOfIncidents: this.getIncidentThreshold("Hum", "frequent"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Hum", "frequent");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.humidity.frequent =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },
    frequentHighTempStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "HIGH_TEMPERATURE",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Temp", "frequent"),
          numberOfIncidents: this.getIncidentThreshold("Temp", "frequent"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Temp", "frequent");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.highTemperature.frequent =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },
    frequentLowTempStats: {
      query: FACILITY_CLIMATE_STATS,
      variables() {
        return {
          climateType: "LOW_TEMPERATURE",
          dateFilter: extractDateFromDateTimeFilter(this.dateTimeFilter),
          facilityIds: this.selectedFacilities.map((facility) => facility.id),
          reportInterval: this.getReportInterval("Temp", "frequent"),
          numberOfIncidents: this.getIncidentThreshold("Temp", "frequent"),
        };
      },
      update(data) {
        const stats = data.facilityClimateStats;
        if (!stats) return;
        const threshold = this.getIncidentThreshold("Temp", "frequent");

        const facilitiesWithIncidents = new Set(
          stats
            .filter((s) => s.incidentCount >= threshold)
            .map((s) => s.facilityId)
        );

        this.comprehensiveStats.lowTemperature.frequent =
          facilitiesWithIncidents.size;
        return stats;
      },
      skip() {
        return !this.selectedFacilities.length || !this.dateTimeFilter?.after;
      },
    },
  },
};
</script>
