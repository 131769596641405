import PageManageFacility from "./PageManageFacility.vue";

const routes = {
  organization: [
    {
      path: "manage",
      name: "ManageFacility",
      component: PageManageFacility,
    },
  ],
};
export default routes;
