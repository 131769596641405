<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
import { formatDateTime } from "@tod-ui/helpers/datetime";

export default {
  name: "IncidentChart",
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
      required: true,
    },
    chartXAxes: {
      type: Array,
      default: null,
      required: false,
    },
    chartYAxes: {
      type: Array,
      default: null,
      required: false,
    },
    min: {
      type: [Number, String],
      default: null,
      required: true,
    },
    isMotion: {
      type: Boolean,
      default: false,
    },
    max: {
      type: [Number, String],
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    let xAxes = this.chartXAxes;
    if (null == xAxes) {
      xAxes = [
        {
          display: true,
          distribution: "linear",
          type: "time",
          time: {
            unit: "hour",
            displayFormats: {
              hour: "ddd hh:mm a",
            },
          },
        },
      ];
    }
    let yAxes = this.chartYAxes;
    if (null == yAxes) {
      if (this.isMotion) {
        yAxes = [
          {
            type: "category",
            position: "left",
            display: true,
            scaleLabel: {
              display: false,
              labelString: "Motion State",
            },
            ticks: {
              reverse: true,
            },
          },
        ];
      } else {
        yAxes = [
          {
            ticks: {
              min: this.min,
              max: this.max,
            },
          },
        ];
      }
    }

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 0,
          right: 20,
          bottom: 0,
          left: 20,
        },
      },
      tooltips: {
        callbacks: {
          title: function (item) {
            return formatDateTime(item[0].label);
          },
        },
      },
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      scales: {
        xAxes: xAxes,
        yAxes: yAxes,
      },
    };

    this.renderChart(this.chartData, options);
  },
};
</script>
