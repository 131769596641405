<template>
  <v-switch v-model="isRegionalView" label="Regional View" />
</template>

<script>
export default {
  name: "RegionalSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isRegionalView: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
