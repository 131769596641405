/**
 *
 * @param {Object | undefined } unitType
 * @param {null | "size" | "type"} displayOnly
 * @returns
 */
export function displayUnitType(unitType, displayOnly = null) {
  const typeName = unitType?.name;
  const unitSize = String(
    unitType?.width || unitType?.length
      ? unitType?.width && unitType?.length
        ? `${unitType.width}x${unitType.length}`
        : unitType?.width || unitType?.length
      : ""
  );
  switch (displayOnly) {
    case "type":
      return typeName || " - ";
    case "size":
      return unitSize || " - ";
    default:
      return typeName || unitSize
        ? unitSize
          ? `${typeName || ""} (${unitSize})`
          : typeName
        : " - ";
  }
}
