import gql from "graphql-tag";
import {
  accountFields,
  deviceFields,
  incidentFields,
  organizationFields,
  profileFields,
} from "../fragments";

// Query for getting a single organization, typically a Zone/Unit
/* @deprecated Query moved to components/smart_units/graphql.js */
export const organization = gql`
  query organization($id: ID!, $expanded: Boolean) {
    organization(id: $id, expanded: $expanded) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      responders {
        id
        escalationLevel
        contactMethod {
          id
          type
          value
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const leanOrganization = gql`
  query organization($id: ID!, $expanded: Boolean) {
    organization(id: $id, expanded: $expanded) {
      ...OrganizationFields
      devices {
        ...DeviceFields
      }
      responders {
        id
        escalationLevel
        contactMethod {
          id
          type
          value
        }
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${deviceFields}
  ${organizationFields}
`;

export const zoneIncidents = gql`
  query ZoneIncidents(
    $organizationId: ID
    $parentId: ID
    $dateTimeFilter: DateTimeFilter!
    $sort: String
    $type: String
    $assigned: Boolean
  ) {
    incidents(
      organizationId: $organizationId
      parentId: $parentId
      dateTimeFilter: $dateTimeFilter
      sort: $sort
      type: $type
      assigned: $assigned
    ) {
      ...IncidentFields
      devices {
        location
      }
      organization {
        id
        name
      }
    }
  }
  ${incidentFields}
`;

/*
  When "adding a renter", these mutations are used in sequence to create the
  necessary entities in the correct order.

  1. Check that the username is valid and available
  2. Register/Create the user.
  3. Create a new billing account, named "user name @ facility name"
  4. Invite/Accept user to the Billing Account with isOwner false (avoid sending esign)
  5. Add the facility to the Billing Account's organizations (establishes relationship)

  ## Assigning a Zone/Smart Unit
  1. Ensure the User is the Billing Account owner (to trigger esign)
  2. Add the Zone/Smart Unit to the Billing Account
*/

export const createBillingAccount = gql`
  mutation CreateBillingAccount(
    $name: String!
    $type: String!
    $status: String!
  ) {
    createBillingAccount(name: $name, type: $type, status: $status) {
      ...AccountFields
    }
  }
  ${accountFields}
`;

export const addAccountOrganizations = gql`
  mutation AddAccountOrganizations(
    $billingAccountId: ID!
    $organizations: [JoinOrganization!]
  ) {
    addAccountOrganizations(
      billingAccountId: $billingAccountId
      organizations: $organizations
    ) {
      id
      billingAccount {
        ...AccountFields
      }
      organization {
        id
        name
        type
      }
    }
  }
  ${accountFields}
`;

/*
  The `recipeint` in this query is the username of the user we want to
  add to the billing account.
*/
export const inviteAccountUser = gql`
  mutation InviteAccountUser(
    $billingAccountId: ID!
    $recipient: String!
    $userId: ID!
  ) {
    inviteAccountUser(
      billingAccountId: $billingAccountId
      recipient: $recipient
      userId: $userId
      isOwner: true
    ) {
      id
    }
  }
`;

/*
  We will need to automatically accept the invitation on behalf of the user
  once we create it. This mutation takes the ID from the invitation we created
  and the ID of the 'facility' to which the user is being associated.
*/
export const acceptInvitation = gql`
  mutation AcceptInvitation($defaultOrganizationId: ID, $invitationId: ID!) {
    acceptInvitation(
      defaultOrganizationId: $defaultOrganizationId
      invitationId: $invitationId
    ) {
      id
    }
  }
`;

export const userOrganizations = gql`
  query userOrganizations(
    $userId: ID!
    $parentOrganizationId: ID!
    $type: String
  ) {
    userOrganizations(
      userId: $userId
      parentOrganizationId: $parentOrganizationId
      type: $type
    ) {
      ...OrganizationFields
      billingAccounts {
        ...AccountFields
      }
      devices {
        ...DeviceFields
      }
    }
  }
  ${accountFields}
  ${deviceFields}
  ${organizationFields}
`;

export const removeAccountOrganizations = gql`
  mutation RemoveAccountOrganizations(
    $billingAccountId: ID!
    $organizationIds: [ID!]
  ) {
    removeAccountOrganizations(
      billingAccountId: $billingAccountId
      organizationIds: $organizationIds
    ) {
      id
      organization {
        id
      }
    }
  }
`;

export const deviceTelemetryEvents = gql`
  query ($deviceId: ID!, $dateTimeFilter: DateTimeFilter!) {
    events: deviceEvents(
      deviceId: $deviceId
      name: "telemetry"
      sort: "timestamp desc"
      dateTimeFilter: $dateTimeFilter
    ) {
      name
      type
      timestamp
      data
    }
  }
`;

export const deviceMotionEvents = gql`
  query ($deviceId: ID!, $dateTimeFilter: DateTimeFilter!) {
    events: deviceEvents(
      deviceId: $deviceId
      name: "motion"
      sort: "timestamp desc"
      dateTimeFilter: $dateTimeFilter
    ) {
      name
      type
      timestamp
      data
    }
  }
`;

export const deviceTriggerEvents = gql`
  query ($deviceId: ID!, $dateTimeFilter: DateTimeFilter!) {
    events: deviceEvents(
      deviceId: $deviceId
      name: "trigger"
      sort: "timestamp desc"
      dateTimeFilter: $dateTimeFilter
    ) {
      name
      type
      timestamp
      data
    }
  }
`;
