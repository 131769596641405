<template>
  <CardLayout
    v-model="inventorySearch"
    :title="cardTitle"
    header-type="search"
    :loading="inventoryLoading"
    :disabled="!initialPrimary && !selectForDemo"
  >
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th v-if="!selectForDemo">Details</th>
            <th>Smart Unit #</th>
            <th>Unit Type</th>
            <th>Unit Size</th>
            <th>Devices(s)</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="inventoryLoading">
          <tr>
            <td colspan="6">
              <v-banner>
                <em>Loading available smart units</em>
              </v-banner>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="filteredAvailableSmartUnits.length > 0">
          <tr v-for="unit in filteredAvailableSmartUnits" :key="unit.id">
            <td v-if="!selectForDemo">
              <ButtonView @click="gotoUnit(unit.id)" />
            </td>
            <td style="width: 30%; white-space: nowrap">
              {{ unit.name }}
            </td>
            <td style="width: 15%; white-space: nowrap">
              {{ displayUnitType(unit.unitType, "type") }}
            </td>
            <td style="width: 15%; white-space: nowrap">
              {{ displayUnitType(unit.unitType, "size") }}
            </td>
            <td style="width: 20%; white-space: nowrap">
              <v-list dense color="transparent">
                <v-list-item
                  v-for="device in unit.devices"
                  :key="device.id"
                  class="ml-0 pl-0"
                >
                  <v-list-item-content>{{
                    device.shortId || device.externalId
                  }}</v-list-item-content>
                  <v-list-item-icon>
                    <DeviceGaugeCluster :device="device" />
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
            </td>
            <td style="width: 1%; white-space: nowrap">
              <v-btn
                v-if="selectForDemo"
                small
                color="success"
                @click="$emit('start-demo-unit', unit)"
              >
                Start Demo
              </v-btn>
              <PossiblePMS v-else v-slot="{ disabled }">
                <v-btn
                  small
                  :color="warnOnAssign(unit) ? 'grey lighten-1' : 'success'"
                  :disabled="disabled || !initialPrimary"
                  @click="assignZone(unit)"
                >
                  Assign Unit
                </v-btn>
              </PossiblePMS>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4">
              <em>No smart units are available</em>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <template v-if="!selectForDemo" #actions>
      <PossiblePMS v-slot="{ disabled }">
        <v-btn
          small
          color="success"
          :disabled="disabled || !initialPrimary"
          @click="createZone"
        >
          Create New Smart Unit
        </v-btn>
      </PossiblePMS>
    </template>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import PossiblePMS from "../common/PossiblePMS.vue";
import ButtonView from "@atoms/ButtonView.vue";

import { assignZoneToRenter } from "./renter";
import { AVAILABLE_SMART_UNITS } from "./graphql";

import { isTimeAfterNow } from "@tod-ui/helpers/datetime";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { displayUnitType } from "@tod-ui/helpers/unitType";
import { unitWithAvailableDevices } from "@components/common/helpers/devices";
import useRenter from "./useRenter";

export default {
  name: "CardSmartUnitsInventory",
  components: {
    CardLayout,
    DeviceGaugeCluster,
    PossiblePMS,
    ButtonView,
  },
  props: {
    renter: {
      validator: (prop) =>
        typeof prop === "undefined" ||
        (typeof prop === "object" &&
          typeof prop.user === "object" &&
          (typeof prop.userBillingAccount === "object" ||
            typeof prop.userBillingAccount === "undefined")),
      default: () => undefined,
    },
  },
  setup() {
    const { currentFacilityId } = useFacility();
    const { addAlert } = useAlerts();
    const { initialPrimary } = useRenter();
    return { currentFacilityId, addAlert, initialPrimary };
  },
  data: () => ({
    availableSmartUnits: [],
    inventorySearch: "",
  }),
  computed: {
    inventoryLoading() {
      return this.$apollo.queries.availableSmartUnits.loading;
    },
    filteredAvailableSmartUnits() {
      const search = this.inventorySearch.trim().toLocaleLowerCase();

      return search && !this.inventoryLoading
        ? this.availableSmartUnits.filter(
            (unit) =>
              [
                unit.name,
                displayUnitType(unit.unitType),
                unit.devices.map((d) => d.shortId || d.externalId).join(" "),
              ]
                .join(" ")
                .toLowerCase()
                .indexOf(search) >= 0
          )
        : this.availableSmartUnits;
    },
    selectForDemo() {
      return this.renter === undefined;
    },
    cardTitle() {
      return this.selectForDemo
        ? "Select a Smart Unit to Demo"
        : "Smart Unit Inventory";
    },
  },
  apollo: {
    availableSmartUnits: {
      query: AVAILABLE_SMART_UNITS,
      variables() {
        return {
          id: this.currentFacilityId,
        };
      },
      update({ availableSmartUnits }) {
        return availableSmartUnits.filter(
          (unit) =>
            unitWithAvailableDevices(unit) &&
            unit.responders.every(
              (r) =>
                r.demoEndingAt === null ||
                !isTimeAfterNow(r.demoEndingAt, unit.timeZone)
            )
        );
      },
    },
  },
  methods: {
    gotoUnit(unitId) {
      this.$router.push({
        name: "ViewSmartUnit",
        params: {
          id: this.currentFacilityId,
          zone_id: unitId,
        },
      });
    },
    createZone() {
      this.$router.push({
        name: "AddAssignSmartUnit",
        params: {
          id: this.currentFacilityId,
          renter_id: this.renter.user.id,
        },
      });
    },
    warnOnAssign(zone) {
      return !!zone.devices.find(
        (d) => d.status !== "ok" || d.disposition !== "in_service"
      );
    },

    async assignZone(zone) {
      const { name } = zone;
      const unhealthy = this.warnOnAssign(zone);

      if (
        !unhealthy ||
        confirm(
          `Smart Unit "${name}" has an Unhealthy Device. Are you sure you want to assign it?`
        )
      ) {
        try {
          await assignZoneToRenter(
            this.$apollo,
            zone,
            this.renter.user,
            this.renter.userBillingAccount
          );
          this.addAlert({
            type: "success",
            message:
              "Make sure the Renter has received and signs the New Tenant Addendum.",
          });
        } catch (error) {
          this.addAlert({
            type: "error",
            message: `There was an error assigning Smart Unit ${name}`,
          });
        }
      }
    },
    displayUnitType,
  },
};
</script>
