export const DECLINE_RED = "#cf0000";
export const INCLINE_GREEN = "#09801b";
export const YELLOW = "#fdc500";

export const SD_PRIMARY = "#2D2E75";
export const SD_ACCENT = "#DF2127";

export const CHART_COLORS = [
  "rgb(119,93,208)",
  "rgb(255,69,96)",
  "rgb(254,176,25)",
  "rgb(0,227,150)",
  "rgb(200,131,145)",
  "rgb(24,216,216)",
  "rgb(162,178,175)",
  "rgb(126,93,115)",
];
