<template>
  <div class="date-card">
    <v-icon small> mdi-calendar-range </v-icon>
    4 April 2024
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.date-card {
  padding: 5px 40px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
</style>
