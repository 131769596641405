<template>
  <v-alert
    v-model="userExists"
    dark
    color="indigo darken-3"
    transition="scale-transition"
  >
    <h3>
      A user with {{ renter.usernameType }}
      {{
        renter.usernameType === "phone"
          ? formatPhoneNumber(renter.username)
          : renter.username
      }}
      already exists.
    </h3>
    <ul v-if="!unAuthorizedExistingUser" class="pa-4" style="list-style: none">
      <li class="body-1">
        <v-icon left small>fas fa-user</v-icon>
        {{ userDisplayName(existingUser) }}
        (Username: {{ existingUser.username }})
      </li>
    </ul>

    <p class="subtitle-1">Would you like to...</p>

    <ul class="mb-1" style="list-style: none; padding: 0px">
      <li v-if="!unAuthorizedExistingUser">
        <v-btn text @click="useExistingUser">
          <v-icon left small>fas fa-chevron-circle-right</v-icon>
          Use {{ userDisplayName(existingUser) }}'s Profile
        </v-btn>
      </li>
      <li>
        <v-btn text @click="useDifferentUsername">
          <v-icon left small>fas fa-chevron-circle-right</v-icon>
          Create a New Renter Profile
        </v-btn>
      </li>
      <li>
        <v-btn text @click="useDifferentPhoneNumber">
          <v-icon left small>fas fa-chevron-circle-right</v-icon>
          Try a Different Phone Number
        </v-btn>
      </li>
    </ul>
  </v-alert>
</template>

<script>
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import useBillingAccount from "./useBillingAccount";
import useRenter from "./useRenter";
import { userDisplayName } from "./helper";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "CreateRenterAlert",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      renter,
      primaryPhoneMethod,
      emailContactMethod,
      fetchRenter,
      createRenter,
      checkRenter,
      findContactMethod,
    } = useRenter();
    const { fetchUserBillingAccounts, assignUser } = useBillingAccount();

    const { addAlert } = useAlerts();
    return {
      renter,
      primaryPhoneMethod,
      emailContactMethod,
      fetchRenter,
      createRenter,
      checkRenter,
      fetchUserBillingAccounts,
      assignUser,
      addAlert,
      findContactMethod,
    };
  },
  data: () => ({
    existingUser: {},
    // RBAC not allowed to get users from different facility
    unAuthorizedExistingUser: false,
  }),
  computed: {
    userExists: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    async tryCreateRenter() {
      try {
        if (!this.renter.username) {
          // Prefer the renter's primary phone as the username
          this.renter.usernameType = "phone";
          this.renter.username = this.primaryPhoneMethod.value;
        }

        // Check if the username is valid and available
        const isValid = await this.checkRenter(this.$apollo);

        // Is the username valid?
        if (!isValid.valid) {
          this.addAlert({
            message: `Error: ${isValid.message || "not a valid data"}`,
          });
          return false;
        }

        // Is the username available?
        if (!isValid.available) {
          // Fetch the existing user
          this.existingUser = await this.fetchRenter(
            this.$apollo,
            isValid.matchingUserId,
            false
          );

          const primaryCM = this.findContactMethod(
            this.existingUser?.contactMethods,
            "mobile",
            true
          );
          if (this.existingUser?.externalId || !primaryCM) {
            await this.useDifferentUsername();
            return;
          } else {
            this.userExists = true;
            return false;
          }
        }

        // We made it here, let's actually create the user/renter
        const newUser = await this.createRenter(this.$apollo);
        this.$emit("renter-created", newUser);
      } catch (error) {
        console.log(error);
        if (error.message.includes("not_authorized")) {
          this.unAuthorizedExistingUser = true;
          this.userExists = true;
        } else {
          this.addAlert({ message: "There was an error adding the renter" });
        }
      }
    },

    useDifferentPhoneNumber() {
      this.userExists = false;
      this.renter.usernameType = "phone";
      this.primaryPhoneMethod.value = null;
      this.renter.username = null;
      this.$emit("reset-phone");
    },
    async useDifferentUsername() {
      this.userExists = false;

      if (
        this.renter.usernameType == "phone" &&
        this.emailContactMethod.value
      ) {
        // First, try to use their email if they've provided one
        this.renter.usernameType = "email";
        this.renter.username = this.emailContactMethod.value;
      } else {
        // Finally, generate a random, hopefully unique, email
        this.renter.usernameType = "email";
        this.renter.username =
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15) +
          "@storage-defender.com";
      }

      // re-attempt create
      await this.tryCreateRenter();
    },
    async useExistingUser() {
      this.userExists = false;
      try {
        //Only create a new billing account if the existing user does not have an active one.
        if (this.existingUser) {
          this.renter = this.existingUser;
          const userBillingAccounts = await this.fetchUserBillingAccounts(
            this.$apollo,
            this.existingUser.id,
            "network-only"
          );

          if (
            !userBillingAccounts
              .map((bau) => bau.billingAccount)
              .some((account) => account.status == "active")
          ) {
            await this.assignUser(this.$apollo, this.existingUser, false);
          }
        }

        // emit as if the user was created, that will allow appropriate actions (redirect, assign demo) in the parent component
        this.$emit("renter-created", this.existingUser);
      } catch (error) {
        this.addAlert({ message: "There was an error adding the renter" });
      }
    },

    userDisplayName,
    formatPhoneNumber,
  },
};
</script>
