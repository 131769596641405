import PageReports from "./PageReports.vue";
import PageReportsOrganization from "./PageReportsOrganization.vue";

const routes = {
  navigation: [
    {
      path: "/reports",
      name: "ReportsOrganization",
      component: PageReportsOrganization,
    },
  ],
  organization: [
    {
      path: "reports",
      name: "Reports",
      component: PageReports,
    },
  ],
};

export default routes;
