<template>
  <ValidationProvider
    v-slot="{ errors }"
    tag="div"
    rules="required"
    name="Time Zone"
  >
    <v-autocomplete
      ref="timeZone"
      v-model="model"
      :items="allTimeZones"
      :autofocus="autofocus"
      :dense="dense"
      :disabled="disabled"
      :hint="hint"
      :outlined="outlined"
      :placeholder="placeholder"
      :required="required"
      label="Time Zone"
      no-data-text="No time zone found"
      :error-messages="errors"
      @blur="$emit('blur', value)"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import gql from "graphql-tag";

export default {
  name: "InputTimeZone",
  components: {
    ValidationProvider,
  },
  props: {
    autofocus: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: undefined,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    value: {
      type: String,
      default: "",
    },
    locationState: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allTimeZones: [],
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  apollo: {
    allTimeZones: {
      query: gql`
        query TimeZones {
          allTimeZones: timeZones
        }
      `,
    },
  },
  methods: {
    focusInput() {
      this.$refs.timeZone.focus();
    },
  },
};
</script>
