<template>
  <DialogLayout v-model="isOpen" title="Remove Renter">
    <p class="subtitle-1">
      This renter doesn't have any Smart Units assigned. Would you like to
      remove <strong>{{ renterName || "this renter" }}</strong> ?
    </p>
    <v-radio-group v-model="shouldRemoveRenter" dense>
      <v-radio label="Yes, I would like to remove this renter" :value="true" />
      <v-radio
        label="No, I would like to leave this renter in the system"
        :value="false"
      />
    </v-radio-group>

    <template #actions>
      <v-btn color="primary" text @click="isOpen = false">Cancel</v-btn>
      <v-btn
        :disabled="shouldRemoveRenter === null"
        color="primary"
        text
        @click="submit"
      >
        Continue
      </v-btn>
    </template>
  </DialogLayout>
</template>

<script>
import DialogLayout from "@layout/DialogLayout.vue";
import { ref, computed } from "vue";

export default {
  name: "DialogRemoveRenter",
  components: { DialogLayout },
  props: {
    renterName: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const shouldRemoveRenter = ref(null);
    const isOpen = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit("input", value);
      },
    });
    function submit() {
      emit("submit", shouldRemoveRenter.value);
    }

    return { isOpen, shouldRemoveRenter, submit };
  },
};
</script>
