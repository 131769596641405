import PageDashboard from "./PageDashboard.vue";

const routes = {
  organization: [
    {
      path: "dashboard",
      name: "FacilityDashboard",
      component: PageDashboard,
    },
  ],
};
export default routes;
