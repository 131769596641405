<template>
  <v-skeleton-loader
    :loading="loading"
    type="table-thead, table-row-divider@10"
  >
    <v-data-table
      :items="tableData.items"
      :headers="tableData.headers"
      :search="tableState.search"
      :sort-by.sync="tableState.sortBy"
      :sort-desc.sync="tableState.sortDesc"
      :page.sync="tableState.page"
      :items-per-page.sync="tableState.itemsPerPage"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
    >
      <template #item="{ item }">
        <tr class="td-container">
          <td>
            {{ regionalView ? item.organizationPath : item.facilityName }}
          </td>
          <td>
            <TableCellWrapper :is-highlighted="highlightColumn.revenueMonth">
              ${{ thousandSeparator(item.currentMonth) }}
            </TableCellWrapper>
          </td>
          <template v-if="selectedPeriod === 'byMonths'">
            <td v-for="month in tableData.months" :key="month">
              <TableCellWrapper
                :is-highlighted="highlightColumn.month === month"
                :value="item[month]"
              />
            </td>
          </template>
          <template v-else>
            <td v-for="month in tableData.byTrendsMonths" :key="month">
              <TableCellWrapper
                :is-highlighted="highlightColumn.month === month"
                :value="item[month]"
              />
            </td>
            <td>
              {{ thousandSeparator(item[ROLLING_AVG]) }}
            </td>
          </template>
        </tr>
      </template>
      <template #body.append>
        <tr class="table-total">
          <td>Total</td>
          <td>
            <TableCellWrapper :is-highlighted="highlightColumn.revenueMonth">
              ${{ thousandSeparator(totalCurrentMonthRevenue) }}
            </TableCellWrapper>
          </td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="tableData.items"
          :headers="tableData.headers"
          filename="monthly_trends"
        />
        <ButtonPrint
          class="order-last"
          :before-print="showAllItems"
          :after-print="restoreItemsPerPage"
        />
      </template>
    </v-data-table>
  </v-skeleton-loader>
</template>

<script>
import {
  formatMonthYear,
  getLast12Months,
  getMonth,
} from "@tod-ui/helpers/datetime";
import { FACILITY_MONTHLY_PERFORMANCE } from "./graphql";
import {
  calculateComparisonPercentage,
  transformMonthlyTrendsData,
  groupByRegion,
} from "../helper";
import TableCellWrapper from "@layout/TableCellWrapper.vue";
import {
  thousandSeparator,
  toLowerCaseAndRemoveSpaces,
} from "@tod-ui/helpers/strings";
import { ROLLING_AVG } from "@tod-ui/constants/dashboard";
import useDataTable from "@components/common/useDataTable";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";
import { calculateTotal, roundValue } from "@tod-ui/helpers/math";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    TableCellWrapper,
    ButtonExportCSV,
    ButtonPrint,
  },
  props: {
    selectedFacilities: {
      type: Array,
      default: () => [],
    },
    selectedPeriod: {
      type: String,
      required: true,
    },
    regionalView: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });

    const { defaultOrganization } = useUser();

    const route = useRoute();
    const highlightColumn = computed(() => {
      const query = route.query.highlight_column;
      return {
        revenueMonth: query === "mtdrevenue",
        month: query,
      };
    });

    return {
      calculateComparisonPercentage,
      ROLLING_AVG,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      highlightColumn,
      thousandSeparator,
      defaultOrganization,
    };
  },
  data() {
    return {
      facilityMonthlyPerformance: [],
    };
  },
  computed: {
    previousMonth() {
      return getMonth(0, -1, this.defaultOrganization.timeZone);
    },
    previousYearSameMonth() {
      return getMonth(-1, 0, this.defaultOrganization.timeZone);
    },
    last12Months() {
      return getLast12Months(
        null,
        this.defaultOrganization.timeZone,
        true
      ).slice(1);
    },
    last3Months() {
      return getLast12Months(
        null,
        this.defaultOrganization.timeZone,
        true
      ).slice(0, 3);
    },
    byTrendsMonths() {
      return [getMonth(0, -1), getMonth(-1, 0)];
    },
    monthlyTrends() {
      return transformMonthlyTrendsData(
        this.facilityMonthlyPerformance,
        "revenue",
        this.last12Months,
        "month",
        this.defaultOrganization.timeZone
      );
    },
    transformedMonthlyTrends() {
      return this.monthlyTrends.map((item) => {
        this.last12Months.forEach((month) => {
          const monthKey = toLowerCaseAndRemoveSpaces(
            formatMonthYear(month, this.defaultOrganization.timeZone)
          );
          item[monthKey] = calculateComparisonPercentage(item, monthKey);
        });
        return item;
      });
    },
    regionWiseReport() {
      const fieldsToSum = [
        "currentMonth",
        "rollingaverage",
        ...this.last12Months.map((dateString) =>
          toLowerCaseAndRemoveSpaces(
            formatMonthYear(dateString, this.defaultOrganization.timeZone)
          )
        ),
      ];
      return groupByRegion(this.monthlyTrends, fieldsToSum).map((item) => {
        this.last12Months.forEach((month) => {
          const monthKey = toLowerCaseAndRemoveSpaces(
            formatMonthYear(month, this.defaultOrganization.timeZone)
          );
          item[monthKey] = calculateComparisonPercentage(item, monthKey);
        });
        return item;
      });
    },
    totalCurrentMonthRevenue() {
      return calculateTotal(this.monthlyTrends, "currentMonth");
    },
    totalRollingAverage() {
      return roundValue(calculateTotal(this.monthlyTrends, ROLLING_AVG));
    },
    loading() {
      return this.$apollo.queries.facilityMonthlyPerformance.loading;
    },

    tableData() {
      const baseHeaders = [
        this.regionalView
          ? { text: "Organization / Region", value: "organizationPath" }
          : { text: "Facility Name", value: "facilityName" },
        { text: "Current Month ($)", value: "currentMonth" },
      ];

      const headers = [...baseHeaders];

      if (this.selectedPeriod === "byMonths") {
        headers.push(...this.generateMonthlyHeaders(this.last12Months));
      } else {
        headers.push(...this.generateByTrendsHeaders());
      }
      return {
        headers,
        items: this.regionalView
          ? this.regionWiseReport
          : this.transformedMonthlyTrends,
        tableMonths: this.last12Months,
        months: this.last12Months.map((month) =>
          toLowerCaseAndRemoveSpaces(
            formatMonthYear(month, this.defaultOrganization.timeZone)
          )
        ),
        byTrendsMonths: this.byTrendsMonths.map((month) =>
          toLowerCaseAndRemoveSpaces(
            formatMonthYear(month, this.defaultOrganization.timeZone)
          )
        ),
      };
    },
  },
  apollo: {
    facilityMonthlyPerformance: {
      query: FACILITY_MONTHLY_PERFORMANCE,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          month: getLast12Months(null, this.defaultOrganization.timeZone, true),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
  methods: {
    generateMonthlyHeaders(tableMonths) {
      const monthsHeaders = tableMonths.map((month) => ({
        text: `.vs ${formatMonthYear(
          month,
          this.defaultOrganization.timeZone
        )} (%)`,
        value: toLowerCaseAndRemoveSpaces(
          formatMonthYear(month, this.defaultOrganization.timeZone)
        ),
      }));

      return monthsHeaders;
    },
    generateByTrendsHeaders() {
      return [
        {
          text: `vs ${formatMonthYear(
            this.previousMonth,
            this.defaultOrganization.timeZone
          )} (%)`,
          value: toLowerCaseAndRemoveSpaces(
            formatMonthYear(
              this.previousMonth,
              this.defaultOrganization.timeZone
            )
          ),
        },
        {
          text: `vs ${formatMonthYear(
            this.previousYearSameMonth,
            this.defaultOrganization.timeZone
          )} (%)`,
          value: toLowerCaseAndRemoveSpaces(
            formatMonthYear(
              this.previousYearSameMonth,
              this.defaultOrganization.timeZone
            )
          ),
        },
        {
          text: "3-Month Rolling Average ($)",
          value: ROLLING_AVG,
        },
      ];
    },
  },
};
</script>

<style scoped>
.td-container td {
  min-width: 205px;
}
</style>
