import { debounce } from "@tod-ui/helpers/utils";
import useFacility from "../authentication/useFacility";
import { SEARCH_EXISTING_UNITS } from "./graphql";
import { onBeforeUnmount } from "vue";

export default function useSmartUnits() {
  const { currentFacilityId } = useFacility();

  async function searchExistingUnits(
    apolloClient,
    searchText,
    { assigned = undefined, limit = 10 } = {}
  ) {
    let units = [];
    try {
      const response = await apolloClient.query({
        query: SEARCH_EXISTING_UNITS,
        variables: {
          id: currentFacilityId.value,
          searchText: searchText.trim(),
          assigned,
          limit,
        },
        fetchPolicy: "no-cache",
      });

      // Reduce and transform the data
      units = response.data?.searchExistingUnits?.map((unit) => {
        const renter = unit?.responders?.find(
          (responder) => responder.escalationLevel === 1
        )?.user;
        return {
          ...unit,
          assigned: !!renter,
          unitTypeId: unit.unitType?.id,
          renter,
        };
      });
    } catch (error) {
      throw "There was an error searching existing smart units";
    }

    return units;
  }

  const debounced = debounce(searchExistingUnits);

  onBeforeUnmount(debounced.cancel());

  return {
    searchExistingUnits: debounced,
  };
}
