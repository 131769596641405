<template>
  <v-icon :color="_color" :title="_title" v-bind="$attrs" v-text="icon" />
</template>

<script>
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "OrganizationIcon",
  props: {
    color: {
      type: String,
      default: null,
    },
    organization: {
      type: Object,
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    _color() {
      return this.color || this.hasDevices ? "primary" : "grey";
    },
    hasDevices() {
      return this.organization.devices && this.organization.devices.length > 0;
    },
    icon() {
      switch (this.organization.type) {
        case "platform":
          return "mdi-shield-account";
        case "application":
          return "mdi-cloud";
        case "organization":
          return "fas fa-project-diagram";
        case "region":
          return "fas fa-map-marked-alt";
        case "facility":
          return "fas fa-warehouse";
        case "zone":
          switch (this.organization.subtype) {
            case "zone":
              return "mdi-cast-connected";
            default:
              return "mdi-garage-variant";
          }
        default:
          return "mdi-sitemap";
      }
    },
    _title() {
      if (this.title) return this.title;

      const { type, subtype } = this.organization;
      return titleCase(subtype || type);
    },
  },
};
</script>
