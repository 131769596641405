<template>
  <div style="width: 100%">
    <CardLayoutAuthenticationForm
      title="Password Reset"
      submit-text="Reset"
      :in-process="inProcess"
      @cancel="cancelReset"
      @submit="tryReset"
    >
      <ValidatedTextField
        v-model="password"
        :disabled="inProcess"
        name="New Password"
        type="password"
        rules="required|min:6|max:128"
        vid="confirm"
      />
      <ValidatedTextField
        v-model="password2"
        :disabled="inProcess"
        name="Confirm Password"
        type="password"
        rules="password:@confirm"
      />
    </CardLayoutAuthenticationForm>
  </div>
</template>

<script>
import CardLayoutAuthenticationForm from "./CardLayoutAuthenticationForm.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";

import { onBeforeMount, ref, watch } from "vue";
import { required, max, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

import useUser from "./useUser";
import useAlerts from "@tod-ui/composables/useAlerts";
import { redirectToLogin } from "../../router/auth-hooks";
import { isTokenExpired } from "../../apollo-client/apollo-token";

extend("max", {
  ...max,
  message: "{_field_} may not be longer than {length} characters",
});

extend("min", {
  ...min,
  message: "{_field_} must be at least {length} characters",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});

export default {
  name: "PageChangePassword",
  components: {
    CardLayoutAuthenticationForm,
    ValidatedTextField,
  },
  props: {
    token: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const password = ref("");
    const password2 = ref("");
    const { inProcess, resetPassword } = useUser();
    const { addAlert, clearAllAlerts } = useAlerts();
    onBeforeMount(() => {
      clearAllAlerts();
      if (isTokenExpired(props.token)) {
        redirectToLogin();
      }
    });

    return {
      password,
      password2,
      inProcess,
      resetPassword,
      addAlert,
    };
  },
  methods: {
    async tryReset() {
      const result = await this.resetPassword(this.$apollo, this.password);
      if (result) {
        this.addAlert({
          type: "success",
          message: "Password has been changed successfully. You can login now",
          timeout: 15,
        });
      } else {
        this.addAlert({
          type: "error",
          message: "Unable to change the password. Please try again",
          timeout: 10,
        });
      }
      redirectToLogin();
    },
    cancelReset: redirectToLogin,
  },
};
</script>
