export const facilityData = [
  {
    id: 1,
    name: "3x3 Self Storage - Denton",
    organizationName: "Three by Three Storage",
    smartZone: "1001",
    durationOfMotion: 1,
    visits: 0,
    status: "Ended",
    renter: "John Doe",
    currentActiveSubscriptionMTD: 7,
    totalMoveInMTD: 28,
    conversion: 25,
    subscriptionMTD: 2,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 432,
    missedSubscriptions: 20,
    missedLifetimeRevenue: "2,880",
    activeSubscription: 379,
    totalUnit: 450,
    facilityPenetrations: 84,
    annualizedRevenue: "54,576",
    lastMonthConversion: -10,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 41,
    june2024Conversion: -7,
    may2024Conversion: 25,
    april2024Conversion: -7,
    march2024Conversion: 25,
    february2024Conversion: -7,
    january2024Conversion: 25,
    december2023Conversion: -7,
    november2023Conversion: 25,
    october2023Conversion: -7,
    september2023Conversion: 25,
    august2023Conversion: -7,
    july2023Conversion: 25,
    currentMonthRevenue: 384,
    lastMonthRevenue: 10,
    previousMonthRevenue: 91,
    threeMonthsRollingAverageRevenue: 376,
    june2024: 2,
    may2024: 4,
    april2024: 6,
    march2024: 6,
    february2024: 19,
    january2024: 38,
    december2023: 41,
    november2023: 34,
    october2023: 25,
    september2023: 78,
    august2023: 81,
    july2023: 91,
  },
  {
    id: 2,
    name: "3x3 Self Storage - Fort Worth",
    organizationName: "Three by Three Storage",
    smartZone: "1002",
    durationOfMotion: 1,
    visits: 0,
    status: "Not Authorized And Not Inspected",
    renter: "Stuart Little",
    currentActiveSubscriptionMTD: 9,
    totalMoveInMTD: 16,
    conversion: 56,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: 576,
    missedSubscriptions: 7,
    missedLifetimeRevenue: "1,003",
    activeSubscription: 336,
    totalUnit: 487,
    facilityPenetrations: 69,
    annualizedRevenue: "48,384",
    lastMonthConversion: 0,
    previousMonthConversion: 36,
    threeMonthsRollingAverageConversion: 87,
    june2024Conversion: 24,
    may2024Conversion: 60,
    april2024Conversion: 24,
    march2024Conversion: 60,
    february2024Conversion: 24,
    january2024Conversion: 60,
    december2023Conversion: 24,
    november2023Conversion: 60,
    october2023Conversion: 24,
    september2023Conversion: 60,
    august2023Conversion: 24,
    july2023Conversion: 60,
    currentMonthRevenue: 636,
    lastMonthRevenue: 10,
    previousMonthRevenue: 28,
    threeMonthsRollingAverageRevenue: 628,
    june2024: 2,
    may2024: 2,
    april2024: 4,
    march2024: 8,
    february2024: 11,
    january2024: 9,
    december2023: 6,
    november2023: 9,
    october2023: 11,
    september2023: 9,
    august2023: 17,
    july2023: 28,
  },
  {
    id: 3,
    name: "3x3 Self Storage - Waco",
    organizationName: "Three by Three Storage",
    smartZone: "209",
    durationOfMotion: 1,
    visits: 42,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 23,
    totalMoveInMTD: 21,
    conversion: 110,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: "1,584",
    missedSubscriptions: 21,
    missedLifetimeRevenue: "3,024",
    activeSubscription: 301,
    totalUnit: 350,
    facilityPenetrations: 86,
    annualizedRevenue: "43,344",
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 121,
    june2024Conversion: 22,
    may2024Conversion: 47,
    april2024Conversion: 22,
    march2024Conversion: 47,
    february2024Conversion: 22,
    january2024Conversion: -8,
    december2023Conversion: 22,
    november2023Conversion: 47,
    october2023Conversion: 22,
    september2023Conversion: 47,
    august2023Conversion: -15,
    july2023Conversion: 47,
    currentMonthRevenue: 900,
    lastMonthRevenue: 10,
    previousMonthRevenue: 7,
    threeMonthsRollingAverageRevenue: 1012,
    june2024: 2,
    may2024: 1,
    april2024: 10,
    march2024: 4,
    february2024: 9,
    january2024: 3,
    december2023: 3,
    november2023: 8,
    october2023: 5,
    september2023: 7,
    august2023: 8,
    july2023: 7,
  },
  {
    id: 4,
    name: "3x3 Self Storage - lewisville",
    organizationName: "Three by Three Storage",
    smartZone: "209",
    durationOfMotion: 1,
    visits: 0,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 26,
    totalMoveInMTD: 28,
    conversion: 93,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: "1,728",
    missedSubscriptions: 13,
    missedLifetimeRevenue: "1,872",
    activeSubscription: 280,
    totalUnit: 400,
    facilityPenetrations: 70,
    annualizedRevenue: "40,320",
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 86,
    june2024Conversion: 26,
    may2024Conversion: 43,
    april2024Conversion: 26,
    march2024Conversion: 43,
    february2024Conversion: 26,
    january2024Conversion: 43,
    december2023Conversion: 26,
    november2023Conversion: 43,
    october2023Conversion: 26,
    september2023Conversion: 43,
    august2023Conversion: 26,
    july2023Conversion: 43,
    currentMonthRevenue: 1536,
    lastMonthRevenue: 10,
    previousMonthRevenue: 16,
    threeMonthsRollingAverageRevenue: 1512,
    june2024: 2,
    may2024: 1,
    april2024: 6,
    march2024: 5,
    february2024: 9,
    january2024: 9,
    december2023: 7,
    november2023: 13,
    october2023: 12,
    september2023: 9,
    august2023: 15,
    july2023: 16,
  },
  {
    id: 5,
    name: "3x3 Self Storage - Abllene",
    organizationName: "Three by Three Storage",
    smartZone: "209",
    durationOfMotion: 21,
    visits: 0,
    status: "Ended",
    renter: "Mary Jane",
    currentActiveSubscriptionMTD: 4,
    totalMoveInMTD: 6,
    conversion: 67,
    subscriptionMTD: 1,
    subscriptionPrice: 12,
    avgSubscriptionDuration: 12,
    lifetimeRevenueAdds: "2,160",
    missedSubscriptions: 21,
    missedLifetimeRevenue: "3,024",
    activeSubscription: 278,
    totalUnit: 500,
    facilityPenetrations: 56,
    annualizedRevenue: "40,032",
    lastMonthConversion: 20,
    previousMonthConversion: 10,
    threeMonthsRollingAverageConversion: 49,
    june2024Conversion: 26,
    may2024Conversion: 97,
    april2024Conversion: -5,
    march2024Conversion: 97,
    february2024Conversion: 26,
    january2024Conversion: 97,
    december2023Conversion: 26,
    november2023Conversion: 97,
    october2023Conversion: 26,
    september2023Conversion: 97,
    august2023Conversion: 26,
    july2023Conversion: 97,
    currentMonthRevenue: 372,
    lastMonthRevenue: 10,
    previousMonthRevenue: 15,
    threeMonthsRollingAverageRevenue: 1288,
    june2024: 2,
    may2024: 1,
    april2024: 7,
    march2024: 6,
    february2024: 8,
    january2024: 14,
    december2023: 15,
    november2023: 8,
    october2023: 28,
    september2023: 22,
    august2023: 15,
    july2023: 15,
  },
];

export const averageDuration = {
  data: {
    averageDuration: [
      {
        id: 1,
        name: "Mason facility",
        duration: 24.03,
      },
      {
        id: 2,
        name: "Johnson center",
        duration: 30.55,
      },
      {
        id: 3,
        name: "Smith warehouse",
        duration: 18.12,
      },
      {
        id: 4,
        name: "Taylor plant",
        duration: 14.45,
      },
      {
        id: 5,
        name: "Clark depot",
        duration: 8.81,
      },
      {
        id: 6,
        name: "Anderson hub",
        duration: 5.2,
      },
      {
        id: 7,
        name: "Wilson factory",
        duration: 25.98,
      },
      {
        id: 8,
        name: "Brown distribution center",
        duration: 0,
      },
      {
        id: 9,
        name: "Martinez warehouse",
        duration: 1.4,
      },
      {
        id: 10,
        name: "Garcia plant",
        duration: 1.67,
      },
    ],
  },
};
