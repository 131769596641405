import { unref } from "vue";

function unrefValue(ref) {
  return unref(ref);
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    year: "numeric",
  }).format(date);
}

export function getLast12Months() {
  const currentDate = new Date();
  const recentMonths = [];
  const recentMonthsISO = [];

  for (let i = 0; i < 12; i++) {
    const month = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i,
      1
    );
    const formattedDate = formatDate(month);
    const isoDate = `${month.toISOString().slice(0, 7)}-01`;

    recentMonths.push(formattedDate);
    recentMonthsISO.push(isoDate);
  }

  return { recentMonths, recentMonthsISO };
}

export function categorizeData(data, limit, propertyName, name) {
  let categorizedData = {};

  data.forEach((item) => {
    const itemName = item[name];
    if (categorizedData[itemName]) {
      categorizedData[itemName] += item[propertyName];
    } else {
      categorizedData[itemName] = item[propertyName];
    }
  });

  // Sort the categorized data by value in descending order
  const sortedCategorizedData = Object.entries(categorizedData)
    .sort((a, b) => b[1] - a[1])
    .slice(0, limit);

  let labels = sortedCategorizedData.map((entry) => entry[0]);
  let datasetData = sortedCategorizedData.map((entry) => entry[1]);

  // If there are remaining items, group them under "Other"
  if (Object.keys(categorizedData).length > limit) {
    const otherTotal = Object.values(categorizedData)
      .slice(limit)
      .reduce((acc, curr) => acc + curr, 0);
    labels.push("Other");
    datasetData.push(otherTotal);
  }

  return { labels, datasetData };
}

export function conversionRate(
  currentActiveSubscriptions,
  totalNumberOfMoveIns
) {
  const activeSubs = unrefValue(currentActiveSubscriptions);
  const moveIns = unrefValue(totalNumberOfMoveIns);

  if (activeSubs === 0 || moveIns === 0) return 0;

  return parseFloat((activeSubs / moveIns) * 100);
}

export function calculateTotalMonthlySubscriptions(monthlyNewSubscriptions) {
  const currentDate = new Date();
  const months = [];
  const counts = [];

  for (let i = 0; i < 12; i++) {
    const month = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i
    );
    const formattedMonth = formatDate(month);
    months.unshift(formattedMonth);

    const count = (monthlyNewSubscriptions || []).reduce((total, entry) => {
      const subscriptionMonth = formatDate(entry.subscriptionMonth);
      return subscriptionMonth === formattedMonth
        ? total + entry.subscriptionsCount
        : total;
    }, 0);

    counts.unshift(count);
  }

  return { months, counts };
}

export const DoughnutChartBgColors = [
  "rgb(119,93,208)",
  "rgb(0,227,150)",
  "rgb(254,176,25)",
  "rgb(255,69,96)",
  "rgb(119,93,208)",
  "rgb(119,93,208)",
  "rgb(0,227,150)",
  "rgb(254,176,25)",
];

export function formatNumberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
