<template>
  <span v-if="isFacilityManager">
    {{ currentFacility.name }}
  </span>
  <span v-else-if="selected" class="d-inline-block my-2">
    {{ currentFacility.name }}
    <v-btn
      outlined
      small
      class="ml-4"
      title="Click to select a different facility"
      @click="selected = false"
    >
      <v-icon x-small left color="#000000a0">fas fa-warehouse</v-icon>
      Change Facility
    </v-btn>
  </span>
  <OrganizationAutocomplete
    v-else
    v-model="selecting"
    :organization="defaultOrganization"
    :autofocus="autofocus"
    :prefetch="prefetch"
    no-data-text="No facilities found"
    max-width="22em"
    :filter="{ type: 'facility' }"
    :label="`Select ${defaultOrganization.name} facility`"
    @keydown="onKeydown"
    @blur="selected = true"
  />
</template>

<script>
import useUser from "../authentication/useUser";
import useFacility from "../authentication/useFacility";
import { ref } from "vue";
import OrganizationAutocomplete from "../utils/OrganizationAutocomplete.vue";

export default {
  name: "FacilityName",
  components: { OrganizationAutocomplete },
  props: {
    autofocus: {
      type: Boolean,
      default: true,
    },
    prefetch: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { defaultOrganization, isFacilityManager } = useUser();
    const { currentFacility, selectFacility } = useFacility();

    const selected = ref(!!currentFacility.value);
    const selecting = ref(currentFacility.value || {});
    function onKeydown(event) {
      //if Escape key
      if (event.keyCode === 27) {
        selected.value = true;
      }
    }

    return {
      isFacilityManager,
      currentFacility,
      selected,
      selecting,
      onKeydown,
      selectFacility,
      defaultOrganization,
    };
  },
  watch: {
    selecting(facility) {
      if (facility?.id && facility.id !== this.currentFacility?.id) {
        this.selectFacility(this.$apollo, facility, true);
        this.selected = true;
      }
    },
  },
};
</script>
