// Shared GQL fragments
import gql from "graphql-tag";
import { accountDocumentFields } from "../components/documents/graphql";

export const accountFields = gql`
  fragment AccountFields on BillingAccount {
    id
    name
    status
    type
    users {
      id
    }
    documents {
      ...AccountDocumentFields
      organization {
        id
        name
      }
    }
  }
  ${accountDocumentFields}
`;

export const contactMethodFields = gql`
  fragment ContactMethodFields on ContactMethod {
    id
    isDefault
    isActive
    type
    value
  }
`;

export const deviceFields = gql`
  fragment DeviceFields on Device {
    id
    batteryLevel
    disposition
    rmaRequest
    externalId
    humidity
    lastContactedAt
    name
    shortId
    subtype
    signalStrength
    observableState
    actionStartedAt
    status
    temperature
    type
    motionState
    motionStartedAt
    entryState
    parkingState
    serviceRequestState
    specs {
      location
    }
  }
`;

export const deviceEventFields = gql`
  fragment DeviceEventFields on DeviceEvent {
    id
    data
    externalId
    name
    receivedAt
    timestamp
    type
  }
`;

export const gatewayFields = gql`
  fragment GatewayFields on Gateway {
    id
    disposition
    externalId
    lastContactedAt
    name
    status
    type
  }
`;

export const gatewayEventFields = gql`
  fragment GatewayEventFields on GatewayEvent {
    id
    data
    externalId
    name
    receivedAt
    timestamp
    type
  }
`;

export const incidentFields = gql`
  fragment IncidentFields on Incident {
    id
    type
    resolution
    status
    escalationLevel
    firstTriggeredAt
    lastUntriggeredAt
    closedAt
    escalatedAt
    resolvedAt
    startedAt
    insertedAt
    updatedAt
    duringAccessHours
    extendedDuration
    isVacant
    hasDisabledLienResponder
    queuedUntilDawn
    bypassedAccess
  }
`;

export const invitationFields = gql`
  fragment InvitationFields on AccountInvitation {
    id
    invitedAt
    recipient
    status
    firstName
    lastName
    isOwner
    billingAccount {
      ...AccountFields
    }
    user {
      ...UserFields
    }
  }
`;

export const locationFields = gql`
  fragment LocationFields on Location {
    street
    city
    state
    postalCode
    country
    latitude
    longitude
  }
`;

export const profileFields = gql`
  fragment ProfileFields on ProfileUnion {
    ... on DefaultProfile {
      email
      phoneNumber
      autoEmailForScheduler
      enableTenantLedger
      tenantLedgerReportType
      enableRecurringchargeReconciliationReport
      reconciliationReportType
      autoEmailForReconciliationReport
    }
    ... on FacilityProfile {
      primaryZoneId
      propertyManagementSystem
      unitEnrollmentMode
      accountNonPaymentLockOn
      languageOptions
      tenantAddendumName
      enableTenantLedger
      tenantLedgerReportType
    }
    ... on ZoneProfile {
      climateConfig {
        disabled
        highHumidityThreshold
        highTemperatureThreshold
        lowHumidityThreshold
        lowTemperatureThreshold
      }
      entryConfig {
        disabled
        schedule
        startsAt
        endsAt
      }
      motionConfigs {
        disabled
        mode
        schedule
        startsAt
        endsAt
      }
      serviceRequestConfig {
        disabled
        schedule
        startsAt
        endsAt
      }
      videoConfig {
        disabled
        videoUrl
        videoCameraEvents
      }
    }
  }
`;

export const organizationFields = gql`
  fragment OrganizationFields on Organization {
    id
    name
    parentId
    timeZone
    type
    subtype
    externalId
    ancestors(type: "facility") {
      id
      name
    }
    profile {
      ...ProfileFields
    }
    unitType {
      id
      name
      width
      length
    }
  }
  ${profileFields}
`;

export const userFields = gql`
  fragment UserFields on User {
    id
    username
    firstName
    lastName
    muteAlerts
    muteNotifications
    insertedAt
    updatedAt
    externalId
    preferredLanguage
    contactMethods {
      id
      type
      value
      isDefault
    }
  }
`;

export const schedulingWindowFields = gql`
  fragment SchedulingWindowFields on SchedulingWindow {
    startsAt
    endsAt
    schedule
  }
`;
