<template>
  <LayoutPage title="">
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegion"
          dense
          :items="regions"
          label="Select Regions"
          outlined
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          dense
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedSchedule"
          :items="['During Access Hours', 'During After Hours']"
          dense
          outlined
          label="Facility Schedule"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedDuration"
          :items="['Visit Within 7 Days', 'Visit Within 30 Days']"
          dense
          outlined
          label="Duration"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedIsVisited"
          :items="['Yes', 'No']"
          dense
          outlined
          label="Is Visited"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <div class="mr-4">
        <DateTimeFilter
          v-model="dateRange"
          :ranges="['Today', '1w', '1m']"
          custom
          inverted
          time-zone="America/New_York"
        />
      </div>
      <DemoIncidentFilter class="mr-4" />
      <div>
        <SearchField v-model="search" />
      </div>
    </div>
    <div>
      <v-data-table
        :headers="filteredHeaders"
        :items="filteredTableData"
        :items-per-page="10"
        :search="search"
        class="elevation-1"
      />
    </div>
  </LayoutPage>
</template>

<script>
import SearchField from "@atoms/SearchField.vue";
import LayoutPage from "@components/layout/LayoutPage.vue";
import DemoIncidentFilter from "../DemoIncidentFilter.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router/composables";

export default {
  components: {
    SearchField,
    LayoutPage,
    DemoIncidentFilter,
    DateTimeFilter,
  },
  setup() {
    const route = useRoute();
    const selectedDisposition = ref("Open");
    const status = computed(() => {
      return route.params.status;
    });
    const selectedDuration = ref("Visit Within 7 Days");
    const selectedIsVisited = ref("Yes");

    watchEffect(() => {
      if (status.value) {
        const [duration, visited] = status.value.split(",");
        if (duration === "30") {
          selectedDuration.value = "Visit Within 30 Days";
        } else {
          selectedDuration.value = "Visit Within 7 Days";
        }
        if (visited === "yes") {
          selectedIsVisited.value = "Yes";
        } else {
          selectedIsVisited.value = "No";
        }
      }
    });

    const tableData = ref([
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 100,
        move_out: "09/01/2024",
        incident_after_move_out: "09/01/2024, 7:00 am",
        cleaned_on: "3 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 101,
        move_out: "09/01/2024",
        incident_after_move_out: "09/01/2024, 6:53 am",
        cleaned_on: "4 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 102,
        move_out: "09/01/2024",
        incident_after_move_out: "09/01/2024, 6:36 am",
        cleaned_on: "5 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 103,
        move_out: "09/01/2024",
        incident_after_move_out: "09/01/2024, 6:34 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 104,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 5:27 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 105,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 5:12 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 106,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 1:53 am",
        cleaned_on: "7 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 107,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 10:53 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: 108,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 8:53 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",

        smart_unit: 109,
        move_out: "09/01/2024",
        incident_after_move_out: "09/02/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",

        smart_unit: 110,
        move_out: "09/02/2024",
        incident_after_move_out: "09/02/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",

        smart_unit: 111,
        move_out: "09/02/2024",
        incident_after_move_out: "09/02/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",

        smart_unit: 112,
        move_out: "09/02/2024",
        incident_after_move_out: "09/02/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
      {
        organization: "The Storage Asset Management",
        facility: "Long Beach",

        smart_unit: 113,
        move_out: "08/29/2024",
        incident_after_move_out: "09/02/2024, 8:12 PM",
        cleaned_on: "9 Days after move out",
      },
    ]);

    const filteredTableData = computed(() => {
      if (selectedIsVisited.value === "Yes") {
        return tableData.value.slice(0, 4);
      } else {
        return tableData.value.slice(0, 10);
      }
    });

    const filteredHeaders = computed(() => {
      const baseHeaders = [
        { text: "Organization", value: "organization" },
        { text: "Facility", value: "facility" },
        { text: "Smart Unit", value: "smart_unit" },
        { text: "MoveOut Date", value: "move_out" },
        { text: "Incident After Move Out", value: "incident_after_move_out" },
      ];

      if (selectedDuration.value !== "Visit Within 30 Days") {
        baseHeaders.push({ text: "Cleaned On", value: "cleaned_on" });
      }

      return baseHeaders;
    });

    return {
      selectedDisposition,
      tableData,
      selectedDuration,
      selectedIsVisited,
      filteredTableData,
      filteredHeaders,
    };
  },
  data() {
    return {
      dateRange: { after: "2024-04-25T06:59:59.999Z" },
      selectedSchedule: "During Access Hours",
      selectedRegion: "Three by Three Storage",
      regions: ["Three by Three Storage", "Region 2", "Region 3"],
      selectedAssignee: "Helen(Facility Manager)",
      selectedFacilities: [{ id: 1, name: "3x3 Self Storage - Denton" }],
      facilities: [
        { id: 1, name: "3x3 Self Storage - Denton" },
        { id: 2, name: "3x3 Self Storage - Fort Worth" },
        { id: 3, name: "3x3 Self Storage - Waco" },
      ],
      search: null,
      headers: [
        { text: "Organization", value: "organization" },
        { text: "Facility", value: "facility" },
        { text: "Smart Unit", value: "smart_unit" },
        { text: "MoveOut Date", value: "move_out" },
        { text: "Incident After Move Out", value: "incident_after_move_out" },
        { text: "Cleaned On", value: "cleaned_on" },
      ],
    };
  },
  methods: {
    gotoZone(status) {
      this.$router.push({
        name: "IncidentInfo",
        params: {
          status: `${status.incident_disposition}&${status.severity}`,
        },
      });
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}
</style>
