<template>
  <LayoutPage title="Penetrations & Annualized Lifetime Revenue">
    <template #actions>
      <SwitchRegionalView v-model="regionalView" />
    </template>
    <v-row>
      <v-col cols="12" md="6">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedYear"
          :items="YEAR_OPTIONS"
          label="Year"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedMonth"
          :items="MONTH_OPTIONS"
          label="Month"
          outlined
          dense
        />
      </v-col>
      <v-col>
        <SearchField v-model="tableState.search" />
      </v-col>
    </v-row>

    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        :items="tableData.items"
        :headers="tableData.headers"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.penetrationRate="{ item }">
          <TableCellWrapper :is-highlighted="highlightColumn.penetration">
            {{ roundValue(item.penetrationRate) }}%
          </TableCellWrapper>
        </template>
        <template #item.annualizedLifetimeRevenue="{ item }">
          <TableCellWrapper
            :is-highlighted="highlightColumn.annualized_revenue"
          >
            ${{ thousandSeparator(roundValue(item.annualizedLifetimeRevenue)) }}
          </TableCellWrapper>
        </template>
        <template #body.append>
          <tr class="table-total">
            <td>Total</td>
            <td>{{ thousandSeparator(totalCurrentSubscriptions) }}</td>
            <td>{{ thousandSeparator(totalUnits) }}</td>
            <td>
              <TableCellWrapper :is-highlighted="highlightColumn.penetration">
                {{ totalPenetrationRate }}%
              </TableCellWrapper>
            </td>
            <td>
              <TableCellWrapper
                :is-highlighted="highlightColumn.annualized_revenue"
              >
                ${{ thousandSeparator(totalAnnualizedLifetimeRevenue) }}
              </TableCellWrapper>
            </td>
          </tr>
        </template>
        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="tableData.items"
            :headers="tableData.headers"
            filename="Penetrations_And_Annualized_Revenue_Report"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute } from "vue-router/composables";
import LayoutPage from "@layout/LayoutPage.vue";
import TableCellWrapper from "@layout/TableCellWrapper.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import { PENETRATIONS_AND_ANNUALIZED_LIFETIME_REVENUE } from "./graphql";
import useDashboard from "../useDashboard";
import { calculateTotal, roundValue } from "@tod-ui/helpers/math";
import { getDateParts } from "@tod-ui/helpers/datetime";
import { MONTH_OPTIONS, YEAR_OPTIONS } from "@tod-ui/constants/dashboard";
import useDataTable from "@components/common/useDataTable";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import { thousandSeparator } from "@tod-ui/helpers/strings";
import { calculatePenetrationRate, groupByRegion } from "../helper";
import SwitchRegionalView from "@atoms/SwitchRegionalView.vue";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    LayoutPage,
    RegionAndFacilitySelect,
    ButtonPrint,
    ButtonExportCSV,
    SearchField,
    TableCellWrapper,
    SwitchRegionalView,
  },
  setup() {
    const { selectedFacilities } = useDashboard();
    const { defaultOrganization } = useUser();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });
    const selectedYear = ref(
      getDateParts(
        { year: true },
        undefined,
        defaultOrganization.value.timeZone
      )
    );
    const selectedMonth = ref(
      getDateParts(
        { month: true },
        undefined,
        defaultOrganization.value.timeZone
      )
    );
    const selectedDate = computed(() => {
      return `${selectedYear.value}-${selectedMonth.value}-01`;
    });

    const route = useRoute();
    const highlightColumn = computed(() => {
      const query = route.query.highlight_column;
      return {
        penetration: query === "penetration%",
        annualized_revenue: query === "annualizedrevenue",
      };
    });

    return {
      tableState,
      showAllItems,
      restoreItemsPerPage,
      selectedFacilities,
      selectedYear,
      selectedMonth,
      selectedDate,
      highlightColumn,
      roundValue,
      YEAR_OPTIONS,
      MONTH_OPTIONS,
      thousandSeparator,
    };
  },
  data() {
    return {
      penetrationsReport: [],
      regionalView: true,
    };
  },
  computed: {
    loading() {
      return this.$apollo.loading;
    },
    regionWiseReport() {
      const fieldsToSum = [
        "annualizedLifetimeRevenue",
        "totalUnits",
        "netActiveSubscriptionsInMonth",
      ];

      const groupedData = groupByRegion(this.penetrationsReport, fieldsToSum);

      // Perform additional calculations
      return groupedData.map((item) => {
        item.penetrationRate = item.totalUnits
          ? calculatePenetrationRate(
              item.netActiveSubscriptionsInMonth,
              item.totalUnits
            )
          : 0;

        return item;
      });
    },
    tableData() {
      return {
        headers: [
          this.regionalView
            ? { text: "Organization / Region", value: "organizationPath" }
            : { text: "Facility Name", value: "facilityName" },
          {
            text: "Current Subscriptions",
            value: "netActiveSubscriptionsInMonth",
          },
          { text: "Total Units", value: "totalUnits" },
          { text: "Facility Penetrations (%)", value: "penetrationRate" },
          {
            text: "Annualized Lifetime ($)",
            value: "annualizedLifetimeRevenue",
          },
        ],
        items: this.regionalView
          ? this.regionWiseReport
          : this.penetrationsReport,
      };
    },
    totalCurrentSubscriptions() {
      return calculateTotal(
        this.penetrationsReport,
        "netActiveSubscriptionsInMonth"
      );
    },
    totalUnits() {
      return calculateTotal(this.penetrationsReport, "totalUnits");
    },
    totalPenetrationRate() {
      return calculatePenetrationRate(
        this.totalCurrentSubscriptions,
        this.totalUnits
      );
    },
    totalAnnualizedLifetimeRevenue() {
      return roundValue(
        calculateTotal(this.penetrationsReport, "annualizedLifetimeRevenue")
      );
    },
  },
  apollo: {
    penetrationsReport: {
      query: PENETRATIONS_AND_ANNUALIZED_LIFETIME_REVENUE,
      variables() {
        return {
          facilityIds: this.selectedFacilities.map((f) => f.id),
          month: this.selectedDate,
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
};
</script>
