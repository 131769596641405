<template>
  <v-tooltip
    v-if="accountDisabled"
    :disabled="!enableBtnDisabled"
    bottom
    nudge-top="10"
  >
    <template #activator="{ on }">
      <span v-on="on">
        <v-btn
          :disabled="enableBtnDisabled"
          small
          color="primary"
          @click="setAccountStatus('active')"
        >
          {{ endedService ? "Resume Service" : "Enable Renter" }}
        </v-btn>
      </span>
    </template>
    <span>
      Enabling renter is controlled by {{ propertyManagementSystem }} automation
    </span>
  </v-tooltip>

  <v-menu v-else offset-y>
    <template #activator="{ on, attrs }">
      <v-btn small color="error" v-bind="attrs" v-on="on">
        Disable Renter
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item
        v-if="isDeviceControlledEnrollment"
        @click="setAccountStatus('ended_service')"
      >
        End Service
      </v-list-item>
      <v-list-item @click="setAccountStatus('disabled_maintenance')">
        Disable for Maintenance
      </v-list-item>

      <v-tooltip :disabled="!hasNonPaymentAutomation" bottom nudge-top="15">
        <template #activator="{ on }">
          <span v-on="on">
            <v-list-item
              :disabled="hasNonPaymentAutomation"
              @click="setAccountStatus('disabled_lien')"
            >
              Disable for Non-Payment
            </v-list-item>
          </span>
        </template>
        <span
          >Disabling renter is controlled by
          {{ propertyManagementSystem }} automation
        </span>
      </v-tooltip>
    </v-list>
  </v-menu>
</template>

<script>
import { computed } from "vue";
import useBillingAccount from "./useBillingAccount";
import useRenter from "./useRenter";
import usePMS from "../common/usePMS";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "ButtonRenterStatus",
  setup() {
    const { accountDisabled, endedService, updateAccountStatus } =
      useBillingAccount();
    const { renter } = useRenter();
    const { propertyManagementSystem, renterHasNonPaymentAutomation } =
      usePMS();
    const hasNonPaymentAutomation = computed(() =>
      renterHasNonPaymentAutomation(renter)
    );
    const enableBtnDisabled = computed(
      () =>
        (hasNonPaymentAutomation.value &&
          accountDisabled.value === "disabled_lien") ||
        accountDisabled.value === "disabled_device_upgrade"
    );
    const { addAlert, clearAllAlerts } = useAlerts();
    const { isDeviceControlledEnrollment } = usePMS();

    return {
      accountDisabled,
      endedService,
      updateAccountStatus,
      propertyManagementSystem,
      hasNonPaymentAutomation,
      enableBtnDisabled,
      addAlert,
      clearAllAlerts,
      isDeviceControlledEnrollment,
    };
  },
  methods: {
    async setAccountStatus(status) {
      this.clearAllAlerts();
      // Prompt, only if disabling the account
      if (
        status === "active" ||
        confirm(
          status === "ended_service"
            ? "Are you sure you want to End SD service for this renter for all units?"
            : "Are you sure you want to disable this renter?"
        )
      ) {
        try {
          const message = await this.updateAccountStatus(this.$apollo, status);
          this.$emit("account-status-changed");
          this.addAlert({ type: "success", message, timeout: 15 });
        } catch (errorMessage) {
          this.addAlert({ message: errorMessage });
        }
      }
    },
  },
};
</script>
