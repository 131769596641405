import Vue from "vue";
import VueApollo from "vue-apollo";
import { ApolloClient } from "apollo-client";
import { createHttpLink } from "apollo-link-http";
import { createLink } from "apollo-absinthe-upload-link";
import { from } from "apollo-link";
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
  defaultDataIdFromObject,
} from "apollo-cache-inmemory";
import introspectionQueryResultData from "../graphql/fragmentTypes.json";
import { logApolloClientErrorsLink } from "./errors-link";
import createAuthLink from "./auth-link";
import createSentryLink from "./sentry-link";

const cache = new InMemoryCache({
  fragmentMatcher: new IntrospectionFragmentMatcher({
    introspectionQueryResultData,
  }),
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "IncidentResponder":
        return `${object.__typename}:${object.id}:${object.type}:${object.status}`;
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

// Install the vue plugin
Vue.use(VueApollo);

export function initApolloProvider(options = {}) {
  // Create apollo clients
  const apolloClient = new ApolloClient({
    cache,
    link: from([
      createAuthLink(),
      logApolloClientErrorsLink,
      createSentryLink(),
      createLink({
        uri:
          import.meta.env.VUE_APP_GRAPHQL_HTTP ||
          "http://localhost:4000/graphql",
      }),
    ]),
    ...options,
  });

  const apolloMPClient = new ApolloClient({
    cache,
    link: from([
      createAuthLink(),
      logApolloClientErrorsLink,
      createSentryLink(),
      createHttpLink({
        uri:
          import.meta.env.VUE_APP_GRAPHQL_MP_HTTP ||
          "http://localhost:4020/graphql",
      }),
    ]),
    ...options,
  });

  const apolloSitelinkServiceClient = new ApolloClient({
    cache,
    link: from([
      createAuthLink(),
      logApolloClientErrorsLink,
      createSentryLink(),
      createHttpLink({
        uri:
          import.meta.env.VUE_APP_GRAPHQL_SITELINK_SERVICE_HTTP ||
          "http://localhost:4100/graphql",
      }),
    ]),
    ...options,
  });

  const apolloStoredgeServiceClient = new ApolloClient({
    cache,
    link: from([
      createAuthLink(),
      logApolloClientErrorsLink,
      createSentryLink(),
      createHttpLink({
        uri:
          import.meta.env.VUE_APP_GRAPHQL_STOREDGE_SERVICE_URL ||
          "http://localhost:4050/graphql",
      }),
    ]),
    ...options,
  });

  const apolloSsmServiceClient = new ApolloClient({
    cache,
    link: from([
      createAuthLink(),
      logApolloClientErrorsLink,
      createSentryLink(),
      createHttpLink({
        uri:
          import.meta.env.VUE_APP_GRAPHQL_SSM_SERVICE_URL ||
          "http://localhost:4070/graphql",
      }),
    ]),
    ...options,
  });

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
      apolloMPClient,
      apolloSitelinkServiceClient,
      apolloStoredgeServiceClient,
      apolloSsmServiceClient,
    },
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // Enabling caching-and-network so refetchQueries work
        fetchPolicy: "cache-and-network",
      },
    },
    errorHandler(error) {
      // Errors are logged through dedicated Apollo Client link;
      // But looks like this handler is needed so error wouldn't get thrown in components when using `apollo` option
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function resetCache(apolloClient) {
  try {
    apolloClient.stop();
    await apolloClient.clearStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset", "color: orange;", e.message);
  }
}
