// Provides document utility functions
import { diff } from "@tod-ui/helpers/datetime";

const WAIT_FOR_SIGNATURE_MIN = 2;

function documentAgeInMinutes(document) {
  return Math.floor(diff(document.insertedAt).as("minutes"));
}

export function simplifiedStatus(document, hasPms = false) {
  if (!document?.status) return "unavailable";

  switch (document.status) {
    case "disabled":
      return "disabled";

    case "grandfathered":
    case "active":
    case "completed":
    case "executed":
    case "signed":
    case "uploaded":
      return "signed";

    case "created":
    case "sent":
    case "viewed":
      return "unsigned";

    case "initialized":
    case "originated":
    case "requested":
      // If the doc is new or PMS, consider it unsigned...
      return hasPms || documentAgeInMinutes(document) < WAIT_FOR_SIGNATURE_MIN
        ? "unsigned"
        : "error";

    default:
      return hasPms ? "unsigned" : "error";
  }
}

export function smartUnitDocument(
  billingAccountDocuments,
  unitId,
  hasPms = false
) {
  const document =
    billingAccountDocuments &&
    unitId &&
    billingAccountDocuments.find(
      ({ organization }) => unitId === organization?.id
    );

  return {
    ...(document || {}),
    status: simplifiedStatus(document, hasPms),
    unitName: document?.organization?.name,
  };
}
