// Provides incident utility functions
import { titleCase } from "@tod-ui/helpers/strings";

export function incidentAuthorizedStatus(incident) {
  if (!incident) return "";
  if (incident?.resolution === "claimed") return "claimed";

  if (incident?.status === "resolved" && incident?.resolution === "surveyed")
    return "resolved-surveyed";

  if (
    incident?.status === "resolved" &&
    incident?.resolution === "presence_confirmed"
  )
    return "presence-confirmed";

  if (incident?.type === "unconfirmed_access") return "presence-unconfirmed";

  if (incident?.status === "closed") {
    if (
      incident?.resolution === "denied" ||
      incident?.hasDisabledLienResponder ||
      incident?.type === "facility_motion"
    ) {
      return "unauthorized";
    }

    if (incident?.isVacant) return "closed-vacant";
    if (incident?.queuedUntilDawn) return "unexplained-after-hours";
    if (incident?.resolution === "unexplained") return "closed-unexplained";
  }
  if (incident?.resolution === "denied") return "possible-unauthorized";

  if (incident?.resolution === "unexplained" && incident?.queuedUntilDawn)
    return "unexplained-after-hours";

  if (
    incident?.resolution === "unexplained" &&
    (incident?.status === "started" || incident?.status === "muted")
  )
    return incident?.hasDisabledLienResponder ||
      incident?.type === "facility_motion"
      ? "unauthorized"
      : "ongoing";
  return "";
}

export function incidentStatus(incident) {
  if (incident.type === "entry") {
    return incident.closedAt ? `Door Closed` : "Door Opened";
  }
  if (incident.type === "water") {
    return incident.closedAt ? `Water Undetected` : "Water Detected";
  }
  switch (incidentAuthorizedStatus(incident)) {
    case "claimed":
      return "Authorized by Renter";
    case "presence-confirmed":
      return accessStatus("presence_confirmed");
    case "presence-unconfirmed":
      return accessStatus("unconfirmed_access");
    case "resolved-surveyed":
      return "Unauthorized and Inspected";
    case "unauthorized":
      return incident?.status === "closed"
        ? "Unauthorized and Not Inspected"
        : `Ongoing ${formatIncidentType(incident)}`;
    case "unexplained-after-hours":
      return "Unauthorized, Renter notified during normal hours";
    case "closed-vacant":
      return "Ended";
    case "closed-unexplained":
      return "Ended, No Response from Renter";
    case "possible-unauthorized":
      return "Possible Unauthorized";
    case "ongoing":
      return `Ongoing ${formatIncidentType(incident)}`;
  }
  return formatIncidentType(incident);
}

export function formatIncidentType(incident) {
  if (["humidity", "temperature", "service_request"].includes(incident.type))
    return titleCase(incident.type);
  if (incident.type === "water") return "Water Detected";
  if (incident.type === "entry") return "Door Open";
  if (incident.type === "unconfirmed_access") return "Access Code w/o Motion";

  if (isMultiUnit(incident)) return "Multi-Unit Motion";

  let prefix = undefined;
  if (incident.type === "motion")
    prefix = incident.queuedUntilDawn === true ? "After Hours" : "Active";
  if (incident.isVacant && incident.extendedDuration) {
    prefix =
      incident.duringAccessHours === true
        ? "Day Time "
        : incident.duringAccessHours === false
        ? "After Hours "
        : "";
    prefix += "Extended";
  }
  if (incident.hasDisabledLienResponder) prefix = "Disabled-Overlocked";

  let suffix = undefined;
  if (incident?.resolution === "presence_confirmed") {
    suffix = "w/ Access Code";
  } else if (incident?.bypassedAccess && !incident.isVacant) {
    suffix = "w/o Access Code";
  }

  return [prefix, titleCase(incident.type || incident), suffix]
    .join(" ")
    .trim();
}

export function isMultiUnit(incident) {
  return incident?.type === "facility_motion";
}

export function accessStatus(type) {
  switch (type) {
    case "presence_confirmed":
      return "Pre Authorized Unit Entry";

    case "unconfirmed_access":
      return "Access Code w/o Unit Entry";

    case "bypassed_access":
      return "Unit Entry w/o Access Code";

    default:
      break;
  }
}
