import { ref, unref } from "vue";
import { useRoute } from "vue-router/composables";

const isDrawerShown = ref(true);

const STORE_DRAWER = "showDrawer";
function toggleDrawer(newValue) {
  if (typeof newValue !== "boolean") {
    newValue = !isDrawerShown.value;
  }

  if (newValue !== isDrawerShown.value) {
    isDrawerShown.value = newValue;
    window.localStorage.setItem(
      STORE_DRAWER,
      JSON.stringify(isDrawerShown.value)
    );
  }
}

function restoreDrawerState() {
  const persisted = JSON.parse(window.localStorage.getItem(STORE_DRAWER));
  if (typeof persisted === "boolean" && persisted !== isDrawerShown.value) {
    isDrawerShown.value = persisted;
  }
}

function useNavigation(restore = false) {
  const $route = useRoute();

  if (restore) {
    restoreDrawerState();
  }

  function isCurrentRoute(compareRoute) {
    const theCompareRoute = unref(compareRoute);
    const params = $route?.params || {};
    const compareParams = theCompareRoute?.params;
    return (
      theCompareRoute?.name === $route?.name &&
      Object.keys(params).length === Object.keys(compareParams).length &&
      Object.keys(params).every(
        (key) =>
          compareParams.hasOwnProperty(key) &&
          params[key] === compareParams[key]
      )
    );
  }

  return {
    toggleDrawer,
    isDrawerShown,
    isCurrentRoute,
  };
}

export default useNavigation;
