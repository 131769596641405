import gql from "graphql-tag";
export const EEN_AUTH_STATUS = gql`
  query EenAuthStatus($organization_id: ID!) {
    EenAuthStatus(organization_id: $organization_id) {
      accessToken
      expiresIn
      receivedAt
      refreshToken
      scope
      tokenType
    }
  }
`;
