import { ref, unref } from "vue";
import router from ".";
import { getToken } from "../apollo-client/apollo-token";

export const $lastRoute = ref();

export function addBeforeNavigationAuthHook() {
  router.beforeEach((to, from, next) => {
    if (to.meta.withoutAuth || getToken()) {
      next();
    } else {
      setLastRoute(to);
      next({ name: "Login" });
    }
  });
}

export function redirectToLogin() {
  if (router.currentRoute?.name !== "Login") {
    setLastRoute(router.currentRoute);
    router.replace({ name: "Login" });
  }
}

export function setLastRoute(routeRef) {
  const route = unref(routeRef);
  if (!route.meta.withoutAuth) {
    $lastRoute.value = route;
  }
}
