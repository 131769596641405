// Provides audit log utility functions
import { titleCase } from "./strings";

import { displayUnitType } from "./unitType";
import { billingAccountStatusText } from "./billingAccount";

const ignoredKeys = [
  "updated_at",
  "inserted_at",
  "updated_by",
  "text_search_english",
];

const updateKeys = ["updated_at", "updated_by"];
const duplicateLogsKey = [
  "starts_at",
  "ends_at",
  "unit_enrollment_mode",
  "alert_resumption_margin",
  "late_customer_margin",
  "disabled",
  "sensitivity",
  "spots",
  "mute_reason",
  "status",
  "phone_number",
  "muted_until",
];

function formatOperationType(log) {
  const operation = log?.operation;
  switch (operation) {
    case 0:
      return "added";
    case 1:
      return "updated";
    case 2:
      return "deleted";
    default:
      break;
  }
}

export function formatLogDescription(log) {
  let operation = formatOperationType(log);
  switch (log?.entity) {
    case "billing_accounts":
      return formatBillingAccount(log, operation);

    case "billing_account_organization_assignments":
      return formatBillingAccountOrganizationAssignment(log, operation);

    case "contact_methods":
      return formatContactMethod(log, operation);

    case "unit_types":
      return formatUnitType(log, operation);

    case "device_assignments":
      return formatDeviceAssignment(log, operation);

    case "devices":
      return formatDevice(log, operation);

    case "organizations":
      return formatOrganization(log, operation);

    case "organization_details":
      return formatOrganizationDetail(log, operation);

    default:
      break;
  }
  return `${titleCase(operation)} ${titleCase(log?.entity)}`;
}

function customizeLogDescription(log) {
  if (log?.primary_zone_change) {
    return `Primary zone changed from ${log.primary_zone_change}`;
  }
  if (log?.unit_type_change) {
    return `Unit type has been changed from ${log.unit_type_change}`;
  }
  return formatChangeObjectToDescription(log?.changes, [
    ...ignoredKeys,
    "path",
  ]);
}

function formatOrganization(log, operation) {
  const { organizationType, organizationSubtype, organizationName } = log;
  const description = customizeLogDescription(log);

  if (organizationType === "zone") {
    const name = titleCase(organizationName);
    const subtype = titleCase(organizationSubtype);
    const capitalizedOperation = titleCase(operation);

    switch (operation) {
      case "added":
        return `${subtype} ${name} has been added`;
      case "updated":
        const changes = description ? `: ${description}` : "with no changes";
        return `${capitalizedOperation} ${subtype} ${name} ${changes}`;
      default:
        return description;
    }
  }

  return description;
}

function formatOrganizationDetail(log, operation) {
  const description = formatChangeObjectToDescription(
    operation === "added" ? jsonDiff(log?.data, {}) : log?.changes,
    [...ignoredKeys, "organization_id"]
  );

  return `${titleCase(operation)} ${
    log?.organizationType === "zone"
      ? `${log?.organizationSubtype} ${titleCase(
          log?.organizationName
        )} details with ${
          description === "" ? "no changes" : `: ${description}`
        }`
      : ""
  }`;
}

function formatBillingAccount(log, operation) {
  operation =
    operation === "added"
      ? `${titleCase(log?.name?.split("@")[0])} has been added`
      : operation === "updated"
      ? `${titleCase(
          log?.name?.split("@")[0]
        )} has been updated: ${billingAccountStatusText(log?.status)}`
      : `${titleCase(log?.name?.split("@")[0])} has been deleted`;

  return `${operation} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : ""
  }`;
}

function formatBillingAccountOrganizationAssignment(log, operation) {
  if (operation === "added") {
    return `${titleCase(log?.name?.split("@")[0])} has been ${
      log?.organizationType === "zone"
        ? ` assigned to ${titleCase(log?.organizationSubtype)} ${
            log?.organizationName
          }`
        : "added"
    }`;
  }

  if (
    (operation === "updated" && log?.data?.unassigned_at) ||
    operation === "deleted"
  ) {
    return `${titleCase(log?.name?.split("@")[0])} has been ${
      log?.organizationType === "zone"
        ? ` unassigned from ${titleCase(log?.organizationSubtype)} ${
            log?.organizationName
          }`
        : "removed"
    }`;
  }

  return `${log?.organizationType === "facility" ? "Added" : "Assigned"} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : ""
  }`;
}

function formatContactMethod(log, operation) {
  const description = `${titleCase(log?.name)}'s ${
    log?.data?.is_default ? "primary " : ""
  }${log?.data?.type} has been ${
    operation === "added"
      ? "added"
      : operation === "updated"
      ? "updated from"
      : "removed"
  }`;

  const changes = `${
    operation === "added"
      ? log?.data?.value
      : `${log?.changes?.value?.old || log?.data?.value} to ${
          log?.changes?.value?.new || log?.data?.value
        }`
  }`;

  return `${description} ${changes}`;
}

function formatUnitType(log, operation) {
  const { oldData, newData } = mergeDataWithChanges(log);

  const description = `${
    operation === "added" ? displayUnitType(newData) : displayUnitType(oldData)
  } has been ${operation}`;

  const changes = `${
    operation === "updated" ? `to ${displayUnitType(newData)}` : ""
  }`;

  return `Unit type ${description} ${changes}`;
}

function formatDevice(log, operation) {
  let disposition = log?.changes?.disposition?.new ?? log?.data?.disposition;

  operation = `Device ${log?.data?.type?.toUpperCase()}-${
    log?.data?.short_id
  } has been ${
    operation === "added"
      ? "added"
      : `marked as ${titleCase(
          disposition == "in_service" ? "found" : disposition
        )}`
  }`;

  return operation;
}

function formatDeviceAssignment(log, operation) {
  operation =
    operation === "added"
      ? `Device ${log?.data?.short_id} has been ${
          log?.organizationType === "facility" ? "moved" : "added"
        } to`
      : (operation === "updated" && log?.data?.unassigned_at) ||
        operation === "deleted"
      ? `Device ${log?.data?.short_id} has been removed from`
      : `Device ${log?.data?.short_id} has been ${
          log?.organizationType === "facility" ? "moved" : "added"
        } to`;

  return `${operation} ${
    log?.organizationType === "zone"
      ? `${titleCase(log?.organizationSubtype)} ${log?.organizationName}`
      : "inventory"
  }`;
}

function formatChangeObjectToDescription(changes, ignoreKeys = []) {
  let description = "";
  for (const key in changes) {
    if (changes.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
      const change = changes[key];
      if (
        (typeof change.old === "object" || typeof change.new === "object") &&
        (change.old !== null || change.new !== null) &&
        !Array.isArray(change.old) &&
        !Array.isArray(change.new)
      ) {
        description += formatChangeObjectToDescription(
          jsonDiff(change.new, change.old),
          ignoreKeys
        );
        continue;
      }

      if (checkEquality(change.old, change.new)) continue;

      description += `${titleCase(key)}- ${
        change.old
          ? `changed from ${formatValue(change.old)} to ${formatValue(
              change.new
            )}`
          : ` ${formatValue(change.new)} has been added`
      }\n`;
    }
  }
  return description;
}

function checkEquality(oldValue, newValue) {
  if (!oldValue && !newValue) return true;

  if (Array.isArray(oldValue) && Array.isArray(newValue)) {
    return oldValue.sort().join("") === newValue.sort().join("");
  }
  return oldValue === newValue;
}

function formatValue(value) {
  if (Array.isArray(value)) {
    return value
      .map((val) => {
        return titleCase(String(val));
      })
      .join(", ");
  }
  return value;
}

function jsonDiff(newObject, oldObject) {
  const result = {};

  newObject = newObject ?? {};
  oldObject = oldObject ?? {};
  if (typeof newObject !== "object" || typeof oldObject !== "object")
    return result;

  var keys = Object.keys(newObject).concat(Object.keys(oldObject));

  keys.forEach(function (key) {
    if (!(key in oldObject) || newObject[key] !== oldObject[key]) {
      result[key] = { old: oldObject[key], new: newObject[key] };
    }
  });

  return result;
}

function mergeDataWithChanges(log) {
  const newData = { ...log.data };
  const oldData = { ...log.data };

  for (const key in log.changes) {
    if (log.changes.hasOwnProperty(key)) {
      const change = log.changes[key];
      oldData[key] = change.old;
      newData[key] = change.new;
    }
  }

  return { oldData, newData };
}

// AuditLogs for BackOffice
export function formatLogDescriptionForBO(log) {
  let operation = formatOperationType(log);
  switch (log?.entity) {
    case "organizations":
      return formatOrganizationForBO(log, operation);
    case "organization_details":
      return formatOrganizationDetailForBO(log, operation);
  }
  return `${titleCase(operation)} ${titleCase(log?.entity)}`;
}
// Format Organization Details
function formatOrganizationDetailForBO(log, operation) {
  const keys = Object.keys(log?.changes);
  const hasOnlyUpdatedKeys = keys.every((key) => updateKeys.includes(key));
  if (hasOnlyUpdatedKeys) {
    return `${titleCase(operation)} ${titleCase(
      log?.organizationName
    )} details with no changes`;
  }

  const description = "";
  return [
    `${titleCase(operation)} ${
      log?.organizationType === "facility"
        ? ` ${titleCase(log?.organizationName)} details with ${
            description === "" ? "no changes" : `: ${description}`
          }`
        : ""
    }`,
  ];
}
// Format Organization
function formatOrganizationForBO(log, operation) {
  // Check if changes object contains only allowed keys
  const keys = Object.keys(log?.changes);
  const hasOnlyUpdatedKeys = keys.every((key) => updateKeys.includes(key));
  if (hasOnlyUpdatedKeys) {
    return `${titleCase(operation)} ${titleCase(
      log?.organizationName
    )} details with no changes`;
  }
  const formattedLogs = formatChangeObjectToDescriptionForBO(
    log?.changes,
    ignoredKeys
  );
  return formattedLogs.split(",").filter(Boolean);
}

function formatChangeObjectToDescriptionForBO(changes, ignoreKeys = []) {
  let description = "";
  for (const key in changes) {
    if (changes.hasOwnProperty(key) && !ignoreKeys.includes(key)) {
      const change = changes[key];

      switch (key) {
        case "office_schedule":
        case "monitoring_schedule":
          description = formatTimeSchedule(change, ignoreKeys, key);
          break;
        case "property_management_system":
        case "pms_paused_at":
        case "unit_enrollment_mode":
          description += formatPMS(changes, change, key);
          break;
        case "night_agg_motion_config":
          description += formatNightAggMotionConfig(change, key);
          break;
        case "alerting_strategy":
          description += formatAlertingStrategy(change, key);
          break;
        case "location":
        case "shipping_address":
          description += formatLocationUpdate(change, key);
          break;
      }

      if (
        (typeof change.old === "object" || typeof change.new === "object") &&
        (change.old !== null || change.new !== null) &&
        !Array.isArray(change.old) &&
        !Array.isArray(change.new)
      ) {
        description += formatChangeObjectToDescriptionForBO(
          jsonDiff(change.new, change.old),
          ignoreKeys
        );
        continue;
      }

      if (duplicateLogsKey.includes(key)) continue;
      if (checkEquality(change.old, change.new)) continue;
      // format boolean logs
      if (typeof change.new === "boolean" || typeof change.old === "boolean") {
        description += `${titleCase(key)} has been ${
          change.new ? "turned on" : "turned off"
        },`;
      } else {
        description += `${titleCase(key)}- ${
          change.old !== undefined
            ? change.new !== undefined
              ? `changed from <red>${formatValue(
                  change.old
                )}</red> to <green>${formatValue(change.new)}</green>,`
              : `<red>${formatValue(change.old)}</red> has been removed,`
            : `<green>${formatValue(change.new)}</green> has been added,`
        }`;
      }
    }
  }
  return description;
}

function formatAlertingStrategy(change, parentKey) {
  if (!change.new || !change.old) {
    return `${titleCase(parentKey)} has been ${
      change.new ? "added" : "removed"
    },`;
  }

  return Object.entries(change.new)
    .filter(([key, newValue]) => newValue !== change.old[key])
    .map(([key, newValue]) => {
      const oldValue = change.old[key];
      if (key === "status") {
        return `${titleCase(parentKey)} has been ${newValue},`;
      }
      return `${titleCase(parentKey)} ${titleCase(key)} ${
        oldValue ? `changed from <red>${oldValue}</red> to ` : ""
      }<green>${newValue}</green>,`;
    })
    .join(" ");
}

function formatNightAggMotionConfig(change, key) {
  let description = "";
  description += `${titleCase(key)} ${
    change.new.disabled ? "disabled" : "enabled"
  } : Sensitivity ${change.new.sensitivity},`;

  return description;
}

function formatPMS(changes, change, key) {
  let description = "";
  if (key === "pms_paused_at") {
    description += `${titleCase(
      change["property_management_system"]
    )} sync has been paused,`;
  } else if (key === "unit_enrollment_mode") {
    const newMode = titleCase(changes["property_management_system"].new);
    const oldMode = titleCase(change.old);
    const newChange = titleCase(change.new);
    description += change.old
      ? `Pms sync mode changed from "${oldMode}" to "${newChange}",`
      : `${newMode} mode <green>"${newChange}"</green> has been added,`;
  } else if (change.new && !change.old) {
    description += `${titleCase(change.new)} sync has been turned on,`;
  } else {
    description += `${titleCase(change.old)} sync has been turned off,`;
  }
  return description;
}

function formatTimeSchedule(changes, ignoreKeys = [], parentKey) {
  let description = "";
  Object.entries(changes.new).forEach(([key, newValue]) => {
    if (
      !ignoreKeys.includes(key) &&
      JSON.stringify(newValue) !== JSON.stringify(changes.old[key])
    ) {
      description += [
        "alert_resumption_margin",
        "late_customer_margin",
      ].includes(key)
        ? `${titleCase(key)} changed from <red>${
            changes.old[key]
          }</red> to <green>${newValue}</green>,`
        : `${
            parentKey === "office_schedule" ? "Office" : "Access"
          } hours has been updated on ${titleCase(key)} from <red>"${
            changes.old[key].starts_at
          }" to "${changes.old[key].ends_at}"</red> to <green>"${
            newValue.starts_at
          }" to "${newValue.ends_at}"</green>,`;
    }
  });
  return description;
}

function formatLocationUpdate(changes, parentKey) {
  if (!changes) return "";

  const { new: newLocation, old: oldLocation } = changes;

  // Handle cases where entire new or old object is null
  if (newLocation === null && oldLocation !== null) {
    return `${titleCase(parentKey)} has been removed,`;
  }
  if (oldLocation === null && newLocation !== null) {
    return `${titleCase(parentKey)} has been added,`;
  }

  // If both are null, return empty string
  if (newLocation === null && oldLocation === null) {
    return "";
  }

  const descriptionParts = Object.keys({ ...newLocation, ...oldLocation })
    .filter((key) => {
      const oldValue = oldLocation?.[key];
      const newValue = newLocation?.[key];
      return oldValue !== newValue && (oldValue !== null || newValue !== null);
    })
    .map((key) => {
      const oldValue = oldLocation?.[key];
      const newValue = newLocation?.[key];
      if (oldValue === undefined && newValue !== null) {
        return `${titleCase(key)} <green>${formatValue(
          newValue
        )}</green> has been added,`;
      } else if (oldValue !== null && newValue === undefined) {
        return `${titleCase(key)} <red>${formatValue(
          oldValue
        )}</red> has been removed,`;
      } else {
        return `${titleCase(key)} changed from <red>${formatValue(
          oldValue
        )}</red> to <green>${formatValue(newValue)}</green>,`;
      }
    });

  return descriptionParts.length
    ? `${titleCase(parentKey)}- ${descriptionParts.join(" ")}`
    : "";
}
