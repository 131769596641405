<template>
  <CardLayout title="Sensors">
    <v-data-table :headers="headers" :items="devices" :loading="loading">
      <template #item.subtype="{ item }">
        {{ titleCase(deviceType(item)) }}
      </template>
      <template #item.location="{ item }">
        <CardDeviceLocations :device="item" />
      </template>
      <template #item.shortId="{ item }">
        {{ item.shortId || item.externalId }}
      </template>
      <template #item.batteryLevel="{ item }">
        <DeviceGaugeCluster :device="item" />
      </template>
      <template #item.motionStartedAt="{ item }">
        {{ deviceLastMotion(item) }}
      </template>
      <template #item.manage>
        <v-btn text @click="gotoManageDevices">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import { deviceLastMotion } from "@components/common/helpers/devices";
import CardLayout from "@layout/CardLayout.vue";
import { titleCase } from "@tod-ui/helpers/strings";
import CardDeviceLocations from "./CardDeviceLocations.vue";
import { deviceType } from "@tod-ui/helpers/devices";

export default {
  name: "CardSensors",
  components: {
    CardLayout,
    DeviceGaugeCluster,
    CardDeviceLocations,
  },
  props: {
    devices: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      headers: [
        { text: "Sensor Type", value: "subtype" },
        { text: "Device Location", value: "location" },
        { text: "Device ID", value: "shortId" },
        { text: "Health", value: "batteryLevel" },
        { text: "Last Incident", value: "motionStartedAt" },
        { text: "Manage Devices", value: "manage", sortable: false },
      ],
    };
  },
  computed: {
    smartZoneId() {
      return this.$route.params.smart_zone_id;
    },
  },
  methods: {
    gotoManageDevices() {
      this.$router.push({
        name: "ManageSmartUnitDevices",
        query: { subtype: "zone" },
        params: { zone_id: this.smartZoneId },
      });
    },
    deviceLastMotion,
    titleCase,
    deviceType,
  },
};
</script>
