<template>
  <v-skeleton-loader :loading="loading" type="image">
    <ZoneEnvironmentReportChart
      v-if="hasEvents"
      :chart-data="chartData"
      :min="chartMin"
      :max="chartMax"
    />
    <div v-else>
      <em>No data available</em>
    </div>
  </v-skeleton-loader>
</template>

<script>
import { ref, computed } from "vue";
import { deviceTelemetryEvents } from "../../../graphql/organizations/zone";
import ZoneEnvironmentReportChart from "@components/reports/ZoneEnvironmentReportChart.vue";
import { SD_ACCENT, SD_PRIMARY } from "@tod-ui/constants/colors";

export default {
  name: "ZoneHumidityChart",
  components: {
    ZoneEnvironmentReportChart,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    dateTimeFilter: {
      type: Object,
      default: () => ({
        before: null,
        after: null,
      }),
    },
    zoneProfile: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const events = ref({
      humidityEvents: [],
      hhThresh: null,
      lhThresh: null,
    });
    const loading = ref(false);

    const hasEvents = computed(() => events.value.humidityEvents.length > 0);
    const chartMin = computed(() => events.value.humidityEvents[0]?.x);
    const chartMax = computed(
      () =>
        events.value.humidityEvents[events.value.humidityEvents.length - 1]?.x
    );

    const chartData = computed(() => {
      if (!hasEvents.value) return null;

      let ds = {
        datasets: [
          {
            label: "Humidity %",
            data: events.value.humidityEvents,
            backgroundColor: "rgba(196, 200, 255, 0.8)",
            borderColor: SD_PRIMARY,
            borderWidth: 2,
            pointBackgroundColor: SD_PRIMARY,
            pointBorderColor: SD_PRIMARY,
            hoverRadius: 5,
            tension: 0.4,
            showLine: true,
            fill: true,
          },
        ],
      };

      // Add humidity thresholds if zone profile exists and climate config is enabled
      if (
        props.zoneProfile?.climateConfig &&
        !props.zoneProfile.climateConfig.disabled
      ) {
        const { highHumidityThreshold, lowHumidityThreshold } =
          props.zoneProfile.climateConfig;

        // Add high humidity threshold
        if (highHumidityThreshold && events.value.humidityEvents.length > 0) {
          const hhThreshData = [
            {
              y: highHumidityThreshold,
              x: events.value.humidityEvents[0].x,
            },
            {
              y: highHumidityThreshold,
              x: events.value.humidityEvents[
                events.value.humidityEvents.length - 1
              ].x,
            },
          ];

          ds.datasets.push({
            label: "Upper Humidity Threshold %",
            data: hhThreshData,
            backgroundColor: "transparent",
            borderColor: SD_ACCENT,
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            hoverRadius: 5,
            tension: 0.4,
            showLine: true,
            borderDash: [5, 2],
            fill: false,
            pointRadius: 0,
          });
        }

        // Add low humidity threshold
        if (lowHumidityThreshold && events.value.humidityEvents.length > 0) {
          const lhThreshData = [
            {
              y: lowHumidityThreshold,
              x: events.value.humidityEvents[0].x,
            },
            {
              y: lowHumidityThreshold,
              x: events.value.humidityEvents[
                events.value.humidityEvents.length - 1
              ].x,
            },
          ];

          ds.datasets.push({
            label: "Lower Humidity Threshold %",
            data: lhThreshData,
            backgroundColor: "transparent",
            borderColor: "Blue",
            borderWidth: 2,
            pointBackgroundColor: "transparent",
            pointBorderColor: "transparent",
            hoverRadius: 5,
            tension: 0.4,
            showLine: true,
            borderDash: [5, 2],
            fill: false,
            pointRadius: 0,
          });
        }
      }

      return ds;
    });

    async function fetchData() {
      loading.value = true;
      try {
        const response = await this.$apollo.query({
          query: deviceTelemetryEvents,
          variables: {
            deviceId: props.deviceId,
            dateTimeFilter: props.dateTimeFilter,
          },
        });

        const humidityEvents = [];
        response.data.events.forEach((event) => {
          const payload = JSON.parse(event.data);
          if (payload.humid_pcnt != undefined) {
            humidityEvents.push({
              y: payload.humid_pcnt,
              x: new Date(payload.timestamp).toISOString(),
            });
          }
        });

        events.value = { humidityEvents };
      } catch (error) {
        console.error(error);
      } finally {
        loading.value = false;
      }
    }

    return {
      loading,
      hasEvents,
      chartData,
      chartMin,
      chartMax,
      fetchData,
    };
  },
  watch: {
    deviceId: {
      immediate: true,
      handler: "fetchData",
    },
    dateTimeFilter: {
      handler: "fetchData",
      deep: true,
    },
  },
};
</script>
