import gql from "graphql-tag";

export const accountDocumentFields = gql`
  fragment AccountDocumentFields on AccountDocument {
    id
    status
    type
    name
    file
    insertedAt
    updatedAt
  }
`;

export const BILLING_ACCOUNT_DOCUMENTS = gql`
  query BillingAccountDocuments($billingAccountId: ID!) {
    billingAccountDocuments(billingAccountId: $billingAccountId) {
      ...AccountDocumentFields
      organization {
        id
        name
      }
    }
  }
  ${accountDocumentFields}
`;

export const ACCOUNT_DOCUMENT = gql`
  query BillingAccountDocument($documentId: ID!) {
    billingAccountDocument(documentId: $documentId) {
      ...AccountDocumentFields
    }
  }
  ${accountDocumentFields}
`;

export const ACCOUNT_DOCUMENT_FILE = gql`
  query BillingAccountDocumentFile($documentId: ID!) {
    billingAccountDocument(documentId: $documentId) {
      ...AccountDocumentFields
      fileUrl
    }
  }
  ${accountDocumentFields}
`;

export const RESEND_DOCUMENT_NOTIFICATIONS = gql`
  mutation ResendDocumentNotifications($documentId: ID!) {
    resendDocumentNotifications(documentId: $documentId) {
      type
      id
    }
  }
`;

export const UPLOAD_DOCUMENT_FILE = gql`
  mutation UploadDocumentFile(
    $documentId: ID!
    $file: Upload!
    $organizationId: ID!
  ) {
    uploadDocumentFile(
      documentId: $documentId
      file: $file
      organizationId: $organizationId
    ) {
      ...AccountDocumentFields
      fileUrl
    }
  }
  ${accountDocumentFields}
`;
