<template>
  <v-container
    :class="{ 'highlight-column': isHighlighted }"
    :style="containerStyle"
  >
    <div :style="{ color: textColor }">
      {{ formattedValue }}
      <IconArrow
        v-if="showArrow"
        :direction="arrowDirection"
        :color="textColor"
      />
      <slot></slot>
    </div>
  </v-container>
</template>

<script>
import IconArrow from "@atoms/IconArrow.vue";
import { DECLINE_RED, INCLINE_GREEN } from "@tod-ui/constants/colors";

export default {
  name: "HighlightContainer",
  components: {
    IconArrow,
  },
  props: {
    isHighlighted: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, String],
      default: "",
    },
    highlightColor: {
      type: String,
      default: "rgba(14, 59, 110, 0.2)",
    },
    isArrow: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    return { INCLINE_GREEN };
  },
  computed: {
    containerStyle() {
      return {
        backgroundColor: this.isHighlighted ? this.highlightColor : "",
      };
    },
    textColor() {
      if (typeof this.value !== "number" || this.value === 0) return "black";
      return this.value > 0 ? INCLINE_GREEN : DECLINE_RED;
    },
    showArrow() {
      return this.isArrow && typeof this.value === "number" && this.value !== 0;
    },
    arrowDirection() {
      return this.value > 0 ? "up" : "down";
    },
    formattedValue() {
      if (typeof this.value !== "number") return this.value;
      return `${Math.abs(this.value)}%`;
    },
  },
};
</script>

<style scoped>
.highlight-column {
  height: 100%;
  width: 100%;
}
</style>
