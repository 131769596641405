<template>
  <v-card v-if="editing" outlined tile>
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left>fas fa-door-open</v-icon>
      Zone Entry
      <v-spacer />
      <v-switch
        v-model="config.disabled"
        title="Enable/Disable"
        hide-details
        inset
        :false-value="true"
        :true-value="false"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text>
      <v-radio-group v-model="config.schedule" :disabled="config.disabled">
        <v-radio label="24/7 monitoring" value="always" />
        <v-radio label="Night monitoring" value="night" />
        <v-radio label="Day monitoring" value="day" />
        <v-radio label="Custom schedule" value="custom" />
        <template v-if="config.schedule === 'custom'">
          <TimeSelector
            v-model="config.startsAt"
            label="Starts At"
          ></TimeSelector>
          <TimeSelector v-model="config.endsAt" label="Ends At"></TimeSelector>
        </template>
      </v-radio-group>
    </v-card-text>
  </v-card>

  <v-card v-else outlined tile :disabled="config.disabled">
    <v-toolbar dense flat class="subtitle-1">
      <v-icon left :color="config.disabled ? null : 'success'">
        fas fa-door-open
      </v-icon>
      Zone Entry
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text v-if="!config.disabled">
      <span>Schedule: {{ schedule }}</span>
      <template v-if="config.schedule === 'custom'">
        <br />
        <span>
          {{ formatTime12hr(config.startsAt) }}
          -
          {{ formatTime12hr(config.endsAt) }}
        </span>
      </template>
    </v-card-text>
    <v-card-text v-else> Disabled </v-card-text>
  </v-card>
</template>

<script>
import { formatTime12hr } from "@tod-ui/helpers/datetime";
import TimeSelector from "../utils/TimeSelector";

export default {
  name: "EntryConfigWidget",
  components: {
    TimeSelector,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    editing: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    schedule() {
      const { schedule } = this.config;
      if (!schedule) return schedule;

      if (schedule === "always") return "24/7 monitoring";
      if (schedule === "day") return "Day monitoring";
      if (schedule === "night") return "Night monitoring";
      if (schedule === "custom") return "Custom";
    },
  },
  methods: {
    formatTime12hr,
  },
};
</script>
