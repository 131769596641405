import gql from "graphql-tag";
import {
  contactMethodFields,
  organizationFields,
  profileFields,
  userFields,
} from "../../graphql/fragments";

export const ZONE_RESPONDERS = gql`
  query ZoneResponders($id: ID!) {
    organizationResponders(id: $id, includeAncestors: true) {
      id
      escalationLevel
      incidentTypes
      accessHours
      type
      status
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
      }
      user {
        ...UserFields
      }
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${userFields}
`;

export const UPDATE_SMART_ZONE = gql`
  mutation UpdateZone(
    $id: ID!
    $name: String!
    $timeZone: String!
    $profile: InputZoneProfile!
    $subtype: String!
  ) {
    updateZone(
      id: $id
      name: $name
      timeZone: $timeZone
      subtype: $subtype
      profile: $profile
    ) {
      ...OrganizationFields
      profile {
        ...ProfileFields
      }
    }
  }
  ${organizationFields}
  ${profileFields}
`;

export const EEN_EVENTS = gql`
  query eenEvents(
    $organizationId: String!
    $actor: String!
    $eventType: String!
  ) {
    eenEvents(
      organizationId: $organizationId
      actor: $actor
      eventType: $eventType
    ) {
      accountId
      actorId
      actorType
      creatorId
      endTimestamp
      id
      startTimestamp
      thumbnail
      type
    }
  }
`;

export const EEN_VIDEO_FEED = gql`
  query eenVideoFeed(
    $fromTimeStamp: String!
    $deviceId: String!
    $organization_id: String!
  ) {
    eenVideoFeed(
      fromTimestamp: $fromTimeStamp
      deviceId: $deviceId
      organization_id: $organization_id
    ) {
      video
    }
  }
`;

export const EEN_VIDEO_CAMERA_CONFIG = gql`
  query eenVideoCameraConfig($cameraId: String!, $organizationId: String!) {
    eenVideoCameraConfig(cameraId: $cameraId, organizationId: $organizationId) {
      typeOfEvents
    }
  }
`;

export const GET_ALL_EEN_EVENTS = gql`
  query eenAllEvents(
    $organizationId: ID!
    $actor: String!
    $eventType: String!
    $pageToken: String
    $pageSize: Int
  ) {
    eenAllEvents(
      organizationId: $organizationId
      actor: $actor
      eventType: $eventType
      pageToken: $pageToken
      pageSize: $pageSize
    ) {
      results {
        id
        startTimestamp
        endTimestamp
        actorId
        type
      }
      nextPageToken
      prevPageToken
    }
  }
`;
