<template>
  <v-footer class="footer">
    <div class="footer-content mx-auto mt-2">
      <p class="body-2 grey--text text--darken-2">
        {{ copyright }}
      </p>
    </div>
  </v-footer>
</template>

<script>
import { copyrightInfo } from "@tod-ui/helpers/strings";

export default {
  setup() {
    const copyright = copyrightInfo();
    return { copyright };
  },
};
</script>

<style lang="scss" scoped>
@media print {
  .theme--light.v-footer {
    background: none;
    .footer-content {
      margin-right: 1em !important;
      height: 1em;
    }
  }
}
</style>
