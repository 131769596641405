<template>
  <v-sheet v-if="src" class="embedded-wrapper">
    <iframe :src="src" frameborder="0" class="embedded-content"></iframe>
  </v-sheet>
</template>

<script>
import { computed, unref } from "vue";
import { addUrlParams } from "../common/helpers/datastudio";
import { timeZoneOffset } from "@tod-ui/helpers/datetime";
import useFacility from "../authentication/useFacility";

export default {
  name: "FacilityManagementReport",
  setup() {
    const { currentFacility } = useFacility();

    const src = computed(() => {
      const facility = unref(currentFacility);
      return addUrlParams(
        import.meta.env.VUE_APP_EMBEDDED_FACILITY_MANAGEMENT_URL,
        {
          id: facility.id,
          organization_id: facility.parentId,
          mdiff: timeZoneOffset(facility.timeZone),
        }
      );
    });

    return { src };
  },
};
</script>
