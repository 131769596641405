import gql from "graphql-tag";

export const ORGANIZATION_UNIT_TYPES = gql`
  query OrganizationUnitTypes($id: ID!) {
    organization(id: $id) {
      id
      unitTypes {
        id
        name
        width
        length
      }
    }
  }
`;
