import PageSmartZones from "./PageSmartZones.vue";
import PageSmartZoneDrilldown from "./PageSmartZoneDrilldown.vue";

const routes = {
  organization: [
    {
      path: "SmartZones",
      name: "SmartZones",
      component: PageSmartZones,
    },
    {
      path: "SmartZone/:smart_zone_id",
      name: "ViewSmartZone",
      component: PageSmartZoneDrilldown,
    },
  ],
};

export default routes;
