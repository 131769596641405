<script>
import { Line, mixins, Scatter } from "vue-chartjs";
// https://github.com/chartjs/chartjs-adapter-luxon
// FIXME: import "chartjs-adapter-luxon";
const { reactiveProp } = mixins;

export default {
  name: "FacilityIncidentReportDailyChart",
  extends: Scatter,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: null,
      required: true,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 500,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            let lbl = `${Number(tooltipItem.value).toFixed(2)} events at `;
            if (tooltipItem.index == 0) {
              return lbl + `12:00 AM`;
            } else if (tooltipItem.index < 12) {
              return lbl + `${tooltipItem.index}:00 AM`;
            } else if (tooltipItem.index == 12) {
              return lbl + `${tooltipItem.index}:00 PM`;
            } else {
              return lbl + `${tooltipItem.index - 12}:00 PM`;
            }
          },
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            distribution: "linear",
            ticks: {
              min: 0,
              max: 24,
              stepSize: 1,
              autoSkip: false,
              callback: function (label, index, labels) {
                if (index == 0) {
                  return `12:00 AM`;
                } else if (index < 12) {
                  return `${index}:00 AM`;
                } else if (index == 12) {
                  return `${index}:00 PM`;
                } else {
                  return `${index - 12}:00 PM`;
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              stepSize: 1,
              min: 0,
              max: this.max,
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
  methods: {},
};
</script>
