<template>
  <span class="actions-wrap">
    <v-btn
      v-if="notification.hasResolution && !isCurrentResolution"
      :icon="icon"
      title="Resolve this notification"
      color="warning"
      small
      @click.stop="redirect"
    >
      <v-icon small :left="!icon">fas fa-arrow-circle-right</v-icon>
      {{ icon ? "" : "Resolve" }}
    </v-btn>
    <v-btn
      v-if="notification.isDismissible && isForCurrentUser"
      :icon="icon"
      :outlined="!icon"
      title="Dismiss this notification"
      small
      @click.stop="dismiss"
    >
      <v-icon small :left="!icon">fas fa-times-circle</v-icon>
      {{ icon ? "" : "Dismiss" }}
    </v-btn>
  </span>
</template>

<script>
import { computed } from "vue";
import useNavigation from "@layout/useNavigation";

export default {
  name: "NotificationActionButtons",
  props: {
    notification: {
      type: Object,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    isForCurrentUser: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { isCurrentRoute } = useNavigation();

    const isCurrentResolution = computed(() =>
      isCurrentRoute(props.notification.resolutionRoute)
    );

    function redirect() {
      emit("redirect", props.notification);
    }
    function dismiss() {
      if (props.isForCurrentUser) {
        emit("dismiss", props.notification);
      }
    }

    return {
      isCurrentResolution,
      redirect,
      dismiss,
    };
  },
};
</script>

<style lang="scss" scoped>
.actions-wrap {
  display: inline-flex;
  & > .v-btn:not(.v-btn--icon) {
    margin-left: 8px;
  }
  & > .v-btn:first-child {
    margin-left: 0;
  }
}
</style>
