<template>
  <v-card tile>
    <v-card-text>
      <v-row>
        <v-col md="6">
          <v-skeleton-loader :loading="loading" type="article, actions">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(save)">
                <v-row dense>
                  <v-col cols="12">
                    <v-select
                      v-if="facility.profile"
                      v-model="primaryZoneId"
                      clearable
                      clear-icon="far fa-times-circle"
                      hint="Sets the Smart Zone displayed on the Dashboard"
                      label="Primary Smart Zone"
                      :items="smartZoneOptions"
                    />
                  </v-col>
                </v-row>

                <v-card-actions class="pt-4">
                  <v-btn depressed tile class="success" type="submit">
                    Save Changes
                  </v-btn>
                  <v-btn depressed outlined tile @click="cancel">Cancel</v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { omitTypename } from "../utils/graphql";

import { organizationSmartZones } from "../../graphql/organizations";

import { required } from "vee-validate/dist/rules";
import { extend, setInteractionMode, ValidationObserver } from "vee-validate";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";

setInteractionMode("lazy");

extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  name: "EditFacilitySmartZones",
  components: {
    // ValidatedTextField,
    ValidationObserver,
  },
  props: {
    facility: {
      validator: (val) => val === null || typeof val === "object",
      required: true,
    },
  },
  setup() {
    const { currentFacilityId, updateCurrentFacility } = useFacility();
    const { addAlert } = useAlerts();
    return { currentFacilityId, updateCurrentFacility, addAlert };
  },
  data() {
    return {
      smartZones: [],
      primaryZoneId: this.facility.profile?.primaryZoneId,
    };
  },
  computed: {
    loading() {
      return !this.facility?.name;
    },
    smartZonesLoading() {
      return this.$apollo.queries.smartZones.loading;
    },
    smartZoneOptions() {
      if (this.smartZonesLoading) return [];

      return this.smartZones.map(({ id, name }) => {
        return { value: id, text: name };
      });
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      update({ smartZones }) {
        return smartZones.filter((zone) => zone.devices.length > 0);
      },
    },
  },
  methods: {
    cancel() {
      this.$apollo.queries.facilityDetails.refetch();
    },
    async save() {
      try {
        const result = await this.updateCurrentFacility(this.$apollo, {
          profile: {
            ...omitTypename(this.facility.profile),
            primaryZoneId: this.primaryZoneId,
          },
        });

        if (result) {
          this.addAlert({
            type: "success",
            message: "Facility Smart Zones updated successfully",
            timeout: 15,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
        });
      }
    },
  },
};
</script>
