<template>
  <v-btn
    small
    :disabled="notSelected || inProcess"
    color="success"
    @click="assignToSmartUnit"
  >
    Assign
  </v-btn>
</template>
<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed, defineComponent, h, ref } from "vue";
import useDevice from "../useDevice";

export default defineComponent({
  name: "ButtonAssignDevice",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { moveDevice, selectedUnit } = useDevice(props.device);
    const notSelected = computed(() => !selectedUnit.value);
    const inProcess = ref(false);
    const { addAlert } = useAlerts();
    return { moveDevice, notSelected, inProcess, selectedUnit, addAlert };
  },
  methods: {
    async assignToSmartUnit() {
      if (!this.device?.id || !this.selectedUnit) return;

      this.inProcess = true;
      try {
        const successMessage = await this.moveDevice(
          this.$apollo,
          this.selectedUnit
        );
        if (successMessage) {
          this.addAlert({
            type: "success",
            message: successMessage,
            timeout: 10,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
          timeout: 15,
        });
      }
      this.inProcess = false;
    },
  },
});
</script>
