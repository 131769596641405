<template>
  <LayoutPage title="Task List">
    <AddTaskDialog v-model="isOpen" />
    <div class="d-flex justify-end mb-1">
      <v-btn dense style="height: 45px" color="primary" @click="openDialog"
        ><v-icon>mdi-plus</v-icon> Add Task</v-btn
      >
    </div>
    <IncidentHistory
      v-model="isIncidentHistoryOpen"
      selected-disposition="Open"
      :task="selectedTask"
    />
    <v-row>
      <v-col>
        <v-select
          v-model="selectedRegion"
          dense
          :items="regions"
          label="Select Regions"
          outlined
          item-text="name"
          item-value="id"
        />
      </v-col>
      <v-col>
        <v-autocomplete
          v-model="selectedFacilities"
          dense
          :items="facilities"
          label="Select Facilities"
          multiple
          outlined
          clearable
          item-text="name"
          item-value="id"
        >
          <template #selection="{ item, index }">
            <span v-if="index === 0">{{ item.name }}</span>
            <span v-if="index === 1" class="grey--text caption"
              >(+{{ selectedFacilities.length - 1 }} others)</span
            >
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          v-model="selectedAssignee"
          :items="['Helen(Facility Manager)', 'John (Facility Admin)']"
          dense
          outlined
          label="Assignee"
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedTaskStatus"
          :items="['All', 'To Do', 'In Progress', 'Done']"
          dense
          outlined
          label="Task Status"
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <div class="mr-4">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template #activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Due Date"
              readonly
              v-bind="attrs"
              outlined
              dense
              v-on="on"
            />
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer />
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(date)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
      <div style="min-width: 260px; margin-bottom: 25px">
        <SearchField v-model="search" />
      </div>
    </div>

    <v-data-table
      :headers="headers"
      :items="filteredTableData"
      :items-per-page="10"
      :search="search"
      class="elevation-1"
    >
      <template #item="{ item }">
        <tr>
          <td>
            <ButtonView title="View" @click="openIncidentHistory(item)" />
          </td>
          <td style="min-width: 150px">{{ item.id }}</td>
          <td style="max-width: 300px">
            <div><strong>Organization</strong> : {{ item.organization }}</div>
            <div class="mt-2">
              <strong>Facility</strong> : {{ item.facility }}
            </div>
            <div class="mt-2">
              <strong>Smart Unit / Zone</strong> : {{ item.smart_unit }}
            </div>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.type }}</td>
          <td>{{ item.assignee }}</td>
          <td>
            <div>
              {{ item.status }}
              <span v-if="item.overdue" class="overdue-text">Overdue!</span>
            </div>
          </td>
          <td>{{ item.priority }}</td>
          <td>
            {{ item.due_date }}
          </td>
        </tr>
      </template>
      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="tableData"
          :headers="headers"
          filename="Incident Disposition Report"
        />
      </template>
    </v-data-table>
  </LayoutPage>
</template>

<script>
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import ButtonView from "@atoms/ButtonView.vue";
import SearchField from "@atoms/SearchField.vue";
import LayoutPage from "@components/layout/LayoutPage.vue";
import { computed, ref, watchEffect } from "vue";
import { useRoute } from "vue-router/composables";
import IncidentHistory from "./IncidentHistory.vue";
import AddTaskDialog from "./AddTaskDialog.vue";

export default {
  components: {
    SearchField,
    LayoutPage,
    ButtonExportCSV,
    ButtonView,
    IncidentHistory,
    AddTaskDialog,
  },
  setup() {
    const route = useRoute();
    const status = computed(() => {
      return route.params.status;
    });
    const selectedTaskStatus = ref("To Do");
    const isOverdue = computed(() => {
      return status.value.includes("overdue");
    });

    watchEffect(() => {
      if (status.value === "inprogress") {
        selectedTaskStatus.value = "In Progress";
      }
      if (status.value === "overdue") {
        selectedTaskStatus.value = "All";
      }
    });

    const tableData = ref([
      {
        id: "FL-TUS-01",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "100",
        name: "Unit Inspection - Vacant Smart Unit Activity During Day",
        type: "Clean Up",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "13/6/2024",
        overdue: false,
      },
      {
        id: "FL-TUS-02",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "101",
        name: "Cleaning Unit- Vacant Smart Unit Activity During Day",
        type: "Clean Up",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "13/6/2024",
        overdue: false,
      },
      {
        id: "FL-TUS-03",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "102",
        name: "Cleaning Unit- Vacant Smart Unit Activity During Day",
        type: "Clean Up",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "13/6/2024",
        overdue: false,
      },
      {
        id: "FL-TUS-04",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "103",
        name: "Cleaning Unit- Vacant Smart Unit Activity During Day",
        type: "Clean Up",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "13/6/2024",
        overdue: false,
      },
      {
        id: "FL-TUS-05",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "104",
        name: "Look into Active Tenant Smart Unit Un-Authorized Activity After Hours",
        type: "Incident Resolution",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "13/6/2024",
        overdue: false,
      },
      {
        id: "FL-TUS-06",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "105",
        name: "Look into Active Tenant Smart Unit Un-Authorized Activity After Hours",
        type: "Incident Resolution",
        assignee: "Helen(Facility Manager)",
        status: "In Progress",
        priority: "Low",
        due_date: "10/6/2024",
        overdue: true,
      },
      {
        id: "FL-TUS-07",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "106",
        name: "Look into Active Tenant Smart Unit Un-Authorized Activity After Hours",
        type: "Incident Resolution",
        assignee: "Helen(Facility Manager)",
        status: "In Progress",
        priority: "Medium",
        due_date: "10/6/2024",
        overdue: true,
      },
      {
        id: "FL-TUS-08",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "107",
        name: "Look into Active Tenant Smart Unit Un-Authorized Activity After Hours",
        type: "Incident Resolution",
        assignee: "Helen(Facility Manager)",
        status: "In Progress",
        priority: "Medium",
        due_date: "10/6/2024",
        overdue: true,
      },
      {
        id: "FL-TUS-09",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "Floor2 Hallway",
        name: "Fix the door magnet Properly",
        type: "Maintenance",
        assignee: "Helen(Facility Manager)",
        status: "Done",
        priority: "Medium",
        due_date: "05/06/2024",
        overdue: true,
      },
      {
        id: "FL-TUS-10",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "Floor1 Hallway",
        name: "Fix the door magnet Properly",
        type: "Maintenance",
        assignee: "Helen(Facility Manager)",
        status: "Done",
        priority: "Medium",
        due_date: "05/06/2024",
        overdue: true,
      },
      {
        id: "FL-TUS-11",
        organization: "The Storage Asset Management",
        facility: "Long Beach",
        smart_unit: "108",
        name: "Unit 101 need device assignment",
        type: "Others",
        assignee: "Helen(Facility Manager)",
        status: "To Do",
        priority: "High",
        due_date: "10/6/2024",
        overdue: true,
      },
    ]);

    // Computed property for filtered table data
    // Computed property for filtered table data
    const filteredTableData = computed(() => {
      return tableData.value.filter((task) => {
        const matchesStatus =
          selectedTaskStatus.value === "All" ||
          task.status === selectedTaskStatus.value;

        // Check if the task is overdue and if it matches the selected status
        const matchesOverdue =
          selectedTaskStatus.value === "All" && task.overdue;

        return matchesStatus && (matchesOverdue || !isOverdue.value);
      });
    });

    return {
      selectedTaskStatus,
      filteredTableData,
      tableData,
    };
  },
  data() {
    return {
      menu: null,
      isOpen: false,
      isIncidentHistoryOpen: false,
      selectedAssignee: "Helen(Facility Manager)",
      date: "2024-06-07",
      selectedRegion: { id: 1, name: "Three by Three Storage" },
      regions: [
        { id: 1, name: "Three by Three Storage" },
        { id: 2, name: "Region 2" },
        { id: 3, name: "Region 3" },
      ],
      selectedFacilities: [
        { id: 1, name: "3x3 Self Storage - Denton" },
        { id: 2, name: "3x3 Self Storage - Fort Worth" },
        { id: 3, name: "3x3 Self Storage - Waco" },
      ],
      facilities: [
        { id: 1, name: "3x3 Self Storage - Denton" },
        { id: 2, name: "3x3 Self Storage - Fort Worth" },
        { id: 3, name: "3x3 Self Storage - Waco" },
      ],
      search: null,
      notification: {
        id: 1,
        message: "This is a test notification",
        hasResolution: true,
        isDismissible: true,
        resolutionRoute: "/resolution-route",
      },
      headers: [
        { text: "View Task", value: "view" },
        { text: "Task ID", value: "id" },
        { text: "Task Location", value: "task_location" },
        // { text: "Organization", value: "organization" },
        // { text: "Facility", value: "facility" },
        // { text: "Smart Unit/Zone", value: "smart_unit" },
        { text: "Task Name", value: "name" },
        { text: "Task Type", value: "type" },
        { text: "Assignee", value: "assignee" },
        { text: "Task Status", value: "status" },
        { text: "Task Priority", value: "type" },
        { text: "Task Due Date", value: "due_date" },
      ],
      selectedTask: {
        id: "INC001",
        name: "Active Motion",
        severity: "Severe",
        assignee: "Helen(Facility Manager)",
        dueDate: "2024-06-16",
        comments: "This is a comment",
      },
    };
  },
  methods: {
    openIncidentHistory(task) {
      this.selectedTask = task;
      this.isIncidentHistoryOpen = true;
    },
    openDialog() {
      this.isOpen = true;
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 1rem;
}

.overdue-text {
  color: rgb(254, 10, 5);
  font-weight: bold;
  display: block;
  margin-top: 4px;
  font-size: 12px;
}
</style>
