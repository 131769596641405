import { ref } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import useFacility from "../authentication/useFacility";
import { GET_ASSIGNED_UNITS } from "./graphql";

const assignedUnits = ref({});
const assignedUnitsLoading = ref(true);

export default function useAssignedUnits() {
  assignedUnitsLoading.value = true;
  const { currentFacilityId } = useFacility();

  let observable = null;

  function fetchAssignedUnits(apolloClient, renterId) {
    observable = apolloWatchQuery(
      apolloClient,
      {
        query: GET_ASSIGNED_UNITS,
        variables: {
          userId: renterId,
          parentOrganizationId: currentFacilityId.value,
        },
        fetchPolicy: "network-only",
      },
      (data) => {
        assignedUnits.value = (data.userOrganizations || []).sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        );
        assignedUnitsLoading.value = false;
      }
    );
    return observable;
  }

  async function refetchAssignedUnits() {
    if (observable) {
      const { data } = await observable.refetch();
      return data?.userOrganizations;
    }
  }

  return {
    assignedUnits,
    assignedUnitsLoading,
    fetchAssignedUnits,
    refetchAssignedUnits,
  };
}
