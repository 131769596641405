<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: !!this.title,
        text: this.title,
      },
      legend: {
        position: "top",
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      onClick: this.handleClick,
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (items.length > 0) {
        const clickedIndex = items[0]._index;
        const clickedDataset = items[0]._datasetIndex;
        const zoneLabel = this.chartData.datasets[clickedDataset].label;
        this.$emit("chartClicked", {
          date: this.chartData.labels[clickedIndex],
          zoneName: zoneLabel,
        });
      }
    },
  },
};
</script>
