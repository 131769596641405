// Provides organization utility functions
import { omitTypename } from "./graphql";

const DEFAULT_LOW_TEMPERATURE_THRESHOLD = "0.00"; //32 °F;
const DEFAULT_HIGH_TEMPERATURE_THRESHOLD = "27.78"; //82 °F;
const DEFAULT_LOW_HUMIDITY_THRESHOLD = "20";
const DEFAULT_HIGH_HUMIDITY_THRESHOLD = "74";

export function zoneSubtypes() {
  return [
    { text: "Smart Unit", value: "unit" },
    { text: "Smart Zone", value: "zone" },
  ];
}
export function buildZoneProfile({ type, deviceTypes }) {
  const defaultProfile = {
    climateConfig: {
      disabled: true,
      highHumidityThreshold: null,
      lowHumidityThreshold: null,
      highTemperatureThreshold: null,
      lowTemperatureThreshold: null,
    },
    motionConfigs: [
      {
        disabled: false,
        mode: "motion",
        schedule: "always",
      },
      {
        disabled: true,
        mode: "people_counting",
        schedule: "always",
      },
    ],
    entryConfig: {
      disabled: false,
      schedule: "always",
    },
  };

  if (type === "zone") {
    let profile = {};

    if (deviceTypes.includes("xpClimate")) {
      profile = {
        ...profile,
        climateConfig: defaultProfile.climateConfig,
        motionConfigs: defaultProfile.motionConfigs,
      };
    } else if (deviceTypes.includes("xp") || deviceTypes.includes("monit")) {
      profile.motionConfigs = defaultProfile.motionConfigs;
    }

    if (deviceTypes.includes("rb")) {
      profile.entryConfig = defaultProfile.entryConfig;
    }

    return profile;
  } else {
    return {};
  }
}

export function inputifyProfile(profile) {
  if (!profile) return profile;

  if (profile.climateConfig) {
    const {
      disabled,
      highTemperatureThreshold,
      lowTemperatureThreshold,
      highHumidityThreshold,
      lowHumidityThreshold,
    } = profile.climateConfig;

    profile.climateConfig = {
      disabled,
      highHumidityThreshold:
        highHumidityThreshold || DEFAULT_HIGH_HUMIDITY_THRESHOLD,
      lowHumidityThreshold:
        lowHumidityThreshold || DEFAULT_LOW_HUMIDITY_THRESHOLD,
      highTemperatureThreshold:
        highTemperatureThreshold || DEFAULT_HIGH_TEMPERATURE_THRESHOLD,
      lowTemperatureThreshold:
        lowTemperatureThreshold || DEFAULT_LOW_TEMPERATURE_THRESHOLD,
    };
  }

  if (profile.entryConfig) {
    profile.entryConfig = omitTypename(profile.entryConfig);
  }

  if (profile.motionConfigs) {
    profile.motionConfigs = profile.motionConfigs.map((c) => omitTypename(c));
  }

  if (profile.serviceRequestConfig) {
    profile.serviceRequestConfig = omitTypename(profile.serviceRequestConfig);
  }

  if (profile.videoConfig) {
    profile.videoConfig = omitTypename(profile.videoConfig);
  }

  return omitTypename(profile);
}
