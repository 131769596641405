<template>
  <v-chip
    class="severity-chip"
    style="color: white"
    :color="getColor(severity)"
  >
    {{ severity }}
  </v-chip>
</template>

<script>
export default {
  props: {
    severity: {
      type: String,
      required: true,
    },
  },
  setup() {
    function getColor(severity) {
      if (severity === "Critical" || severity === "High")
        return "rgb(254,10,5)";
      else if (severity === "Severe") return "rgb(173,2,1)";
      else if (severity === "Substantial") return "rgb(248,127,16)";
      else if (severity === "Moderate" || severity === "Medium")
        return "rgb(105,189,217)";
      else return "rgb(66,171,86)";
    }
    return {
      getColor,
    };
  },
};
</script>

<style>
.severity-chip {
  width: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
