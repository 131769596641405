<template>
  <LayoutPage title="Regional Reports">
    <v-tabs v-model="tab">
      <v-tab key="summaries">
        {{ organizationSummaryTitle }}
      </v-tab>
      <v-tab key="monthly_subscriptions">
        {{ organizationMontlySubscriptionTitle }}
      </v-tab>

      <v-tab
        v-if="enableReconciliationReport"
        key="reconciliation_charge_report"
      >
        Recurring Charge Reconciliation
      </v-tab>

      <v-tab v-if="enableTenantLedger" key="enable_tenant_ledger">
        Tenant Ledger
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="summaries">
        <OrganizationFacilitySummaries />
      </v-tab-item>
      <v-tab-item key="monthly_subscriptions">
        <OrganizationFacilitySubscriptions />
      </v-tab-item>

      <v-tab-item
        key="reconciliation_charge_report"
        v-if="enableReconciliationReport"
      >
        <ReconciliationChargesReport />
      </v-tab-item>

      <v-tab-item key="enable_tenant_ledger" v-if="enableTenantLedger">
        <TenantLedgerReport />
      </v-tab-item>
    </v-tabs-items>
  </LayoutPage>
</template>

<script>
import { computed, watch, ref } from "vue";
import useUser from "../authentication/useUser";
import useRouteQuery from "@components/common/useRouteQuery";

import OrganizationFacilitySummaries from "./OrganizationFacilitySummaries.vue";
import OrganizationFacilitySubscriptions from "./OrganizationFacilitySubscriptions.vue";
import TenantLedgerReport from "./TenantLedgerReport.vue";
import ReconciliationChargesReport from "./ReconciliationChargesReport.vue";

import { ORGANIZATION_PROFILE } from "./graphql";

import LayoutPage from "@layout/LayoutPage.vue";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "PageReportsOrganization",
  components: {
    LayoutPage,
    OrganizationFacilitySummaries,
    OrganizationFacilitySubscriptions,
    TenantLedgerReport,
    ReconciliationChargesReport,
  },
  setup() {
    const { addAlert, clearAllAlerts } = useAlerts();

    const { defaultOrganization } = useUser();

    const { tab, accordion } = useRouteQuery();

    //reset accordion value on tab switch
    watch(tab, (_value) => {
      accordion.value = undefined;
    });

    const organizationSummaryTitle = computed(
      () => `${defaultOrganization.value.name} Summary`
    );

    const organizationMontlySubscriptionTitle = computed(
      () => `${defaultOrganization.value.name} Subscriptions`
    );

    const organizationId = ref(null);
    const enableTenantLedger = ref(false);
    const enableReconciliationReport = ref(false);
    watch(
      defaultOrganization,
      (newVal) => {
        if (newVal?.id) {
          organizationId.value = newVal.id;
        }
      },
      { immediate: true }
    );

    return {
      tab,
      organizationSummaryTitle,
      organizationMontlySubscriptionTitle,
      enableTenantLedger,
      enableReconciliationReport,
      organizationId,
      addAlert,
    };
  },
  apollo: {
    organization: {
      query: ORGANIZATION_PROFILE,
      variables() {
        return {
          id: this.organizationId,
        };
      },
      update(data) {
        this.enableTenantLedger =
          data?.organization?.profile?.enableTenantLedger ?? false;
        this.enableReconciliationReport =
          data?.organization?.profile
            ?.enableRecurringchargeReconciliationReport ?? false;

        return data;
      },
      skip() {
        return !this.organizationId;
      },
    },
  },
};
</script>
