<template>
  <CardLayoutV2 title="Monthly New Subscriptions" :loading="loading">
    <ChartMonthlyNewSubscriptions :chart-data="chartData" :min="minValue" />
  </CardLayoutV2>
</template>

<script>
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import ChartMonthlyNewSubscriptions from "./MonthlyNewSubscriptionsChart.vue";
import { formatMonthYear } from "@tod-ui/helpers/datetime";
import { groupDataByMonth } from "../helper";
import { CHART_COLORS } from "@tod-ui/constants/colors";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    CardLayoutV2,
    ChartMonthlyNewSubscriptions,
  },
  props: {
    monthlySubscriptions: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    return { defaultOrganization };
  },
  computed: {
    chartData() {
      const { chartData, chartMonths } = groupDataByMonth(
        this.monthlySubscriptions,
        "subscriptionMonth",
        "subscriptionsCount"
      );

      return {
        labels: chartMonths.map((month) =>
          formatMonthYear(month, this.defaultOrganization.timeZone)
        ),
        datasets: [
          {
            label: "Monthly New Subscriptions",
            backgroundColor: CHART_COLORS[0],
            data: chartData,
          },
        ],
      };
    },
    minValue() {
      const minDataValue = Math.min(...this.chartData.datasets[0].data);
      const adjustedMin = minDataValue - Math.ceil(minDataValue * 0.1);
      return adjustedMin <= 0 ? 0 : adjustedMin;
    },
  },
};
</script>
