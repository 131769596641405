<template>
  <v-btn
    v-if="unitWithoutDevices && !removingAllDevices"
    small
    color="warning"
    :disabled="renterSynced && !renterSyncedInDeviceControlledMode"
    @click="submit"
  >
    {{ action }}
  </v-btn>

  <DialogLayout v-else v-model="isOpen" :title="title">
    <template v-if="!removingAllDevices" #activator="{ on }">
      <v-btn
        small
        color="warning"
        :disabled="renterSynced && !renterSyncedInDeviceControlledMode"
        v-on="on"
      >
        {{ action }}
      </v-btn>
    </template>

    <p class="subtitle-1">
      {{ questionText }}
    </p>

    <v-radio-group v-model="shouldRemoveDevices" dense>
      <v-radio label="Yes, I am removing the device(s)" :value="true" />
      <v-radio
        :label="`No, ${
          renterSyncedInDeviceControlledMode
            ? 'end the SD service for this renter (for all units), but leave device(s) in unit'
            : 'I am leaving the device(s) installed'
        }`"
        :value="false"
      />
    </v-radio-group>

    <template #actions>
      <v-btn color="primary" text @click="isOpen = false">Cancel</v-btn>
      <v-btn
        :disabled="shouldRemoveDevices === null"
        color="primary"
        text
        @click="submit"
      >
        Continue
      </v-btn>
    </template>
  </DialogLayout>
</template>

<script>
import DialogLayout from "@layout/DialogLayout.vue";
import usePMS from "@components/common/usePMS";
import useBillingAccount from "./useBillingAccount";
import { ref, computed, watch } from "vue";

export default {
  name: "DialogRemoveDevices",
  components: { DialogLayout },
  props: {
    unit: {
      type: Object,
      default: null,
    },
    renterSynced: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: null,
    },
  },
  setup(props, { emit }) {
    const shouldRemoveDevices = ref(null);
    const removingAllDevices = computed(() => !props.unit);
    const unitWithoutDevices = computed(() =>
      Array.isArray(props.unit?.devices)
        ? props.unit.devices.length === 0
        : null
    );
    watch(
      unitWithoutDevices,
      (value) => {
        if (value === true) {
          shouldRemoveDevices.value = true;
        }
      },
      { immediate: true }
    );

    const _value = ref(false);
    const isOpen = computed({
      get() {
        return props.value === null ? _value.value : props.value;
      },
      set(val) {
        if (props.value === null) {
          _value.value = val;
        } else {
          emit("input", val);
        }
      },
    });

    const { isDeviceControlledEnrollment } = usePMS();
    const { endedService } = useBillingAccount();
    const renterSyncedInDeviceControlledMode = computed(
      () =>
        props.renterSynced &&
        isDeviceControlledEnrollment.value &&
        !endedService.value
    );
    const title = computed(() =>
      renterSyncedInDeviceControlledMode.value
        ? "End SD Service"
        : `Remove ${removingAllDevices.value ? "Renter" : "Smart Unit"}`
    );
    const action = computed(() =>
      renterSyncedInDeviceControlledMode.value ? "End Service" : "Remove Unit"
    );
    const questionText = computed(() =>
      removingAllDevices.value
        ? `Removing this renter will unassign all of their Smart Units. Are you also removing the device(s) from this renter's Smart Unit(s)?`
        : renterSyncedInDeviceControlledMode.value
        ? `You want to terminate StorageDefender service for this renter. Are you removing the device(s) and stopping service only for Smart Unit ${props.unit.name}?`
        : `Are you also removing the device(s) from Smart Unit ${props.unit.name}?`
    );

    function submit() {
      isOpen.value = false;
      emit("submit", {
        value: shouldRemoveDevices.value,
        selectedUnit: props.unit,
      });
    }

    return {
      isOpen,
      shouldRemoveDevices,
      title,
      action,
      questionText,
      submit,
      removingAllDevices,
      unitWithoutDevices,
      renterSyncedInDeviceControlledMode,
    };
  },
};
</script>
