<template>
  <LayoutPage :title="['Renters', 'Add New Renter']">
    <v-row>
      <v-col lg="8">
        <CardLayout title="Renter Information">
          <CreateRenterAlert
            ref="createAlert"
            v-model="userExistsAlert"
            @renter-created="gotoRenter"
            @reset-phone="resetPrimaryPhone"
          />
          <FormRenterInfo ref="formInfo" new-renter />

          <v-card-actions>
            <v-btn
              :disabled="userExistsAlert || inProcess"
              color="success"
              @click="createRenter"
            >
              Add New Renter
            </v-btn>
            <v-btn color="grey" @click="$router.go(-1)"> Cancel </v-btn>
            <v-btn dark color="black" @click="clear"> Clear </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!canSendMessage || inProcess"
              @click="sendDemoMessage"
            >
              Send a Demo Message
            </v-btn>
          </v-card-actions>
        </CardLayout>
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CardLayout from "@layout/CardLayout.vue";
import CreateRenterAlert from "./CreateRenterAlert.vue";
import FormRenterInfo from "./FormRenterInfo.vue";

import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { createDemoMessage } from "./renter";

import useRenter from "./useRenter";
import usePMS from "../common/usePMS";
import useAlerts from "@tod-ui/composables/useAlerts";
import { onUnmounted, ref } from "vue";

export default {
  name: "PageRenterAdd",
  components: {
    LayoutPage,
    CardLayout,
    CreateRenterAlert,
    FormRenterInfo,
  },
  setup() {
    const { hasPMS } = usePMS();
    const { renter, primaryPhoneMethod, resetRenter, initRenter } = useRenter();
    const { addAlert, clearAllAlerts } = useAlerts();
    const inProcess = ref(false);

    onUnmounted(resetRenter);
    return {
      hasPMS,
      renter,
      primaryPhoneMethod,
      addAlert,
      clearAllAlerts,
      resetRenter,
      initRenter,
      inProcess,
    };
  },
  data: () => ({
    alert: {
      show: false,
      message: null,
      type: null,
    },
    userExistsAlert: false,
    // tailor the error message on username conflict
    canSendMessage: true,
  }),
  created() {
    if (this.hasPMS) {
      this.$router.replace({ name: "Renters" });
    }
  },
  beforeMount() {
    const { renterFirst, renterLast, primaryPhone } = this.$route.query;
    this.initRenter(renterFirst, renterLast, primaryPhone);
  },
  methods: {
    wait(ms) {
      if (!ms || ms <= 0) return;

      let start = new Date().getTime();
      let end = start;
      while (end < start + ms) {
        end = new Date().getTime();
      }
    },
    async sendDemoMessage() {
      // Don't allow send requests to overlap
      if (!this.canSendMessage) return;

      this.clearAllAlerts();
      const valid = await this.$refs.formInfo.validate();
      if (!valid) {
        this.addAlert({
          message:
            "Complete the required fields before sending the demo message",
        });
        return;
      }

      this.inProcess = true;
      try {
        // Disable the Send button & prevent multiple requests
        this.canSendMessage = false;
        const response = await createDemoMessage(this.$apollo, {
          ...this.renter,
          primaryPhone: this.primaryPhoneMethod.value,
        });
        if (response.data.createDemoMessage.id) {
          this.addAlert({
            type: "success",
            message: `Demo message has been sent to ${formatPhoneNumber(
              this.primaryPhoneMethod?.value
            )}`,
            timeout: 15,
          });
        } else {
          this.addAlert({
            message:
              "Demo message failed to send. Make sure the renter's primary phone number is valid.",
          });
        }
      } catch (error) {
        this.addAlert({
          message:
            "Demo message failed to send. Make sure the renter's primary phone number is valid.",
        });
      }

      this.canSendMessage = true;
      this.inProcess = false;
    },

    clear() {
      this.clearAllAlerts();
      this.userExistsAlert = false;
      // reset this.renter
      this.resetRenter();
      // reset the form validator
      this.$refs.formInfo.reset();
    },
    resetPrimaryPhone() {
      this.$refs.formInfo.reset();
      this.$refs.formInfo.focusPrimaryPhone();
    },

    async createRenter() {
      this.inProcess = true;
      const valid = await this.$refs.formInfo.validate();
      if (valid) {
        await this.$refs.createAlert.tryCreateRenter();
      }
      this.inProcess = false;
    },
    gotoRenter(renter) {
      // Push to the View Renter page
      this.$router.replace({
        name: "ViewRenter",
        params: {
          renter_id: renter.id,
        },
      });
    },
  },
};
</script>
