import router from "../router";

function lazyQueue() {
  const events = [];
  let started = false;

  return {
    push: (event) => {
      if (started) event();
      else events.push(event);
    },
    start: () => {
      started = true;

      while (events.length >= 1) {
        events.shift()();
      }
    },
  };
}

function loadScript(url) {
  return new Promise((resolve, reject) => {
    const head = document.head || document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.async = true;
    script.src = url;

    head.appendChild(script);

    script.onload = resolve;
    script.onerror = reject;
  });
}

function track({ category, action, label }) {
  if (_kmq) {
    _kmq.push(["record", category, action, label]);
  }
}

function trackPageView(to, from) {
  if (_kmq) {
    _kmq.push(["record", `Viewed ${to.name} Page`]);
  }
}

async function install(Vue) {
  if (!import.meta.env.VUE_APP_KISSMETRICS_KEY) return;

  const queue = lazyQueue();

  Vue.prototype.$kmTrack = (payload) => {
    queue.push(() => track(payload));
  };

  router.afterEach((to, from) => {
    queue.push(() => trackPageView(to, from));
  });

  const listeners = {};

  Vue.directive("track", {
    bind(el, { arg: event, value }, vnode) {
      if (vnode.componentInstance) {
        vnode.componentInstance.$on(event, () => {
          track(value);
        });
      } else {
        listeners[el] = () => {
          track(value);
        };
        el.addEventListener(event, listeners[el]);
      }
    },
    unbind(el, { arg: event }, vnode) {
      if (vnode.componentInstance) {
        vnode.componentInstance.$off(event);
      } else {
        el.removeEventListener(event, listeners[el]);
      }
    },
  });

  let _kmk = import.meta.env.VUE_APP_KISSMETRICS_KEY;
  await loadScript("//i.kissmetrics.io/i.js");
  await loadScript("//scripts.kissmetrics.io/" + _kmk + ".2.js");
  queue.start();
}

export default {
  install,
};
