<template>
  <CardLayout
    v-model="editing"
    title="Alert Recipients"
    :loading="smartUnitLoading"
    header-type="edit"
    hint="Edit Recipients"
  >
    <template #load>
      <em>Loading renter details</em>
    </template>

    <template #edit>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveResponders)">
          <v-card-text>
            <dl class="property-list">
              <dt>Renter</dt>
              <dd>{{ primaryResponder.name }}</dd>

              <dt>Primary Contact</dt>
              <dd>{{ primaryResponder.phone }}</dd>
            </dl>

            <v-radio-group
              v-model="selectedSecondaryId"
              dense
              style="margin: 0 0 0 107px"
            >
              <v-radio
                v-for="cm in additionalContactMethods"
                :key="cm.id"
                :label="cm.value"
                :value="cm.id"
              />
              <v-radio label="No Secondary Contact" value="none" />
              <v-radio label="New Secondary Contact" value="add" />

              <div
                v-if="selectedSecondaryId === 'add'"
                class="ml-8"
                style="margin-top: -8px"
              >
                <InputPhoneNumber
                  v-model="newSecondary"
                  name="Secondary Contact Number"
                  :required="selectedSecondaryId === 'add'"
                />
              </div>
            </v-radio-group>

            <v-btn depressed tile small color="success" type="submit">
              Save Recipients
            </v-btn>
            <v-btn outlined tile small class="ml-2" @click="editing = ''">
              Cancel
            </v-btn>
          </v-card-text>
        </form>
      </ValidationObserver>
    </template>

    <dl class="property-list">
      <dt>Renter</dt>
      <dd>{{ primaryResponder.name }}</dd>

      <dt>Primary Contact</dt>
      <dd>{{ primaryResponder.phone || "N/A" }}</dd>

      <dt>Secondary Contact</dt>
      <dd>{{ (secondaryResponder && secondaryResponder.phone) || "N/A" }}</dd>
    </dl>
  </CardLayout>
</template>

<script>
import CardLayout from "@layout/CardLayout.vue";
import InputPhoneNumber from "@tod-ui/components/InputPhoneNumber.vue";

import { ValidationObserver } from "vee-validate";

import { formatPhoneNumber } from "@tod-ui/helpers/phone";

import { ref, watch } from "vue";
import useSmartUnit from "./useSmartUnit";
import useResponder from "./useResponder";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "CardAlertResponders",
  components: { CardLayout, ValidationObserver, InputPhoneNumber },
  props: {
    renterId: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const {
      smartUnit,
      smartUnitLoading,
      primaryResponder,
      secondaryResponder,
    } = useSmartUnit();
    const {
      renterContactMethods,
      fetchContactMethods,
      saveSecondaryResponder,
    } = useResponder(props.renterId);

    const selectedSecondaryId = ref("none");
    watch(secondaryResponder, (resp) => {
      selectedSecondaryId.value = resp ? resp.contactMethod.id : "none";
    });

    const { addAlert, clearAllAlerts } = useAlerts();
    return {
      smartUnit,
      smartUnitLoading,
      primaryResponder,
      secondaryResponder,
      renterContactMethods,
      fetchContactMethods,
      saveSecondaryResponder,
      selectedSecondaryId,
      newSecondary: "",
      addAlert,
      clearAllAlerts,
    };
  },
  computed: {
    additionalContactMethods() {
      if (this.smartUnitLoading || !this.renterContactMethods) return [];

      const primaryPhone = this.primaryResponder.phone;

      // Return existing secondary mobile phones for the user
      return this.renterContactMethods.reduce((acc, cm) => {
        if (!cm.isDefault && cm.type === "mobile") {
          const value = formatPhoneNumber(cm.value);

          if (value !== primaryPhone) {
            acc.push({ ...cm, value });
          }
        }
        return acc;
      }, []);
    },
    editing: {
      get() {
        return this.value;
      },
      set(editingResponders) {
        this.$emit("input", editingResponders);
      },
    },
  },
  async beforeMount() {
    await this.fetchContactMethods(this.$apollo, this.renterId);
  },
  methods: {
    async saveResponders() {
      this.clearAllAlerts();
      try {
        const message = await this.saveSecondaryResponder(
          this.$apollo,
          this.smartUnit.id,
          this.selectedSecondaryId,
          this.newSecondary
        );
        if (message) {
          this.editing = "";
          this.newSecondary = "";
          this.addAlert({
            type: "success",
            message,
            timeout: 15,
          });
        }
      } catch (errorMessage) {
        this.addAlert({
          type: "error",
          message: errorMessage,
        });
      }
    },
  },
};
</script>
