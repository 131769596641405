<template>
  <v-dialog v-model="isOpen" :width="width">
    <template #activator="{ on }">
      <slot name="activator" :on="on"></slot>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2">
        {{ title }}
        <v-spacer />
        <v-btn icon @click="isOpen = false">
          <v-icon>fas fa-times-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <slot></slot>
      </v-card-text>

      <template v-if="hasActions">
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <slot name="actions"></slot>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed, watch } from "vue";

export default {
  name: "DialogLayout",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    width: {
      type: String,
      default: "500px",
    },
  },
  setup(props, { emit, slots }) {
    const isOpen = computed({
      get() {
        return props.value;
      },
      set(value) {
        emit("input", value);
      },
    });
    const hasActions = computed(() => !!slots.actions);

    const { clearAllAlerts } = useAlerts();
    watch(isOpen, (value) => {
      if (value) {
        clearAllAlerts();
      }
    });

    return { isOpen, hasActions };
  },
};
</script>
