<template>
  <LayoutPage
    :title="`${defaultOrganizationName} Operations & Security Dashboard`"
    :loading="loading"
  >
    <div class="sticky-container">
      <div v-if="showRegionFacilitySelectBoxes">
        <RegionAndFacilitySelect over-view-page />
      </div>

      <div></div>
    </div>
    <v-row>
      <v-col>
        <CardHumanBehaviorSummary />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <CardClimateMonitoring />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <CardMoveOutHygieneCompliance />
      </v-col>
      <v-col cols="6">
        <CardOngoingVacantUnitHygiene />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import useUser from "@components/authentication/useUser";
import LayoutPage from "@layout/LayoutPage.vue";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import { computed } from "vue";
import CardHumanBehaviorSummary from "./CardHumanBehaviorSummary.vue";
import CardClimateMonitoring from "./CardClimateMonitoring.vue";
import CardMoveOutHygieneCompliance from "./CardMoveOutHygieneCompliance.vue";
import CardOngoingVacantUnitHygiene from "./CardOngoingVacantUnitHygiene.vue";

export default {
  components: {
    RegionAndFacilitySelect,
    LayoutPage,
    CardHumanBehaviorSummary,
    CardClimateMonitoring,
    CardMoveOutHygieneCompliance,
    CardOngoingVacantUnitHygiene,
  },
  setup() {
    const { defaultOrganization } = useUser();
    const defaultOrganizationName = computed(
      () => defaultOrganization.value?.name
    );
    return { defaultOrganization, defaultOrganizationName };
  },
  data() {
    return {
      loading: false,
      showRegionFacilitySelectBoxes: true,
    };
  },
};
</script>
