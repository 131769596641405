<template>
  <v-row>
    <v-col cols="12" md="4">
      <DashboardTileV2
        top-title="Conversions % (MTD)"
        bottom-title="Conversions"
        top-tooltip="Frequency of smart unit move-ins in the facility"
        bottom-tooltip="Smart unit move-in / total move-in"
        :top-value="conversionRateMTD"
        :bottom-value="conversions"
        top-unit=" %"
        bottom-unit=""
        :avatar-color="SD_ACCENT"
        avatar-name="conversion"
        :loading="loading"
        @valueClick="goToMonthlyTrends"
      />
    </v-col>
    <v-col cols="12" md="4">
      <DashboardTileV2
        top-title="Lifetime Revenue Adds (MTD)"
        bottom-title="Missed Lifetime Revenue"
        top-tooltip="Revenue from new move-ins this month"
        bottom-tooltip="Lost revenue from non-smart unit move-ins this month"
        :top-value="lifeTimeRevenueAddsMTD"
        :bottom-value="missedLifeTimeRevenue"
        top-unit="$"
        bottom-unit="$"
        :avatar-color="SD_PRIMARY"
        avatar-name="revenue"
        :loading="loading"
        @valueClick="goToRevenueReport"
      />
    </v-col>
    <v-col cols="12" md="4">
      <DashboardTileV2
        top-title="Penetration %"
        bottom-title="Annualized Revenue"
        top-tooltip="Total ratio of smart unit move-ins to total move-ins"
        bottom-tooltip="The revenue over a year from active subscriptions"
        :top-value="penetrationMTD"
        :bottom-value="annualizedRevenue"
        top-unit=" %"
        bottom-unit="$"
        :avatar-color="SD_ACCENT"
        avatar-name="penetration"
        :loading="loading"
        @valueClick="goToPenetrationsReport"
      />
    </v-col>
  </v-row>
</template>

<script>
import { calculateTotal, roundValue } from "@tod-ui/helpers/math";
import DashboardTileV2 from "./DashboardTileV2.vue";
import { MANAGEMENT_SUMMARY } from "./graphql";
import { calculateConversionRate } from "../helper";
import { SD_ACCENT, SD_PRIMARY } from "@tod-ui/constants/colors";
import { getMonth } from "@tod-ui/helpers/datetime";
import useUser from "@components/authentication/useUser";

export default {
  components: {
    DashboardTileV2,
  },
  props: {
    selectedFacilities: {
      type: Array,
      default: () => [],
    },
    organizationLoading: {
      type: Boolean,
      default: false,
    },
    monthlyConversions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    return {
      defaultOrganization,
      SD_PRIMARY,
      SD_ACCENT,
    };
  },
  data() {
    return {
      dashboard: [],
    };
  },
  computed: {
    currentMonth() {
      return getMonth(0, 0, this.defaultOrganization.timezone);
    },
    currentMonthConversions() {
      return this.monthlyConversions.filter(
        (conversion) => conversion.month === this.currentMonth
      );
    },
    loading() {
      return this.$apollo.loading || this.organizationLoading;
    },
    lifeTimeRevenueAddsMTD() {
      return roundValue(calculateTotal(this.dashboard, "lifeTimeRevenue"));
    },

    missedLifeTimeRevenue() {
      return roundValue(
        calculateTotal(this.dashboard, "missedLifetimeRevenue")
      );
    },
    annualizedRevenue() {
      return roundValue(
        calculateTotal(this.dashboard, "annualizedLifetimeRevenue")
      );
    },
    activeSubscriptions() {
      return calculateTotal(this.dashboard, "totalActiveSubscriptions");
    },
    currentActiveSubscriptions() {
      return calculateTotal(this.currentMonthConversions, "subscriptionsAdded");
    },
    totalMoveIns() {
      return calculateTotal(this.currentMonthConversions, "totalMoveIns");
    },
    totalUnits() {
      return calculateTotal(this.dashboard, "totalUnits");
    },
    penetrationMTD() {
      if (this.totalUnits === 0) return 0;
      else {
        return roundValue((this.activeSubscriptions / this.totalUnits) * 100);
      }
    },
    conversions() {
      return `${this.currentActiveSubscriptions}/${this.totalMoveIns}`;
    },
    conversionRateMTD() {
      return calculateConversionRate(
        this.currentActiveSubscriptions,
        this.totalMoveIns
      );
    },
  },
  apollo: {
    dashboard: {
      query: MANAGEMENT_SUMMARY,
      variables() {
        return {
          ids: this.selectedFacilities.map((f) => f.id),
        };
      },
      skip() {
        return this.selectedFacilities.length === 0;
      },
    },
  },
  methods: {
    goToPenetrationsReport(event) {
      this.$router.push({
        name: "PenetrationsAndAnnualizedLifetimeRevenueReport",
        query: {
          highlight_column: event,
        },
      });
    },
    goToRevenueReport(event) {
      this.$router.push({
        name: "RevenueReport",
        query: {
          highlight_column: event,
        },
      });
    },
    goToMonthlyTrends(event) {
      this.$router.push({
        name: "MonthlyTrends",
        query: {
          report: "conversion",
          period: "byTrends",
          highlight_column: event,
        },
      });
    },
  },
};
</script>
