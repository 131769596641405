<template>
  <v-btn outlined small v-bind="$attrs" v-on="$listeners">
    <v-icon small left>fas fa-times-circle</v-icon>
    Cancel
  </v-btn>
</template>

<script>
export default {
  name: "ButtonCancel",
};
</script>
