<template>
  <LayoutPage title="Renters" :loading="loading">
    <template #actions>
      <v-btn
        class="action-btn"
        :color="
          tableState.showSmartRenters === true ? 'success' : 'grey lighten-1'
        "
        @click="tableState.showSmartRenters = true"
      >
        Smart Unit Renters
      </v-btn>
      <v-btn
        class="action-btn"
        :color="
          tableState.showSmartRenters === false ? 'success' : 'grey lighten-1'
        "
        @click="tableState.showSmartRenters = false"
      >
        Other Renters
      </v-btn>
      <v-spacer />
      <div class="mr-4" style="min-width: 240px">
        <SearchField v-model="tableState.search" />
      </div>
      <PossiblePMS v-slot="{ disabled }">
        <v-btn
          class="action-btn"
          color="success"
          :disabled="disabled"
          :to="{ name: 'AddRenter' }"
        >
          Add New Renter
        </v-btn>
      </PossiblePMS>
    </template>

    <v-skeleton-loader
      :loading="loading"
      type="table-thead, table-row-divider@10"
    >
      <v-data-table
        class="table-striped"
        :headers="
          tableState.showSmartRenters ? smartRenterHeaders : otherRenterHeaders
        "
        :items="tableState.showSmartRenters ? smartRenters : otherRenters"
        item-key="user.id"
        :custom-filter="customFilter"
        :custom-sort="sortItems"
        :search="tableState.search"
        :sort-by.sync="tableState.sortBy"
        :sort-desc.sync="tableState.sortDesc"
        :page.sync="tableState.page"
        :items-per-page.sync="tableState.itemsPerPage"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
      >
        <template #item.actions="{ item }">
          <ButtonView title="View Renter" @click="gotoRenter(item.user.id)" />
        </template>

        <template #item.user.firstName="{ item }">
          <PossiblePMS as-icon :sync-obj="item.user" content-class="mr-2">
            <UserIcon
              left
              :billing-account="item.billingAccount"
              :responder="item.demoResponder"
            />
          </PossiblePMS>
          {{ item.user.firstName }}
          <span
            v-if="
              item.user.preferredLanguage &&
              item.user.preferredLanguage !== 'en'
            "
            class="text-muted"
          >
            ({{ languageName(item.user.preferredLanguage) }})
          </span>
        </template>

        <template #item.smartUnits="{ item }">
          <v-list dense color="transparent">
            <v-list-item
              v-for="unit in item.smartUnits"
              :key="unit.id"
              class="ml-0 pl-0"
            >
              <v-list-item-icon class="mr-1">
                <DocumentIcon
                  v-if="showDocuments"
                  :status="unit.document.status"
                  :title="`${titleCase(unit.document.type)} Status: ${titleCase(
                    unit.document.status
                  )}`"
                  small
                  right
                />
              </v-list-item-icon>
              <v-list-item-content>
                {{ unit.name }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>

        <template #footer.prepend>
          <ButtonExportCSV
            class="order-last"
            :table-data="showSmartRenters ? smartRenters : otherRenters"
            :headers="
              showSmartRenters ? smartRenterHeaders : otherRenterHeaders
            "
            :filename="`SD_${showSmartRenters ? 'Active' : 'Inactive'}_Renters`"
          />
          <ButtonPrint
            class="order-last"
            :before-print="showAllItems"
            :after-print="restoreItemsPerPage"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
  </LayoutPage>
</template>

<script>
import SearchField from "@atoms/SearchField.vue";
import UserIcon from "./UserIcon.vue";
import ButtonView from "@atoms/ButtonView.vue";
import PossiblePMS from "@components/common/PossiblePMS.vue";
import DocumentIcon from "../documents/DocumentIcon.vue";
import LayoutPage from "@layout/LayoutPage.vue";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";

import { organizationUsersReport } from "../../graphql/users";
import { DEMO_SMART_UNIT_RESPONDERS } from "../../graphql/organizations";

import { demoResponder } from "./helper";
import { smartUnitDocument } from "../documents/helper";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import {
  stringSortCollator,
  titleCase,
  languageName,
} from "@tod-ui/helpers/strings";
import { sortItems } from "@tod-ui/helpers/dataItems";
import useFacility from "../authentication/useFacility";
import useDataTable from "@components/common/useDataTable";
import usePMS from "@components/common/usePMS";
import { computed } from "vue";

export default {
  name: "PageRenters",
  components: {
    SearchField,
    UserIcon,
    ButtonView,
    PossiblePMS,
    DocumentIcon,
    LayoutPage,
    ButtonPrint,
    ButtonExportCSV,
  },
  setup() {
    const { currentFacilityId, facilityFeatureEnabled } = useFacility();
    const { hasPMS, pmsServiceHasDocuments } = usePMS();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["user.firstName"],
      showSmartRenters: true,
    });
    const showDocuments = computed(
      () =>
        facilityFeatureEnabled("facility_integrated_esign") ||
        pmsServiceHasDocuments.value
    );
    return {
      currentFacilityId,
      hasPMS,
      showDocuments,
      tableState,
      showAllItems,
      restoreItemsPerPage,
      languageName,
    };
  },
  data: () => ({
    organizationUsersReport: [],
    demoSmartUnitResponders: [],
    showSmartRenters: true,
  }),
  computed: {
    otherRenterHeaders() {
      return [
        {
          text: "Details",
          align: "start",
          filterable: false,
          sortable: false,
          class: "action-column",
          cellClass: "action-column",
          value: "actions",
        },
        { text: "First Name", value: "user.firstName" },
        { text: "Last Name", value: "user.lastName" },
        { text: "Primary Phone", value: "primaryPhone" },
        { text: "Secondary Phone", value: "secondaryPhone" },
        { text: "Email", value: "email" },
      ];
    },
    smartRenterHeaders() {
      return [
        ...this.otherRenterHeaders,
        {
          text: "Smart Unit(s)",
          value: "smartUnits",
          sort: (a, b) =>
            stringSortCollator.compare(a.smartUnitsNames, b.smartUnitsNames),
          toString: (unit) =>
            unit.name +
            (this.showDocuments ? ` (${titleCase(unit.document.status)})` : ""),
        },
      ];
    },
    loading() {
      return (
        this.$apollo.queries.organizationUsersReport.loading ||
        this.$apollo.queries.demoSmartUnitResponders.loading
      );
    },
    renters() {
      if (this.loading) return [];
      return this.organizationUsersReport.map((ou) => {
        const demoResponder = this.demoSmartUnitResponders.find(
          (responder) => responder.user.id === ou.user.id
        );
        if (demoResponder) {
          ou = { ...ou, demoResponder };
        }
        if (ou.smartUnits.length > 0) {
          const smartUnits = ou.smartUnits.sort((a, b) =>
            stringSortCollator.compare(a.name, b.name)
          );
          ou = {
            ...ou,
            smartUnits,
            smartUnitsNames: smartUnits.map((u) => u.name).join(" "),
          };
        }
        return ou;
      });
    },
    smartRenters() {
      return this.renters.filter(
        (ou) => ou.smartUnits.length > 0 || ou.demoResponder
      );
    },
    otherRenters() {
      return this.renters.filter(
        (ou) => !ou.smartUnits?.length && !ou.demoResponder
      );
    },
  },
  apollo: {
    organizationUsersReport: {
      // This is already filtering for only "consumer" users
      query: organizationUsersReport,
      variables() {
        return { id: this.currentFacilityId };
      },
      update({ organizationUsersReport }) {
        // Filter out removed renters and combine into single row per renter
        return organizationUsersReport.reduce((acc, ou) => {
          if (ou.billingAccount.status === "closed") return acc;

          const unit =
            ou.organization.type === "zone" &&
            ou.billingAccount.status !== "ended_service"
              ? {
                  ...ou.organization,
                  document: smartUnitDocument(
                    ou.billingAccount.documents,
                    ou.organization.id,
                    this.hasPMS
                  ),
                }
              : null;

          const existing = acc.find((ex) => ex.user.id === ou.user.id);
          if (!existing) {
            const primaryPhone = formatPhoneNumber(ou.primaryPhone);
            const secondaryPhone = formatPhoneNumber(ou.secondaryPhone);
            // push new renter into accumulator w/smartUnits array
            acc.push({
              ...ou,
              primaryPhone,
              secondaryPhone,
              smartUnits: unit ? [unit] : [],
            });
          } else {
            // push additional, unique org into smartUnits
            if (unit && existing.smartUnits.every((o) => o.id !== unit.id)) {
              existing.smartUnits.push(unit);
            }
          }

          return acc;
        }, []);
      },
    },
    demoSmartUnitResponders: {
      query: DEMO_SMART_UNIT_RESPONDERS,
      variables() {
        return { id: this.currentFacilityId };
      },
      update({ demoSmartUnitResponders }) {
        return demoSmartUnitResponders.reduce((acc, unit) => {
          const responder = demoResponder(unit);
          if (responder) {
            acc.push(responder);
          }
          return acc;
        }, []);
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is a `device`
      let _search = search.toLowerCase();

      // ===Fields to filter on===
      // email
      if (item.email != null)
        if (item.email.toLowerCase().indexOf(_search) >= 0) {
          return true;
        }
      // primaryPhone (Prefixed w/ +)
      if (item.primaryPhone?.toLowerCase().indexOf(_search) >= 0) {
        return true;
      }

      // secondaryPhone (Prefixed w/ + sometimes null)
      if (item.secondaryPhone != null)
        if (item.secondaryPhone.toLowerCase().indexOf(_search) >= 0) {
          return true;
        }

      // user.firstName
      if (item.user.firstName.toLowerCase().indexOf(_search) >= 0) {
        return true;
      }

      // user.lastName
      if (item.user.lastName.toLowerCase().indexOf(_search) >= 0) {
        return true;
      }
      // Filter on unit names
      // smartUnits[array].name (Only ones with .type zone)
      for (var unit in item.smartUnits) {
        if (item.smartUnits[unit].name.toLowerCase().indexOf(_search) >= 0)
          return true;
      }
      return false;
    },
    gotoRenter(renterId) {
      this.$router.push({
        name: "ViewRenter",
        params: { renter_id: renterId },
      });
    },
    titleCase,
    sortItems,
  },
};
</script>
