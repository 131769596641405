<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row justify="end">
        <v-col md="2">
          <v-text-field
            v-model="daysAgo"
            label="Number of Days"
            type="number"
            dense
          />
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <FacilityIncidentReportDailyChart
          :chart-data="{
            datasets: [
              {
                borderColor: '#03A9F4',
                pointBackgroundColor: '#03A9F4',
                pointBorderColor: '#03A9F4',
                label: 'Incident Count',
                data: dataArray,
                showLine: true,
              },
            ],
          }"
          :max="chartMax"
          :min="chartMin"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { getDaysAgo } from "@tod-ui/helpers/datetime";
import { FACILITY_REPORT_INCIDENTS } from "./graphql";
import FacilityIncidentReportDailyChart from "./FacilityIncidentReportDailyChart.vue";
import useFacility from "../authentication/useFacility";

export default {
  name: "DailyIncidentReport",
  components: {
    FacilityIncidentReportDailyChart,
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    incidents: [],
    daysAgo: 5,
  }),
  computed: {
    chartMin() {
      return 0;
    },
    bucketsPerDay() {
      return 24;
    },
    loading() {
      return this.$apollo.queries.incidents.loading;
    },
    dataArray() {
      // Track the max event count for the chart scale/boundary

      //Okay, so we want a last day format averaged over the number of days selected.
      let dataArray = initializeDataArray(0, this.bucketsPerDay);

      for (let incident of this.incidents) {
        let incidentTime = convertToLuxonDT(incident.startedAt).hour;
        let incidentBucket = this.formatDateTime(incidentTime);
        const dataElem = dataArray.find(({ x }) => x == incidentBucket);
        if (dataElem) {
          dataElem.y += 1;
        }
      }
      return averageIncidents(dataArray, this.daysAgo);
    },
    chartMax() {
      let max = Math.max.apply(
        Math,
        this.incidents.map(function (o) {
          return o.y;
        })
      );
      max = Math.ceil(max);
      return max > 0 ? max : 1;
    },
  },
  apollo: {
    incidents: {
      // This is already filtering for only "consumer" users
      query: FACILITY_REPORT_INCIDENTS,
      variables() {
        return {
          parentId: this.currentFacilityId,
          after: getDaysAgo(this.daysAgo),
        };
      },
    },
  },
  methods: {
    formatDateTime(hour) {
      if (!hour) return 0;
      let bucket_size = 24 / this.bucketsPerDay;
      let bucket_tracker = 0;
      while (bucket_tracker < 24) {
        if (hour < bucket_tracker) {
          return bucket_tracker;
        }
        bucket_tracker += bucket_size;
      }
    },
  },
};
function initializeDataArray(initialValue, bucketsPerDay) {
  let bucket_size = 24 / bucketsPerDay;
  let data_array = [];
  for (let i = 0; i < bucketsPerDay; i++) {
    data_array.push({
      x: i * bucket_size,
      y: initialValue,
    });
  }
  return data_array;
}
function averageIncidents(dataArray, daysAgo) {
  for (let i = 0; i < dataArray.length; i++) {
    dataArray[i].y = dataArray[i].y / daysAgo;
  }
  return dataArray;
}
</script>
