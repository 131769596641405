<template>
  <v-btn
    icon
    small
    color="primary"
    title="Export to CSV file"
    class="action-btn"
    :disabled="disabled"
    v-bind="$attrs"
    v-on="$listeners"
    @click="exportCSV"
  >
    <v-icon>mdi-download</v-icon>
  </v-btn>
</template>

<script>
import { constructCsvContent, forceFileDownload } from "../helpers/exportCSV";

export default {
  name: "ButtonExportCSV",
  props: {
    tableData: {
      validator: (prop) => prop === undefined || Array.isArray(prop),
      required: true,
    },
    headers: {
      type: Array,
      default: null,
    },
    filename: {
      type: String,
      default: "Storage Defender data",
    },
  },
  computed: {
    disabled() {
      return !(this.tableData && this.tableData.length > 0);
    },
  },
  methods: {
    exportCSV() {
      const csvContent = constructCsvContent(this.tableData, this.headers);
      forceFileDownload(csvContent, this.filename);
    },
  },
};
</script>
