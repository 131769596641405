<template>
  <v-sheet class="iframe-wrapper">
    <iframe :src="src" frameborder="0" class="iframe-content"></iframe>
  </v-sheet>
</template>

<script>
import { computed, unref } from "vue";
import useUser from "../authentication/useUser";
import { addUrlParams } from "../common/helpers/datastudio";

export default {
  name: "OrganizationFacilitySubscriptions",
  setup() {
    const { defaultOrganization } = useUser();
    const src = computed(() => {
      return addUrlParams(
        import.meta.env.VUE_APP_EMBEDDED_REGIONAL_SUBSCRIPTION_REPORT_URL,
        {
          id: unref(defaultOrganization).id,
        }
      );
    });
    return { src };
  },
};
</script>

<style scoped>
.iframe-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.iframe-content {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 1600px;
  min-height: 2100px;
  flex-grow: 1;
}
</style>
