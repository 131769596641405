import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function formatPhoneNumber(val, asE164 = false, defaultRegion = "US") {
  if (!val) return null;

  try {
    const number = phoneUtil.parseAndKeepRawInput(val, defaultRegion);

    if (asE164) {
      return phoneUtil.format(number, PhoneNumberFormat.E164);
    }
    if (phoneUtil.getRegionCodeForNumber(number) === defaultRegion) {
      return phoneUtil.format(number, PhoneNumberFormat.NATIONAL);
    }
    return phoneUtil.formatInOriginalFormat(number, "US");
  } catch (error) {
    return val;
  }
}

export function isValidNumber(val, region = "US") {
  if (!val) return false;

  try {
    const number = phoneUtil.parseAndKeepRawInput(val, region);
    return phoneUtil.isValidNumber(number);
  } catch (error) {
    // console.error(error);
    return false;
  }
}

export function exampleNumber(region = "US") {
  return phoneUtil.format(
    phoneUtil.getExampleNumber(region),
    PhoneNumberFormat.INTERNATIONAL
  );
}
