<template>
  <CardLayout title="Recurring Charge Reconciliation">
    <template #header-actions>
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['Today', '2d', '1w', '1m', '2m', '3m']"
        default="1m"
        max-range="3m"
        custom
        inverted
      />
    </template>

    <v-row>
      <RegionAndFacilitySelect
        v-if="!isFacilityManager"
        over-view-page
        class="mt-2 mx-2"
      />
      <v-select
        v-model="chargeStatus"
        :items="['All', 'Yes', 'No']"
        label="Charge Status"
        class="ml-4"
      />

      <ButtonSend
        class="mt-4 mx-5 primary"
        v-if="!isFacilityManager"
        small
        @click="openEmailPopup"
      >
        Send Email
      </ButtonSend>
      <DialogLayout
        v-if="isPopupVisible"
        v-model="isPopupVisible"
        title="Send Email"
      >
        <div>
          <ValidatedTextField
            v-model="emailId"
            type="email"
            name="Enter Your Email Address"
            rules="required|min:10|max:250"
          />
        </div>
        <template #actions>
          <v-btn color="primary" text @click="sendEmail">Send</v-btn>
          <v-btn color="primary" text @click="cancel">Cancel</v-btn>
        </template>
      </DialogLayout>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="reconciliationChargesReport"
      :loading="loading"
    >
      <template #item.action="{ item }">
        <template v-if="item.sdDeviceAssigned === false">
          <div class="btn-container">
            <v-btn
              depressed
              small
              tile
              color="success"
              @click="manageDevices(item.id, item.facilityId)"
              >Assign Device</v-btn
            >
          </div>
        </template>
      </template>

      <template #item.sdStartDate="{ item }">
        {{ formatDate(item.sdStartDate) }}
      </template>

      <template #item.sdEndDate="{ item }">
        {{ formatDate(item.sdEndDate) }}
      </template>

      <template #item.sdDeviceAssigned="{ item }">
        {{ formatDeviceAvailability(item.sdDeviceAssigned) }}
      </template>

      <template #footer.prepend>
        <ButtonExportCSV
          class="order-last"
          :table-data="reconciliationChargesReport"
          :headers="headers"
          filename="SD_Reconciliation_Report"
        />
        <ButtonPrint
          class="order-last"
          :before-print="showAllItems"
          :after-print="restoreItemsPerPage"
        />
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import {
  SEND_RECURRING_CHARGE_REPORT_EMAIL,
  RECONCILIATION_REPORT,
} from "./graphql";
import { ref, watchEffect, computed } from "vue";
import CardLayout from "@layout/CardLayout.vue";
import useFacility from "../authentication/useFacility";
import useDataTable from "@components/common/useDataTable";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import ButtonSend from "@atoms/ButtonSend.vue";
import { formatDate, getCustomDate } from "@tod-ui/helpers/datetime";
import useUser from "../authentication/useUser";
import DialogLayout from "@layout/DialogLayout.vue";
import ValidatedTextField from "@components/utils/ValidatedTextField.vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import ButtonPrint from "@atoms/ButtonPrint.vue";
import ButtonExportCSV from "@atoms/ButtonExportCSV.vue";
import RegionAndFacilitySelect from "../dashboard/RegionAndFacilitySelect.vue";
import useDashboard from "../dashboard/useDashboard";
import useAccess from "@components/authentication/useAccess";

export default {
  name: "PMSReconciliationStatusReport",
  components: {
    DateTimeFilter,
    CardLayout,
    ButtonSend,
    DialogLayout,
    ValidatedTextField,
    ButtonPrint,
    ButtonExportCSV,
    RegionAndFacilitySelect,
  },
  setup() {
    const { addAlert, clearAllAlerts } = useAlerts();
    const {
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      currentFacilityId,
    } = useFacility();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });
    const { isFacilityManager, defaultOrganization } = useUser();
    const currentOrganizationId = computed(() => defaultOrganization.value?.id);
    const { organizationsLoading, selectedFacilities } = useDashboard();
    const defaultOrganizationName = defaultOrganization.value.name;
    const { organizationAncestors } = useAccess();

    return {
      tableState,
      showAllItems,
      restoreItemsPerPage,
      facilityTimeZone,
      reportDateTime: facilityFormatDateTime4Humans,
      formatDate,
      currentOrganizationId,
      addAlert,
      organizationsLoading,
      selectedFacilities,
      defaultOrganization,
      defaultOrganizationName,
      isFacilityManager,
      organizationAncestors,
    };
  },
  data() {
    return {
      emailId: "",
      dateRange: {
        after: undefined,
        before: undefined,
      },
      chargeStatus: "All",
      reconciliationChargesReport: [],
      filter: {},
      isDownloading: false,
      isPopupVisible: false,
      selectedOrganizationId: null,
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.reconciliationChargesReport.loading;
    },
    shouldSkip() {
      return !this.dateRange?.after || !this.currentOrganizationId;
    },
    headers() {
      return [
        { text: "Facility Name", value: "facilityName", width: 200 },
        {
          text: "SD Subscription Start Date",
          value: "sdStartDate",
          width: 200,
        },
        { text: "Unit Number", value: "sdUnitName", width: 200 },
        { text: "SD End Date", value: "sdEndDate", width: 200 },
        {
          text: "Recurring Charge Name",
          value: "sdRecurringChargeName",
          width: 200,
        },
        { text: "Recurring Charge Price", value: "sdChargeAmount", width: 200 },
        {
          text: "SD Charge Reconciliation Status",
          value: "sdChargeReconcilation",
          width: 200,
        },
        ...(this.isFacilityManager
          ? [
              {
                text: "Device Assignment",
                value: "sdDeviceAssigned",
                width: 200,
              },
              { text: "Action", value: "action", width: 200 },
            ]
          : []),
      ];
    },
  },
  apollo: {
    reconciliationChargesReport: {
      query: RECONCILIATION_REPORT,
      variables() {
        const variables = {
          organizationId: this.isFacilityManager
            ? this.organizationAncestors[0]?.id
            : this.currentOrganizationId,
          dateTimeFilter: {
            after: this.dateRange.after,
            before: this.dateRange.before || getCustomDate({ days: 1 }),
          },
          facilityIds: this.isFacilityManager
            ? this.currentOrganizationId
            : this.selectedFacilities.map((f) => f.id),
          ...this.filter,
        };
        variables.chargeStatus = this.chargeStatus;
        return variables;
      },
      skip() {
        return this.shouldSkip || this.organizationAncestors == null;
      },
      update(data) {
        const reconciliationReport = data?.reconciliationReport;
        if (!reconciliationReport) {
          return [];
        }
        return reconciliationReport.map((report) => {
          return {
            ...report,
          };
        });
      },
      error(error) {
        this.addAlert({
          type: "error",
          message: "Unable to load the report. Please try again.",
          timeout: 5,
        });
      },
    },
  },
  methods: {
    formatDeviceAvailability(isDeviceAvailable) {
      if (isDeviceAvailable) {
        return "Yes";
      } else {
        return "No";
      }
    },
    manageDevices(unitId, organization_id) {
      this.$router.push({
        name: "ManageSmartUnitDevices",
        params: { zone_id: unitId, organization_id: organization_id },
      });
    },
    setDateRange(after, before) {
      this.dateRange = { after, before };
    },
    openEmailPopup() {
      if (this.currentOrganizationId) {
        this.selectedOrganizationId = this.currentOrganizationId;
        this.isPopupVisible = true;
      }
    },
    async sendEmail() {
      try {
        const response = await this.$apollo.mutate({
          mutation: SEND_RECURRING_CHARGE_REPORT_EMAIL,
          variables: {
            organizationId: this.currentOrganizationId,
            email: this.emailId,
            dateTimeFilter: {
              after: this.dateRange.after,
              before: this.dateRange.before || getCustomDate({ days: 1 }),
            },
            facilityIds: this.selectedFacilities.map((f) => f.id),
            chargeStatus: this.chargeStatus,
            ...this.filter,
          },
        });
        if (
          response.data &&
          response.data.sendRecurringChargeEmail.status === "success"
        ) {
          this.addAlert({
            type: "success",
            message:
              response.data.sendRecurringChargeEmail.message ||
              "Email sent successfully",
            timeout: 5,
          });
        } else {
          this.addAlert({
            type: "error",
            message: "Unable to send email. Please try again.",
            timeout: 5,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isPopupVisible = false;
      }
    },
    cancel() {
      this.isPopupVisible = false;
    },
  },
};
</script>

<style scoped>
.btn-container {
  display: flex;
  gap: 1rem;
}
</style>
