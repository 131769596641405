<template>
  <v-skeleton-loader :loading="loading" type="image" :height="150">
    <v-card class="dashboard-tile pa-1" outlined>
      <div v-if="topTitle" class="main-stat" @click="$emit('click-top')">
        <p class="text-subtitle-1 cursor-pointer">{{ topTitle }}</p>
        <p
          class="text-h6 cursor-pointer"
          :class="{ highlighted: selectedValue === 'top' }"
        >
          {{ topValue }}
        </p>
      </div>
      <div v-if="leftTitle" class="sub-stats d-flex justify-space-between">
        <div class="stat-item" @click="$emit('click-left')">
          <p class="cursor-pointer">{{ leftTitle }}</p>
          <p
            class="text-h6 cursor-pointer"
            :class="{ highlighted: selectedValue === 'left' }"
          >
            {{ leftValue }}
          </p>
        </div>
        <v-divider vertical class="mx-2" />
        <div v-if="rightTitle" class="stat-item" @click="$emit('click-right')">
          <p class="cursor-pointer">{{ rightTitle }}</p>
          <p
            class="text-h6 cursor-pointer"
            :class="{ highlighted: selectedValue === 'right' }"
          >
            {{ rightValue }}
          </p>
        </div>
      </div>
    </v-card>
  </v-skeleton-loader>
</template>

<script>
export default {
  name: "CardDashboardTile",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    topTitle: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: "",
    },
    leftTitle: {
      type: String,
      default: "",
    },
    leftValue: {
      type: [String, Number],
      default: "",
    },
    rightTitle: {
      type: String,
      default: "",
    },
    rightValue: {
      type: [String, Number],
      default: "",
    },
    selectedValue: {
      type: String,
      default: "",
      validator: (value) => ["", "top", "left", "right"].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-tile {
  .main-stat {
    text-align: center;
  }

  .sub-stats {
    .stat-item {
      text-align: center;
      flex: 1;
    }
  }

  .clickable {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.highlighted {
  color: #1976d2 !important;
  font-weight: bold;
}
</style>
