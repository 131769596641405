<template>
  <v-btn outlined small color="grey darken-3" :title="title" v-on="$listeners">
    <v-icon left small>fas fa-eye</v-icon>
    <slot>View</slot>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonView",
  props: {
    title: {
      type: String,
      default: "View Details",
    },
  },
};
</script>
