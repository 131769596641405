// Provides string utility functions

export function pluralize(word, count) {
  if (!word || count === 1 || word.endsWith("s")) return word;
  return word.endsWith("y") ? `${word.slice(0, -1)}ies` : `${word}s`;
}

export function toInitials(str, max = 3) {
  if (str === undefined || str === null || str === "") return "";

  return str
    .split(" ")
    .map((w) => w.slice(0, 1))
    .slice(0, max)
    .join("");
}

export function titleCase(str) {
  if (str === undefined || str === null || str === "") return "";

  if (str.toLowerCase() === "n/a") return "N/A";

  return str
    .toLowerCase()
    .replace(/_|-/g, " ")
    .split(" ")
    .filter((word) => !!word)
    .map((word) => {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
}

export function camelCase(str, separator = "_") {
  return str
    .split(separator)
    .map((word, i) =>
      i > 0
        ? word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase()
        : word.toLowerCase()
    )
    .join("");
}

export function snakeCase(str, separator = "_") {
  return str
    .split(/\.?(?=[A-Z])|\s/)
    .join(separator)
    .toLowerCase();
}

export function pascalCase(str) {
  return str
    .replace(/(?:^|\s|_|-|\W)(\w)/g, (_, c) => (c ? c.toUpperCase() : ""))
    .replace(/\s+|_+/g, "");
}

export const stringSortCollator = new Intl.Collator("en", {
  usage: "sort",
  sensitivity: "base",
  ignorePunctuation: true,
  numeric: true,
});

export function stringSearch(searchValue) {
  const searchStr = searchValue.trim().toLowerCase();
  return {
    includedIn: (subject) =>
      subject.trim().toLowerCase().indexOf(searchStr) >= 0,
    exactMatch: (subject) => subject.trim().toLowerCase() === searchStr,
  };
}

export function loremIpsum(qty = 5) {
  const paragraph =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus nisl tincidunt eget nullam. Netus et malesuada fames ac turpis. A diam maecenas sed enim. Enim ut tellus elementum sagittis. Porta nibh venenatis cras sed felis. In hendrerit gravida rutrum quisque non tellus. Velit scelerisque in dictum non consectetur a erat nam at. Nunc sed blandit libero volutpat. Integer vitae justo eget magna. Cras tincidunt lobortis feugiat vivamus. Habitant morbi tristique senectus et netus et malesuada fames. Mi quis hendrerit dolor magna eget. Dignissim sodales ut eu sem integer vitae justo eget magna. Mollis nunc sed id semper risus in hendrerit. Nullam ac tortor vitae purus faucibus ornare suspendisse sed.";

  const sentences = paragraph.split(".");
  const max = Math.min(qty, sentences.length);
  const set = new Set();

  while (set.size < max) {
    set.add(sentences[Math.floor(Math.random() * (sentences.length - 1))]);
  }

  return Array.from(set).join(".") + ".";
}

export function copyrightInfo() {
  const currentYear = new Date().getFullYear();
  return `© 2020-${currentYear} StorageDefender Inc.`;
}

const languages = [
  { name: "english", code: "en" },
  { name: "spanish", code: "es" },
];

export function languageOptions() {
  return languages.map((lang) => ({
    ...lang,
    name: titleCase(lang.name),
  }));
}

export function languageName(code) {
  const lang = languages.find((lang) => lang.code === code);
  return lang ? titleCase(lang.name) : code;
}

export function toLowerCaseAndRemoveSpaces(str) {
  return str.toLowerCase().replace(/\s/g, "");
}

export function thousandSeparator(str) {
  return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
