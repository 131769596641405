<template>
  <div>
    <v-menu ref="menu" bottom :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <v-btn style="text-transform: none" small v-bind="attrs" v-on="on">
          {{ localLocation || "Add Location" }}
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item
          v-for="(item, index) in locations"
          :key="index"
          @click="updateLocation(item)"
        >
          <v-list-item-title class="cursor-pointer">
            {{ item }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title
            class="cursor-pointer"
            @click="showAddLocation = true"
          >
            <v-btn small>
              <v-icon left small>mdi-plus</v-icon>
              Add Location
            </v-btn>
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-if="showAddLocation">
          <div class="d-flex flex-column">
            <v-text-field v-model="newLocation" dense label="New Location" />
            <div class="d-flex justify-center">
              <v-btn class="primary mr-4" small @click="addLocation">Add</v-btn>
              <v-btn small @click="cancelAddLocation">Cancel</v-btn>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import useFacility from "@components/authentication/useFacility";
import { updateDeviceLocation } from "@components/smart_units/useDevice";
import useAlerts from "@tod-ui/composables/useAlerts";
import { ref } from "vue";

export default {
  props: {
    device: {
      type: Object,
      required: true,
    },
    zoneId: {
      type: String,
      default: "",
    },
    allowUpdate: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { currentFacility, updateCurrentFacility } = useFacility();
    const locations = ref(currentFacility.value.location?.spots);
    const { addAlert } = useAlerts();

    return { locations, updateDeviceLocation, addAlert, updateCurrentFacility };
  },
  data() {
    return {
      closeOnContentClick: true,
      showAddLocation: false,
      newLocation: "",
      localLocation: this.device?.specs?.location,
    };
  },
  methods: {
    async updateLocation(location) {
      if (!this.allowUpdate) {
        this.$emit("input", { location });
        this.localLocation = location;
        this.$refs.menu.isActive = false;
        return;
      }
      const variables = {
        apolloClient: this.$apollo,
        id: this.device.id,
        externalId: this.device.externalId,
        type: this.device.type,
        specs: { location },
        organizationId: this.zoneId
          ? this.zoneId
          : this.$route.params.smart_zone_id,
      };
      try {
        const response = await this.updateDeviceLocation(variables);
        if (response) {
          this.localLocation = response.data.updateDevice?.specs?.location;
          this.addAlert({
            type: "success",
            message: "Device location updated successfully",
            timeout: 15,
          });
        }
      } catch (error) {
        console.log(error);
        this.addAlert({
          type: "error",
          message: "There was an error updating the device location",
        });
      }

      this.$refs.menu.isActive = false;
    },
    async addLocation() {
      if (this.newLocation) {
        if (!Array.isArray(this.locations)) {
          this.locations = [];
        }

        const variables = {
          location: {
            spots: [...this.locations, this.newLocation.trim()],
          },
        };
        try {
          const response = await this.updateCurrentFacility(
            this.$apollo,
            variables
          );
          if (response) {
            this.addAlert({
              type: "success",
              message: "New location added successfully",
              timeout: 15,
            });
            this.locations = [...this.locations, this.newLocation];
            this.newLocation = "";
            this.showAddLocation = false;
          }
        } catch (error) {
          this.addAlert({
            type: "error",
            message: "There was an error adding new location",
          });
        }
      }
    },
    cancelAddLocation() {
      this.newLocation = "";
      this.showAddLocation = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
