import { ref, unref, computed } from "vue";
import {
  checkUsername,
  findUser,
  registerUser,
  updateUser,
} from "../../graphql/users/index";
import { userDisplayName } from "./helper";
import { omitTypename } from "../utils/graphql";
import useBillingAccount from "./useBillingAccount";
import { GET_USER_CONTACT_METHODS } from "./graphql";

const renter = ref({});
const primaryPhoneMethod = ref({});
const secondaryPhoneMethod = ref({});
const emailContactMethod = ref({});

function resetRenter() {
  renter.value = {};
  primaryPhoneMethod.value = {
    value: null,
    type: "mobile",
    isDefault: true,
  };
  secondaryPhoneMethod.value = {
    value: null,
    type: "mobile",
    isDefault: false,
  };
  emailContactMethod.value = {
    value: null,
    type: "email",
    isDefault: false,
  };
}
resetRenter();

function initRenter(firstName, lastName, primaryPhone) {
  resetRenter();
  renter.value = {
    firstName,
    lastName,
    name: userDisplayName({ firstName, lastName }),
  };
  primaryPhoneMethod.value.value = primaryPhone;
}

const existingContactMethods = computed(() => {
  return renter.value?.contactMethods;
});

const initialPrimary = computed(() =>
  findContactMethod(existingContactMethods, "mobile", true)
);

const initialSecondary = computed(() =>
  findContactMethod(existingContactMethods, "mobile", false)
);

const initialEmail = computed(() =>
  findContactMethod(existingContactMethods, "email")
);

const secondaryPhoneAdded = computed(
  () => secondaryPhoneMethod.value?.value && !initialSecondary.value
);

async function fetchRenter(apolloClient, renterId, setRenter = true) {
  const response = await apolloClient.query({
    query: findUser,
    variables: { id: renterId },
  });
  const user = formatUser(response.data.user);
  if (setRenter) {
    setState(user);
  }
  return user;
}

const additionalContactMethods = computed(() => {
  if (!existingContactMethods.value) return [];
  const values = [];
  if (initialPrimary.value) {
    values.push(initialPrimary.value.value);
  }
  if (
    primaryPhoneMethod.value?.value &&
    primaryPhoneMethod.value.value !== initialPrimary.value?.value
  ) {
    values.push(primaryPhoneMethod.value.value);
  }
  if (initialSecondary.value) {
    values.push(initialSecondary.value.value);
  }
  if (
    secondaryPhoneMethod.value?.value &&
    secondaryPhoneMethod.value.value !== initialSecondary.value?.value
  ) {
    values.push(secondaryPhoneMethod.value.value);
  }
  if (initialEmail.value) {
    values.push(initialEmail.value.value);
  }
  if (
    emailContactMethod.value?.value &&
    emailContactMethod.value.value !== initialEmail.value?.value
  ) {
    values.push(emailContactMethod.value.value);
  }

  return existingContactMethods.value.reduce((acc, cm) => {
    if (!values.includes(cm.value)) {
      acc.push(omitTypename(cm));
    }
    return acc;
  }, []);
});

async function saveRenter(apolloClient) {
  // Raise an error because primary is required
  if (!primaryPhoneMethod.value) {
    throw "A Primary Phone is required for a Renter";
  }
  const updatedContactMethods = [
    { ...primaryPhoneMethod.value, isActive: undefined },
  ];

  // Check for duplicate primary and secondary
  if (secondaryPhoneMethod.value.value) {
    if (primaryPhoneMethod.value.value === secondaryPhoneMethod.value.value) {
      throw "The primary and secondary should not be the same phone number";
    }
    updatedContactMethods.push({
      ...secondaryPhoneMethod.value,
      isActive: undefined,
    });
  }

  if (emailContactMethod.value.value) {
    updatedContactMethods.push({
      ...emailContactMethod.value,
      isActive: undefined,
    });
  }

  if (additionalContactMethods.value?.length) {
    updatedContactMethods.push(...additionalContactMethods.value);
  }

  try {
    const response = await apolloClient.mutate({
      mutation: updateUser,
      variables: {
        id: renter.value.id,
        firstName: renter.value.firstName,
        lastName: renter.value.lastName,
        preferredLanguage: renter.value.preferredLanguage,
        contactMethods: updatedContactMethods,
      },
      refetchQueries: [
        {
          query: GET_USER_CONTACT_METHODS,
          variables: { id: renter.value.id },
        },
      ],
    });
    setState(formatUser(response.data.updateUser));
  } catch (error) {
    throw "There was an error updating the renter";
  }

  return "Renter updated successfully";
}

async function checkRenter(apolloClient) {
  const { data } = await apolloClient.query({
    query: checkUsername,
    fetchPolicy: "no-cache",
    variables: {
      username: renter.value.username,
    },
  });

  return data.checkUsername;
}

async function createRenter(apolloClient, mute = undefined) {
  let newUser = {
    username: renter.value.username,
    // TODO: Generate strong password (leave blank and let the b/e do it?)
    password: "TestPassword1",
    firstName: renter.value.firstName,
    lastName: renter.value.lastName,
    preferredLanguage: renter.value.preferredLanguage,
    contactMethods: [],
  };

  if (mute !== undefined) {
    newUser.muteAlerts = mute;
    newUser.muteNotifications = mute;
  }

  if (primaryPhoneMethod.value?.value) {
    newUser.contactMethods.push({ ...primaryPhoneMethod.value });
  }

  // Secondary is optional, so only push it if a value is provided
  if (secondaryPhoneMethod.value?.value) {
    newUser.contactMethods.push({ ...secondaryPhoneMethod.value });
  }

  // Email is optional, so only push it if a value is provided
  if (emailContactMethod.value?.value?.trim()) {
    newUser.contactMethods.push({ ...emailContactMethod.value });
  }

  const response = await apolloClient.mutate({
    mutation: registerUser,
    variables: { ...newUser },
  });

  newUser = response.data.registerUser;
  const { assignUser } = useBillingAccount();
  await assignUser(apolloClient, newUser);

  return await fetchRenter(apolloClient, newUser.id);
}

export default function useRenter() {
  return {
    renter,
    primaryPhoneMethod,
    secondaryPhoneMethod,
    emailContactMethod,
    existingContactMethods,
    additionalContactMethods,
    secondaryPhoneAdded,
    initialPrimary,
    initialSecondary,
    initialEmail,
    fetchRenter,
    saveRenter,
    createRenter,
    checkRenter,
    resetRenter,
    initRenter,
    findContactMethod,
  };
}

function setState(userWithContactMethods) {
  const user = unref(userWithContactMethods);
  renter.value = user;

  primaryPhoneMethod.value = initialPrimary.value || {
    value: null,
    type: "mobile",
    isDefault: true,
  };
  secondaryPhoneMethod.value = initialSecondary.value || {
    value: null,
    type: "mobile",
    isDefault: false,
  };
  emailContactMethod.value = initialEmail.value || {
    value: null,
    type: "email",
  };
}

function findContactMethod(contactMethods, type, isDefault = true) {
  const methods = unref(contactMethods)?.filter((cm) => cm.type === type);
  if (!methods) return undefined;
  let contactMethod = methods.find((cm) => cm.isDefault === isDefault);

  if (!contactMethod && type !== "mobile" && methods?.length) {
    //when only one non-default "email" cm
    contactMethod = methods[0];
  }
  return contactMethod && omitTypename(contactMethod);
}

function formatUser(user) {
  if (!user || isEmpty(user)) return user;

  const name = userDisplayName(user);
  const usernameType = user.username.includes("@") ? "email" : "phone";

  return {
    ...user,
    name,
    usernameType,
  };
}

function isEmpty(obj) {
  return (
    Object.keys(obj).length === 0 &&
    Object.getPrototypeOf(obj) === Object.prototype
  );
}
