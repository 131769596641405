<template>
  <div>
    <DialogLayout
      v-model="isOpen"
      title="Viewing Video Clip"
      width="900px"
      @close="closeDialog"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
        class="loader"
      ></v-progress-circular>
      <PopupVideoView :video="video" />
    </DialogLayout>
    <v-data-table
      :headers="headers"
      :items="events"
      :server-items-length="totalItems"
      :loading="loading"
      :options.sync="options"
      @update:options="fetchEvents"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-row justify="end">
            <v-col md="4" class="ml-auto">
              <div class="d-flex">
                <v-select
                  v-model="selectedEvent"
                  :items="allEvents"
                  item-text="displayName"
                  item-value="typeOfEvents"
                  label="Select Event"
                  @change="fetchEvents"
                  class="w-100"
                />
              </div>
            </v-col>
          </v-row>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template #item.startTimestamp="{ item }">
        {{ formatTimestamp(item.startTimestamp) }}
      </template>

      <template #item.endTimestamp="{ item }">
        {{ formatTimestamp(item.endTimestamp) }}
      </template>

      <template #item.actorId="{ item }">
        {{ devices?.externalId }}
      </template>

      <template #item.type="{ item }">
        {{ getEventDisplayName(item.type) }}
      </template>
      <template #item.actorId="{ item }">
        {{ devices?.shortId }}
      </template>
      <template #item.viewVideo="{ item }">
        <v-btn
          depressed
          color="transparent"
          title="View Video"
          @click="
            fetchVideo(item.actorId, item.startTimestamp, currentFacilityId)
          "
        >
          <v-icon color="error" left> fas fa-play-circle </v-icon>
          View Video
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-if="nextPageToken"
      v-model="options.page"
      :length="totalPages"
      @input="fetchEvents"
    ></v-pagination>
  </div>
</template>

<script>
import { GET_ALL_EEN_EVENTS, EEN_VIDEO_FEED } from "../smart_zones/graphql";
import ValidatedTextField from "../utils/ValidatedTextField";
import { formatDate, getCustomDate } from "@tod-ui/helpers/datetime";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import DialogLayout from "@components/smart_zones/DialogLayout.vue";
import PopupVideoView from "./PopupVideoView.vue";

export default {
  name: "VideoCameraEvents",
  components: {
    ValidatedTextField,
    DialogLayout,
    PopupVideoView,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    devices: {
      type: Object,
      required: true,
    },
    currentFacilityId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { facilityTimeZone, facilityFormatDateTime4Humans } = useFacility();
    const { addAlert } = useAlerts();
    return {
      facilityTimeZone,
      facilityFormatDateTime4Humans,
      addAlert,
    };
  },
  data: () => ({
    headers: [
      { text: "Start Timestamp", value: "startTimestamp" },
      { text: "End Timestamp", value: "endTimestamp" },
      { text: "Device", value: "actorId" },
      { text: "Type", value: "type" },
      { text: "Actions", value: "viewVideo" },
    ],
    events: [],
    totalItems: 0,
    loading: false,
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    nextPageToken: null,
    prevPageToken: null,
    totalPages: 1,
    selectedEvent: null,
    formatDate,
    video: null,
    isOpen: false,
  }),
  apollo: {
    events: {
      query: GET_ALL_EEN_EVENTS,
      fetchPolicy: "no-cache",
      variables() {
        return {
          organizationId: this.currentFacilityId,
          actor: "camera:" + this.devices?.externalId,
          eventType: this.selectedEvent,
          pageToken: this.nextPageToken,
          pageSize: this.options.itemsPerPage || 10,
        };
      },
      update(data) {
        if (!data || !data.eenAllEvents) {
          return [];
        }
        const { results, nextPageToken, prevPageToken } = data.eenAllEvents;
        this.nextPageToken = nextPageToken;
        this.prevPageToken = prevPageToken;
        this.totalItems = results.length;
        this.totalPages = nextPageToken
          ? this.options.page + 1
          : this.options.page;
        return results.map((event) => ({
          ...event,
          displayName: this.getEventDisplayName(event.type),
        }));
      },
      skip() {
        return !this.devices?.externalId || !this.selectedEvent;
      },
    },
  },
  computed: {
    allEvents() {
      return (
        this.config?.videoCameraEvents.map((event) => ({
          typeOfEvents: event,
          displayName: this.getEventDisplayName(event),
        })) || []
      );
    },
  },
  methods: {
    async closeDialog() {
      this.isOpen = false;
      if (this.$refs.popupVideoView) {
        this.$refs.popupVideoView.pauseVideo();
      }
      this.video = null;
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      date.setHours(date.getHours() - 1);
      return this.facilityFormatDateTime4Humans(
        date.toISOString(),
        false,
        true,
        true
      );
    },
    async fetchEvents() {
      if (!this.selectedEvent) return; // Do not fetch if no event is selected
      if (!this.$apollo.queries || !this.$apollo.queries.events) {
        console.error("Apollo query 'events' is not defined.");
        this.loading = false;
        return;
      }
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const pageToken = page > 1 ? this.nextPageToken : null;

      try {
        const response = await this.$apollo.queries.events.refetch({
          organizationId: this.currentFacilityId,
          actor: "camera:" + this.devices?.externalId,
          eventType: this.selectedEvent,
          pageToken,
          pageSize: itemsPerPage || 10,
        });

        if (response && response.data && response.data.eenAllEvents) {
          const { results, nextPageToken, prevPageToken } =
            response.data.eenAllEvents;
          this.events = results.map((event) => ({
            ...event,
            displayName: this.getEventDisplayName(event.type),
          }));
          this.nextPageToken = nextPageToken;
          this.prevPageToken = prevPageToken;
          this.totalItems = results.length;
          this.totalPages = nextPageToken
            ? this.options.page + 1
            : this.options.page;
        }
      } catch (error) {
        console.error("Error fetching events:", error);
      } finally {
        this.loading = false;
      }
    },
    getEventDisplayName(eventType) {
      switch (eventType) {
        case "een.personDetectionEvent.v1":
          return "Person Detection";
        case "een.deviceCloudStatusUpdateEvent.v1":
          return "Device Cloud Status Update";
        case "een.vehicleDetectionEvent.v1":
          return "Vehicle Detection";
        case "een.motionDetectionEvent.v1":
          return "Motion Detection";
        default:
          return eventType;
      }
    },
    async fetchVideo(deviceId, fromTimeStamp, organization_id) {
      try {
        this.video = null;
        this.isOpen = true;
        this.loading = true;

        const response = await this.$apollo.query({
          query: EEN_VIDEO_FEED,
          variables: { fromTimeStamp, deviceId, organization_id },
        });
        this.video = response?.data?.eenVideoFeed?.video ?? "";
      } catch (error) {
        console.error(error);
        this.addAlert({
          type: "error",
          message: "There was an error fetching the video. Please try again.",
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    selectedEvent: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
    options: {
      handler() {
        this.fetchEvents();
      },
      deep: true,
    },
  },
};
</script>
