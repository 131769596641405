// Provides user utility functions
import { isTimeAfterNow } from "@tod-ui/helpers/datetime";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { titleCase } from "@tod-ui/helpers/strings";

export function userDisplayName({ firstName, lastName }) {
  return (firstName || "Anonymous") + " " + (lastName || "User");
}

export function demoResponder(organization) {
  if (!organization?.responders) return false;
  const activeDemoResponders = organization?.responders?.filter(
    (r) => !!r?.demoEndingAt && isTimeAfterNow(r.demoEndingAt)
  );
  return (
    activeDemoResponders.length > 0 && {
      ...activeDemoResponders[0],
      organization: { name: organization.name },
    }
  );
}

export function getResponderByLevel(
  responders,
  escalationLevel,
  formatNameAndPhone = false
) {
  const responder =
    responders &&
    Array.isArray(responders) &&
    responders.find((resp) => resp.escalationLevel === escalationLevel);

  if (!formatNameAndPhone) return responder;

  if (responder) {
    return {
      ...responder,
      name: userDisplayName(responder.user),
      phone: formatPhoneNumber(responder.contactMethod.value),
    };
  }
  // Secondary is optional
  return escalationLevel === 1 ? { name: "(no recipient)" } : null;
}
