<template>
  <div class="d-flex flex-column align-center">
    <v-icon size="45" :color="thermometerColor">
      {{ thermometerIcon }}
    </v-icon>
    <p>{{ temperature }}°F</p>
    <p>{{ time }} minutes ago</p>
  </div>
</template>

<script>
export default {
  name: "CardThermometer",
  props: {
    temperature: {
      type: Number,
      required: true,
    },
    time: {
      type: Number,
      required: true,
    },
  },
  computed: {
    thermometerColor() {
      if (this.temperature < 20) {
        return "blue lighten-3";
      } else if (this.temperature <= 80) {
        return "primary";
      } else {
        return "error";
      }
    },
    thermometerIcon() {
      if (this.temperature < 20) {
        return "fas fa-temperature-frigid";
      } else if (this.temperature <= 80) {
        return "fas fa-thermometer-half";
      } else {
        return "fas fa-temperature-hot";
      }
    },
  },
};
</script>

<style scoped>
.thermometer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
