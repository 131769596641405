import gql from "graphql-tag";
import {
  accountFields,
  contactMethodFields,
  deviceFields,
  organizationFields,
  userFields,
} from "../fragments";

export const registerUser = gql`
  mutation RegisterUser(
    $username: String!
    $firstName: String!
    $lastName: String!
    $password: String!
    $contactMethods: [InputContactMethod]
    $preferredLanguage: String
  ) {
    registerUser(
      username: $username
      password: $password
      firstName: $firstName
      lastName: $lastName
      contactMethods: $contactMethods
      preferredLanguage: $preferredLanguage
    ) {
      ...UserFields
    }
  }
  ${userFields}
`;

export const findUser = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserFields
      contactMethods {
        ...ContactMethodFields
      }
    }
  }
  ${userFields}
  ${contactMethodFields}
`;

export const organizationUsersReport = gql`
  query OrganizationUsersReport($id: ID!) {
    organizationUsersReport(id: $id, userType: "consumer") {
      email
      primaryPhone
      secondaryPhone
      userType
      user {
        id
        firstName
        lastName
        username
        externalId
        preferredLanguage
      }
      organization {
        id
        name
        type
        responders {
          id
          demoEndingAt
          user {
            id
          }
        }
      }
      billingAccount {
        ...AccountFields
      }
    }
  }
  ${accountFields}
`;

export const updateUser = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $muteAlerts: Boolean
    $muteNotifications: Boolean
    $contactMethods: [InputContactMethod!]
    $preferredLanguage: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      muteAlerts: $muteAlerts
      muteNotifications: $muteNotifications
      contactMethods: $contactMethods
      preferredLanguage: $preferredLanguage
    ) {
      ...UserFields
      contactMethods {
        ...ContactMethodFields
      }
    }
  }
  ${userFields}
  ${contactMethodFields}
`;

export const userBillingAccounts = gql`
  query UserBillingAccounts($userId: ID!, $parentOrganizationId: ID) {
    userBillingAccounts(
      userId: $userId
      parentOrganizationId: $parentOrganizationId
    ) {
      id
      isOwner
      billingAccount {
        ...AccountFields
      }
    }
  }
  ${accountFields}
`;

// Toggle ownership of the billing account. The ID here is for the join table
// and not the Billing Account itself.
export const updateAccountUser = gql`
  mutation UpdateAccountUser($accountUserId: ID!, $isOwner: Boolean!) {
    updateAccountUser(accountUserId: $accountUserId, isOwner: $isOwner) {
      id
      isOwner
    }
  }
`;

export const userOrganizations = gql`
  query UserOrganizations($userId: ID!, $parentOrganizationId: ID) {
    userOrganizations(
      userId: $userId
      parentOrganizationId: $parentOrganizationId
    ) {
      ...OrganizationFields
    }
  }
  ${organizationFields}
`;

export const userResponders = gql`
  query UserResponders($userId: ID!, $parentOrganizationId: ID) {
    userResponders(
      userId: $userId
      parentOrganizationId: $parentOrganizationId
    ) {
      id
      demoEndingAt
      contactMethod {
        ...ContactMethodFields
      }
      organization {
        ...OrganizationFields
        devices {
          ...DeviceFields
        }
      }
      insertedAt
      updatedAt
    }
  }
  ${contactMethodFields}
  ${organizationFields}
  ${deviceFields}
`;

export const createEosForm = gql`
  mutation (
    $billingAccountId: ID!
    $reason: String!
    $comments: String
    $organizationId: ID
  ) {
    createEosForm(
      billingAccountId: $billingAccountId
      reason: $reason
      comments: $comments
      organizationId: $organizationId
    ) {
      id
      reason
      comments
    }
  }
`;

export const checkUsername = gql`
  query CheckUsername($username: String!) {
    checkUsername(username: $username) {
      username
      normalizedUsername
      valid
      available
      matchingUserId
      message
    }
  }
`;
