<template>
  <v-dialog v-model="dialog" width="800px" max-width="800px">
    <template #activator="{ on }">
      <v-btn
        :small="small"
        depressed
        color="transparent"
        title="View Video"
        v-on="on"
      >
        <v-icon color="error" :small="small" left> fas fa-play-circle </v-icon>
        View Video
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Video Viewer
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="mt-4" style="height: auto; width: auto">
        <div>
          <div v-if="authStatus.value">
            <iframe
              v-if="showIframe"
              id="sd_vms_iframe"
              height="600px"
              width="750px"
              title="History Browser"
              :src="iframeSrc"
              @load="setupIframeListener"
            ></iframe>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EEN_AUTH_STATUS } from "./graphql";
import { ref, onMounted, nextTick, computed } from "vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import ButtonSubmit from "@atoms/ButtonSubmit.vue";
import useFacility from "../authentication/useFacility";

export default {
  name: "VideoViewer",
  components: {
    ButtonSubmit,
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  props: {
    zone: {
      type: Object,
      required: false,
    },
    videoConfig: {
      type: Object,
      required: true,
    },
    devices: {
      type: Array,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      authStatus: { value: false, accessToken: null },
      showIframe: false,
    };
  },
  computed: {
    iframeSrc() {
      const device = this.devices.find(
        (device) => device.type === "een" && device.subtype === "video_camera"
      );
      return device
        ? `https://iframe.eagleeyenetworks.com/#/historylive/${device.externalId}`
        : "";
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    async fetchAuthenticationStatus() {
      try {
        const response = await this.$apollo.query({
          query: EEN_AUTH_STATUS,
          variables: { organization_id: this.currentFacilityId },
        });
        const authStatusData = response.data.EenAuthStatus;

        if (authStatusData.accessToken) {
          this.authStatus.value = true;
          this.authStatus.accessToken = authStatusData.accessToken;
          this.showIframe = true;
        }
      } catch (error) {
        console.error(error);
      }
    },

    setupIframeListener() {
      const iframe = document.getElementById("sd_vms_iframe");
      if (iframe) {
        this.handleIframeLoad();
      } else {
        console.error("Iframe element not found");
      }
    },
    handleIframeLoad() {
      const iframe = document.getElementById("sd_vms_iframe");
      const iframeWindow = iframe.contentWindow;
      if (iframeWindow) {
        window.addEventListener("message", this.handleIframeMessage);
      } else {
        console.error("Iframe contentWindow is null");
      }
    },
    handleIframeMessage(event) {
      if (
        event.data === "een-iframe-loaded" ||
        event.data === "een-iframe-token-expired"
      ) {
        const iframe = document.getElementById("sd_vms_iframe");
        if (iframe && iframe.contentWindow) {
          iframe.contentWindow.postMessage(
            {
              type: "een-token",
              token: this.authStatus.accessToken,
            },
            "https://iframe.eagleeyenetworks.com/"
          );
        } else {
          console.error("Iframe or iframe contentWindow is null");
        }
      }
    },
  },
  mounted() {
    this.fetchAuthenticationStatus();
  },
};
</script>

<style scoped>
.button-container {
  text-align: right;
}
</style>
