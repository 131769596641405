<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template #activator="{ on }">
      <v-btn
        icon
        rounded
        :title="`${titleCase(document.type)} ${
          document.status
        }. Click for more info`"
        v-on="on"
      >
        <DocumentIcon :status="document.status" :title="null" small />
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        {{ documentType }} Document Information
        <v-spacer />
        <v-btn icon @click="cancel">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4 pb-0">
        <dl class="property-list">
          <template v-if="showDocumentName">
            <dt>Document Name</dt>
            <dd>{{ currentFacility.profile.tenantAddendumName || "N/A" }}</dd>
          </template>

          <dt class="d-flex align-center">Status</dt>
          <dd class="d-flex document-status align-center">
            {{ titleCase(document.status) }}
            <DocumentIcon :status="document.status" small left right />
            <v-spacer />
            <span v-if="document.fileUrl" class="success--text">
              <a :href="document.fileUrl" target="_blank" class="mr-2">
                {{ document.file }}
              </a>
            </span>
            <div v-else-if="allowUpload" class="upload-hint">
              <p>Upload the document:</p>
              <small class="grey--text">Allowed types: .pdf, .png, .jpg</small>
            </div>
            <v-btn
              v-if="allowUpload"
              icon
              :disabled="uploadingFile"
              :title="`${
                !document.file ? 'Upload' : 'Update'
              } Signed ${documentType}`"
              @click="selectFile"
            >
              <v-icon small>fas fa-file-upload</v-icon>
              <input
                ref="fileInput"
                class="d-none"
                accept="application/pdf, image/jpeg, image/png"
                type="file"
                @change="uploadDocument"
              />
            </v-btn>
          </dd>
          <dt>Smart Unit #</dt>
          <dd>{{ unit.name || "N/A" }}</dd>
          <dt>Last Updated</dt>
          <dd>
            {{
              facilityFormatDateTime4Humans(document.updatedAt, false, false) ||
              "N/A"
            }}
          </dd>
        </dl>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <template v-if="allowResend">
          <v-btn color="primary" depressed small @click="resendDocuments()">
            Re-send Document
          </v-btn>
        </template>
        <v-spacer />
        <v-btn color="primary" text @click="cancel">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DocumentIcon from "./DocumentIcon.vue";
import { titleCase } from "@tod-ui/helpers/strings";
import {
  BILLING_ACCOUNT_DOCUMENTS,
  UPLOAD_DOCUMENT_FILE,
  RESEND_DOCUMENT_NOTIFICATIONS,
  ACCOUNT_DOCUMENT,
  ACCOUNT_DOCUMENT_FILE,
} from "./graphql";
import { simplifiedStatus, smartUnitDocument } from "./helper";
import useFacility from "@components/authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import usePMS from "@components/common/usePMS";

export default {
  name: "DocumentDialog",
  components: {
    DocumentIcon,
  },
  props: {
    unit: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      currentFacility,
      currentFacilityId,
      facilityFormatDateTime4Humans,
      facilityFeatureEnabled,
    } = useFacility();

    const { pmsServiceHasDocuments } = usePMS();
    const { hasPMS } = usePMS();
    const { addAlert, clearAllAlerts } = useAlerts();
    return {
      currentFacility,
      currentFacilityId,
      hasPMS,
      facilityFormatDateTime4Humans,
      addAlert,
      clearAllAlerts,
      facilityFeatureEnabled,
      pmsServiceHasDocuments,
    };
  },
  data() {
    const billingAccount =
      this.unit?.billingAccount ||
      (this.unit?.billingAccounts?.length && this.unit.billingAccounts[0]);
    const document =
      this.unit?.document ||
      smartUnitDocument(billingAccount.documents, this.unit.id, this.hasPMS);
    return {
      dialog: false,
      uploadingFile: false,
      billingAccount,
      document,
    };
  },
  computed: {
    allowResend() {
      return !this.hasPMS && this.document.status == "unsigned";
    },
    allowUpload() {
      if (this.document.file) return true;
      return (
        !this.hasPMS &&
        this.document.id &&
        ["unsigned", "error", "disabled"].includes(this.document.status)
      );
    },
    documentType() {
      return titleCase(this.document?.type) || "Document";
    },
    showDocumentName() {
      return (
        !this.facilityFeatureEnabled("facility_integrated_esign") &&
        this.pmsServiceHasDocuments
      );
    },
  },
  apollo: {
    document() {
      const options = {
        fetchPolicy: "network-only",
        pollInterval: !this.hasPMS ? 15000 : undefined,
        skip() {
          return (
            !this.billingAccount.id ||
            this.document.status === "signed" ||
            (this.hasPMS && this.document)
          );
        },
      };
      if (this.document.id) {
        return {
          query: ACCOUNT_DOCUMENT,
          variables: { documentId: this.document.id },
          update({ billingAccountDocument }) {
            return {
              ...billingAccountDocument,
              status: simplifiedStatus(billingAccountDocument, this.hasPMS),
            };
          },
          ...options,
        };
      } else {
        return {
          query: BILLING_ACCOUNT_DOCUMENTS,
          variables: {
            billingAccountId: this.billingAccount.id,
          },
          update({ billingAccountDocuments }) {
            return smartUnitDocument(
              billingAccountDocuments,
              this.unit.id,
              this.hasPMS
            );
          },
          ...options,
        };
      }
    },
  },
  watch: {
    async dialog(isOpen) {
      if (isOpen && this.allowUpload) {
        const response = await this.$apollo.query({
          query: ACCOUNT_DOCUMENT_FILE,
          variables: { documentId: this.document.id },
          fetchPolicy: "no-cache",
        });
        const document = response?.data?.billingAccountDocument;
        if (document) {
          this.document = {
            ...document,
            status: simplifiedStatus(document, this.hasPMS),
          };
        }
      }
    },
  },
  methods: {
    cancel() {
      this.dialog = false;
    },
    async resendDocuments() {
      this.clearAllAlerts();
      try {
        const response = await this.$apollo.mutate({
          mutation: RESEND_DOCUMENT_NOTIFICATIONS,
          variables: {
            documentId: this.document.id,
          },
        });
        this.addAlert({
          type: "success",
          message: "eSign Document re-sent.",
          timeout: 5,
        });
      } catch (error) {
        this.addAlert({
          type: "error",
          message: "Could not re-send document.",
          timeout: 5,
        });
      }
      this.dialog = false;
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    async uploadDocument({ target: { files } }) {
      if (!files || !files.length) return;

      this.uploadingFile = true;
      this.clearAllAlerts();

      try {
        //TODO: replace hardcoded value with env variable or rely on server response status code
        if (files[0].size > 10 * 1024 * 1024) {
          throw new Error("File too large");
        }
        const response = await this.$apollo.mutate({
          mutation: UPLOAD_DOCUMENT_FILE,
          variables: {
            documentId: this.document.id,
            organizationId: this.currentFacilityId,
            file: files[0],
          },
        });
        const result = response?.data?.uploadDocumentFile;
        if (!result) {
          throw new Error("Error uploading file");
        }

        this.document = result;
        this.addAlert({
          type: "success",
          message: `File "${files[0].name}" uploaded and document status updated successfully.`,
          timeout: 15,
        });
      } catch (error) {
        if (
          error.message === "File too large" ||
          error?.networkError?.statusCode === 413
        ) {
          this.addAlert({
            message: `File "${files[0].name}" exceeds max allowed upload size. Try to scan in lower resolution.`,
          });
        } else {
          this.addAlert({
            message: `Failed to upload file "${files[0].name}"`,
            timeout: 15,
          });
        }
      }

      this.dialog = false;
      this.uploadingFile = false;
    },
    titleCase,
  },
};
</script>

<style lang="scss" scoped>
.upload-hint {
  font-weight: normal;
  line-height: 1em;
  align-self: end;
  & > p {
    margin: 1.2em 0 0 0;
  }
}
.document-status {
  a {
    font-weight: normal;
    font-style: italic;
    color: inherit;
  }
}
</style>
