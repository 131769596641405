import gql from "graphql-tag";

export const CREATE_TASK = gql`
  mutation CreateTask(
    $assigneeId: ID!
    $description: String
    $dueAt: DateTime
    $organizationId: ID!
    $priority: String!
    $resolution: String!
    $taskTypeId: ID!
    $title: String!
    $file: Upload
  ) {
    createTask(
      assigneeId: $assigneeId
      description: $description
      dueAt: $dueAt
      organizationId: $organizationId
      priority: $priority
      resolution: $resolution
      taskTypeId: $taskTypeId
      title: $title
      file: $file
    ) {
      id
      description
      assignee {
        id
        firstName
        lastName
        roles {
          id
          role {
            id
            name
          }
        }
      }
      files {
        file
        fileUrl
      }
      dueAt
      priority
      organization {
        id
        name
        facility: ancestors(type: "facility") {
          id
          name
        }
        organization: ancestors(type: "organization") {
          id
          name
        }
      }
      title
      taskType {
        id
        name
      }
      taskId
      resolution
    }
  }
`;

export const TASK_TYPES = gql`
  query TaskTypes($sort: String, $status: String) {
    taskTypes(sort: $sort, status: $status) {
      id
      name
      status
    }
  }
`;

export const UNIT_WITH_PARENTS = gql`
  query unitWithParents($id: ID!, $searchText: String) {
    organizationDescendants(id: $id, type: "zone", searchText: $searchText) {
      name
      type
      id
      facility: ancestors(type: "facility") {
        id
        name
        code
      }
      ancestors(types: ["facility", "organization", "region"]) {
        id
        name
      }
    }
  }
`;

export const TASKS = gql`
  query Tasks(
    $assigneeId: ID
    $dateTimeFilter: DateTimeFilter
    $parentId: ID
    $priorities: [String]
    $resolutions: [String]
    $taskTypes: [ID]
  ) {
    tasks(
      assigneeId: $assigneeId
      dateTimeFilter: $dateTimeFilter
      parentId: $parentId
      priorities: $priorities
      resolutions: $resolutions
      taskTypes: $taskTypes
    ) {
      id
      description
      assignee {
        id
        firstName
        lastName
        roles {
          id
          role {
            id
            name
          }
        }
      }
      dueAt
      priority
      organization {
        id
        name
        facility: ancestors(type: "facility") {
          id
          name
        }
        organization: ancestors(type: "organization") {
          id
          name
        }
      }
      title
      taskType {
        id
        name
      }
      taskId
      resolution
    }
  }
`;

export const GET_TASK = gql`
  query FindTask($id: ID!) {
    findTask(id: $id) {
      id
      description
      assignee {
        id
        firstName
        lastName
        roles {
          id
          role {
            id
            name
          }
        }
      }
      files {
        id
        file
        fileUrl
      }
      dueAt
      priority
      organization {
        id
        name
      }
      title
      taskType {
        id
        name
      }
      taskId
      resolution
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask(
    $id: ID!
    $assigneeId: ID!
    $description: String
    $dueAt: DateTime
    $organizationId: ID!
    $priority: String!
    $resolution: String!
    $taskTypeId: ID!
    $title: String!
  ) {
    updateTask(
      id: $id
      assigneeId: $assigneeId
      description: $description
      dueAt: $dueAt
      organizationId: $organizationId
      priority: $priority
      resolution: $resolution
      taskTypeId: $taskTypeId
      title: $title
    ) {
      id
      description
      dueAt
      priority
      resolution
      title
    }
  }
`;

export const UPDATE_TASK_TYPE = gql`
  mutation UpdateTaskType($id: ID!, $name: String, $status: String) {
    updateTaskType(id: $id, name: $name, status: $status) {
      id
      name
      status
    }
  }
`;

export const TASK_HISTORY = gql`
  query TaskHistory($taskId: ID!) {
    taskHistory(taskId: $taskId) {
      id
      entity
      entityId
      operation
      changeset
      newData
      insertedAt
      updatedAt
      user {
        id
        firstName
        lastName
        roles {
          role {
            id
            name
          }
        }
      }
    }
  }
`;

export const CREATE_TASK_COMMENT = gql`
  mutation CreateTaskComment($taskId: ID!, $content: String!) {
    createTaskComment(taskId: $taskId, content: $content) {
      id
      content
    }
  }
`;

export const TASK_COMMENTS = gql`
  query Comments($taskId: ID!) {
    taskComments(taskId: $taskId) {
      id
      content
      creator {
        firstName
        lastName
        roles {
          role {
            id
            name
          }
        }
      }
      insertedAt
    }
  }
`;

export const DELETE_TASK_COMMENT = gql`
  mutation DeleteTaskComment($id: ID!) {
    deleteTaskComment(id: $id) {
      id
    }
  }
`;

export const ADD_TASK_ATTACHMENT = gql`
  mutation AddTaskAttachment($taskId: ID!, $file: Upload!) {
    addTaskAttachment(taskId: $taskId, file: $file) {
      id
      file
      fileUrl
    }
  }
`;

export const DELETE_TASK_ATTACHMENT = gql`
  mutation DeleteTaskAttachment($id: ID!) {
    deleteTaskAttachment(id: $id) {
      id
    }
  }
`;
