<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    isKudo: {
      type: Boolean,
      default: false,
    },
    chartData: {
      type: [Array, Object],
      default: () => ({}),
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    redirectValue: {
      type: Number,
      required: false,
      default: null,
    },
  },
  mounted() {
    const data = this.processChartData(this.chartData);

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];
            const value = data.datasets[0].data[tooltipItem.index];
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        onClick: (e) => e.stopPropagation(),
      },
      onClick: this.handleClick,
    };

    this.renderChart(data, options);
  },
  methods: {
    processChartData(chartData) {
      return chartData;
    },
    handleClick(event, items) {
      if (this.isKudo) {
        return;
      }
      if (items.length > 0) {
        const clickedData = this.chartData.labels[items[0]._index];
        this.goToRevenueTable(clickedData);
      }
    },
    replaceString(input, replacements) {
      for (const [key, value] of Object.entries(replacements)) {
        const escapedKey = key.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        input = input.replace(new RegExp(escapedKey, "g"), value);
      }
      return input;
    },
    goToRevenueTable() {
      this.$router.push(
        `/operation_and_security/vacant_unit/${this.redirectValue}`
      );
    },
  },
};
</script>
