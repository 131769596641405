<template>
  <v-sheet>
    <v-app-bar app color="white" elevate-on-scroll clipped-left>
      <v-app-bar-nav-icon v-show="!isDrawerShown" @click="toggleDrawer(true)" />

      <router-link :to="defaultPage">
        <img
          src="/assets/SDLOGO_40.png"
          alt="StorageDefender"
          srcset="/assets/SDLOGO_sm.png 42w, /assets/SDLOGO_40.png"
          sizes="(max-width: 600px) 42px, 306px"
        />
      </router-link>
      <div v-if="buildVersion" class="build-version">
        {{ buildVersion }}
      </div>

      <v-spacer />
      <v-toolbar-title
        v-if="organizationName"
        class="page-facility-title headline"
      >
        {{ organizationName }}
      </v-toolbar-title>
      <FacilityName v-if="isSuperAdmin" :prefetch="false" :autofocus="false" />
      <PmsSyncNow />
      <TheNotificationMenu class="action-btn" />
      <v-menu bottom right offset-y min-width="160px">
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" class="action-btn pa-7 mr-4" v-on="on">
            <v-icon small right>fas fa-user</v-icon>
            <v-icon small left>fas fa-caret-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item dense to="/help">
            <v-icon left>mdi-help-circle</v-icon>
            <v-list-item-title class="subtitle-2">Get Help</v-list-item-title>
          </v-list-item>

          <v-list-item @click="doLogout">
            <v-icon left>mdi-logout-variant</v-icon>
            <v-list-item-title class="subtitle-2">Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-sheet>
</template>

<script>
import TheNotificationMenu from "../notifications/TheNotificationMenu.vue";
import PmsSyncNow from "./PmsSyncNow.vue";
import FacilityName from "./FacilityName.vue";

import { computed, unref } from "vue";
import useUser from "../authentication/useUser";
import useNavigation from "./useNavigation";
import useFacility from "../authentication/useFacility";

export default {
  components: {
    TheNotificationMenu,
    PmsSyncNow,
    FacilityName,
  },
  setup() {
    const { currentFacility } = useFacility();
    const { defaultOrganization, defaultPage, logout } = useUser();
    const { isDrawerShown, toggleDrawer } = useNavigation();

    const organizationName = computed(
      () => unref(currentFacility)?.name || unref(defaultOrganization).name
    );

    const isSuperAdmin = computed(() =>
      ["platform", "application"].includes(defaultOrganization.value.type)
    );

    const buildVersion =
      import.meta.env.VUE_APP_BUILD_ENV !== "production"
        ? import.meta.env.VUE_APP_SENTRY_RELEASE
        : null;

    return {
      defaultPage,
      logout,
      isDrawerShown,
      toggleDrawer,
      organizationName,
      isSuperAdmin,
      buildVersion,
    };
  },
  methods: {
    doLogout() {
      // do not await for apollo cache to be cleared to avoid updating components without authentication
      this.logout(this.$apollo);
      this.$router.replace({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.page-facility-title {
  display: none;
}
.v-app-bar {
  border-bottom: 1px solid black !important;
}
.build-version {
  position: absolute;
  bottom: 0;
  font-size: 0.8em;
  color: gray;
}

@media print {
  .page-facility-title {
    display: block;
  }
  .v-app-bar {
    box-shadow: none !important;
  }
}
</style>
