import PageLogin from "./PageLogin.vue";
import PageManagementDashboard from "./PageManagementDashboard.vue";
import PageChangePassword from "./PageChangePassword.vue";
const routes = {
  navigation: [
    {
      path: "/login",
      name: "Login",
      component: PageLogin,
      meta: { withoutAuth: true },
    },
    {
      path: "/",
      name: "ManagementDashboard",
      component: PageManagementDashboard,
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: PageChangePassword,
      meta: { withoutAuth: true },
      props: (route) => ({ token: route.query.token }),
    },
  ],
};

export default routes;
