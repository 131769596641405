<template>
  <v-card outlined>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      {{ title }}
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="incidents"
      :loading="incidentsLoading"
      :search="search"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :sort-by="['startedAt']"
      :sort-desc="[true]"
      :no-data-text="noDataText"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
    >
      <template #item.actions="{ item }">
        <ButtonView
          v-if="item.organization.type != 'facility'"
          title="View Smart Unit"
          @click="gotoZone(item.organization)"
        />
      </template>

      <template #item.name="{ item }">
        {{ item.organization.name }}
      </template>

      <template #item.devices="{ item }">
        <v-list
          v-if="item.organization.type != 'facility'"
          dense
          color="transparent"
        >
          <v-list-item
            v-for="device in item.organization.devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-content>
              {{ device.shortId || device.externalId }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.health="{ item }">
        <v-list
          v-if="item.organization.type != 'facility'"
          dense
          color="transparent"
        >
          <v-list-item
            v-for="device in item.organization.devices"
            :key="device.id"
            class="ml-0 pl-0"
          >
            <v-list-item-icon>
              <DeviceGaugeCluster :device="device" />
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>

      <template #item.responderFirst="{ item }">
        {{ responderFirstName(item.organization) }}
      </template>

      <template #item.responderLast="{ item }">
        {{ responderLastName(item.organization) }}
      </template>

      <template #item.startedAt="{ item }">
        {{ facilityFormatDateTime4Humans(item.startedAt) }}
      </template>

      <template #item.incident="{ item }">
        <v-btn
          v-if="item.status === 'resolved'"
          color="success"
          small
          title="View Incident"
          @click="gotoIncident(item)"
        >
          <v-icon left small>fas fa-exclamation-triangle</v-icon>
          View
        </v-btn>
        <v-btn
          v-else
          color="amber lighten-3"
          small
          title="Resolve Incident"
          @click="gotoIncident(item)"
        >
          <v-icon left small>fas fa-exclamation-triangle</v-icon>
          Resolve
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";
import useFacility from "../authentication/useFacility";
import useDashboardIncidents from "./useDashboardIncidents";

export default {
  name: "IncidentsInfoTable",
  components: {
    DeviceGaugeCluster,
    SearchField,
    ButtonView,
  },
  props: {
    authorized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup(props) {
    const { facilityFormatDateTime4Humans } = useFacility();
    const { incidents, incidentsLoading, fetchIncidentsDetailed } =
      useDashboardIncidents();

    const title = `${
      props.authorized ? "Authorized" : "Unauthorized"
    } Accesses`;

    const noDataText = `No any ${
      props.authorized ? "authorized" : "unauthorized"
    } accesses`;

    return {
      facilityFormatDateTime4Humans,
      incidents,
      incidentsLoading,
      fetchIncidentsDetailed,
      title,
      noDataText,
    };
  },
  data: () => ({
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
      },
      { text: "Smart Unit #", value: "name" },
      { text: "First Name", value: "responderFirst" },
      { text: "Last Name", value: "responderLast" },
      { text: "Assigned Devices", value: "devices" },
      {
        text: "Device Health",
        filterable: false,
        sortable: false,
        value: "health",
      },
      { text: "Started At", value: "startedAt" },
      {
        text: "Motion Incident",
        filterable: false,
        sortable: false,
        value: "incident",
      },
    ],
    search: null,
  }),
  watch: {
    authorized(val) {
      this.fetchIncidentsDetailed(this.$apollo, val);
    },
  },
  beforeMount() {
    this.fetchIncidentsDetailed(this.$apollo, this.authorized);
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `incident`
      let _search = search.toLowerCase();
      const org = item.organization;

      // Filter on `name`
      if (org.name.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `responder`
      const firstName = this.responderFirstName(org);
      if (firstName && firstName.toLowerCase().indexOf(_search) >= 0)
        return true;

      const lastName = this.responderLastName(org);
      if (lastName && lastName.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `devices`
      const devices = org.devices
        .map((device) => device.shortId || device.externalId)
        .join(" ")
        .toLowerCase();
      if (devices.indexOf(_search) >= 0) return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      if (_sortBy === "startedAt") {
        // `incident` is sorted on the item.startedAt
        items.sort((a, b) => {
          if (!_sortDesc) return a.startedAt < b.startedAt ? -1 : 1;
          return b.startedAt < a.startedAt ? -1 : 1;
        });
      } else {
        // All other columns are sorted on the item.organization.<property>
        // so we destructure the organization into `a` and `b` vars.
        items.sort(({ organization: a }, { organization: b }) => {
          if (_sortBy === "name") {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "responderFirst") {
            const aName = this.responderFirstName(a).toLowerCase();
            const bName = this.responderFirstName(b).toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "responderLast") {
            const aName = this.responderLastName(a).toLowerCase();
            const bName = this.responderLastName(b).toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "devices") {
            const aDevices = a.devices
              .map((device) => device.shortId || device.externalId)
              .sort((da, db) => {
                if (!_sortDesc) return da < db ? -1 : 1;
                return db < da ? -1 : 1;
              })
              .join(" ");
            const bDevices = b.devices
              .map((device) => device.shortId || device.externalId)
              .sort((da, db) => {
                if (!_sortDesc) return da < db ? -1 : 1;
                return db < da ? -1 : 1;
              })
              .join(" ");

            if (!_sortDesc) return aDevices < bDevices ? -1 : 1;
            return bDevices < aDevices ? -1 : 1;
          } else {
            // Default sort comparison
            if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
            return b[_sortBy] < a[_sortBy] ? -1 : 1;
          }
        });
      }

      return items;
    },
    responderFirstName({ responders }) {
      if (responders.length === 0) return "(error)";

      const responder = responders[0];
      if (responder.user) return responder.user.firstName;
    },
    responderLastName({ responders }) {
      if (responders.length === 0) return "";

      const responder = responders[0];
      if (responder.user) return responder.user.lastName;
    },
    gotoIncident(incident) {
      this.$router.push({
        name: "Incident",
        params: {
          incident_id: incident.id,
        },
      });
    },
    gotoZone(zone) {
      this.$router.push({
        name: "AssignedSmartUnitDrilldown",
        params: {
          renter_id: zone.responders[0].user.id,
          zone_id: zone.id,
        },
      });
    },
  },
};
</script>
