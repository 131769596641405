import { readonly, ref, watch } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import { LIST_ORGANIZATION_DESCENDANTS } from "../../graphql/organizations";
import { buildOrganizationHierarchy } from "./helper";

const regions = ref([]);
const facilities = ref([]);
const allFacilities = ref([]); // Backup for all facilities
const otherFacilities = ref([]); // Facilities not under any region
const selectedRegions = ref([]);
const selectedFacilities = ref([]);
const organizationsLoading = ref(false);
const organizationTree = ref([]);
const selectedFacility = ref({});

let initialized = false;
export default function useDashboard() {
  async function fetchOrganizations(apolloClient, id, types) {
    organizationsLoading.value = true;
    apolloWatchQuery(
      apolloClient,
      {
        query: LIST_ORGANIZATION_DESCENDANTS,
        variables: {
          id,
          types,
        },
        fetchPolicy: "cache-first",
      },
      ({ organizationDescendants }, queryLoading) => {
        organizationsLoading.value = queryLoading;
        if (organizationDescendants) {
          const nonDemoOrganizations = noDemoOrganizationsFilter(
            organizationDescendants
          );
          // Create a lookup map for quick parent reference
          const parentMap = new Map();
          nonDemoOrganizations.forEach((descendant) => {
            parentMap.set(descendant.id, descendant);
          });
          populateRegions(nonDemoOrganizations, parentMap);
          populateFacilities(nonDemoOrganizations, parentMap);
          selectedFacility.value = facilities.value[0];

          // Initially select all regions
          if (!initialized) {
            selectedRegions.value = [...regions.value];
            initialized = true;
          }
          organizationsLoading.value = false;
        }
      }
    );
  }

  function noDemoOrganizationsFilter(organizations) {
    return organizations.filter((organization) => !organization.isDemo);
  }

  function populateRegions(organizationDescendants, parentMap) {
    const regionsList = organizationDescendants.filter((descendant) =>
      ["region", "organization"].includes(descendant.type)
    );

    organizationTree.value = buildOrganizationHierarchy(regionsList);

    regions.value = regionsList;
  }

  function populateFacilities(organizationDescendants, parentMap) {
    allFacilities.value = organizationDescendants.filter(
      (descendant) => descendant.type === "facility"
    );

    // Separate facilities into those belonging to regions / organizations and those without a region /organization
    const regionIds = new Set(regions.value.map((region) => region.id));
    otherFacilities.value = allFacilities.value.filter(
      (facility) => !regionIds.has(facility.parentId)
    );
  }

  // Function to update selected facilities based on selected regions / organizations
  function updateSelectedFacilities() {
    const selectedRegionIds = selectedRegions.value.map((region) => region.id);
    const filteredFacilities = allFacilities.value.filter((facility) =>
      selectedRegionIds.includes(facility.parentId)
    );

    // Combine filtered facilities with "Other" facilities if "Other" is selected
    facilities.value = filteredFacilities;

    selectedFacilities.value = facilities.value;
  }

  watch(selectedRegions, updateSelectedFacilities);

  // Function to reset global states
  function clearDashboardData() {
    regions.value = [];
    facilities.value = [];
    allFacilities.value = [];
    otherFacilities.value = [];
    selectedRegions.value = [];
    selectedFacilities.value = [];
    organizationsLoading.value = false;
    initialized = false;
    organizationTree.value = [];
  }

  return {
    fetchOrganizations,
    regions: readonly(regions),
    facilities: readonly(facilities),
    selectedRegions,
    selectedFacilities,
    organizationsLoading,
    organizationTree: readonly(organizationTree),
    clearDashboardData,
    selectedFacility,
  };
}
