import { onUnmounted, reactive, ref, unref } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import { getDaysAgo } from "@tod-ui/helpers/datetime";
import {
  DASHBOARD_COUNT_INCIDENTS,
  DASHBOARD_RECENT_INCIDENTS,
  DASHBOARD_INCIDENTS_DETAILED,
  DASHBOARD_COUNT_ZONE_INCIDENTS,
  DASHBOARD_ZONE_INCIDENTS,
} from "./graphql";
import useFacility from "@components/authentication/useFacility";

const $recentIncidentsQueries = {};
const $recentIncidents = {
  "active-units": ref([]),
  "vacant-units": ref([]),
  "after-hours": ref([]),
};
const $recentIncidentsLoading = {
  "active-units": ref(true),
  "vacant-units": ref(true),
  "after-hours": ref(true),
};

function useDashboardIncidents() {
  const { currentFacilityId, facilityFeatureEnabled } = useFacility();
  const facilityId = unref(currentFacilityId);

  const countIncidents = reactive({ authorized: 0, unauthorized: 0 });
  const countZoneIncidents = reactive({
    motion: 0,
    climate: 0,
    serviceRequest: 0,
  });
  const countPollingQueries = [];
  const incidentsLoading = ref(true);
  const incidents = ref();
  const zoneIncidentsLoading = ref(true);
  const zoneIncidents = ref();

  function fetchCountFacilityIncidents(apolloClient) {
    let authorized = true;
    countPollingQueries[0] = apolloWatchQuery(
      apolloClient,
      {
        query: DASHBOARD_COUNT_INCIDENTS,
        variables: {
          parentId: facilityId,
          authorized,
          after: daysAgo(authorized),
        },
        fetchPolicy: "network-only",
        pollInterval: 30 * 1000,
      },
      (data) => {
        if (data.incidents) {
          countIncidents.authorized = data.incidents.length;
        }
      }
    );

    authorized = false;
    countPollingQueries[1] = apolloWatchQuery(
      apolloClient,
      {
        query: DASHBOARD_COUNT_INCIDENTS,
        variables: {
          parentId: facilityId,
          authorized,
          after: daysAgo(authorized),
        },
        fetchPolicy: "network-only",
        pollInterval: 30 * 1000,
      },
      (data) => {
        if (data?.incidents) {
          countIncidents.unauthorized = data.incidents.length;
        }
      }
    );

    if (facilityFeatureEnabled("smart_zones")) {
      countPollingQueries[2] = apolloWatchQuery(
        apolloClient,
        {
          query: DASHBOARD_COUNT_ZONE_INCIDENTS,
          variables: {
            parentId: facilityId,
            after: getDaysAgo(2),
          },
          fetchPolicy: "network-only",
          pollInterval: 30 * 1000,
        },
        (data) => {
          if (data?.incidents) {
            data.incidents.forEach((incident) => {
              if (incident.type === "motion") {
                countZoneIncidents.motion++;
              } else if (
                incident.type === "temperature" ||
                incident.type === "humidity"
              ) {
                countZoneIncidents.climate++;
              } else if (incident.type === "service_request") {
                countZoneIncidents.serviceRequest++;
              }
            });
          }
        }
      );
    }
  }

  function fetchRecentIncidents(apolloClient, incidentType, after) {
    if (!after) return;

    const variables = {
      parentId: facilityId,
      after: after,
      organizationSubtype: incidentType === "after-hours" ? undefined : "unit",
      duringAccessHours: incidentType === "after-hours" ? false : undefined,
    };

    if ($recentIncidentsQueries[incidentType]) {
      $recentIncidentsQueries[incidentType].stopPolling();
    }
    $recentIncidentsQueries[incidentType] = apolloWatchQuery(
      apolloClient,
      {
        query: DASHBOARD_RECENT_INCIDENTS,
        variables,
        fetchPolicy: "no-cache",
        pollInterval: (incidentType === "active-units" ? 30 : 60) * 1000,
      },
      (data) => {
        if (data.incidents) {
          $recentIncidentsLoading[incidentType].value = false;
          $recentIncidents[incidentType].value =
            incidentType === "active-units"
              ? data.incidents.filter((incident) => !incident.isVacant)
              : incidentType === "vacant-units"
              ? data.incidents.filter((incident) => incident.isVacant)
              : data.incidents;
        }
      }
    );
  }

  function fetchIncidentsDetailed(apolloClient, authorized) {
    incidentsLoading.value = true;
    return apolloWatchQuery(
      apolloClient,
      {
        query: DASHBOARD_INCIDENTS_DETAILED,
        variables: {
          parentId: facilityId,
          authorized,
          after: daysAgo(authorized),
        },
        fetchPolicy: "cache-and-network",
      },
      (data, loading) => {
        if (data?.incidents) {
          incidents.value = data.incidents;
        }
        incidentsLoading.value = loading;
      }
    );
  }

  function fetchZoneIncidents(
    apolloClient,
    /** @type {"motion"|"climate"|"service_request"} */ incidentType,
    after
  ) {
    if (!facilityFeatureEnabled("smart_zones")) return;
    if (!after) return;

    zoneIncidentsLoading.value = true;
    return apolloWatchQuery(
      apolloClient,
      {
        query: DASHBOARD_ZONE_INCIDENTS,
        variables: {
          parentId: facilityId,
          after: after,
        },
        fetchPolicy: "cache-and-network",
      },
      (data, loading) => {
        if (data?.incidents) {
          zoneIncidents.value = data.incidents.filter((incident) =>
            incidentType === "climate"
              ? incident.type === "temperature" || incident.type === "humidity"
              : incident.type === incidentType
          );
        }
        zoneIncidentsLoading.value = loading;
      }
    );
  }

  onUnmounted(() => {
    countPollingQueries.forEach(
      (
        /** @type {import("apollo-client").ObservableQuery} */ observableQuery
      ) => observableQuery.stopPolling()
    );
    for (const incidentType in $recentIncidentsQueries) {
      $recentIncidentsQueries[incidentType].stopPolling();
    }
  });

  return {
    incidents,
    incidentsLoading,
    fetchIncidentsDetailed,
    zoneIncidentsLoading,
    zoneIncidents,
    fetchZoneIncidents,
    countIncidents,
    countZoneIncidents,
    fetchCountFacilityIncidents,
    recentIncidents: $recentIncidents,
    recentIncidentsLoading: $recentIncidentsLoading,
    fetchRecentIncidents,
  };
}

export default useDashboardIncidents;

function daysAgo(authorized) {
  return getDaysAgo(authorized ? 2 : 7);
}
