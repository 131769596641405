<script>
import { Line, mixins, Scatter } from "vue-chartjs";
// https://github.com/chartjs/chartjs-adapter-luxon
// FIXME: import "chartjs-adapter-luxon";
const { reactiveProp } = mixins;

import { formatDateTime } from "@tod-ui/helpers/datetime";
import { DateTime } from "luxon";

export default {
  name: "FacilityIncidentReportWeeklyChart",
  extends: Scatter,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 500,
    },
    title: {
      type: String,
      default: undefined,
    },
    bucketsPerDay: {
      type: Number,
      required: true,
    },
  },
  mounted() {
    //Needed to access this value in the callback. Javascript is so good.
    const bpd = this.bucketsPerDay;
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            //Dataset index is weeks ago
            //index is hour of week.
            let weeksAgo = tooltipItem.datasetIndex;
            let bucket_size = 24 / bpd;
            let hourOfWeek = (tooltipItem.index + 1) * bucket_size;
            let date = DateTime.local();
            let startOfWeek = date.startOf("week");
            startOfWeek = startOfWeek.minus({ weeks: weeksAgo });
            startOfWeek = startOfWeek.plus({ hours: hourOfWeek });
            return `${tooltipItem.value} incidents on ${formatDateTime(
              startOfWeek
            )}`;
          },
          title: function (tooltipItem, data) {},
        },
      },
      scales: {
        xAxes: [
          {
            display: true,
            distribution: "linear",
            ticks: {
              min: 0,
              max: this.bucketsPerDay * 7 - 1,
              stepSize: 1,
              autoSkip: false,
              callback: function (label, index, labels) {
                let pos = JSON.parse(JSON.stringify(label));
                pos += 1;
                if (pos % bpd == 0) {
                  let day = pos / bpd;
                  if (day == 1) {
                    return "Monday";
                  }
                  if (day == 2) {
                    return "Tuesday";
                  }
                  if (day == 3) {
                    return "Wednesday";
                  }
                  if (day == 4) {
                    return "Thursday";
                  }
                  if (day == 5) {
                    return "Friday";
                  }
                  if (day == 6) {
                    return "Saturday";
                  }
                  if (day == 7) {
                    return "Sunday";
                  }
                }
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              min: 0,
              max: this.max,
            },
          },
        ],
      },
    };

    this.renderChart(this.chartData, options);
  },
  methods: {},
};
</script>
