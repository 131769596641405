import { ApolloLink } from "apollo-link";
import * as Sentry from "@sentry/vue";
import { print } from "graphql/language/printer";

export default function createSentryLink() {
  return new ApolloLink((operation, forward) => {
    const definition = extractDefinition(operation);
    const query = stringify(definition.loc?.source?.body ?? print(definition));
    const operationName =
      operation?.operationName || definition.name?.value || "some query";

    Sentry.configureScope((scope) => {
      scope.setTransactionName(`GraphQL: ${operationName}`);
      scope.setFingerprint(["{{ default }}", operationName]);
    });

    Sentry.addBreadcrumb({
      type: "http",
      category: `graphql.${definition.operation}`,
      message: query,
    });

    return forward(operation);
  });
}

function extractDefinition(operation) {
  return operation.query.definitions.find(
    (q) => q.kind === "OperationDefinition"
  );
}

function stringifyObjectKeys(object) {
  const stringified = {};

  for (const [key, value] of Object.entries(object)) {
    stringified[key] = typeof value === "object" ? stringify(value) : value;
  }

  return stringified;
}

function stringify(value) {
  return JSON.stringify(value, null, 2);
}
