<template>
  <v-avatar :color="avatarInfo.color" :size="size" class="mr-2">
    <span class="white--text">
      {{ avatarInfo.initials }}
    </span>
  </v-avatar>
</template>

<script>
import { getInitials } from "@components/manage_tasks/helper";

export default {
  name: "CardUserAvatar",
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: [Number, String],
      default: 35,
    },
  },
  computed: {
    avatarInfo() {
      return getInitials(this.user);
    },
  },
};
</script>
