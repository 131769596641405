import PageFacilityVisitsReport from "./PageFacilityVisitsReport.vue";
import PageManagementSummary from "./PageManagementSummary.vue";
import PagePenetrationsAndAnnualizedLifetimeRevenueReport from "./PagePenetrationsAndAnnualizedLifetimeRevenueReport.vue";
import PageRevenueReport from "./PageRevenueReport.vue";
import PageMonthlyTrends from "./PageMonthlyTrends.vue";

const routes = {
  navigation: [
    {
      path: "/management_summary",
      name: "ManagementSummary",
      component: PageManagementSummary,
    },
    {
      path: "/management_summary/facility_visits_report",
      name: "FacilityVisitsReport",
      component: PageFacilityVisitsReport,
    },
    {
      path: "/management_summary/penetrations_and_annualized_revenue_report",
      name: "PenetrationsAndAnnualizedLifetimeRevenueReport",
      component: PagePenetrationsAndAnnualizedLifetimeRevenueReport,
    },
    {
      path: "/management_summary/revenue_report",
      name: "RevenueReport",
      component: PageRevenueReport,
    },
    {
      path: "/management_summary/monthly_trends",
      name: "MonthlyTrends",
      component: PageMonthlyTrends,
    },
  ],
};
export default routes;
