import PageIncident from "./PageIncident.vue";
import PageZoneIncident from "./PageZoneIncident.vue";

const routes = {
  organization: [
    {
      path: "incidents/:incident_id",
      name: "Incident",
      component: PageIncident,
    },
    {
      path: "ZoneIncidents/:incident_id",
      name: "ZoneIncident",
      component: PageZoneIncident,
    },
  ],
};

export default routes;
