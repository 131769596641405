<template>
  <div>
    <TreeNode
      v-for="node in treeItems"
      :key="node.id"
      :node="node"
      :checked-nodes="selectedItems"
      @update:checked-nodes="handleNodeUpdate"
    />
  </div>
</template>

<script>
import TreeNode from "./TreeNode.vue";

export default {
  name: "TreeView",
  components: {
    TreeNode,
  },
  props: {
    treeItems: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleNodeUpdate(item) {
      this.$emit("input", item);
    },
  },
};
</script>
