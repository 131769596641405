<template>
  <v-skeleton-loader
    v-if="authenticated"
    type="heading, table-tbody"
    min-width="400px"
  />
  <div v-else style="width: 100%">
    <CardLayoutAuthenticationForm
      v-if="remindPassword"
      title="Forgot Password"
      submit-text="Remind"
      :in-process="inProcess"
      @cancel="remindPassword = false"
      @submit="tryRemind"
    >
      <ValidatedTextField
        v-model="username"
        class="mt-8"
        :disabled="inProcess"
        name="Username"
        rules="required"
      />
    </CardLayoutAuthenticationForm>

    <CardLayoutAuthenticationForm
      v-else
      title="Log In"
      submit-text="Log In"
      :in-process="inProcess"
      @submit="tryLogin"
    >
      <ValidatedTextField
        v-model="username"
        :disabled="inProcess"
        :autofocus="true"
        name="Username"
        rules="required"
      />
      <div class="relative">
        <ValidatedTextField
          v-model="password"
          :disabled="inProcess"
          :type="showPassword ? 'text' : 'password'"
          name="Password"
          rules="required|min:6|max:128"
        />
        <v-icon
          small
          class="show-password-icon"
          @click="togglePasswordVisibility"
        >
          {{ showPassword ? "mdi-eye-off-outline" : "mdi-eye-outline" }}
        </v-icon>
      </div>
      <p class="pa-0 ma-0">
        <a @click="remindPassword = true"> Forgot Password? </a>
      </p>
    </CardLayoutAuthenticationForm>
  </div>
</template>

<script>
import CardLayoutAuthenticationForm from "./CardLayoutAuthenticationForm.vue";
import ValidatedTextField from "../utils/ValidatedTextField.vue";

import { ref, watch } from "vue";
import { required, max, min } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

import useUser from "./useUser";
import useAlerts from "@tod-ui/composables/useAlerts";

extend("max", {
  ...max,
  message: "{_field_} may not be longer than {length} characters",
});

extend("min", {
  ...min,
  message: "{_field_} must be at least {length} characters",
});

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "PageLogin",
  components: {
    CardLayoutAuthenticationForm,
    ValidatedTextField,
  },
  setup() {
    const username = ref("");
    const password = ref("");
    const remindPassword = ref(false);
    const showPassword = ref(false);
    const { inProcess, authenticated, login, requestPasswordReset, clearUser } =
      useUser();
    const { addAlert, clearAllAlerts } = useAlerts();

    watch(remindPassword, (val) => {
      if (!val) {
        password.value = "";
      }
      clearAllAlerts();
    });

    return {
      username,
      password,
      remindPassword,
      inProcess,
      authenticated,
      login,
      requestPasswordReset,
      clearUser,
      addAlert,
      clearAllAlerts,
      showPassword,
    };
  },
  beforeMount() {
    this.clearUser(this.$apollo);
  },
  methods: {
    async tryLogin() {
      this.clearAllAlerts();
      const { logged, message } = await this.login(
        this.$apollo,
        this.username,
        this.password
      );
      if (!logged && message) {
        this.addAlert({
          type: "error",
          message: message,
        });
      }
    },
    async tryRemind() {
      this.clearAllAlerts();
      const [success, message] = await this.requestPasswordReset(
        this.$apollo,
        this.username
      );
      this.remindPassword = false;
      this.$nextTick(() => {
        this.addAlert({
          type: success ? "info" : "error",
          message: message || "Something went wrong. Please try again later",
          timeout: 15,
        });
      });
    },
    togglePasswordVisibility() {
      return (this.showPassword = !this.showPassword);
    },
  },
};
</script>

<style scoped>
.show-password-icon {
  position: absolute;
  bottom: 122px;
  right: 26px;
}
</style>
