<template>
  <DialogLayout
    v-if="!loading"
    v-model="openViewTask"
    :title="task.title"
    width="1000px"
  >
    <AlertsAnimated :alerts="localAlerts" />
    <v-skeleton-loader
      :loading="loading"
      type="list-item-two-line, list-item-two-line, list-item-two-line"
    >
      <dl class="property-list my-4 two-columns">
        <dt>Task ID</dt>
        <div>{{ task.taskId }}</div>
        <dt>Task Name</dt>
        <div>{{ task.title }}</div>
        <dt>Task Status</dt>
        <div>
          <v-select
            v-model="selectedStatus"
            style="max-width: 250px"
            dense
            :items="resolutionStatusItems"
            item-text="text"
            item-value="value"
            @change="() => updateTask('task_status')"
          />
        </div>
        <dt>Task Type</dt>
        <div>{{ titleCase(task.taskType?.name) }}</div>
        <dt>Due Date</dt>
        <div>
          <v-text-field
            :value="formatDateTime4Humans(task.dueAt)"
            style="max-width: 250px"
            readonly
            solo
            dense
          />
        </div>
        <dt>Assignee</dt>
        <div>
          <SelectOrganizationUsers
            :value="selectedAssignee"
            :organization-id="parentFacilityId"
            @input="updateAssignee"
          />
        </div>
        <dt>Task Priority</dt>
        <div>
          <SeverityChip :severity="titleCase(task.priority)" />
        </div>
        <dt>Task Description</dt>
        <div>
          <v-textarea
            v-model="taskDescription"
            rows="3"
            row-height="15"
            :maxlength="250"
            counter="250"
            :rules="[(v) => v.length <= 250 || 'Max 250 characters']"
            @input="onDescriptionChange"
          />
          <div class="d-flex justify-end mb-5">
            <v-btn
              v-if="isDescriptionChanged"
              class="mr-2"
              small
              @click="cancelDescriptionChange"
            >
              Cancel
            </v-btn>
            <v-btn
              v-if="isDescriptionChanged"
              small
              color="primary"
              @click="() => updateTask('done')"
            >
              Save
            </v-btn>
          </div>
        </div>
      </dl>
    </v-skeleton-loader>
    <v-tabs v-model="selectedTab">
      <v-tab>History</v-tab>
      <v-tab>Comments</v-tab>
      <v-tab>Attachments</v-tab>
    </v-tabs>

    <v-tabs-items v-model="selectedTab">
      <v-tab-item>
        <CardTaskHistory :task-id="task.id" />
      </v-tab-item>
      <v-tab-item>
        <CardTaskComments :task-id="task.id" />
      </v-tab-item>
      <v-tab-item>
        <CardTaskAttachments
          :task-id="task.id"
          :initial-attachments="task.files"
        />
      </v-tab-item>
    </v-tabs-items>
  </DialogLayout>
</template>

<script>
import DialogLayout from "@layout/DialogLayout.vue";
import SeverityChip from "@tod-ui/components/SeverityChip.vue";
import { UPDATE_TASK, GET_TASK, TASKS, TASK_HISTORY } from "./graphql";
import { RESOLUTION_STATUS } from "@tod-ui/constants/task_management";
import { titleCase } from "@tod-ui/helpers/strings";
import { formatDate } from "@tod-ui/helpers/datetime";
import useUser from "@components/authentication/useUser";
import CardTaskHistory from "./CardTaskHistory.vue";
import { formatName } from "./helper";
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import useAlerts from "@tod-ui/composables/useAlerts";
import CardTaskComments from "./CardTaskComments.vue";
import CardTaskAttachments from "./CardTaskAttachments.vue";
import SelectOrganizationUsers from "@components/common/SelectOrganizationUsers.vue";
import { ORGANIZATION_ANCESTORS } from "../../graphql/organizations";

export default {
  name: "DialogViewTask",
  components: {
    DialogLayout,
    SeverityChip,
    CardTaskHistory,
    AlertsAnimated,
    CardTaskComments,
    CardTaskAttachments,
    SelectOrganizationUsers,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const { defaultOrganization } = useUser();
    const { addLocalAlert, clearAllLocalAlerts, localAlerts } = useAlerts();
    return {
      defaultOrganization,
      addLocalAlert,
      clearAllLocalAlerts,
      localAlerts,
    };
  },
  data() {
    return {
      resolutionStatusItems: [
        { text: "To Do", value: RESOLUTION_STATUS.TODO },
        { text: "In Progress", value: RESOLUTION_STATUS.IN_PROGRESS },
        { text: "In Review", value: RESOLUTION_STATUS.IN_REVIEW },
        { text: "Done", value: RESOLUTION_STATUS.DONE },
        { text: "Cancelled", value: RESOLUTION_STATUS.CANCELLED },
      ],
      selectedStatus: RESOLUTION_STATUS.TODO,
      selectedAssignee: null,
      taskDescription: "",
      originalDescription: "",
      isDescriptionChanged: false,
      selectedTab: 0,
      task: {},
      assigneeOptions: [],
      comments: [],
      parentFacilityId: "",
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.task.loading;
    },
    openViewTask: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    refetchTasks() {
      return [
        {
          query: TASKS,
          variables: {
            parentId: this.defaultOrganization.id,
            resolutions: [RESOLUTION_STATUS.TODO],
          },
        },
        {
          query: TASK_HISTORY,
          variables: {
            taskId: this.taskId,
          },
        },
      ];
    },
  },
  apollo: {
    task: {
      query: GET_TASK,
      variables() {
        return {
          id: this.taskId,
        };
      },
      update(data) {
        if (data.findTask) {
          const { resolution, assignee, description } = data.findTask;
          this.selectedStatus = resolution;
          this.selectedAssignee = assignee?.id;
          this.taskDescription = description;
          this.originalDescription = description;

          if (assignee) {
            this.assigneeOptions = [
              {
                id: assignee.id,
                name: formatName(assignee),
              },
            ];
          }
        }
        return data.findTask;
      },
    },
    organizationAncestors: {
      query: ORGANIZATION_ANCESTORS,
      variables() {
        return {
          id: this.task?.organization?.id,
          types: ["facility"],
        };
      },
      update(data) {
        this.parentFacilityId = data.organization.ancestors[0].id;
      },
      skip() {
        return !this.task?.organization?.id;
      },
    },
  },
  methods: {
    updateAssignee(newAssigneeId) {
      this.selectedAssignee = newAssigneeId;
      this.updateTask("assignee");
    },
    updateTask(operation) {
      this.clearAllLocalAlerts();
      try {
        this.$apollo.mutate({
          mutation: UPDATE_TASK,
          variables: {
            id: this.taskId,
            assigneeId: this.selectedAssignee,
            description: this.taskDescription,
            dueAt: this.task.dueAt,
            organizationId: this.task.organization.id,
            priority: this.task.priority,
            resolution: this.selectedStatus,
            taskTypeId: this.task.taskType.id,
            title: this.task.title,
          },
          refetchQueries: this.refetchTasks,
        });
        this.addLocalAlert({
          type: "success",
          message: "Task updated successfully",
          timeout: 15,
        });
      } catch (error) {
        this.addLocalAlert({
          type: "error",
          message: "Failed to update task",
          timeout: 15,
        });
      }
    },
    titleCase,
    formatDateTime4Humans(date) {
      return formatDate(date, this.defaultOrganization.timeZone);
    },
    onDescriptionChange() {
      this.isDescriptionChanged =
        this.taskDescription !== this.originalDescription;
    },
    cancelDescriptionChange() {
      this.taskDescription = this.originalDescription;
      this.isDescriptionChanged = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.property-list {
  dd {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &.two-columns {
    grid-template-columns: repeat(2, max-content auto);
  }
}
</style>
