<template>
  <v-card v-if="!loading" outlined tile>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      Unhealthy Defenders
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="unhealthyDevices"
      :search="search"
      :custom-filter="customFilter"
      :custom-sort="customSort"
      :footer-props="{ 'items-per-page-options': [10, 25, 50, 500] }"
      :sort-by="['name']"
    >
      <template #item.actions="{ item }">
        <ButtonView
          title="View Smart Unit"
          @click="gotoZone(item.organization)"
        />
      </template>

      <template #item.name="{ item }">
        {{ item.organization.name }}
      </template>

      <template #item.responderFirst="{ item }">
        {{ responderFirstName(item.organization) }}
      </template>

      <template #item.responderLast="{ item }">
        {{ responderLastName(item.organization) }}
      </template>

      <template #item.device="{ item }">
        <v-list dense color="transparent">
          <v-list-item class="ml-0 pl-0">
            <v-list-item-content>
              {{ item.shortId || item.externalId }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <template #item.health="{ item }">
        <v-list dense color="transparent">
          <v-list-item class="ml-0 pl-0">
            <v-list-item-icon>
              <DeviceGaugeCluster :device="item" />
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </template>

      <template #item.motionStartedAt="{ item }">
        <v-list dense color="transparent">
          <v-list-item class="ml-0 pl-0">
            <v-list-item-content>
              {{ deviceLastMotion(item) }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { listDevices } from "../../graphql/incidents";
import useFacility from "../authentication/useFacility";
import { deviceLastMotion } from "../common/helpers/devices";

import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";

export default {
  name: "UnhealthyInfoTable",
  components: {
    DeviceGaugeCluster,
    SearchField,
    ButtonView,
  },
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    unhealthyDevices: [],
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
      },
      { text: "Smart Unit #", value: "name" },
      { text: "First Name", value: "responderFirst" },
      { text: "Last Name", value: "responderLast" },
      { text: "Assigned Device", value: "device" },
      {
        text: "Device Health",
        filterable: false,
        sortable: false,
        value: "health",
      },
      { text: "Last Motion", value: "motionStartedAt" },
    ],
    search: null,
  }),
  computed: {
    loading() {
      return this.$apollo.queries.unhealthyDevices.loading;
    },
  },
  apollo: {
    unhealthyDevices: {
      query: listDevices,
      variables() {
        return {
          parentId: this.currentFacilityId,
          disposition: "in_service",
          status: "lost_contact",
        };
      },
      fetchPolicy: "no-cache",
      update({ organizationDevices }) {
        // Filter only devices assigned to zone
        return organizationDevices.filter(
          (device) =>
            device.organization.type == "zone" &&
            device.organization.responders.length > 0
        );
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is a `device`
      let _search = search.toLowerCase();
      const org = item.organization;

      // Filter on `name`
      if (org.name.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `responder`
      const firstName = this.responderFirstName(org);
      if (firstName && firstName.toLowerCase().indexOf(_search) >= 0)
        return true;

      const lastName = this.responderLastName(org);
      if (lastName && lastName.toLowerCase().indexOf(_search) >= 0) return true;

      // Filter on `device`
      if ((item.shortId || item.externalId).toLowerCase().indexOf(_search) >= 0)
        return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      if (["device", "motionStartedAt"].includes(_sortBy)) {
        items.sort((a, b) => {
          if (_sortBy === "device") {
            const aName = (a.shortId || a.externalId).toLowerCase();
            const bName = (b.shortId || b.externalId).toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "motionStartedAt") {
            if (!_sortDesc)
              return a.motionStartedAt < b.motionStartedAt ? -1 : 1;
            return b.motionStartedAt < a.motionStartedAt ? -1 : 1;
          }
        });
      } else {
        items.sort(({ organization: a }, { organization: b }) => {
          if (_sortBy === "name") {
            const aName = a.name.toLowerCase();
            const bName = b.name.toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "responderFirst") {
            const aName = this.responderFirstName(a).toLowerCase();
            const bName = this.responderFirstName(b).toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "responderLast") {
            const aName = this.responderLastName(a).toLowerCase();
            const bName = this.responderLastName(b).toLowerCase();

            if (!_sortDesc) return aName < bName ? -1 : 1;
            return bName < aName ? -1 : 1;
          } else if (_sortBy === "motionStartedAt") {
            const aDate = a.devices
              .map((device) => device.motionStartedAt)
              .sort((da, db) => {
                if (!_sortDesc) return da < db ? -1 : 1;
                return db < da ? -1 : 1;
              })[0];
            const bDate = b.devices
              .map((device) => device.motionStartedAt)
              .sort((da, db) => {
                if (!_sortDesc) return da < db ? -1 : 1;
                return db < da ? -1 : 1;
              })[0];

            if (!_sortDesc) return aDate < bDate ? -1 : 1;
            return bDate < aDate ? -1 : 1;
          } else {
            // Default sort comparison
            if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
            return b[_sortBy] < a[_sortBy] ? -1 : 1;
          }
        });
      }

      return items;
    },
    responderFirstName({ responders }) {
      if (responders.length === 0) return "";

      const responder = responders[0];
      if (responder.user) return responder.user.firstName;
    },
    responderLastName({ responders }) {
      if (responders.length === 0) return "";

      const responder = responders[0];
      if (responder.user) return responder.user.lastName;
    },
    gotoZone(zone) {
      this.$router.push({
        name: "AssignedSmartUnitDrilldown",
        params: {
          renter_id: zone.responders[0].user.id,
          zone_id: zone.id,
        },
      });
    },
    deviceLastMotion,
  },
};
</script>
