<template>
  <v-card v-if="!loading" outlined flat tile>
    <div :style="`background-color: ${color}; height: 18px`"></div>
    <v-card-title class="subtitle-1">
      Active Smart Zones
      <v-spacer />
      <div style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="zonesWithTraffic"
        :loading="trafficLoading"
        loading-text="Loading Smart Zones..."
        :search="search"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        :footer-props="{ 'items-per-page-options': [10, 25, 50, -1] }"
        :sort-by="['name']"
      >
        <template #header.traffic>
          {{ `${facilityYesterdayDOW()}'s Traffic` }}
        </template>

        <template #item.actions="{ item }">
          <ButtonView @click="gotoZone(item.id)" />
        </template>

        <template #item.climate="{ item: { devices, incidents, profile } }">
          <ZoneClimateCluster
            small
            :devices="devices"
            :incidents="incidents"
            :climate-config="profile.climateConfig"
            :motion-config-enabled="hasMotionEnabled(profile)"
          />
        </template>

        <template #item.profile="{ item: { profile } }">
          <template v-if="hasMotionEnabled(profile)">
            <v-icon small color="success" class="mr-4" title="Motion Enabled">
              fas fa-walking
            </v-icon>
          </template>
          <template v-else>
            <v-icon
              small
              color="grey lighten-1"
              class="mr-4"
              title="Motion Disabled"
            >
              fas fa-walking
            </v-icon>
          </template>

          <template v-if="hasPeopleCountingEnabled(profile)">
            <v-icon small color="success" class="mr-4" title="Traffic Enabled">
              fas fa-users-class
            </v-icon>
          </template>
          <template v-else>
            <v-icon
              small
              color="grey lighten-1"
              class="mr-4"
              title="Traffic Disabled"
            >
              fas fa-users-class
            </v-icon>
          </template>

          <template v-if="hasClimateEnabled(profile)">
            <v-icon small color="success" class="mr-4" title="Climate Enabled">
              fas fa-temperature-hot
            </v-icon>
          </template>
          <template v-else>
            <v-icon
              small
              color="grey lighten-1"
              class="mr-4"
              title="Climate Disabled"
            >
              fas fa-temperature-hot
            </v-icon>
          </template>
        </template>

        <template #item.traffic="{ item }">
          <template v-if="item.traffic === 'N/A'">
            <span class="grey--text">{{ item.traffic }}</span>
          </template>
          <template v-else>{{ item.traffic }}</template>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { yesterdayStartedAt, yesterdayEndedAt } from "@tod-ui/helpers/datetime";

import SearchField from "@atoms/SearchField.vue";
import ButtonView from "@atoms/ButtonView.vue";
import ZoneClimateCluster from "../fixtures/ZoneClimateCluster.vue";

import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import useFacility from "../authentication/useFacility";

export default {
  name: "SmartZonesTable",
  components: {
    SearchField,
    ButtonView,
    ZoneClimateCluster,
  },
  props: {
    zones: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#aaaaaa",
    },
  },
  setup() {
    const { currentFacilityId, facilityYesterdayDOW } = useFacility();
    return { currentFacilityId, facilityYesterdayDOW };
  },
  data: () => ({
    search: "",
    headers: [
      {
        text: "Details",
        align: "start",
        filterable: false,
        sortable: false,
        value: "actions",
        width: "5%",
      },
      {
        text: "Smart Zone",
        value: "name",
        width: "25%",
      },
      {
        text: "Traffic",
        align: "center",
        filterable: false,
        value: "traffic",
        width: "20%",
      },
      {
        text: "Zone Status",
        filterable: false,
        sortable: false,
        value: "climate",
        width: "25%",
      },
      {
        text: "Active Sensors",
        filterable: false,
        sortable: false,
        value: "profile",
        width: "25%",
      },
    ],
    zoneTraffic: [],
  }),
  computed: {
    trafficLoading() {
      return this.$apollo.queries.zoneTraffic.loading;
    },
    zonesWithTraffic() {
      if (this.trafficLoading) return [];

      return this.zones.map((zone) => {
        return {
          ...zone,
          traffic: this.yesterdaysTrafficCount(zone),
        };
      });
    },
  },
  apollo: {
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      variables() {
        return {
          parentId: this.currentFacilityId,
          dateTimeFilter: {
            after: yesterdayStartedAt(this.timeZone),
            before: yesterdayEndedAt(this.timeZone),
          },
          aggregate: "day",
        };
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      // NOTE: item is an `organization`
      let _search = search.toLowerCase();

      // Filter on `name`
      const name = `${item.name}`;
      if (name.toLowerCase().indexOf(_search) >= 0) return true;
    },
    customSort(items, sortBy, sortDesc) {
      // sortBy and sortDesc are arrays
      const _sortBy = sortBy[0];
      const _sortDesc = sortDesc[0];

      items.sort((a, b) => {
        if (_sortBy === "name") {
          const aName = a.name.toLowerCase();
          const bName = b.name.toLowerCase();

          if (!_sortDesc) return aName < bName ? -1 : 1;
          return bName < aName ? -1 : 1;
        } else if (_sortBy === "traffic") {
          if (!_sortDesc) return a.traffic < b.traffic ? -1 : 1;
          return b.traffic < a.traffic ? -1 : 1;
        } else {
          // Default sort comparison
          if (!_sortDesc) return a[_sortBy] < b[_sortBy] ? -1 : 1;
          return b[_sortBy] < a[_sortBy] ? -1 : 1;
        }
      });

      return items;
    },
    hasClimateEnabled(profile) {
      const climate = profile.climateConfig;
      return climate && !climate.disabled;
    },
    hasMotionEnabled(profile) {
      const motion = profile.motionConfigs.find((c) => c.mode === "motion");
      return motion && !motion.disabled;
    },
    hasPeopleCountingEnabled(profile) {
      const counting = profile.motionConfigs.find(
        (c) => c.mode === "people_counting"
      );
      return counting && !counting.disabled;
    },
    viewIncident({ incidents }) {
      const { id: incidentId } = incidents[0];
      this.$router.push({
        name: "ZoneIncident",
        params: {
          incident_id: incidentId,
        },
      });
    },
    gotoZone(zoneId) {
      this.$router.push({
        name: "ViewSmartZone",
        params: {
          smart_zone_id: zoneId,
        },
      });
    },
    yesterdaysTrafficCount({ id, profile }) {
      if (!this.zoneTraffic || this.zoneTraffic.length === 0) return 0;

      const counting = profile.motionConfigs.find(
        ({ mode }) => mode === "people_counting"
      );
      if (counting && counting.disabled) return "N/A";

      const traffic = this.zoneTraffic.find(
        ({ organization }) => organization.id === id
      );

      if (!traffic) return 0;
      return traffic.count;
    },
  },
};
</script>
