<template>
  <CardLayout title="Comments">
    <v-row dense>
      <v-col cols="6">
        <v-textarea
          v-model="newComment"
          label="Add a comment"
          rows="3"
          outlined
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="text-left">
        <v-btn small @click="cancelComment"> Cancel </v-btn>
        <v-btn
          color="primary"
          small
          :disabled="!newComment.trim()"
          :loading="isAddingComment"
          class="ml-2"
          @click="addComment"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
    <v-list v-if="comments.length" class="mt-4">
      <v-list-item
        v-for="comment in comments"
        :key="comment.id"
        class="mb-4 elevation-1"
      >
        <v-list-item-avatar>
          <CardUserAvatar :user="comment.creator" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-2">
            {{ formatName(comment.creator) }}
            <span class="text-caption grey--text ml-2">
              {{ formatDate(comment.insertedAt) }}
            </span>
          </v-list-item-title>
          <v-list-item-subtitle class="mt-2">
            {{ comment.content }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon small @click="deleteComment(comment.id)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-alert v-else type="info" class="my-4">No comments yet.</v-alert>
  </CardLayout>
</template>

<script>
import { ref } from "vue";
import CardLayout from "@layout/CardLayout.vue";
import CardUserAvatar from "@components/common/CardUserAvatar.vue";
import {
  TASK_COMMENTS,
  CREATE_TASK_COMMENT,
  DELETE_TASK_COMMENT,
} from "./graphql";
import { formatName, getInitials } from "./helper";

export default {
  name: "CardTaskComments",
  components: {
    CardLayout,
    CardUserAvatar,
  },
  props: {
    taskId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const newComment = ref("");
    const isAddingComment = ref(false);
    const comments = ref([]);

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      return date.toLocaleString();
    };

    return {
      comments,
      newComment,
      isAddingComment,
      getInitials,
      formatName,
      formatDate,
    };
  },
  apollo: {
    taskComments: {
      query: TASK_COMMENTS,
      variables() {
        return {
          taskId: this.taskId,
        };
      },
      update(data) {
        this.comments = data.taskComments;
      },
    },
  },
  methods: {
    async addComment() {
      this.isAddingComment = true;
      try {
        await this.$apollo.mutate({
          mutation: CREATE_TASK_COMMENT,
          variables: {
            taskId: this.taskId,
            content: this.newComment.trim(),
          },
        });
        this.$apollo.queries.taskComments.refetch();
        this.newComment = "";
      } catch (error) {
        console.error("Error adding comment:", error);
      } finally {
        this.isAddingComment = false;
      }
    },
    async deleteComment(commentId) {
      try {
        await this.$apollo.mutate({
          mutation: DELETE_TASK_COMMENT,
          variables: {
            id: commentId,
          },
          update: (store) => {
            const data = store.readQuery({
              query: TASK_COMMENTS,
              variables: { taskId: this.taskId },
            });
            data.taskComments = data.taskComments.filter(
              (comment) => comment.id !== commentId
            );
            store.writeQuery({
              query: TASK_COMMENTS,
              variables: { taskId: this.taskId },
              data,
            });
          },
        });
      } catch (error) {
        console.error("Error deleting comment:", error);
      }
    },
    cancelComment() {
      this.newComment = "";
    },
  },
};
</script>
