import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router/composables";

const $cachedState = ref({});

const DEFAULT_STATE = {
  itemsPerPage: null,
  page: 1,
  sortBy: undefined,
  sortDesc: false,
  search: "",
};

function useDataTable(initialTableState = {}) {
  const $route = useRoute();
  const key = computed(
    () => $route.name + "." + ($route?.params?.organization_id || "")
  );

  const tableState = ref({
    ...DEFAULT_STATE,
    ...initialTableState,
    ...($cachedState.value[key.value] || {}),
  });

  watch(
    tableState,
    (newState) => {
      if (
        $cachedState.value[key.value]?.search !== undefined &&
        newState.search !== $cachedState.value[key.value].search &&
        newState.page > 1
      ) {
        tableState.value.page = 1;
        return;
      }

      $cachedState.value[key.value] = { ...newState };
    },
    { deep: true }
  );

  let prevItemsPerPage = null;
  function showAllItems() {
    prevItemsPerPage = tableState.value.itemsPerPage;
    tableState.value.itemsPerPage = -1;
  }
  function restoreItemsPerPage() {
    if (prevItemsPerPage) {
      tableState.value.itemsPerPage = prevItemsPerPage;
    }
  }

  return {
    showAllItems,
    restoreItemsPerPage,
    tableState,
  };
}

export default useDataTable;
