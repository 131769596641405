<template>
  <v-card outlined flat tile>
    <v-card-title class="subtitle-1">
      <v-spacer />
      <v-row dense justify="end">
        <v-col cols="12" xs="12" md="5">
          <v-select
            v-model="selectedZone"
            :disabled="loading"
            placeholder="View traffic for..."
            :items="smartZones"
            item-text="name"
            item-value="id"
            single-line
          />
        </v-col>
        <v-col cols="12" xs="12" md="5">
          <div class="pt-4">
            <DateTimeFilter
              v-model="dateRange"
              :ranges="['Today', '1w', '1m']"
              default="1m"
              max-range="1m"
              custom
            />
          </div>
        </v-col>
      </v-row>
      <v-progress-linear :active="loading" bottom height="2" indeterminate />
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader :loading="loading" type="image, image" height="300px">
        <ZoneTrafficReportChart
          :chart-data="zoneTraffic"
          :max="chartMax"
          :min="chartMin"
        />
      </v-skeleton-loader>
    </v-card-text>
  </v-card>
</template>

<script>
import { formatMonthDay } from "@tod-ui/helpers/datetime";
import ZoneTrafficReportChart from "./ZoneTrafficReportChart.vue";
import { zonePeopleCountingEvents } from "../../graphql/organizations/smartZones";
import { organizationSmartZones } from "../../graphql/organizations";
import useFacility from "../authentication/useFacility";
import { CHART_COLORS } from "@tod-ui/constants/colors";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";

export default {
  name: "ZoneTrafficReport",
  components: {
    ZoneTrafficReportChart,
    DateTimeFilter,
  },
  props: {
    color: {
      type: String,
      default: "#FFF",
    },
  },
  setup() {
    const { currentFacilityId, facilityTimeZone } = useFacility();
    return { currentFacilityId, facilityTimeZone };
  },
  data: () => ({
    zoneTraffic: [],
    zoneOptions: [],
    smartZones: [],
    selectedZone: null,
    dateRange: { after: undefined },
  }),
  computed: {
    loading() {
      const { smartZones, zoneTraffic } = this.$apollo.queries;
      return smartZones.loading || zoneTraffic.loading;
    },
  },
  apollo: {
    smartZones: {
      query: organizationSmartZones,
      variables() {
        return {
          id: this.currentFacilityId,
          sort: "name",
        };
      },
      update({ smartZones }) {
        const selectableZones = [
          {
            id: "all",
            name: "All Zones",
          },
          {
            divider: true,
          },
        ];

        smartZones.reduce((acc, zone) => {
          // must have devices and people-counting enabled
          if (zone.devices.length) {
            const config = zone.profile.motionConfigs?.find(
              ({ mode, disabled }) => mode === "people_counting" && !disabled
            );

            if (config) acc.push({ id: zone.id, name: zone.name });
          }

          return acc;
        }, selectableZones);

        return selectableZones;
      },
    },
    zoneTraffic: {
      query: zonePeopleCountingEvents,
      variables() {
        const vars = {
          aggregate: "hour",
          dateTimeFilter: this.dateRange,
          sort: "timestamp",
        };

        if (!this.selectedZone || this.selectedZone === "all") {
          vars.parentId = this.currentFacilityId;
        } else {
          vars.id = this.selectedZone;
        }

        return vars;
      },
      skip() {
        return !this.dateRange?.after;
      },
      update({ zoneTraffic }) {
        const processedData = zoneTraffic.reduce((acc, item) => {
          const label = formatMonthDay(item.timestamp);
          if (!acc[label]) {
            acc[label] = 0;
          }
          acc[label] += item.count;
          return acc;
        }, {});

        const labels = Object.keys(processedData);
        const data = Object.values(processedData);

        return {
          datasets: [
            {
              label: "Zone Traffic",
              backgroundColor: CHART_COLORS[0],
              data: data,
            },
          ],
          labels: labels,
        };
      },
    },
  },
};
</script>
