<template>
  <v-autocomplete
    v-model="selectedUnit"
    :items="units"
    :search-input.sync="searchInput"
    item-text="text"
    item-value="value"
    placeholder="Search for a unit"
    prepend-icon="mdi-magnify"
    return-object
    :no-data-text="noDataText"
    v-bind="$attrs"
    v-on="$listeners"
    @change="handleSelection"
  />
</template>

<script>
import useUser from "@components/authentication/useUser";
import { UNIT_WITH_PARENTS } from "@components/manage_tasks/graphql";
import { ref, watch } from "vue";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const { defaultOrganization, isFacilityManager } = useUser();
    const searchInput = ref("");
    const selectedUnit = ref(null);
    const units = ref([]);
    const search = ref("");
    const noDataText = ref("Type in a unit number");

    // Watch searchInput and update search only when typing
    watch(searchInput, (newValue) => {
      if (newValue !== selectedUnit.value?.text) {
        search.value = newValue;
      }
    });

    const handleSelection = () => {
      // Clear searchInput when an item is selected
      searchInput.value = "";
      emit("input", selectedUnit.value.value);
      emit("parentFacilityId", selectedUnit.value.parentFacility);
    };

    return {
      defaultOrganization,
      searchInput,
      selectedUnit,
      units,
      search,
      handleSelection,
      isFacilityManager,
      noDataText,
    };
  },
  apollo: {
    units: {
      query: UNIT_WITH_PARENTS,
      variables() {
        return {
          id: this.defaultOrganization.id,
          searchText: this.search,
        };
      },
      update(data) {
        return (this.units = data.organizationDescendants.map((unit) => ({
          text: this.isFacilityManager
            ? unit.name
            : `${unit.name} - ${unit.facility[0].name} - ${unit.facility[0].code}`,
          value: unit.id,
          parentFacility: unit.facility[0].id,
        })));
      },
      skip() {
        return !this.search;
      },
      debounce: 300,
    },
  },
};
</script>

<style lang="scss" scoped></style>
