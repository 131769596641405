<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: !!this.title,
        text: this.title,
      },
      legend: {
        position: "top",
      },
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
      tooltips: {
        mode: "point",
        intersect: true,
      },
      onClick: (event, activeElements) => {
        const chart = this.$data._chart;

        if (activeElements && activeElements.length > 0) {
          const element = activeElements[0];
          const xValue = chart.scales["x-axis-0"].getValueForPixel(
            event.offsetX
          );
          const yValue = chart.scales["y-axis-0"].getValueForPixel(
            event.offsetY
          );

          const datasets = chart.data.datasets;
          let clickedDataset = null;
          let accumulatedHeight = 0;

          for (let i = 0; i < datasets.length; i++) {
            const dataset = datasets[i];
            const value = dataset.data[element._index];
            accumulatedHeight += value || 0;

            if (yValue <= accumulatedHeight) {
              clickedDataset = dataset;
              break;
            }
          }

          if (clickedDataset) {
            const clickedData = {
              date: chart.data.labels[element._index],
              datasetLabel: clickedDataset.label,
              value: clickedDataset.data[element._index],
            };
            this.$emit("chartClicked", clickedData);
          }
        }
      },
      hover: {
        onHover: (event) => {
          const chart = this.$data._chart;
          const activePoints = chart.getElementsAtEvent(event);

          if (activePoints && activePoints.length) {
            chart.canvas.style.cursor = "pointer";
          } else {
            chart.canvas.style.cursor = "default";
          }
        },
      },
    };
    this.renderChart(this.chartData, options);
  },
};
</script>
