<template>
  <LayoutPage @assign-demo="assignDemoZone" @end-demo="endDemo">
    <template #title>
      <v-btn icon @click="gotoPageRenters">
        <v-icon color="primary">fas fa-chevron-circle-left</v-icon>
      </v-btn>
      <v-toolbar-title class="headline">
        <span class="grey--text text--darken-2">Renters</span>
        <v-icon small left right>fas fa-chevron-right</v-icon>
        <PossiblePMS as-icon :sync-obj="renter" content-class="mr-2">
          {{ renter.name }}
        </PossiblePMS>
        <span v-if="accountDisabled" class="error--text ml-2">
          ({{ billingAccountStatusText(billingAccount.status) }})
        </span>
      </v-toolbar-title>
    </template>
    <template #actions>
      <v-btn
        :outlined="!showAccountHistory"
        :color="`grey ${showAccountHistory ? 'lighten-2' : 'darken-2'}`"
        @click="showAccountHistory = !showAccountHistory"
      >
        Account History
        <v-icon
          small
          right
          class="icon-details"
          :class="{ open: showAccountHistory }"
        >
          fas fa-arrow-circle-right
        </v-icon>
      </v-btn>
    </template>

    <v-expand-transition>
      <v-row v-if="showAccountHistory">
        <v-col offset-xl="5" xl="7">
          <AccountHistoryTable :billing-account-id="billingAccount.id" />
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row>
      <v-col xl="5">
        <CardRenterInfo
          :demo-renter="isActiveDemo"
          @added-secondary-phone="addNewResponder"
          @remove-renter="maybeRemoveAllDevices"
          @account-status-changed="showAccountHistory = false"
        />
      </v-col>

      <v-col xl="7">
        <CardLayout v-if="isActiveDemo" title="Demo Smart Unit">
          <v-simple-table>
            <thead>
              <tr>
                <th>Smart Unit #</th>
                <th>Unit Type</th>
                <th>Unit Size</th>
                <th>Devices(s)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="width: 30%; white-space: nowrap">
                  {{ demoUnit.name }}
                </td>
                <td style="width: 15%; white-space: nowrap">
                  {{ displayUnitType(demoUnit.unitType, "type") }}
                </td>
                <td style="width: 15%; white-space: nowrap">
                  {{ displayUnitType(demoUnit.unitType, "size") }}
                </td>
                <td style="width: 20%; white-space: nowrap">
                  <v-list dense color="transparent">
                    <v-list-item
                      v-for="device in demoUnit.devices"
                      :key="device.id"
                      class="ml-0 pl-0"
                    >
                      <v-list-item-content>{{
                        device.shortId || device.externalId
                      }}</v-list-item-content>
                      <v-list-item-icon>
                        <DeviceGaugeCluster :device="device" />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </td>
                <td style="width: 1%; white-space: nowrap">
                  <PossiblePMS v-slot="{ disabled }" inline>
                    <v-btn
                      small
                      color="success"
                      :disabled="disabled || disableAssignDemo"
                      @click="assignDemoZone"
                    >
                      Assign Unit
                    </v-btn>
                  </PossiblePMS>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </CardLayout>

        <CardLayout
          v-else
          title="Assigned Smart Units"
          :loading="assignedUnitsLoading"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th>Details</th>
                <th>Smart Unit #</th>
                <th>Device(s)</th>
                <th v-show="showDocuments" class="text-center">
                  Document Status
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="assignedUnitsLoading">
              <tr>
                <td colspan="5">
                  <v-banner>
                    <em>Loading assigned smart units</em>
                  </v-banner>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="assignedUnits.length > 0">
              <tr v-for="unit in assignedUnits" :key="unit.id">
                <td>
                  <ButtonView @click="gotoSmartUnitDrilldown(unit)" />
                </td>
                <td
                  style="white-space: nowrap"
                  :class="{ 'grey--text': endedService }"
                >
                  {{ unit.name }}
                </td>
                <td style="white-space: nowrap">
                  <v-list dense color="transparent">
                    <v-list-item
                      v-for="device in unit.devices"
                      :key="device.id"
                      class="ml-0 pl-0"
                    >
                      <v-list-item-content
                        :class="{ 'grey--text': endedService }"
                        >{{
                          device.shortId || device.externalId
                        }}</v-list-item-content
                      >
                      <v-list-item-icon>
                        <DeviceGaugeCluster :device="device" />
                      </v-list-item-icon>
                    </v-list-item>
                  </v-list>
                </td>
                <td
                  v-if="showDocuments"
                  class="text-center"
                  style="white-space: nowrap"
                >
                  <DocumentDialog v-if="!endedService" :unit="unit" />
                  <span v-else class="grey--text">--</span>
                </td>
                <td style="width: 1%; white-space: nowrap">
                  <v-btn
                    small
                    class="mr-2"
                    color="primary"
                    outlined
                    @click="gotoUnitDevices(unit)"
                  >
                    Manage Devices
                  </v-btn>
                  <PossiblePMS
                    v-slot="{ disabled }"
                    :sync-obj="renter"
                    check-enrollment-mode
                    :force-tooltip="endedService"
                    inline
                  >
                    <DialogRemoveDevices
                      :unit="unit"
                      :renter-synced="disabled"
                      @submit="removeDevices"
                    />
                  </PossiblePMS>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <em>No smart units are assigned</em>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </CardLayout>

        <CardSmartUnitsInventory
          v-if="!isActiveDemo"
          class="mt-4"
          :renter="{ user: renter, userBillingAccount }"
        />
      </v-col>
    </v-row>

    <DialogRemoveRenter
      v-model="showRemoveRenterDialog"
      :renter-name="renter.name"
      @submit="submitRenterDialog"
    />
    <DialogRemoveDevices
      v-model="showRemoveAllDevicesDialog"
      :renter-synced="!!renter.externalId && hasPMS"
      @submit="removeAllRenterDevices"
    />
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import CardLayout from "@layout/CardLayout.vue";
import CardSmartUnitsInventory from "./CardSmartUnitsInventory.vue";
import DocumentDialog from "../documents/DocumentDialog.vue";
import DeviceGaugeCluster from "@atoms/DeviceGaugeCluster.vue";
import ButtonView from "@atoms/ButtonView.vue";
import PossiblePMS from "../common/PossiblePMS.vue";
import CardRenterInfo from "./CardRenterInfo.vue";
import AccountHistoryTable from "./AccountHistoryTable.vue";
import DialogRemoveDevices from "./DialogRemoveDevices.vue";
import { displayUnitType } from "@tod-ui/helpers/unitType";

import { userResponders } from "../../graphql/users";

import { addOrganizationDevices } from "../../graphql/organizations";

import { removeAccountOrganizations } from "../../graphql/organizations/zone";
import {
  GET_ASSIGNED_UNITS,
  REMOVE_UNIT_RESPONDER,
  AVAILABLE_SMART_UNITS,
} from "./graphql";
import { assignZoneToRenter, maybeAddSecondaryResponder } from "./renter";

import useRenter from "./useRenter";
import useBillingAccount from "./useBillingAccount";
import useAssignedUnits from "./useAssignedUnits";
import useFacility from "../authentication/useFacility";
import useAlerts from "@tod-ui/composables/useAlerts";
import { billingAccountStatusText } from "@tod-ui/helpers/billingAccount";
import { isTimeAfterNow } from "@tod-ui/helpers/datetime";
import { computed, onUnmounted } from "vue";
import usePMS from "../common/usePMS";
import DialogRemoveRenter from "./DialogRemoveRenter.vue";

export default {
  name: "PageRenter",
  components: {
    DeviceGaugeCluster,
    ButtonView,
    DocumentDialog,
    PossiblePMS,
    CardLayout,
    LayoutPage,
    CardRenterInfo,
    CardSmartUnitsInventory,
    AccountHistoryTable,
    DialogRemoveDevices,
    DialogRemoveRenter,
  },
  setup() {
    const { currentFacilityId, facilityFeatureEnabled } = useFacility();
    const {
      hasPMS,
      propertyManagementSystem,
      isDeviceControlledEnrollment,
      pmsServiceHasDocuments,
    } = usePMS();
    const { renter, fetchRenter, resetRenter } = useRenter();
    resetRenter();

    const {
      userBillingAccount,
      billingAccount,
      accountDisabled,
      endedService,
      fetchUserBillingAccounts,
      updateAccountStatus,
    } = useBillingAccount();

    const {
      assignedUnits,
      assignedUnitsLoading,
      fetchAssignedUnits,
      refetchAssignedUnits,
    } = useAssignedUnits();

    const { addAlert, clearAllAlerts } = useAlerts();

    const showDocuments = computed(
      () =>
        facilityFeatureEnabled("facility_integrated_esign") ||
        pmsServiceHasDocuments.value
    );
    onUnmounted(resetRenter);
    return {
      renter,
      fetchRenter,
      userBillingAccount,
      billingAccount,
      accountDisabled,
      endedService,
      fetchUserBillingAccounts,
      updateAccountStatus,
      assignedUnits,
      assignedUnitsLoading,
      fetchAssignedUnits,
      refetchAssignedUnits,
      currentFacilityId,
      hasPMS,
      propertyManagementSystem,
      isDeviceControlledEnrollment,
      showDocuments,
      addAlert,
      clearAllAlerts,
    };
  },
  data: () => ({
    userResponders: [],
    showRemoveRenterDialog: false,
    removeRenter: null,
    showRemoveAllDevicesDialog: false,
    showAccountHistory: false,
    demoAlertAdded: false,
  }),
  computed: {
    renterId() {
      return this.$route.params.renter_id;
    },
    demoUnit() {
      return this.userResponders[0].organization;
    },
    isActiveDemo() {
      return this.userResponders.length > 0;
    },
    disableAssignDemo() {
      return (
        this.hasPMS ||
        (this.isActiveDemo &&
          !isTimeAfterNow(
            this.userResponders[0].demoEndingAt,
            this.demoUnit.timeZone
          ))
      );
    },
    unitRefetchQueries() {
      return [
        // assignedUnits
        {
          query: GET_ASSIGNED_UNITS,
          variables: {
            userId: this.renterId,
            parentOrganizationId: this.currentFacilityId,
          },
        },
        // availableZones
        {
          query: AVAILABLE_SMART_UNITS,
          variables: {
            id: this.currentFacilityId,
          },
        },
      ];
    },
  },
  apollo: {
    userResponders: {
      query: userResponders,
      variables() {
        return {
          userId: this.renterId,
          parentOrganizationId: this.currentFacilityId,
        };
      },
      update({ userResponders }) {
        const demoResponders = userResponders.filter((ur) => !!ur.demoEndingAt);
        return demoResponders;
      },
      fetchPolicy: "network-only",
    },
  },
  watch: {
    userResponders(demoResponders) {
      if (demoResponders.length > 0 && this.renter?.name) {
        this.addDemoAlert();
      }
    },
    //second watch in case userResponders resolves faster then fetchRenter
    renter(theRenter) {
      if (this.userResponders.length > 0 && theRenter?.name) {
        this.addDemoAlert();
      }
    },
  },
  async beforeMount() {
    await this.fetchRenter(this.$apollo, this.renterId);
    await this.fetchUserBillingAccounts(this.$apollo, this.renterId);
    await this.fetchAssignedUnits(this.$apollo, this.renterId);
  },
  methods: {
    // navigation methods
    gotoRemoveRenter() {
      this.$router.push({
        name: "RemoveRenter",
        params: { renter_id: this.renter.id },
      });
    },
    gotoUnitDevices({ id: unitId }) {
      this.$router.push({
        name: "ManageSmartUnitDevices",
        params: { zone_id: unitId },
      });
    },
    gotoPageRenters() {
      this.$router.push({
        name: "Renters",
      });
    },
    gotoSmartUnitDrilldown({ id: unitId }) {
      this.$router.push({
        name: "AssignedSmartUnitDrilldown",
        params: {
          renter_id: this.renter.id,
          zone_id: unitId,
        },
      });
    },

    maybeRemoveAllDevices() {
      this.clearAllAlerts();
      if (this.assignedUnits.length > 0) {
        this.showRemoveAllDevicesDialog = true;
      } else {
        this.gotoRemoveRenter();
      }
    },
    removeDevices({ value, selectedUnit }) {
      if (value) {
        this.unassignUnitAndDevices(selectedUnit);
      } else if (this.isDeviceControlledEnrollment && this.renter.externalId) {
        this.terminateService();
      } else {
        this.unassignUnit(selectedUnit.id);
      }
      if (!this.hasPMS && this.assignedUnits.length <= 1) {
        this.showRemoveRenterDialog = true;
      }
    },
    async removeAllRenterDevices({ value }) {
      if (value) {
        for (let unit of this.assignedUnits) {
          await this.unassignUnitAndDevices(unit);
        }
      }
      this.gotoRemoveRenter();
    },
    submitRenterDialog(shouldRemoveRenter) {
      if (shouldRemoveRenter) {
        this.gotoRemoveRenter();
      } else {
        this.showRemoveRenterDialog = false;
      }
    },

    // mutation methods
    async unassignUnit(selectedUnitId) {
      try {
        const response = await this.$apollo.mutate({
          mutation: removeAccountOrganizations,
          variables: {
            billingAccountId: this.billingAccount.id,
            organizationIds: [selectedUnitId],
          },
          refetchQueries: this.unitRefetchQueries,
        });
      } catch (error) {
        this.addAlert({
          type: "error",
          message: `There was an error removing Smart Unit ${zone.name}`,
        });
      }
    },

    async unassignUnitAndDevices(unit) {
      // Unassign the Smart Unit first. This prevents multiple notifications
      await this.unassignUnit(unit.id);

      const devices = unit.devices.map((device) => device.id);
      if (devices.length === 0) return;

      try {
        await this.$apollo.mutate({
          // "add" it back to facility inventory
          mutation: addOrganizationDevices,
          variables: { id: this.currentFacilityId, devices },
          refetchQueries: this.unitRefetchQueries,
        });
      } catch (error) {
        this.addAlert({
          type: "error",
          message: `There was an error removing devices from Smart Unit ${unit.name}`,
        });
      }
    },
    async terminateService() {
      try {
        this.showAccountHistory = false;
        await this.updateAccountStatus(this.$apollo, "ended_service");
        this.addAlert({
          type: "success",
          message: `SD service for the renter ${this.renter.name} was ended successfully`,
          timeout: 15,
        });
      } catch (error) {
        this.addAlert({
          type: "error",
          message: `There was an error ending SD service for ${this.renter.name}`,
        });
      }
    },

    async addNewResponder(newContactMethod) {
      const updatedUnitNames = await Promise.all(
        this.assignedUnits.map((unit) =>
          maybeAddSecondaryResponder(
            this.$apollo,
            unit,
            this.renterId,
            newContactMethod.id
          )
        )
      ).then((results) => results.filter((unitName) => unitName !== null));

      if (updatedUnitNames.length) {
        this.addAlert({
          type: "success",
          message: `Secondary alert recipient was added to ${updatedUnitNames.join(
            ", "
          )}`,
          timeout: 30,
        });
      }
    },
    warnOnAssign(zone) {
      return !!zone.devices.find(
        (d) => d.status !== "ok" || d.disposition !== "in_service"
      );
    },

    addDemoAlert() {
      if (this.demoAlertAdded) return;
      const actions = this.disableAssignDemo
        ? {
            "end-demo": "End Demo",
          }
        : {
            "assign-demo": "Assign Unit",
            "end-demo": "End Demo",
          };

      this.addAlert({
        type: "warning",
        icon: "fas fa-user-clock",
        title: "Demo in Progress",
        message: `Renter ${this.renter.name} is demonstrating service in unit ${
          this.demoUnit.name
        }.
          You may proceed with the demonstration.
          If you’ve finished, ${
            this.hasPMS
              ? `unit assignment for the renter will be synchronized from ${this.propertyManagementSystem}.`
              : "would you like to begin StorageDefender service?"
          }`,
        actions,
      });
      this.demoAlertAdded = true;
    },
    async assignDemoZone() {
      this.clearAllAlerts();
      const { name } = this.demoUnit;
      const unhealthy = this.warnOnAssign(this.demoUnit);

      if (
        !unhealthy ||
        confirm(
          `Smart Unit "${name}" has an Unhealthy Device. Are you sure you want to assign it?`
        )
      ) {
        try {
          await assignZoneToRenter(
            this.$apollo,
            this.demoUnit,
            this.renter,
            this.userBillingAccount,
            this.currentFacilityId
          );
          this.$apollo.queries.userResponders.refetch();

          this.addAlert({
            type: "success",
            message:
              "Make sure the Renter has received and signs the New Tenant Addendum.",
          });
        } catch (error) {
          this.addAlert({
            type: "error",
            message: `There was an error assigning Smart Unit ${name}`,
          });
        }
      }
    },
    async endDemo() {
      this.clearAllAlerts();
      const assignedUnits = await this.refetchAssignedUnits();
      if (assignedUnits?.some((unit) => unit.id === this.demoUnit.id)) return;

      const responder = this.userResponders[0];
      try {
        await this.$apollo.mutate({
          mutation: REMOVE_UNIT_RESPONDER,
          variables: {
            id: responder.id,
            organizationId: this.demoUnit.id,
          },
          refetchQueries: [
            {
              query: GET_ASSIGNED_UNITS,
              variables: {
                userId: this.renterId,
                parentOrganizationId: this.currentFacilityId,
              },
            },
          ],
        });
        this.$apollo.queries.userResponders.refetch();
        if (this.assignedUnits.length === 0 && !this.hasPMS) {
          this.showRemoveRenterDialog = true;
        }
      } catch (error) {
        this.addAlert({
          message: "Error occurred while ending smart unit demonstration",
        });
      }
    },
    displayUnitType,
    billingAccountStatusText,
  },
};
</script>
