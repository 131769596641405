<template>
  <v-card>
    <v-skeleton-loader v-if="loading" type="article, actions" />
  </v-card>
</template>

<script>
import useNotifications from "./useNotifications";

export default {
  name: "NotificationResolution",
  setup() {
    const { loading, redirectToNotification } = useNotifications();
    return { loading, redirectToNotification };
  },
  beforeMount() {
    this.redirectToNotification(this.$apollo, this.route?.params?.id);
  },
};
</script>
