<template>
  <v-btn
    small
    depressed
    class="success"
    type="submit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot>Save</slot>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonSubmit",
};
</script>
