<template>
  <div v-bind="$attrs">
    <v-icon
      v-if="dispositionIcon"
      small
      :title="dispositionText"
      :color="dispositionColor"
      left
    >
      {{ dispositionIcon }}
    </v-icon>
    <v-icon v-if="showBattery" :title="batteryTitle" :color="batteryColor">
      {{ batteryIcon }}
    </v-icon>
  </div>
</template>

<script>
import { rmaRequestType } from "@tod-ui/helpers/devices";
import {
  batteryLevel,
  batteryLevelText,
  dispositionText,
} from "../helpers/devices";

export default {
  name: "DeviceGaugeCluster",
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dispositionText() {
      return dispositionText(this.device);
    },
    dispositionIcon() {
      switch (rmaRequestType(this.device?.rmaRequest)) {
        case "return":
          return "fas fa-undo";
        case "replace":
          return "fas fa-repeat";
      }
      switch (this.dispositionText) {
        case "Device is Shipping":
          return "fas fa-shipping-fast";
        case "Device is Missing":
          return "mdi-shield-search";
        case "Device is Under Maintenance":
          return "fas fa-wrench";
        case "Device is Retired":
          return "fas fa-times-octagon";
        case "Device is Waiting":
          return "fas fa-dot-circle";
        case "Unhealthy Device":
          return "fas fa-first-aid";
      }
      return "";
    },
    dispositionColor() {
      switch (this.dispositionText) {
        case "Device is Missing":
          return "#ffb74d";
        case "Device is Under Maintenance":
          return "#2196f3";
        case "Unhealthy Device":
          return "error";
        default:
          return "grey darken-1";
      }
    },
    showBattery() {
      return [
        "Device is Active",
        "Device is Waiting",
        "Device is Shipping",
      ].includes(this.dispositionText);
    },
    batteryLevel() {
      return batteryLevel(this.device);
    },
    batteryTitle() {
      const batteryLevel = batteryLevelText(this.device);
      return !this.dispositionIcon
        ? `${this.dispositionText}; ${batteryLevel}`
        : batteryLevel;
    },
    batteryIcon() {
      if (this.batteryLevel >= 80) return "fal fa-battery-full";
      if (this.batteryLevel >= 60) return "fal fa-battery-three-quarters";
      if (this.batteryLevel >= 20) return "fas fa-battery-half";
      if (this.batteryLevel > 0) return "fas fa-battery-quarter";
      return "fas fa-battery-empty";
    },
    batteryColor() {
      if (this.batteryLevel >= 80) return "success";
      if (this.batteryLevel >= 60) return "success";
      if (this.batteryLevel >= 20) return "warning";
      if (this.batteryLevel > 0) return "error";
      return "grey lighten-1";
    },
  },
};
</script>
