<template>
  <LayoutPage>
    <template #title>
      <v-toolbar-title class="headline">
        <span class="print-title-prefix">{{ printTitlePrefix }}</span>
        Inventory
      </v-toolbar-title>
    </template>
    <template #actions>
      <v-btn
        class="action-btn"
        :color="tab === 0 ? 'success' : 'grey lighten-1'"
        @click="tab = 0"
      >
        Available Inventory
      </v-btn>
      <v-btn
        class="action-btn"
        :color="tab === 1 ? 'success' : 'grey lighten-1'"
        @click="tab = 1"
      >
        Unavailable Inventory
      </v-btn>
      <v-spacer />
      <div class="mr-4" style="min-width: 240px">
        <SearchField v-model="search" />
      </div>
      <PossiblePMS v-slot="{ disabled }">
        <v-btn
          color="success action-btn"
          :to="{ name: 'AddSmartUnit' }"
          :disabled="disabled"
        >
          Create New Smart Unit
        </v-btn>
      </PossiblePMS>
    </template>

    <InventoryAssignableTable v-if="tab === 0" :search="search" />
    <InventoryUnavailableTable v-else :search="search" />
  </LayoutPage>
</template>

<script>
import SearchField from "@atoms/SearchField.vue";
import InventoryAssignableTable from "./inventory/InventoryAssignableTable.vue";
import InventoryUnavailableTable from "./inventory/InventoryUnavailableTable.vue";
import PossiblePMS from "@components/common/PossiblePMS.vue";
import LayoutPage from "@layout/LayoutPage.vue";

import useInventory from "./useInventory";
import useRouteQuery from "@components/common/useRouteQuery";
import { onUnmounted } from "vue";

export default {
  name: "PageInventory",
  components: {
    InventoryAssignableTable,
    InventoryUnavailableTable,
    SearchField,
    PossiblePMS,
    LayoutPage,
  },
  setup() {
    const { fetchInventory, cleanUpInventoryRefs } = useInventory();
    onUnmounted(cleanUpInventoryRefs);

    const { tab } = useRouteQuery();

    return { fetchInventory, tab };
  },
  data: () => ({
    search: "",
  }),
  computed: {
    printTitlePrefix() {
      return this.tab === 0 ? "Available" : "Unavailable";
    },
  },
  beforeMount() {
    this.fetchInventory(this.$apollo);
  },
};
</script>

<style scoped>
.print-title-prefix {
  display: none;
}
@media print {
  .print-title-prefix {
    display: inline;
  }
}
</style>
