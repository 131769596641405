<template>
  <v-sheet class="wrapper ma-4">
    <iframe :src="src" frameborder="0"></iframe>
  </v-sheet>
</template>

<script>
import { computed, unref } from "vue";
import useUser from "../authentication/useUser";
import { addUrlParams } from "../common/helpers/datastudio";

export default {
  name: "OrganizationFacilitySummaries",
  setup() {
    const { defaultOrganization } = useUser();
    const src = computed(() => {
      return addUrlParams(
        import.meta.env.VUE_APP_EMBEDDED_REGIONAL_REPORT_URL,
        {
          id: unref(defaultOrganization).id,
        }
      );
    });
    return { src };
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
  padding-top: 56.25%; /* 16:9 */
  height: 0;
  overflow-x: auto;
}
iframe {
  position: absolute;
  width: 100%;
  max-width: 1600px;
  min-width: 1200px;
  height: 100%;
  top: 0;
  left: 0;
  border: none;
}
</style>
