<template>
  <div>
    <div class="d-flex align-center justify-end">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        :nudge-bottom="6"
        transition="slide-y-transition"
      >
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" @click="menu = true">
            mdi-filter
          </v-icon>
        </template>
        <v-card>
          <CardComprehensiveFilterSettings
            v-model="menu"
            @close="menu = false"
            @save="handleSave"
          />
        </v-card>
      </v-menu>
    </div>
    <div class="mt-4">
      <DashboardTilesGroup :date-time-filter="dateTimeFilter" />
    </div>
  </div>
</template>

<script>
import CardComprehensiveFilterSettings from "./CardComprehensiveFilterSettings.vue";
import { ref } from "vue";
import DashboardTilesGroup from "./DashboardTilesGroup.vue";

export default {
  components: {
    CardComprehensiveFilterSettings,
    DashboardTilesGroup,
  },
  props: {
    climateType: {
      type: String,
      required: true,
    },
    dateTimeFilter: {
      type: Object,
      default: () => ({
        after: "",
        before: "",
      }),
    },
  },
  setup() {
    const menu = ref(false);

    const handleSave = () => {
      menu.value = false;
    };

    return {
      menu,
      handleSave,
    };
  },
};
</script>
