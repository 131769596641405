<template>
  <v-card>
    <v-card-text>
      <v-skeleton-loader v-if="loading" type="article, article" />
      <v-row v-else>
        <v-col md="6">
          <dl class="property-list">
            <dt>Facility Name</dt>
            <dd>{{ facility.name }}</dd>

            <template v-if="facility.location">
              <dt>Street Address</dt>
              <dd>
                <LocationAddress :location="facility.location" one-line />
              </dd>
            </template>
            <template v-if="facility.shippingAddress">
              <dt>Street Address</dt>
              <dd>
                <LocationAddress
                  :location="facility.shippingAddress"
                  one-line
                />
              </dd>
            </template>

            <dt>Time Zone</dt>
            <dd>{{ facility.timeZone }}</dd>

            <template v-if="facility.profile">
              <dt>Customer Call In Number</dt>
              <dd>
                {{ formatPhoneNumber(facility.profile.phoneNumber) }}
              </dd>

              <dt>Email Address</dt>
              <dd>{{ facility.profile.email }}</dd>
              <dt v-if="facility.profile.numberOfUnits">
                Total Number of Storage Units
              </dt>
              <dd v-if="facility.profile.numberOfUnits">
                {{ facility.profile.numberOfUnits }}
              </dd>
            </template>

            <dt v-if="unitDesign">Facility Design</dt>
            <dd v-if="unitDesign">
              {{ unitDesign }}
            </dd>
          </dl>
        </v-col>

        <v-col v-if="facility.monitoringSchedule" md="3">
          <h4>Access Schedule</h4>
          <div>
            &nbsp;<small v-if="customerMargin">
              ({{ customerMargin }} margin)
            </small>
          </div>
          <WeeklySchedule :schedule="facility.monitoringSchedule" />
        </v-col>
        <v-col
          v-if="
            facility.officeSchedule && facility.profile.isCustomOfficeSchedule
          "
          md="3"
        >
          <h4>Office Schedule</h4>
          <div>&nbsp;</div>
          <WeeklySchedule :schedule="facility.officeSchedule" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import LocationAddress from "@tod-ui/components/LocationAddress.vue";
import WeeklySchedule from "@tod-ui/components/WeeklySchedule.vue";
import { formatPhoneNumber } from "@tod-ui/helpers/phone";
import { pluralize, titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "FacilityProfile",
  components: { LocationAddress, WeeklySchedule },
  props: {
    facility: {
      validator: (val) => val === null || typeof val === "object",
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unitDesign() {
      return this.facility.organizationDetails?.unitDesign
        ?.map(titleCase)
        ?.join(", ");
    },
    customerMargin() {
      const margin = this.facility.monitoringSchedule?.lateCustomerMargin;
      return margin ? `${margin} ${pluralize("hour", margin)}` : null;
    },
  },
  methods: {
    formatPhoneNumber,
  },
};
</script>
