<template>
  <v-btn small :title="title" v-on="$listeners">
    <v-icon left small>mdi-email</v-icon>
    <slot>Send</slot>
  </v-btn>
</template>

<script>
export default {
  name: "ButtonSend",
  props: {
    title: {
      type: String,
      default: "Send",
    },
  },
};
</script>
