<template>
  <v-card
    class="mx-auto"
    max-width="400px"
    outlined
    style="border: 1px solid black"
  >
    <v-toolbar dense tile flat color="grey lighten-2">
      <v-icon left>mdi-login-variant</v-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-progress-linear
        :active="inProcess"
        absolute
        bottom
        height="4"
        indeterminate
      />
    </v-toolbar>
    <v-divider color="black" />

    <v-row justify="center">
      <v-col md="10">
        <v-img src="/assets/SDLOGO_STACKED.png" />
      </v-col>
    </v-row>

    <ValidationObserver ref="validator" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <v-card-text class="input-fields">
          <AlertsAnimated />
          <slot></slot>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            :disabled="inProcess"
            text
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn color="blue darken-1" :disabled="inProcess" text type="submit">
            {{ submitText }}
          </v-btn>
        </v-card-actions>
      </form>
    </ValidationObserver>
  </v-card>
</template>

<script>
import AlertsAnimated from "@tod-ui/components/AlertsAnimated.vue";
import { setInteractionMode, ValidationObserver } from "vee-validate";

setInteractionMode("lazy");

export default {
  name: "FormLayoutAuthentication",
  components: { ValidationObserver, AlertsAnimated },
  props: {
    title: {
      type: String,
      required: true,
    },
    submitText: {
      type: String,
      required: true,
    },
    inProcess: {
      type: Boolean,
      default: false,
    },
  },
  beforeUpdate() {
    this.$refs.validator.reset();
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped>
.input-fields {
  min-height: 14em;
}
</style>
