import gql from "graphql-tag";
import { deviceFields, incidentFields } from "../../graphql/fragments";

/**
 * include organization responders to filter only assigned units
 * see https://github.com/teamofdefenders/sd_frontend/pull/113
 */
export const DASHBOARD_COUNT_INCIDENTS = gql`
  query DashboardCountIncidents(
    $parentId: ID!
    $authorized: Boolean
    $after: DateTime
  ) {
    incidents(
      parentId: $parentId
      authorized: $authorized
      dateTimeFilter: { after: $after }
      assigned: true
      demonstration: false
    ) {
      id
      startedAt
    }
  }
`;

export const DASHBOARD_RECENT_INCIDENTS = gql`
  query DashboardRecentIncidents(
    $parentId: ID!
    $after: DateTime!
    $organizationSubtype: String
    $duringAccessHours: Boolean
  ) {
    incidents(
      parentId: $parentId
      dateTimeFilter: { after: $after }
      organizationSubtype: $organizationSubtype
      duringAccessHours: $duringAccessHours
      demonstration: false
      sort: "startedAt desc"
    ) {
      ...IncidentFields
      organization {
        id
        name
        type
      }
    }
  }
  ${incidentFields}
`;

export const DASHBOARD_INCIDENTS_DETAILED = gql`
  query DashboardIncidentsDetailed(
    $parentId: ID!
    $authorized: Boolean
    $after: DateTime
  ) {
    incidents(
      parentId: $parentId
      authorized: $authorized
      dateTimeFilter: { after: $after }
      assigned: true
      demonstration: false
      sort: "startedAt desc"
    ) {
      ...IncidentFields
      organization {
        id
        name
        type
        devices {
          ...DeviceFields
        }
        responders {
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
  ${incidentFields}
  ${deviceFields}
`;

export const DASHBOARD_COUNT_ZONE_INCIDENTS = gql`
  query DashboardCountZoneIncidents($parentId: ID!, $after: DateTime) {
    incidents(
      parentId: $parentId
      dateTimeFilter: { after: $after }
      organizationSubtype: "zone"
    ) {
      id
      type
      startedAt
    }
  }
`;

export const DASHBOARD_ZONE_INCIDENTS = gql`
  query DashboardZoneIncidents($parentId: ID!, $after: DateTime) {
    incidents(
      parentId: $parentId
      dateTimeFilter: { after: $after }
      sort: "startedAt desc"
      organizationSubtype: "zone"
    ) {
      ...IncidentFields
      organization {
        id
        name
        type
      }
    }
  }
  ${incidentFields}
`;
