<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>Extensive</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="mb-4">
            <div class="section-label mb-4">Temperature</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="extensiveTempFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="extensiveTempIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>

          <div class="mb-4">
            <div class="section-label mb-4">Humidity</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="extensiveHumFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="extensiveHumIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Frequent</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="mb-4">
            <div class="section-label mb-4">Temperature</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="frequentTempFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="frequentTempIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>

          <div class="mb-4">
            <div class="section-label mb-4">Humidity</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="frequentHumFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="frequentHumIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Initial</v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="mb-4">
            <div class="section-label mb-4">Temperature</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="initialTempFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="initialTempIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>

          <div class="mb-4">
            <div class="section-label mb-4">Humidity</div>
            <v-row>
              <v-col cols="6">
                <v-select
                  v-model="initialHumFreq"
                  :items="frequencyOptions"
                  label="Frequency"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="initialHumIncidents"
                  label="Number of Incidents"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="d-flex justify-end mt-4">
      <v-btn :loading="isSaving" color="primary" text @click="handleSave">
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "CardComprehensiveFilterSettings",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["input", "save"],
  setup(props, { emit }) {
    const isSaving = ref(false);

    const frequencyOptions = [
      { text: "Daily", value: "DAYS" },
      { text: "Weekly", value: "WEEKS" },
      { text: "Monthly", value: "MONTHS" },
    ];

    // Initialize all refs from localStorage with default values (converted to uppercase)
    const extensiveTempFreq = ref(
      (localStorage.getItem("extensiveTempFreq") || "DAYS").toUpperCase()
    );
    const extensiveHumFreq = ref(
      (localStorage.getItem("extensiveHumFreq") || "DAYS").toUpperCase()
    );
    const extensiveTempIncidents = ref(
      (localStorage.getItem("extensiveTempIncidents") || "0").toUpperCase()
    );
    const extensiveHumIncidents = ref(
      localStorage.getItem("extensiveHumIncidents") || "0"
    );

    const frequentTempFreq = ref(
      (localStorage.getItem("frequentTempFreq") || "DAYS").toUpperCase()
    );
    const frequentHumFreq = ref(
      (localStorage.getItem("frequentHumFreq") || "DAYS").toUpperCase()
    );
    const frequentTempIncidents = ref(
      localStorage.getItem("frequentTempIncidents") || "0"
    );
    const frequentHumIncidents = ref(
      localStorage.getItem("frequentHumIncidents") || "0"
    );

    const initialTempFreq = ref(
      (localStorage.getItem("initialTempFreq") || "DAYS").toUpperCase()
    );
    const initialHumFreq = ref(
      (localStorage.getItem("initialHumFreq") || "DAYS").toUpperCase()
    );
    const initialTempIncidents = ref(
      localStorage.getItem("initialTempIncidents") || "0"
    );
    const initialHumIncidents = ref(
      localStorage.getItem("initialHumIncidents") || "0"
    );

    const saveToLocalStorage = () => {
      // Extensive settings
      localStorage.setItem(
        "extensiveTempFreq",
        extensiveTempFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "extensiveHumFreq",
        extensiveHumFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "extensiveTempIncidents",
        extensiveTempIncidents.value.toUpperCase()
      );
      localStorage.setItem(
        "extensiveHumIncidents",
        extensiveHumIncidents.value.toUpperCase()
      );

      // Frequent settings
      localStorage.setItem(
        "frequentTempFreq",
        frequentTempFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "frequentHumFreq",
        frequentHumFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "frequentTempIncidents",
        frequentTempIncidents.value.toUpperCase()
      );
      localStorage.setItem(
        "frequentHumIncidents",
        frequentHumIncidents.value.toUpperCase()
      );

      // Initial settings
      localStorage.setItem(
        "initialTempFreq",
        initialTempFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "initialHumFreq",
        initialHumFreq.value.toUpperCase()
      );
      localStorage.setItem(
        "initialTempIncidents",
        initialTempIncidents.value.toUpperCase()
      );
      localStorage.setItem(
        "initialHumIncidents",
        initialHumIncidents.value.toUpperCase()
      );
    };

    const handleSave = async () => {
      try {
        isSaving.value = true;

        // Save directly to localStorage since we're not using DB
        saveToLocalStorage();

        emit("save");
        emit("input", false);
      } catch (error) {
        console.error("Error saving settings:", error);
      } finally {
        isSaving.value = false;
      }
    };

    return {
      extensiveTempFreq,
      extensiveHumFreq,
      extensiveTempIncidents,
      extensiveHumIncidents,
      frequentTempFreq,
      frequentHumFreq,
      frequentTempIncidents,
      frequentHumIncidents,
      initialTempFreq,
      initialHumFreq,
      initialTempIncidents,
      initialHumIncidents,
      frequencyOptions,
      isSaving,
      handleSave,
    };
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none !important;
}

.mb-4 {
  margin-bottom: 1rem;
}

/* Remove bottom margin from row to tighten up spacing */
.v-row {
  margin-bottom: 0;
}

/* Optional: Adjust spacing between columns */
.v-col {
  padding-top: 0;
}
</style>
