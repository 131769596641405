import { getObjectValueByPath } from "@tod-ui/helpers/utils";
import { nowTimestamp } from "@tod-ui/helpers/datetime";

export function constructCsvContent(data, headers = null) {
  if (!data.length) return;

  let tableHeader = "";
  if (headers) {
    headers = headers.filter(
      (header) => !["action-column", "non-export"].includes(header.cellClass)
    );
    tableHeader =
      headers.map((header) => `"${header.text || header.value}"`).join(",") +
      "\n";
  }
  return (
    tableHeader +
    data
      .map((row) => {
        const formatted = headers
          ? headers.map((header) =>
              prepareCell(
                row,
                header.value,
                header.hasOwnProperty("toString") && header.toString
              )
            )
          : Object.values(row);
        return formatted.join(",");
      })
      .join("\n")
  );
}

function prepareCell(rowData, key, toString = null) {
  const value = getObjectValueByPath(rowData, key);
  if (!value) return "";
  let str = Array.isArray(value)
    ? (typeof toString === "function" ? value.map(toString) : value).join(", ")
    : typeof toString === "function"
    ? toString(value)
    : value;

  if (!str) return "";
  return `"${typeof str === "string" ? str.replace('"', '\\"') : str}"`;
}

export function forceFileDownload(content, filename = "data") {
  const link = document.createElement("a");
  link.download = `${filename}_${nowTimestamp()}.csv`;
  // link.download = `${filename}.csv`;
  link.href = window.URL.createObjectURL(
    new Blob([content], { type: "text/csv;charset=utf-8" })
  );
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(link.href);
  document.body.removeChild(link);
}
