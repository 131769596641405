import { ref, computed } from "vue";
import {
  GET_SMART_UNIT,
  UPDATE_SMART_UNIT,
  UPDATE_UNIT_DETAILS,
} from "./graphql";
import { getResponderByLevel } from "../renters/helper";
import useSmartUnits from "./useSmartUnits";
import { apolloWatchQuery } from "../../apollo-client/helper";
import { stringSearch } from "@tod-ui/helpers/strings";

const unitDesigns = ["indoor", "outdoor", "climate_controlled", "drive_up"];
const unitAmenities = ["power"];

const smartUnit = ref({});
const smartUnitLoading = ref(false);

const smartUnitDevices = computed(() => {
  return smartUnit.value?.devices;
});
const smartUnitResponders = computed(() => {
  return smartUnit.value?.responders;
});

const primaryResponder = computed(() => {
  return getResponderByLevel(smartUnitResponders.value, 1, true);
});
const secondaryResponder = computed(() => {
  return getResponderByLevel(smartUnitResponders.value, 2, true);
});

function fetchSmartUnit(apolloClient, unitId) {
  smartUnitLoading.value = true;
  smartUnit.value = { id: unitId };

  return apolloWatchQuery(
    apolloClient,
    {
      query: GET_SMART_UNIT,
      variables: { id: unitId },
      fetchPolicy: "network-only",
    },
    (data, loading) => {
      smartUnit.value = withUnitDetails(
        data?.organization,
        data?.organization?.organizationDetails
      );
      smartUnitLoading.value = loading;
    }
  );
}

function useSmartUnit() {
  const { searchExistingUnits } = useSmartUnits();

  async function updateSmartUnit(
    apolloClient,
    { name, unitTypeId, description, amenities },
    checkUniqueName = false
  ) {
    const notUniqueName =
      checkUniqueName && !(await isNameUnique(this.$apollo, name));

    if (notUniqueName) {
      throw `Smart Unit # ${name} is already taken. Please choose a unique value.`;
    }

    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_SMART_UNIT,
        variables: {
          id: smartUnit.value.id,
          name,
          unitTypeId,
        },
      });
      const detailsResponse = await apolloClient.mutate({
        mutation: UPDATE_UNIT_DETAILS,
        variables: {
          organizationId: smartUnit.value.id,
          description,
          unitDesign: amenities.filter((val) => unitDesigns.includes(val)),
          unitAmenities: amenities.filter((val) => !unitDesigns.includes(val)),
        },
      });
      smartUnit.value = {
        ...withUnitDetails(
          response.data.updateSmartUnit,
          detailsResponse.data?.updateOrganizationDetails
        ),
        devices: smartUnitDevices.value,
        responders: smartUnitResponders.value,
      };
    } catch (error) {
      // throw error;
      throw "There was an error updating the Smart Unit";
    }

    return "The Smart Unit was updated successfully";
  }

  async function isNameUnique(apolloClient, name) {
    const existingUnits = await searchExistingUnits(apolloClient, name, {
      limit: undefined,
    });

    // Check for an exact match
    const matchName = stringSearch(name);
    return !existingUnits.find((unit) => matchName.exactMatch(unit.name));
  }

  return {
    smartUnit,
    smartUnitLoading,
    smartUnitDevices,
    smartUnitResponders,
    primaryResponder,
    secondaryResponder,
    unitAmenityOptions: [...unitDesigns, ...unitAmenities],
    fetchSmartUnit,
    updateSmartUnit,
  };
}

export default useSmartUnit;

function withUnitDetails(result, organizationDetails) {
  if (!result) {
    return result;
  }
  return {
    ...result,
    description: organizationDetails?.description,
    amenities: [
      organizationDetails?.entryLocation,
      organizationDetails?.doorType,
      ...(organizationDetails?.unitDesign || []),
      ...(organizationDetails?.unitAmenities || []),
    ].filter((a) => !!a),
  };
}
