<template>
  <LayoutPage>
    <template #title>
      <v-toolbar-title class="headline">
        {{ `${defaultOrganization.name} Management Dashboard` }}
      </v-toolbar-title>
    </template>
    <v-sheet v-if="src" class="embedded-wrapper">
      <iframe :src="src" frameborder="0" class="embedded-content"></iframe>
    </v-sheet>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import useUser from "./useUser";
import { addUrlParams } from "@components/common/helpers/datastudio";
import { computed, unref } from "vue";
import { timeZoneOffset } from "@tod-ui/helpers/datetime";

export default {
  name: "PageManagementDashboard",
  components: {
    LayoutPage,
  },
  setup() {
    const { defaultOrganization } = useUser();

    const src = computed(() => {
      const userOrganization = unref(defaultOrganization);
      return addUrlParams(
        import.meta.env.VUE_APP_EMBEDDED_MANAGEMENT_DASHBOARD_URL,
        {
          id: userOrganization.id,
          mdiff: timeZoneOffset(userOrganization.timeZone),
        }
      );
    });

    return { defaultOrganization, src };
  },
};
</script>
