<template>
  <LayoutPage :loading="smartUnitLoading">
    <template #title>
      <v-btn icon @click="$router.go(-1)">
        <v-icon color="primary">fas fa-chevron-circle-left</v-icon>
      </v-btn>
      <v-toolbar-title class="headline">
        <span class="grey--text text--darken-2">Smart Units</span>
        <v-icon small left right>fas fa-chevron-right</v-icon>
        <template v-if="!renterId">
          <span class="grey--text text--darken-2">Inventory</span>
          <v-icon small left right>fas fa-chevron-right</v-icon>
        </template>
        <PossiblePMS as-icon :sync-obj="smartUnit" content-class="mr-2">
          {{ smartUnit.name }}
        </PossiblePMS>
      </v-toolbar-title>
    </template>

    <v-row>
      <v-col lg="5">
        <CardSmartUnitInfo v-model="editingUnit" class="mb-4" />

        <CardAlertResponders
          v-if="renterId"
          v-model="editingResponders"
          :renter-id="renterId"
        />
      </v-col>

      <v-col lg="7">
        <CardAssignedDevices class="mb-4" />
        <CardIncidentHistory
          :unit-id="unitId"
          :renter-id="renterId"
          class="mb-4"
        />
        <CardClimateHistory
          :devices="smartUnit.devices"
          :loading="smartUnitLoading"
        />
      </v-col>
    </v-row>
  </LayoutPage>
</template>

<script>
import { ref } from "vue";
import LayoutPage from "@layout/LayoutPage.vue";
import PossiblePMS from "@components/common/PossiblePMS.vue";

import CardSmartUnitInfo from "./CardSmartUnitInfo.vue";
import CardAlertResponders from "./CardAlertResponders.vue";
import CardAssignedDevices from "./CardAssignedDevices.vue";
import CardIncidentHistory from "./CardIncidentHistory.vue";

import useSmartUnit from "./useSmartUnit";
import CardClimateHistory from "./CardClimateHistory.vue";

export default {
  name: "PageSmartUnitDrilldown",
  components: {
    LayoutPage,
    CardSmartUnitInfo,
    CardAlertResponders,
    CardAssignedDevices,
    CardIncidentHistory,
    PossiblePMS,
    CardClimateHistory,
  },
  setup() {
    const { smartUnit, smartUnitLoading, fetchSmartUnit } = useSmartUnit();

    const editingUnit = ref("");
    const editingResponders = ref("");

    return {
      smartUnit,
      smartUnitLoading,
      fetchSmartUnit,
      editingResponders,
      editingUnit,
    };
  },
  computed: {
    unitId() {
      return this.$route.params.zone_id;
    },
    renterId() {
      return this.$route.params.renter_id;
    },
  },
  watch: {
    editingResponders(editingResponders) {
      if (editingResponders) {
        this.editingUnit = "";
      }
    },
    editingUnit(editingUnit) {
      if (editingUnit) {
        this.editingResponders = "";
      }
    },
  },
  async beforeMount() {
    await this.fetchSmartUnit(this.$apollo, this.unitId);
  },
};
</script>
