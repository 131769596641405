<template>
  <div v-if="showNotifications" class="wrapper">
    <v-carousel
      v-model="activeSlide"
      hide-delimiters
      :show-arrows="false"
      height="auto"
    >
      <v-carousel-item v-for="alert in alerts" :key="alert.id">
        <v-alert
          :type="alert.type"
          :icon="alert.icon"
          border="left"
          text
          outlined
        >
          <div v-if="alert.title" class="text-h6 mb-4">{{ alert.title }}</div>
          <div>{{ alert.message }}</div>
          <div v-if="alert.actions" class="d-flex mt-4">
            <v-spacer />
            <v-btn
              v-for="(value, name) in alert.actions"
              :key="name"
              text
              :color="alert.type"
              @click="
                name === 'resolve'
                  ? gotoNotificationResolution(alert)
                  : dismissNotification(alert)
              "
            >
              {{ value }}
            </v-btn>
          </div>
        </v-alert>
      </v-carousel-item>
    </v-carousel>
    <div class="controls grey--text">
      {{ activeSlide + 1 }}/{{ totalSlides }}
      <v-btn
        icon
        small
        class="nav-button prev ml-2"
        color="grey"
        @click="decrementSlide"
      >
        <v-icon small>fas fa-chevron-left</v-icon>
      </v-btn>
      <v-btn
        icon
        small
        class="nav-button next"
        color="grey"
        @click="incrementSlide"
      >
        <v-icon small>fas fa-chevron-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useRoute } from "vue-router/composables";
import useNavigation from "@layout/useNavigation";
import useNotifications from "./useNotifications";
import {
  restoreDocumentTitle,
  flashDocumentTitle,
  severityColor,
} from "./helper";

export default {
  name: "GlobalNotifications",
  setup() {
    const $route = useRoute();
    const { isCurrentRoute } = useNavigation();
    const {
      notifications,
      unreadErrorNotifications,
      dismissNotification,
      markNotificationRead,
    } = useNotifications();
    const alerts = ref([]);
    const activeSlide = ref(0);
    const totalSlides = computed(() => alerts.value.length);
    function incrementSlide() {
      return (activeSlide.value = (activeSlide.value + 1) % totalSlides.value);
    }
    function decrementSlide() {
      return (activeSlide.value =
        (totalSlides.value - 1 + activeSlide.value) % totalSlides.value);
    }

    const isNotificationPage = computed(() => $route?.name === "Notifications");
    const showNotifications = computed(
      () =>
        unreadErrorNotifications.value?.length > 0 && !isNotificationPage.value
    );

    watch(
      unreadErrorNotifications,
      (notifications) => {
        if (notifications.length === 0) {
          restoreDocumentTitle();
        } else {
          flashDocumentTitle();
        }

        //todo include current read notification
        const updatedAlerts = [];
        for (const notification of notifications) {
          const {
            title,
            body: message,
            id,
            hasResolution,
            resolutionRoute,
            isDismissible,
          } = notification;

          const actions = {};
          if (hasResolution) {
            actions.resolve = "Resolve";
          }
          if (isDismissible) {
            actions.dismiss = "Dismiss";
          }

          updatedAlerts.push({
            type: "error",
            title,
            message,
            item: { id, resolutionRoute },
            actions: Object.keys(actions).length > 0 ? actions : undefined,
          });
        }
        alerts.value = updatedAlerts;
        maybeAddCurrentNotification();
      },
      { immediate: true }
    );

    function maybeAddCurrentNotification() {
      const currentIndex = alerts.value.findIndex(
        (alert) =>
          alert?.item?.resolutionRoute &&
          isCurrentRoute(alert.item.resolutionRoute)
      );
      if (currentIndex !== -1) {
        activeSlide.value = currentIndex;
      } else {
        const currentNotification = notifications.value.find(
          (notification) =>
            notification.hasResolution &&
            isCurrentRoute(notification.resolutionRoute)
        );
        if (currentNotification) {
          alerts.value.push({
            type: severityColor(currentNotification.severity),
            title: currentNotification.title,
            message: currentNotification.body,
            item: {
              id: currentNotification.id,
              resolutionRoute: currentNotification.resolutionRoute,
            },
            actions: currentNotification.isDismissible
              ? { dismiss: "Dismiss" }
              : undefined,
          });
          activeSlide.value = alerts.value.length - 1;
        }
      }
    }

    onMounted(() => {
      if (unreadErrorNotifications.value.length > 0) flashDocumentTitle();
    });
    onUnmounted(restoreDocumentTitle);

    return {
      activeSlide,
      totalSlides,
      incrementSlide,
      decrementSlide,
      alerts,
      showNotifications,
      isCurrentRoute,
      dismissNotification,
      markNotificationRead,
    };
  },
  methods: {
    async dismiss(alert) {
      dismissNotification(this.$apollo, alert.item);
    },
    gotoNotificationResolution(alert) {
      const redirect = alert.item?.resolutionRoute;
      if (!redirect || !alert.item?.id || this.isCurrentRoute(redirect)) return;
      if (redirect.name === "Notifications") {
        this.$router.push(redirect);
      } else {
        this.$router.push(redirect, () =>
          this.markNotificationRead(this.$apollo, alert.item)
        );
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: relative;
}
.controls {
  position: absolute;
  top: 0.5em;
  right: 1em;
  display: flex;
  align-items: center;
}
</style>
