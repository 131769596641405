import Vue from "vue";
import * as Sentry from "@sentry/vue";

export default function sentryInit(router) {
  if (import.meta.env.VUE_APP_SENTRY_URL) {
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    const tracesSampleRate =
      import.meta.env.VUE_APP_SENTRY_TRACE_SAMPLE_RATE || "1.0";
    const replaysSessionSampleRate =
      import.meta.env.VUE_APP_SENTRY_REPLAY_SAMPLE_RATE || "0";
    const isDevelopment =
      import.meta.env.MODE === "development" ||
      import.meta.env.VUE_APP_BUILD_ENV === "development";
    Sentry.init({
      Vue,
      dsn: import.meta.env.VUE_APP_SENTRY_URL,
      release: import.meta.env.VUE_APP_SENTRY_RELEASE || "sd_frontend@dev",
      environment: import.meta.env.VUE_APP_BUILD_ENV || import.meta.env.MODE,
      // do not trace in development
      integrations: isDevelopment
        ? []
        : [
            new Sentry.BrowserTracing({
              routingInstrumentation: Sentry.vueRouterInstrumentation(router, {
                routeLabel: "name",
              }),
              tracePropagationTargets: [],
            }),
            new Sentry.Replay(),
          ],
      tracesSampleRate: parseFloat(tracesSampleRate),
      trackComponents: [
        "App",
        "TheHeader",
        "TheFooter",
        "TheSideMenu",
        "PageLogin",
      ],
      replaysSessionSampleRate: parseFloat(replaysSessionSampleRate),
      replaysOnErrorSampleRate: 1.0,
      beforeSend(event) {
        if (
          event?.level === "error" &&
          event?.request?.url?.slice(-10) === "/dashboard" &&
          (event?.transaction === "GraphQL: organizationDevices" ||
            event?.transaction === "GraphQL: incidents")
        )
          return null;
        return event;
      },
    });
  }
}
