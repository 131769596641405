import { ApolloLink, Observable } from "apollo-link";
import { getToken, setToken } from "./apollo-token";
import { redirectToLogin } from "../router/auth-hooks";

export default function createAuthLink() {
  return new ApolloLink((operation, forward) => {
    const token = getToken();
    if (token) {
      operation.setContext({
        headers: {
          authorization: token ? `Bearer ${token}` : "",
        },
      });
    } else if (
      !["AuthenticateUser", "ChangePassword", "UpdatePassword"].includes(
        operation.operationName
      )
    ) {
      redirectToLogin();
      const data = {};
      for (const field of extractFieldNames(operation)) {
        data[field] = null;
      }
      return Observable.of({ data });
    }
    const observable = forward(operation);
    const redirectObservable = observable.map((result) => {
      if (
        (operation.operationName =
          "AuthenticateUser" && result.data?.login?.token)
      ) {
        setToken(result.data.login.token);
      } else if (
        result?.errors?.some(
          (graphQLError) => graphQLError.message == "not_authenticated"
        )
      ) {
        redirectToLogin();
      }
      return result;
    });
    return redirectObservable;
  });
}

function extractDefinition(operation) {
  return operation.query.definitions.find(
    (q) => q.kind === "OperationDefinition"
  );
}

function extractFieldNames(operation) {
  const definition = extractDefinition(operation);
  return definition?.selectionSet?.selections?.reduce(
    (acc, fieldDefinition) => {
      if (fieldDefinition?.kind === "Field" && fieldDefinition?.name?.value) {
        acc.push(fieldDefinition.name.value);
      }
      return acc;
    },
    []
  );
}
