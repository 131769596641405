import { convertCelsiusToFahrenheit } from "@tod-ui/helpers/utils";

export function smartUnitTemperatureColor(temperature) {
  const temperatureInFahrenheit = convertCelsiusToFahrenheit(temperature);
  if (temperatureInFahrenheit < 55) {
    return "blue";
  } else if (temperatureInFahrenheit <= 80) {
    return "success";
  } else {
    return "error";
  }
}

export function smartUnitHumidityColor(humidity) {
  return humidity <= 69 ? "success" : "error";
}
