import PageRenters from "./PageRenters.vue";
import PageRenterAdd from "./PageRenterAdd.vue";
import PageRenter from "./PageRenter.vue";
import PageRenterRemove from "./PageRenterRemove.vue";
import PageDemoSmartUnit from "./PageDemoSmartUnit.vue";

const routes = {
  organization: [
    {
      path: "renters",
      name: "Renters",
      component: PageRenters,
    },
    {
      path: "renters/new",
      name: "AddRenter",
      component: PageRenterAdd,
    },
    {
      path: "renters/demo",
      name: "DemoSmartUnit",
      component: PageDemoSmartUnit,
    },
    {
      path: "renters/:renter_id",
      name: "ViewRenter",
      component: PageRenter,
    },
    {
      path: "renters/:renter_id/remove_renter",
      name: "RemoveRenter",
      component: PageRenterRemove,
    },
  ],
};

export default routes;
