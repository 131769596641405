import * as Sentry from "@sentry/vue";
import { ref, unref, computed, readonly, watch } from "vue";
import { apolloWatchQuery } from "../../apollo-client/helper";
import {
  formatDateTime,
  formatDateTime4Humans,
  lastDayOfMonth,
  toISOLocal,
  yesterdayDOW,
} from "@tod-ui/helpers/datetime";
import { FACILITY_USERS, GET_FACILITY, UPDATE_FACILITY } from "./graphql";

const currentFacility = ref(null);
const currentFacilityId = computed(() => currentFacility.value?.id);
const facilityTimeZone = computed(() => currentFacility.value?.timeZone);
const facilityFeatures = computed(() =>
  currentFacility.value?.features
    ? currentFacility.value.features.reduce((acc, feat) => {
        acc[feat.feature.name] = !feat.disabled;
        return acc;
      }, {})
    : null
);

const facilityObservableQuery = ref(null);
const redirectToDashboard = ref(false);

function useFacility() {
  const facilityUsers = ref([]);

  async function fetchFacilityUsers(dollarApollo, roles) {
    await untilFacility();
    if (!currentFacilityId.value) return;

    if (!roles) roles = ["manager"];

    apolloWatchQuery(
      dollarApollo,
      {
        query: FACILITY_USERS,
        variables: {
          id: currentFacilityId.value,
          roles: roles,
        },
      },
      (data) => {
        if (data?.organizationUsers) {
          facilityUsers.value = data.organizationUsers;
        }
      }
    );
  }

  return {
    currentFacility: readonly(currentFacility),
    currentFacilityId,
    facilityTimeZone,
    facilityFeatures,
    redirectToDashboard,
    facilityUsers,
    selectFacility,
    fetchFacilityUsers,
    updateCurrentFacility,
    restoreCurrentFacilityId,
    clearCurrentFacility,
    facilityFormatDateTime,
    facilityFormatDateTime4Humans,
    facilityYesterdayDOW,
    facilityLastDayOfMonth,
    facilityISOLocal,
    facilityFeatureEnabled,
  };
}

export default useFacility;

function facilityFormatDateTime(dateTime) {
  return formatDateTime(dateTime, facilityTimeZone.value);
}

function facilityFormatDateTime4Humans(
  dateTime,
  shortFor1Week = false,
  includeWeekDay = true,
  includeSeconds = true
) {
  return formatDateTime4Humans(
    dateTime,
    facilityTimeZone.value,
    shortFor1Week,
    includeWeekDay,
    includeSeconds
  );
}

function facilityYesterdayDOW() {
  return yesterdayDOW(facilityTimeZone.value);
}

function facilityLastDayOfMonth(monthOrMonthsBefore = null) {
  return lastDayOfMonth(monthOrMonthsBefore, facilityTimeZone.value).toISO();
}

function facilityISOLocal(isoTimestamp) {
  return toISOLocal(isoTimestamp, facilityTimeZone.value);
}

function facilityFeatureEnabled(name) {
  const features = facilityFeatures.value;
  if (!features) return false;

  // Output a console warning if requested feature is undefined
  if (!features.hasOwnProperty(name)) {
    console.warn("Feature %s is undefined", name);
    return false;
  }

  return features[name];
}

// idea from https://github.com/vueuse/vueuse/blob/main/packages/shared/until/index.ts#L75
async function untilFacility(timeout = 3000) {
  let stop = null;
  const watcher = new Promise((resolve) => {
    stop = watch(
      currentFacility,
      (org) => {
        if (org?.type === "facility") {
          stop?.();
          resolve(org);
        }
      },
      { immediate: true }
    );
  });

  const timeoutPromise = new Promise((resolve) =>
    setTimeout(() => {
      resolve(null);
    }, timeout)
  );
  return Promise.race([watcher, timeoutPromise]);
}

async function selectFacility(
  dollarApollo,
  organization,
  redirectToFacilityDashboard = false
) {
  const facility = unref(organization);
  if (!facility?.id) return null;
  if (facility?.type) {
    if (facility.type === "facility") {
      currentFacility.value = { ...facility };
    } else {
      return null;
    }
  }

  facilityObservableQuery.value = apolloWatchQuery(
    dollarApollo.provider.defaultClient,
    {
      query: GET_FACILITY,
      variables: { id: facility.id },
      fetchPolicy: "network-first",
      pollInterval: 60 * 60 * 1000, // 1 hour
    },
    (data) => {
      if (data?.organization && !!setCurrentFacility(data.organization)) {
        redirectToDashboard.value = redirectToFacilityDashboard;
      }
    }
  );

  return facilityObservableQuery.value.result();
}

async function updateCurrentFacility(apolloClient, updatedFacilityProps) {
  if (!currentFacilityId.value) return null;

  try {
    const response = await this.$apollo.mutate({
      mutation: UPDATE_FACILITY,
      variables: {
        id: currentFacilityId.value,
        name: currentFacility.value.name,
        type: currentFacility.value.type,
        timeZone: currentFacility.value.timeZone,
        ...updatedFacilityProps,
      },
    });

    // Update currentFacility in the store
    setCurrentFacility(response.data.updateFacility);
    return true;
  } catch (error) {
    throw "There was an error updating facility";
  }
}

const STORE_FACILITY = "currentFacility";
function restoreCurrentFacilityId(facilityId) {
  let restoredFacilityId = null;

  if (currentFacilityId.value) {
    restoredFacilityId = currentFacilityId.value;
  } else {
    restoredFacilityId = JSON.parse(
      window.localStorage.getItem(STORE_FACILITY)
    );
    if (restoredFacilityId?.id) {
      restoredFacilityId = restoredFacilityId.id;
    }
  }

  if (facilityId && facilityId !== restoredFacilityId) {
    clearCurrentFacility();
    return facilityId;
  }

  return restoredFacilityId;
}

function clearCurrentFacility() {
  if (facilityObservableQuery.value) {
    facilityObservableQuery.value.stopPolling();
  }
  window.localStorage.removeItem(STORE_FACILITY);
  currentFacility.value = null;
  Sentry.setTag("facility", null);
}

function setCurrentFacility(organizationRef) {
  const organization = unref(organizationRef);
  if (organization?.type !== "facility") return null;

  window.localStorage.setItem(STORE_FACILITY, JSON.stringify(organization.id));
  currentFacility.value = { ...organization };
  Sentry.setTag("facility", organization.name);
  return currentFacility.value;
}
