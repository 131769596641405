<template>
  <aside>
    <v-navigation-drawer
      app
      clipped
      :temporary="$vuetify.breakpoint.mobile"
      :value="isDrawerShown"
      :class="{ 'drawer-hidden': !isDrawerShown }"
      @input="toggleDrawer"
    >
      <OrganizationTree
        v-if="!isFacilityManager"
        v-model="orgTreeExpanded"
        :root-org="defaultOrganization"
        :root-action="gotoDefaultPage"
        leaf-type="facility"
        :current-org-id="currentFacilityId"
        :subheader="currentFacility ? currentFacility.name : ''"
        @action="select"
      />

      <SideMenuList />

      <template #append>
        <v-btn
          icon
          fab
          small
          absolute
          class="drawer-btn"
          :class="{ 'drawer-hidden': !isDrawerShown }"
          :title="drawerBtnTitle"
          @click="toggleDrawer"
        >
          <v-icon color="grey" x-small>{{ drawerBtnIcon }}</v-icon>
        </v-btn>
      </template>
    </v-navigation-drawer>
  </aside>
</template>

<script>
import SideMenuList from "./SideMenuList.vue";
import OrganizationTree from "../utils/OrganizationTree.vue";

import useNavigation from "./useNavigation";
import useFacility from "../authentication/useFacility";
import useUser from "../authentication/useUser";
import useAlerts from "@tod-ui/composables/useAlerts";
import { computed, ref } from "vue";

export default {
  name: "TheSideMenu",
  components: {
    SideMenuList,
    OrganizationTree,
  },
  setup() {
    const { defaultOrganization, isFacilityManager, defaultPage } = useUser();
    const { currentFacility, currentFacilityId, selectFacility } =
      useFacility();
    const { toggleDrawer, isDrawerShown } = useNavigation(true);

    const drawerBtnTitle = computed(
      () => `${isDrawerShown.value ? "Hide" : "Show"} Menu`
    );
    const drawerBtnIcon = computed(() =>
      isDrawerShown.value
        ? "fas fa-chevron-double-left"
        : "fas fa-chevron-double-right"
    );

    const orgTreeExpanded = ref(false);

    const { clearAllAlerts } = useAlerts();
    return {
      currentFacility,
      currentFacilityId,
      selectFacility,
      defaultOrganization,
      isFacilityManager,
      defaultPage,
      toggleDrawer,
      isDrawerShown,
      drawerBtnTitle,
      drawerBtnIcon,
      orgTreeExpanded,
      clearAllAlerts,
    };
  },
  watch: {
    "$vuetify.breakpoint.mobile": function (val) {
      this.toggleDrawer(!val);
    },
  },
  methods: {
    select(facility) {
      this.clearAllAlerts();
      this.selectFacility(this.$apollo, facility, true);
      this.orgTreeExpanded = false;
    },
    gotoDefaultPage(org) {
      if (this.$route.name !== this.defaultPage.name) {
        this.$router.push(this.defaultPage);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
aside {
  border-right: 1px solid #b5b5b5;
}
.v-navigation-drawer.drawer-hidden {
  overflow: visible;
}
.drawer-btn {
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
  &.drawer-hidden {
    right: -32px;
  }
  &.v-btn:focus::before {
    opacity: 0;
  }
  &.v-btn:hover::before {
    opacity: 0.08;
  }
}

@media print {
  aside {
    display: none;
  }
}
</style>
