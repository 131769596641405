<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const self = this;
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      title: {
        display: this.title ? true : false,
        text: this.title,
      },

      legend: {
        position: "top",
      },
      onClick: this.handleClick,
      scales: {
        yAxes: [
          {
            ticks: {
              callback: function (value, index, values) {
                return self.unit === "$"
                  ? self.unit + value
                  : value + self.unit;
              },
            },
          },
        ],
      },
    };
    this.renderChart(this.chartData, options);
  },
  methods: {
    handleClick(event, items) {
      if (items.length > 0) {
        // Extract the data that was clicked
        const clickedData = this.chartData.labels[items[0]._index];
        console.log(clickedData);
        // Navigate to another page with the clicked data
        // this.$router.push({ name: 'details', params: { data: clickedData }});
      }
    },
  },
};
</script>
