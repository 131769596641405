<template>
  <v-dialog v-model="show" max-width="600px">
    <template #activator="{ on, attrs }">
      <v-btn
        icon
        small
        title="View Notification Status History"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon> mdi-information </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">
        Notification Status History
        <v-spacer />
        <v-btn icon small @click="show = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-4">
        <v-list dense>
          <v-skeleton-loader v-if="loading" type="list-item-avatar" />
          <template
            v-else-if="notificationEvents && notificationEvents.length > 0"
          >
            <v-list-item v-for="event in notificationEvents" :key="event.id">
              <v-list-item-icon>
                <v-icon small>far fa-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="one-row">
                <span>{{
                  facilityFormatDateTime4Humans(event.timestamp, false, false)
                }}</span>
                <strong class="ml-auto">{{ titleCase(event.name) }}</strong>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-else>
            <v-list-item-content>
              <v-list-item-title>
                <em>No any notification events found</em>
              </v-list-item-title>
              <v-list-item-subtitle> &nbsp; </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { computed, ref } from "vue";
import useFacility from "@components/authentication/useFacility";
import useNotifications from "./useNotifications";
import { titleCase } from "@tod-ui/helpers/strings";

export default {
  name: "DialogNotificationHistory",
  props: {
    notificationId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { facilityFormatDateTime4Humans } = useFacility();
    const {
      fetchNotificationDetails,
      notificationDetails,
      loadingNotificationDetails,
    } = useNotifications();
    const show = ref(false);

    return {
      facilityFormatDateTime4Humans,
      fetchNotificationDetails,
      notificationEvents: computed(() => notificationDetails.value?.events),
      loading: loadingNotificationDetails,
      show,
    };
  },
  watch: {
    show(val) {
      if (val) {
        this.fetchNotificationDetails(this.$apollo, this.notificationId);
      }
    },
  },
  methods: {
    titleCase,
  },
};
</script>
