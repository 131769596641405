// Composes complex smart unit workflows & interactions
import { addOrganizationDevices, createZone } from "../graphql/organizations";

import { buildZoneProfile } from "../components/utils/organizations";
import { updateDeviceLocation } from "@components/smart_units/useDevice";

export async function addSmartUnit(
  apolloClient,
  smartUnit,
  deviceIds,
  subtype = "unit",
  devices
) {
  // Check if the device is capable of climate sensing
  const deviceTypes = devices.map((device) => {
    if (
      device.type === "xp" &&
      device.temperature !== null &&
      device.humidity !== null
    ) {
      return "xpClimate";
    }
    return device.type;
  });
  // Create the new zone/unit
  smartUnit.profile = buildZoneProfile({
    type: smartUnit.type,
    subtype,
    deviceTypes,
  });
  const {
    data: { createZone: zone },
  } = await apolloClient.mutate({
    mutation: createZone,
    variables: { ...smartUnit, subtype },
  });

  // Assign the selected devices to the zone/unit
  const {
    data: { addOrganizationDevices: added },
  } = await apolloClient.mutate({
    mutation: addOrganizationDevices,
    variables: { id: zone.id, devices: deviceIds },
  });

  if (subtype === "zone") {
    // Update the location for each added device
    await Promise.all(
      devices.map(async (device) => {
        await updateDeviceLocation({
          apolloClient,
          externalId: device.externalId,
          id: device.id,
          organizationId: zone.id,
          type: device.type,
          specs: { location: device.specs?.location },
        });
      })
    );
  }

  return {
    ...zone,
    devices: added,
  };
}
