import OpenIncidence from "./call-center-dashboard/OpenIncidence.vue";
import PageCallCenterDashboard from "./call-center-dashboard/PageCallCenterDashboard.vue";
import ViewTask from "./call-center-dashboard/ViewTask.vue";
import AssignToManager from "./call-center-dashboard/AssignToManager.vue";
import PageOperationAndSecurityDashboard from "./operation-and-security-dashboard/PageOperationAndSecurityDashboard.vue";
import VacantUnitUncleanScoreTable from "./operation-and-security-dashboard/VacantUnitUncleanScoreTable.vue";
import PageManagementDashboard from "./management_dashboard/PageManagementDashboard.vue";
import ConversionTable from "./management_dashboard/ConversionTable.vue";
import MTDTrendsTableRevenue from "./management_dashboard/MTDTrendsTableRevenue.vue";
import LifetimeRevenueTable from "./management_dashboard/LifetimeRevenueTable.vue";
import PenetrationTable from "./management_dashboard/PenetrationTable.vue";
import AverageVisitTable from "./management_dashboard/AverageVisitTable.vue";
import DemoInspectionReport from "./DemoInspectionReport.vue";
import DemoCardIncidentInfo from "./DemoCardIncidentInfo.vue";

const routes = {
  navigation: [
    {
      path: "/demo/call-center",
      name: "CallCenter",
      component: PageCallCenterDashboard,
    },
    {
      path: "/demo/call-center/open-incidence/:status",
      name: "OpenIncidence",
      component: OpenIncidence,
    },
    {
      path: "/demo/call-center/inspection-report",
      name: "InspectionReport",
      component: DemoInspectionReport,
    },
    {
      path: "/demo/call-center/view-task/:status",
      name: "ViewTask",
      component: ViewTask,
    },
    {
      path: "/demo/call-center/incident-info/:status",
      name: "IncidentInfo",
      component: DemoCardIncidentInfo,
    },
    {
      path: "/demo/call-center/assign-to-manager",
      name: "AssignToManager",
      component: AssignToManager,
    },
    {
      path: "/demo/operation-and-security",
      name: "OperationAndSecurity",
      component: PageOperationAndSecurityDashboard,
    },
    {
      path: "/demo/operation-and-security/vacant-unit-unclean-score/:status",
      name: "VacantUnitUncleanScoreTable",
      component: VacantUnitUncleanScoreTable,
    },
    {
      path: "/demo/management",
      name: "PageManagementDashboard",
      component: PageManagementDashboard,
    },
    {
      path: "/demo/management/conversion/:highlightColumn",
      name: "ConversionTable",
      component: ConversionTable,
    },
    {
      path: "/demo/management/mtd-revenue/:highlightColumn",
      name: "MTDTrendsTable",
      component: MTDTrendsTableRevenue,
    },
    {
      path: "/demo/management/revenue/:highlightColumn",
      name: "RevenueTable",
      component: LifetimeRevenueTable,
    },
    {
      path: "/demo/management/penetration/:highlightColumn",
      name: "Penetrations",
      component: PenetrationTable,
    },
    {
      path: "/demo/management/average-visit/:highlightColumn",
      name: "AverageVisit",
      component: AverageVisitTable,
    },
  ],
};
export default routes;
