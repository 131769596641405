import { ref } from "vue";

import { GET_USER_CONTACT_METHODS } from "../renters/graphql";

import useSmartUnit from "./useSmartUnit";
import { addUpdateRemoveSecondaryResponder } from "../renters/renter";

function useResponder(renterId) {
  const renterContactMethods = ref([]);
  const { secondaryResponder } = useSmartUnit();

  async function fetchContactMethods(apolloClient) {
    const observableQuery = apolloClient.watchQuery({
      query: GET_USER_CONTACT_METHODS,
      variables: { id: renterId },
    });
    return observableQuery.subscribe(({ data }) => {
      renterContactMethods.value = data.user?.contactMethods || [];
    });
  }

  async function saveSecondaryResponder(
    apolloClient,
    unitId,
    secondaryContactMethodId,
    newSecondary
  ) {
    return addUpdateRemoveSecondaryResponder(
      apolloClient,
      unitId,
      renterId,
      secondaryContactMethodId,
      secondaryResponder.value,
      newSecondary,
      renterContactMethods.value
    );
  }

  return {
    renterContactMethods,
    fetchContactMethods,
    saveSecondaryResponder,
  };
}

export default useResponder;
