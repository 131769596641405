<template>
  <CardLayout title="Tenant Ledger">
    <template #header-actions>
      <DateTimeFilter
        v-model="dateRange"
        :time-zone="facilityTimeZone"
        :ranges="['Today', '2d', '1w', '1m', '2m', '3m']"
        default="1m"
        max-range="3m"
        custom
        inverted
      />
    </template>

    <v-data-table
      :headers="headers"
      :items="tenantLedgerReport"
      :loading="loading"
    >
      <template #item.action="{ item }">
        <template v-if="item.statusOfReport === 'Created'">
          <div class="btn-container">
            <ButtonDownload
              small
              @click="downloadCSV(item.dateOfReport, item.organizationId)"
            >
              CSV
            </ButtonDownload>
            <ButtonSend
              small
              @click="openPopup(item.dateOfReport, item.organizationId)"
            >
              Send Email
            </ButtonSend>
            <DialogLayout
              v-if="isPopupVisible"
              v-model="isPopupVisible"
              title="Send Email"
            >
              <div>
                <p v-if="formattedReportDate">
                  Report Date:
                  <strong>{{ formattedReportDate }}</strong>
                </p>
                <ValidatedTextField
                  v-model="emailId"
                  type="email"
                  name="Enter Your Email Address"
                  rules="required|min:10|max:250"
                />
              </div>
              <template #actions>
                <v-btn color="primary" text @click="sendEmail">Send</v-btn>
                <v-btn color="primary" text @click="cancel">Cancel</v-btn>
              </template>
            </DialogLayout>
          </div>
        </template>
      </template>
      <template #item.initialEmailSentStatus="{ item }">
        {{ item.initialEmailSentStatus ? "Yes" : "No" }}
      </template>
      <template #item.dateOfReport="{ item }">
        {{ formatDate(item.dateOfReport) }}
      </template>
    </v-data-table>
  </CardLayout>
</template>

<script>
import {
  SEND_TENANT_LEDGER_EMAIL,
  TENANT_LEDGER_REPORT_STATUS,
} from "./graphql";
import { TENANT_LEDGER_PMS_REPORT } from "./graphql";
import { ref } from "vue";
import CardLayout from "@layout/CardLayout.vue";
import useFacility from "../authentication/useFacility";
import useDataTable from "@components/common/useDataTable";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import ButtonDownload from "@atoms/ButtonDownload.vue";
import ButtonSend from "@atoms/ButtonSend.vue";
import { formatDate, getCustomDate } from "@tod-ui/helpers/datetime";
import useUser from "../authentication/useUser";
import DialogLayout from "@layout/DialogLayout.vue";
import ValidatedTextField from "@components/utils/ValidatedTextField.vue";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "TenantLedgerReport",
  components: {
    DateTimeFilter,
    CardLayout,
    ButtonDownload,
    ButtonSend,
    DialogLayout,
    ValidatedTextField,
  },
  setup() {
    const { addAlert, clearAllAlerts } = useAlerts();
    const { facilityTimeZone, facilityFormatDateTime4Humans } = useFacility();
    const { tableState, showAllItems, restoreItemsPerPage } = useDataTable({
      itemsPerPage: 10,
      sortBy: ["id desc"],
    });
    const { defaultOrganization } = useUser();
    const currentOrganizationId = ref(defaultOrganization?.value?.id);

    return {
      tableState,
      showAllItems,
      restoreItemsPerPage,
      facilityTimeZone,
      reportDateTime: facilityFormatDateTime4Humans,
      formatDate,
      currentOrganizationId,
      addAlert,
    };
  },
  data() {
    return {
      emailId: "",
      dateRange: {
        after: undefined,
        before: undefined,
      },
      tenantLedgerReport: [],
      filter: {},
      isDownloading: false,
      isPopupVisible: false,
      currentReportDate: "",
      selectedOrganizationId: null,
    };
  },
  computed: {
    loading() {
      return this.$apollo.queries.tenantLedgerReport.loading;
    },
    shouldSkip() {
      return !this.dateRange?.after || !this.currentOrganizationId;
    },
    headers() {
      return [
        { text: "Name of Report", value: "nameOfReport", width: 200 },
        { text: "Date", value: "dateOfReport", width: 200 },
        { text: "Status", value: "statusOfReport", width: 200 },
        { text: "Action", value: "action", width: 200 },
      ];
    },
    formattedReportDate() {
      return this.formatDate(this.currentReportDate);
    },
  },
  apollo: {
    tenantLedgerReport: {
      query: TENANT_LEDGER_REPORT_STATUS,
      variables() {
        const variables = {
          organizationId: this.currentOrganizationId,
          dateTimeFilter: {
            after: this.dateRange.after,
            before: this.dateRange.before || getCustomDate({ days: 1 }),
          },
          ...this.filter,
        };
        return variables;
      },
      skip() {
        return this.shouldSkip;
      },
      update(data) {
        const tenantLedgerLogs = data?.tenantLedgerLogs;
        if (!tenantLedgerLogs) {
          return [];
        }
        return tenantLedgerLogs.map((report) => {
          return {
            ...report,
            dateOfReport: report.dateOfReport,
          };
        });
      },
      error(error) {},
    },
  },
  methods: {
    setDateRange(after, before) {
      this.dateRange = { after, before };
    },
    async downloadCSV(dateOfReport, organizationId) {
      const dateObject = new Date(dateOfReport);
      const year = dateObject.getFullYear();
      const month = String(dateObject.getMonth() + 1).padStart(2, "0");
      const day = String(dateObject.getDate()).padStart(2, "0");
      const startOfTheDay = new Date(`${year}-${month}-${day}`).toISOString();

      this.isDownloading = true;
      try {
        const response = await this.$apollo.query({
          query: TENANT_LEDGER_PMS_REPORT,
          variables: {
            organizationId,
            dateTimeFilter: {
              after: startOfTheDay,
              before: startOfTheDay,
            },
            pmsName: "sitelink",
          },
        });

        const csvData = response.data.tenantLedgerPmsReport.map((item) => {
          return {
            "Sitelink Site ID": item.siteId,
            "Sitelink Site Code": item.corpCode,
            "SD Start Date": item.sdStartDate,
            "Sitelink Unit ID": item.sdUnitId,
            "Sitelink Unit Name": item.sdUnitName,
            "SD End Date": item.sdEndDate,
            "Sitelink Charge ID (Name)": item.sdChargeId,
            "Sitelink Charge Amount": item.sdChargeAmount,
            "SD Charge Reconciliation": item.sdChargeReconcilation
              ? "Yes"
              : "No",
            "Ledger ID": item.ledgerId,
          };
        });

        const csvContent = this.convertToCSV(csvData);
        const blob = new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `TenantLedgerReport_${dateOfReport}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
      } finally {
        this.isDownloading = false;
      }
    },
    convertToCSV(data) {
      if (!data || data.length === 0) {
        alert("No data available.");
      }
      const headers = Object.keys(data[0]).join(",");
      const rows = data.map((row) => Object.values(row).join(",")).join("\n");
      return `${headers}\n${rows}`;
    },
    openPopup(dateOfReport, organization_id) {
      if (dateOfReport) {
        this.currentReportDate = dateOfReport;
        this.selectedOrganizationId = organization_id;
        this.isPopupVisible = true;
      }
    },
    async sendEmail() {
      try {
        const response = await this.$apollo.query({
          query: SEND_TENANT_LEDGER_EMAIL,
          variables: {
            organizationId: this.currentOrganizationId,
            nowUtc: this.currentReportDate,
            email: this.emailId,
          },
        });
        if (response.data && response.data.sendEmail.status === "success") {
          this.addAlert({
            type: "success",
            message:
              response.data.sendEmail.message || "Email sent successfully",
            timeout: 5,
          });
        } else {
          this.addAlert({
            type: "error",
            message: "Unable to send email. Please try again.",
            timeout: 5,
          });
        }
      } catch (error) {
        console.log(error);
        this.addAlert({
          type: "error",
          message: "Unable to send email. Please try again.",
          timeout: 5,
        });
      } finally {
        this.isPopupVisible = false;
      }
    },
    cancel() {
      this.isPopupVisible = false;
    },
  },
};
</script>
<style scoped>
.btn-container {
  display: flex;
  gap: 1rem;
}
</style>
