<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: [Array, Object],
      default: null,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    min: {
      type: [String, Number],
      default: 0,
    },
  },
  watch: {
    min(newVal) {
      this.renderChartWithMin();
    },
    chartData: {
      handler() {
        this.renderChartWithMin();
      },
      deep: true,
    },
  },
  mounted() {
    this.renderChartWithMin();
  },
  methods: {
    renderChartWithMin() {
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: !!this.title,
          text: this.title,
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                min: Number(this.min), // Dynamically set the min value
              },
            },
          ],
        },
        onClick: this.handleClick,
      };
      this.renderChart(this.chartData, options);
    },
    handleClick(event, items) {
      if (items.length > 0) {
        const clickedData = this.chartData.labels[items[0]._index];
      }
    },
  },
};
</script>
