<template>
  <v-skeleton-loader :loading="loading" type="card">
    <v-card
      class="pt-3 relative black-border"
      :style="{ paddingBottom: !bottomTitle ? '8px' : '' }"
    >
      <div v-if="topTitle" class="pl-5">
        {{ topTitle }}
        <v-tooltip v-if="topTooltip" bottom color="black">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon small>mdi-information</v-icon>
            </span>
          </template>
          <span>{{ topTooltip }}</span>
        </v-tooltip>
      </div>
      <div v-if="topTitle" class="pl-5 py-2 text-h6">
        <span class="cursor-pointer" @click="emitClickEvent('top')">
          <div
            v-if="['Conversions % (MTD)', 'MTD Conversion'].includes(topTitle)"
            :style="{ color: getColorByPercentage(topValue) }"
          >
            {{ topValueWithUnit }}
          </div>
          <div v-else>
            {{ topValueWithUnit }}
          </div>
        </span>
      </div>
      <div v-if="bottomTitle" class="pl-5">
        {{ bottomTitle }}
        <v-tooltip v-if="bottomTooltip" bottom color="black">
          <template #activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-icon small>mdi-information</v-icon>
            </span>
          </template>
          <span>{{ bottomTooltip }}</span>
        </v-tooltip>
      </div>
      <div v-if="bottomTitle" class="pl-5 py-2 text-h6">
        <span class="cursor-pointer" @click="emitClickEvent('bottom')">
          {{ bottomValueWithUnit }}
        </span>
      </div>
      <div
        v-if="bottomTitle"
        :style="{ height: '10px', backgroundColor: avatarColor }"
      ></div>
      <v-avatar
        v-if="avatarColor"
        class="pa-2 avatar-container"
        :color="avatarColor"
        size="60"
      >
        <v-icon color="white">{{ avatar }}</v-icon>
      </v-avatar>
    </v-card>
  </v-skeleton-loader>
</template>
<script>
import { thousandSeparator } from "@tod-ui/helpers/strings";
import { getColorByPercentage } from "../helper";
export default {
  name: "DashboardTileV2",

  props: {
    heading: {
      type: String,
      default: "",
    },
    topTitle: {
      type: String,
      default: "",
    },
    bottomTitle: {
      type: String,
      default: "",
    },
    topTooltip: {
      type: String,
      default: "",
    },
    bottomTooltip: {
      type: String,
      default: "",
    },
    topValue: {
      type: [String, Number],
      default: 0,
    },
    bottomValue: {
      type: [String, Number],
      default: 0,
    },
    topUnit: {
      type: String,
      default: "",
    },
    bottomUnit: {
      type: String,
      default: "",
    },

    topClickEvent: {
      type: String,
      default: "",
    },
    bottomClickEvent: {
      type: String,
      default: "",
    },
    avatarColor: {
      type: String,
      default: "",
    },
    avatarName: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    topValueWithUnit() {
      if (!this.topValue) {
        if (this.topUnit === "$") {
          return "$ 0";
        } else {
          return "0 %";
        }
      }
      return this.topUnit === "$"
        ? `${this.topUnit} ${thousandSeparator(this.topValue)}`
        : `${this.topValue} ${this.topUnit}`;
    },
    bottomValueWithUnit() {
      if (!this.bottomValue) {
        if (this.bottomUnit === "$") {
          return "$ 0";
        } else {
          return "0 %";
        }
      }
      return this.bottomUnit === "$"
        ? `${this.bottomUnit} ${thousandSeparator(this.bottomValue)}`
        : `${this.bottomValue} ${this.bottomUnit}`;
    },

    avatar() {
      if (this.avatarName === "revenue") {
        return "mdi-finance";
      } else if (this.avatarName === "conversion") {
        return "fa-solid fa-funnel-dollar";
      } else if (this.avatarName === "penetration") {
        return "fa-solid fa-bullseye";
      }
      return null;
    },
  },
  methods: {
    emitClickEvent(type) {
      let value = "";
      if (type === "top") {
        value = this.topTitle ? this.topTitle : this.heading;
      } else if (type === "bottom") {
        value = this.bottomTitle;
      }
      value = value.replace(/\s/g, "").toLowerCase();
      this.$emit("valueClick", value);
    },
    getColorByPercentage,
  },
};
</script>
<style scoped>
.avatar-container {
  position: absolute;
  top: 16px;
  right: 16px;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
