<template>
  <ValidationObserver ref="validator" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(resolveIncident)">
      <h2>Inspection Report</h2>
      <ValidatedTextField
        v-model="survey.user"
        autofocus
        name="Please enter your name."
        rules="required|min:2|max:250"
      />

      <ValidationProvider
        v-slot="{ errors }"
        name="A visual inspection response"
        rules="required"
      >
        <v-radio-group
          v-model="survey.visualInspection"
          dense
          :error-messages="errors"
        >
          <p>
            Did you complete a visual inspection?
            <span class="required">*</span>
          </p>
          <v-radio label="Yes" value="yes" />
          <v-radio label="No" value="no" />
          <v-radio label="N/A" value="n/a" />
        </v-radio-group>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="An inspection description"
        rules="required"
      >
        <p
          :class="{
            'body-1': true,
            'mt-4': true,
            'red--text': errors.length > 0,
          }"
        >
          In the text area below, please describe the condition of the unit
          after the Visual Inspection.
          <span class="required">*</span>
        </p>
        <v-textarea
          v-model="survey.visualInspectionDescr"
          outlined
          solo
          :error-messages="errors"
          type="text"
        />
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="A break in response"
        rules="required"
      >
        <v-radio-group
          v-model="survey.breakInDetected"
          dense
          :error-messages="errors"
        >
          <p class="body-1">
            Did you detect a break in?
            <span class="required">*</span>
          </p>
          <v-radio label="Yes" value="yes" />
          <v-radio label="No" value="no" />
          <v-radio label="N/A" value="n/a" />
        </v-radio-group>
      </ValidationProvider>

      <v-radio-group
        v-if="!hasDisabledLienResponder"
        v-model="survey.gateAccessCode"
        dense
      >
        <p class="body-1">
          Did the tenant use the assigned Gate Access Code when they entered?
        </p>
        <v-radio label="Yes" value="yes" />
        <v-radio label="No" value="no" />
        <v-radio label="N/A" value="n/a" />
      </v-radio-group>

      <v-radio-group v-model="survey.damagedLock" dense>
        <p>Was there damage to the Lock?</p>
        <v-radio label="Yes" value="yes" />
        <v-radio label="No" value="no" />
        <v-radio label="N/A" value="n/a" />
      </v-radio-group>

      <v-radio-group v-model="survey.damagedDoor" dense>
        <p>Was there damage to the Door?</p>
        <v-radio label="Yes" value="yes" />
        <v-radio label="No" value="no" />
        <v-radio label="N/A" value="n/a" />
      </v-radio-group>

      <v-radio-group v-model="survey.footageInspection" dense>
        <p>Did you complete a Video Footage inspection?</p>
        <v-radio label="Yes" value="yes" />
        <v-radio label="No" value="no" />
        <v-radio label="N/A" value="n/a" />
      </v-radio-group>

      <p class="body-1 mt-4">
        In the text area below, please describe key details from the Video
        Footage Inspection.
      </p>
      <v-textarea
        v-model="survey.footageInspectionDescr"
        outlined
        solo
        name="footageInspectionDescr"
      />

      <v-card-actions>
        <v-btn color="success" type="submit"> Submit </v-btn>
        <v-btn color="grey" @click="$router.go(-1)"> Cancel </v-btn>
        <v-btn outlined @click="clear">Clear</v-btn>
      </v-card-actions>
    </form>
  </ValidationObserver>
</template>

<script>
import { computed, reactive } from "vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import ValidatedTextField from "../utils/ValidatedTextField.vue";
import useIncident from "./useIncident";
import useAlerts from "@tod-ui/composables/useAlerts";

export default {
  name: "FormIncidentSurvey",
  components: {
    ValidationObserver,
    ValidationProvider,
    ValidatedTextField,
  },
  setup() {
    const { incident, submitIncidentSurvey } = useIncident();
    const hasDisabledLienResponder = computed(
      () => incident.value?.hasDisabledLienResponder
    );
    const survey = reactive({});
    const { addAlert } = useAlerts();
    return { survey, hasDisabledLienResponder, submitIncidentSurvey, addAlert };
  },
  methods: {
    async resolveIncident() {
      try {
        const response = await this.submitIncidentSurvey(
          this.$apollo,
          this.survey
        );

        if (response?.status === "success") {
          // this.$router.go(-1);
          this.addAlert({
            type: "success",
            message: "Incident Resolved with a completed survey",
            timeout: 15, // clears after 15 seconds
          });
        }
      } catch (error) {
        this.addAlert({
          type: "error",
          message: "Error occurred while saving incident survey.",
          timeout: 10,
        });
      }
    },
    clear() {
      this.survey = {};
      this.$refs.validator.reset();
    },
  },
};
</script>
