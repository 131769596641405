<template>
  <v-autocomplete
    ref="autocomplete"
    v-model="model"
    :items="unitTypes"
    label="Unit Type"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import useFacility from "../authentication/useFacility";
import { displayUnitType } from "@tod-ui/helpers/unitType";
import { ORGANIZATION_UNIT_TYPES } from "./graphql";

export default {
  name: "SelectUnitType",
  props: {
    value: {
      validator: (prop) =>
        typeof prop === "string" ||
        typeof prop === "undefined" ||
        prop === null,
      required: true,
    },
  },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
  data: () => ({
    unitTypes: [],
  }),
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(unitTypeId) {
        this.$emit("input", unitTypeId);
      },
    },
  },
  apollo: {
    unitTypes: {
      query: ORGANIZATION_UNIT_TYPES,
      variables() {
        return { id: this.currentFacilityId };
      },
      update({ organization }) {
        return organization.unitTypes.map((unitType) => ({
          value: unitType.id,
          text: displayUnitType(unitType),
        }));
      },
    },
  },
  methods: {
    focus() {
      this.$refs.autocomplete.focus();
    },
  },
};
</script>
