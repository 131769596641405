<template>
  <LayoutPage title="Vacant Unit Report" :loading="loading">
    <template #actions>
      <SwitchRegionalView v-model="regionalView" />
    </template>

    <v-row>
      <v-col cols="3" md="6">
        <RegionAndFacilitySelect />
      </v-col>
      <v-col cols="3">
        <v-select
          v-model="selectedDuration"
          label="Duration"
          :items="duration"
          item-text="name"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <div class="d-flex justify-end">
      <DateTimeFilter
        v-model="dateTime"
        :ranges="['Today', '2d', '1m']"
        default="1m"
        custom
        inverted
      />
      <div style="width: 240px" class="ml-4">
        <v-text-field label="Search" outlined dense />
      </div>
    </div>

    <template v-if="regionalView">
      <RegionalViewTable
        :selected-facilities="selectedFacilities"
        :date-time="formattedDate"
        :cleaned-within-days="selectedDuration"
      />
    </template>

    <template v-else>
      <FacilityViewTable
        :table-headers="tableHeaders"
        :selected-facilities="selectedFacilities"
        :selected-duration="selectedDuration"
        :date-time="formattedDate"
      />
    </template>
  </LayoutPage>
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import DateTimeFilter from "@tod-ui/components/DateTimeFilter.vue";
import { formatToISODate, getMonth } from "@tod-ui/helpers/datetime";
import useDashboard from "../useDashboard";
import useUser from "@components/authentication/useUser";
import RegionAndFacilitySelect from "../RegionAndFacilitySelect.vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import SwitchRegionalView from "@atoms/SwitchRegionalView.vue";
import RegionalViewTable from "./RegionalViewReport.vue";
import FacilityViewTable from "./FacilityViewReport.vue";

export default {
  name: "PageVacantUnitReport",
  components: {
    LayoutPage,
    DateTimeFilter,
    RegionAndFacilitySelect,
    SwitchRegionalView,
    RegionalViewTable,
    FacilityViewTable,
  },

  setup() {
    const route = useRoute();
    const { defaultOrganization } = useUser();

    const { selectedFacilities } = useDashboard();
    const selectedDuration = ref(null);

    const regionalView = ref(false);

    const currentMonth = computed(() => {
      return getMonth(0, 0, defaultOrganization.value.timeZone);
    });
    const currentDate = computed(() => {
      const date = new Date().toISOString();
      return formatToISODate(date, defaultOrganization.value.timeZone);
    });
    const dateTime = ref({
      after: currentMonth.value,
      before: currentDate.value,
    });
    const formattedDate = computed(() => ({
      after: formatToISODate(
        dateTime.value.after,
        defaultOrganization.value.timeZone
      ),
      before: formatToISODate(
        dateTime.value.before,
        defaultOrganization.value.timeZone
      ),
    }));

    const durationFromUrl = Number(route.params.selectedDuration);
    if (durationFromUrl) {
      selectedDuration.value = durationFromUrl;
    }

    return {
      selectedFacilities,
      selectedDuration,
      regionalView,
      dateTime,
      formattedDate,
    };
  },
  data() {
    return {
      loading: false,
      duration: [
        { name: "visited within 7 days", value: 7 },
        { name: "visited within 30 days", value: 30 },
      ],
      tableHeaders: [
        { text: "Organization", value: "organizationName" },
        { text: "Facility", value: "facilityName" },
        { text: "Vacant Unit#", value: "totalVacantUnits" },
        { text: "Moved out on ", value: "movedOutOn" },
        { text: "Incident after move out on", value: "suspiciousIncidents" },
        { text: "Purpose of incident", value: "purposeOfVisit" },
        { text: "Cleaned on", value: "cleanedOn" },
      ],
    };
  },
};
</script>

<style scoped>
.table-container {
  max-width: 100%;
  overflow-x: auto;
}

.table-container td {
  min-width: 150px;
}

.total td {
  font-weight: bold;
}

.negative-value {
  color: red;
}

.positive-value {
  color: green;
}

.highlighted {
  background-color: rgba(14, 59, 110, 0.2);
}
</style>
