<template>
  <div v-if="currentFacilityId">
    <router-view />
  </div>
  <LayoutPage v-else title="Page not found" />
</template>

<script>
import LayoutPage from "@layout/LayoutPage.vue";
import useFacility from "../authentication/useFacility";

export default {
  name: "FacilityWrapper",
  components: { LayoutPage },
  setup() {
    const { currentFacilityId } = useFacility();
    return { currentFacilityId };
  },
};
</script>
