<template>
  <CardLayoutV2 title="Average Duration Per Visit (MTD)" :loading="loading">
    <FacilityVisitsChart
      :chart-data="chartData"
      @quadrantData="goToFacilityVisitsReport"
    />
  </CardLayoutV2>
</template>

<script>
import { VISIT_DURATION } from "@tod-ui/constants/dashboard";
import FacilityVisitsChart from "./FacilityVisitsChart.vue";
import CardLayoutV2 from "@layout/CardLayoutV2.vue";
import { CHART_COLORS } from "@tod-ui/constants/colors";

export default {
  components: {
    FacilityVisitsChart,
    CardLayoutV2,
  },
  props: {
    facilityVisits: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    groupedFacilityVisits() {
      const groups = {
        [VISIT_DURATION.LESS_THAN_2]: 0,
        [VISIT_DURATION.BETWEEN_2_AND_10]: 0,
        [VISIT_DURATION.BETWEEN_10_AND_20]: 0,
        [VISIT_DURATION.GREATER_THAN_20]: 0,
      };

      this.facilityVisits.forEach((facility) => {
        if (facility.avgVisitDurationMinute > 20) {
          groups[VISIT_DURATION.GREATER_THAN_20]++;
        } else if (
          facility.avgVisitDurationMinute >= 2 &&
          facility.avgVisitDurationMinute <= 10
        ) {
          groups[VISIT_DURATION.BETWEEN_2_AND_10]++;
        } else if (
          facility.avgVisitDurationMinute >= 10 &&
          facility.avgVisitDurationMinute < 20
        ) {
          groups[VISIT_DURATION.BETWEEN_10_AND_20]++;
        } else if (facility.avgVisitDurationMinute < 2) {
          groups[VISIT_DURATION.LESS_THAN_2]++;
        }
      });

      const sortedGroups = Object.entries(groups)
        .map(([key, value]) => ({ label: key, value }))
        .sort((a, b) => b.value - a.value);

      return sortedGroups;
    },
    chartData() {
      return {
        data: this.groupedFacilityVisits,
        backgroundColors: {
          [VISIT_DURATION.GREATER_THAN_20]: CHART_COLORS[0],
          [VISIT_DURATION.BETWEEN_10_AND_20]: CHART_COLORS[1],
          [VISIT_DURATION.BETWEEN_2_AND_10]: CHART_COLORS[2],
          [VISIT_DURATION.LESS_THAN_2]: CHART_COLORS[3],
        },
      };
    },
  },
  methods: {
    goToFacilityVisitsReport(quadrant) {
      this.$router.push({
        name: "FacilityVisitsReport",
        query: {
          highlight_column: "visitsDuration",
          select_option: quadrant.label,
        },
      });
    },
  },
};
</script>
