import gql from "graphql-tag";
import {
  locationFields,
  organizationFields,
  profileFields,
  userFields,
} from "../../graphql/fragments";

const featureFields = gql`
  fragment FeatureFields on OrganizationFeature {
    disabled
    feature {
      name
    }
  }
`;

export const AUTHENTICATE_USER = gql`
  mutation AuthenticateUser($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      user {
        ...UserFields
        defaultOrganization {
          id
          name
          type
          subtype
          timeZone
        }
      }
    }
  }
  ${userFields}
`;

export const GET_FACILITY = gql`
  query GetFacility($id: ID!) {
    organization(id: $id) {
      ...OrganizationFields
      features {
        ...FeatureFields
      }
      profile {
        ...ProfileFields
      }
      location {
        spots
      }
    }
  }
  ${organizationFields}
  ${featureFields}
  ${profileFields}
`;

export const FACILITY_USERS = gql`
  query FacilityUsers($id: ID, $roles: [String]!, $parentId: ID) {
    organizationUsers(id: $id, roles: $roles, parentId: $parentId) {
      id
      firstName
      lastName
      username
    }
  }
`;

export const UPDATE_FACILITY = gql`
  mutation UpdateFacility(
    $id: ID!
    $location: InputLocation
    $name: String!
    $timeZone: String!
    $type: String!
    $profile: InputFacilityProfile
  ) {
    updateFacility(
      id: $id
      location: $location
      name: $name
      timeZone: $timeZone
      type: $type
      profile: $profile
    ) {
      ...OrganizationFields
      features {
        disabled
        feature {
          name
        }
      }
      location {
        ...LocationFields
      }
      profile {
        ...ProfileFields
      }
    }
  }
  ${locationFields}
  ${organizationFields}
  ${profileFields}
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($username: String!) {
    changePassword(username: $username) {
      status
      message
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!, $token: String!) {
    updatePassword(password: $password, token: $token) {
      status
      message
    }
  }
`;
